import { useCallback, useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../client/ApiEndpoints";
import HttpClient from "../../client/HttpClient";
import { getDateRange } from "../../utilities/Statics";

const filterOptions = [
  { label: "This week", value: "thisweek" },
  { label: "This month", value: "thismonth" },
  { label: "This year", value: "thisyear" },
  { label: "Last week", value: "lastweek" },
  { label: "Last month", value: "lastmonth" },
  { label: "Custom date range", value: "custom" },
];

const useIncomeCard = ({ selectedIncomeOption, uuid, selectedDateRange }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const foundOption = filterOptions.find(
      (option) => option.label === selectedIncomeOption
    );
    const dateRange = getDateRange(selectedIncomeOption, selectedDateRange);

    console.log(dateRange, "dateRange");

    const getIncomeStats = async () => {
      try {
        const response = await HttpClient.post(
          `${API_ENDPOINTS.GET_INCOME_TOTAL}${uuid}`,
          {
            filter: foundOption.value,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }
        );
        setData(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getIncomeStats();
  }, [selectedIncomeOption, selectedDateRange]);

  return { data, loading, error };
};

export default useIncomeCard;
