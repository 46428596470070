import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  amountMinusWithSign,
  BanksList,
  formatNumberWithCommas,
} from "../../utilities/helper.jsx";
import Logo from "../../assests/images/icons/Black Logo 1.png";

const Amount = ({ amount, colorText }) => {
  const isNegative = amount < 0;
  const absoluteAmount = Math.abs(amount);
  const formattedAmount = formatNumberWithCommas(absoluteAmount);
  const displayAmount = isNegative
    ? `-$${formattedAmount}`
    : `$${formattedAmount}`;
  return (
    <span style={{ color: colorText ? colorText : "#1A124D" }}>
      {displayAmount}
    </span>
  );
};

const AccountBalancesCards = (props) => {
  const { dashboardDetails } = props;

  const currencyCode = localStorage.getItem("currencyCode");

  const { uuid } = useParams();
  const navigate = useNavigate();

  const [showAllAccounts, setShowAllAccounts] = useState(8);

  const handleClick = (item) => {

    navigate(`/accountdetails/${uuid}`, {
      state: {
        avatar: item?.avatar,
        accountName: item?.accountName,
        value: item?.value,
        accountNumber: item?.accountNumber,
        accountId: item?.accountUuid,
        isAkahu: item?.isAkahu,
        type: item?.accountType,
        currency: item?.currency,
        convertedAmount: item?.convertedAmount,
      },
    });
  };

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center mb-1 mt-1"
        style={{ width: "100%", marginBottom: "-22px" }}
      >
        <h4 className="fs-18 fw-500 lh-34 text-charcoal mb-0">
          Account balances
        </h4>
        <span
          onClick={() => navigate(`/accounts/${uuid}`)}
          className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{
            width: "93px",
            height: "25px",
            cursor: "pointer",
            borderRadius: "30px",
          }}
        >
          See all
        </span>
      </div>

      <div
        className="row-cols-lg-4"
        style={{
          gap: "18px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        }}
      >
        {dashboardDetails?.slice(0, showAllAccounts)?.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                borderRadius: "10px",
                boxShadow: "0 5px 30px 0px rgba(0, 0, 0, 0.1)",
                width: "100%",
                height: "136px",
              }}
            >
              <div
                onClick={() => handleClick(item)}
                className="bg-white pointer-cursor"
                style={{
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    padding: "8px 18px",
                  }}
                >
                  <div>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <p className="mb-1 fs-12 fw-400 inter text-dark-gray">
                          {item?.bankName?.trim() || item?.accountType}
                        </p>
                        <div>
                          {currencyCode !== item?.currency ? (
                            <div className="d-flex align-items-center gap-1">
                              <span
                                className="fs-22 fw-700 lh-30 mb-1 "
                                style={{
                                  color:
                                    item?.value < 0
                                      ? "#D04F4F"
                                      : BanksList?.find(
                                          (bank) =>
                                            bank?.name === item?.bankName
                                        )?.color,
                                }}
                              >
                                {item?.accountType === "INVESTMENT" ||
                                item?.accountType === "WALLET" ||
                                item?.accountType === "FOREIGN"
                                  ? `${amountMinusWithSign(
                                      item?.convertedAmount,
                                      currencyCode
                                    )} ${currencyCode}`
                                  : amountMinusWithSign(
                                      item?.convertedAmount,
                                      currencyCode
                                    )}
                              </span>

                              <span
                                style={{
                                  color: "#B8B8B8",
                                  fontSize: "11px",
                                  fontWeight: "500",
                                }}
                              >
                                |
                              </span>

                              <div
                                className="d-flex align-items-center"
                                style={{
                                  color: "#B8B8B8",
                                  fontSize: "11px",
                                  fontWeight: "500",
                                  float: "right",
                                }}
                              >
                                {amountMinusWithSign(
                                  item?.value,
                                  item?.currency
                                )}{" "}
                                {item?.currency}
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className={`mb-0`}>
                                <span
                                  className="fs-22 fw-700 lh-30 mb-1 "
                                  style={{
                                    color:
                                      BanksList?.find(
                                        (bank) => bank?.name === item?.bankName
                                      )?.color ?? "#1A124D",
                                  }}
                                >
                                  {item?.accountType === "INVESTMENT" ||
                                  item?.accountType === "WALLET" ||
                                  item?.accountType === "FOREIGN"
                                    ? `${amountMinusWithSign(
                                        item?.value,
                                        item?.currency
                                      )} ${item?.currency}`
                                    : amountMinusWithSign(
                                        item?.value,
                                        item?.currency
                                      )}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        {/* <h1
                          className="fs-22 fw-700 lh-30 mb-1 "
                          style={{ color: item?.color }}
                        >
                          <Amount
                            amount={item?.value}
                            colorText={
                              item?.value < 0
                                ? "#D04F4F"
                                : BanksList?.find(
                                    (bank) => bank?.name === item?.bankName
                                  )?.color
                            }
                          />
                        </h1> */}
                      </div>
                      <div>
                        <img
                          src={!isEmpty(item?.avatar) ? item?.avatar : Logo}
                          alt="ANZ Logo"
                          className="rounded-circle mt-1"
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    </div>
                    <p className="fs-10 fw-400 inter text-dark-gray mb-1">
                      Account number
                    </p>
                    <p className="fs-10 fw-400 inter  text-dark-gray mb-0">
                      {item?.akahuAccountNumber === null
                        ? "No account "
                        : item?.akahuAccountNumber}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor:
                      BanksList?.find((bank) => bank?.name === item?.bankName)
                        ?.color ?? "#1A124D",
                    padding: "8px 18px",
                  }}
                >
                  <p className="white-text fs-12 fw-600 inter m-0">
                    {item?.accountName}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="d-flex justify-content-end mt-2">
        {showAllAccounts == 8 ? (
          <span
            onClick={() => setShowAllAccounts(dashboardDetails?.length - 1)}
            className="fs-14 fw-700 blue-new pointer-cursor"
          >
            See all
          </span>
        ) : (
          <span
            onClick={() => setShowAllAccounts(8)}
            className="fs-14 fw-700 blue-new pointer-cursor"
          >
            See less
          </span>
        )}
      </div>
    </>
  );
};

export default AccountBalancesCards;
