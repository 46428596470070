import {
  categoryBackgroundCss,
  categoryColorForAll,
} from "../../utilities/Statics";
import { ReactComponent as CrossIcon } from "../../assests/images/icons/cross-icon.svg";
import {
  planMonthYear,
  selectPlanBackground,
  selectPlanBtnColor,
  selectPlanDescription,
  selectPlanNameAmount,
  selectPlanType,
} from "../../utilities/helper";
import { useState } from "react";
import weburls from "../../Weburls/weburls";

export const PopUpForUpdateDelete = ({
  handleUpdateCashflowYes,
  handleUpdateCashflowNo,
  updateOrDeleteText,
}) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Update"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">{updateOrDeleteText} cashflow?</h2>
        <p className="delete-popup-sub-text">
          Would you like to {updateOrDeleteText} your cashflow as well?
        </p>

        <div className="delete-popup-buttons">
          <button className="btn-delete" onClick={handleUpdateCashflowNo}>
            No
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-confirm" onClick={handleUpdateCashflowYes}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpForDeleteWithName = ({
  deleteButtonName,
  handleDeleteConfirm,
  handleDeleteCancel,
}) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Are you sure?</h2>
        <p className="delete-popup-sub-text">This cannot be un-done.</p>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={handleDeleteCancel}>
            Go back
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={handleDeleteConfirm}>
            {deleteButtonName}
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpDelete = ({ handleDeleteConfirm, handleDeleteCancel }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Are you sure?</h2>
        <p className="delete-popup-sub-text">This cannot be un-done.</p>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={handleDeleteCancel}>
            Go back
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={handleDeleteConfirm}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpNoYes = ({ title, subtitle, handleChange }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">{title}</h2>
        <p className="delete-popup-sub-text">{subtitle}</p>
        <div className="delete-popup-buttons">
          <button className="btn-delete" onClick={() => handleChange(false)}>
            No
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-confirm" onClick={() => handleChange(true)}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpYesNoRow = ({ title, subtitle, handleChange }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">{title}</h2>
        <p className="delete-popup-sub-text">{subtitle}</p>
        <div className="popup-buttons">
          <button className="btn-confirm" onClick={() => handleChange(true)}>
            Yes
          </button>
          <button className="btn-delete" onClick={() => handleChange(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpRuleUpdate = ({ handleChanges }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Apply to current transactions?</h2>
        <p className="delete-popup-sub-text">
          Would you like to apply this rule to all current transactions?
        </p>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={() => handleChanges(true)}>
            Yes
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={() => handleChanges(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpDeleteCategory = ({
  categoryEmoji,
  categoryName,
  categoryColor,
  handleDelete,
  handleCancel,
}) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Delete category?</h2>
        <p className="delete-popup-sub-text" style={{ marginBottom: "10px" }}>
          This will remove the category from all merchants and transactions and
          also delete the budget created for it.
        </p>
        <div className="button-blck">
          <div
            className="icon-tag"
            id={`Popover`}
            style={{ marginBottom: "16px" }}
          >
            {categoryName !== "Uncategorised" && (
              <div
                className={`icon-transaction-img ${
                  isHexColor(categoryColor)
                    ? ""
                    : categoryBackgroundCss(categoryColor)
                }`}
                style={{
                  background: categoryColorForAll(categoryColor),
                }}
              >
                {categoryEmoji}
              </div>
            )}
            <div className="content">
              <h6 className="icon-content">{categoryName}</h6>
            </div>
          </div>
        </div>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={() => handleCancel()}>
            Go back
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={() => handleDelete()}>
            Delete category
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpWhoopsGoals = ({
  planDetails,
  oneTimeOTP,
  onClose,
  uuid,
}) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="whoops-popup-content">
        <div className="whoops-popup-header">
          <h2 className="whoops-popup-title">Whoops! 😅</h2>
          <button onClick={onClose} className="whoops-close-button">
            <CrossIcon />
          </button>
        </div>
        <p className="whoops-popup-sub-text">
          It looks like you’ve exceeded the limits of your current plan.
        </p>
        <p className="whoops-popup-sub-text">
          Want to upgrade and unlock the full package of features we have to
          offer?
        </p>
        <p className="whoops-popup-sub-text">Below is your current plan.</p>
        <div
          className="plan-free"
          style={{ marginTop: "0px", backgroundColor: "#43B9F6" }}
        >
          {/* <div className={`plan-standard`}> */}
          <span className="payment-desc" style={{ fontSize: "20px", marginBottom:"-5px" }}>
            Free
          </span>
          <p className="pur-plan-para">
            Take control of your finances, for free, forever. Enjoy access to
            the dashboard, budget, money insights, create 1 goal, view
            transactions and accounts.
          </p>
          <div
            className="update-plan fs-14"
            style={{
              color: "#43B9F6",
            }}
            onClick={() =>
              window.open(
                `https://account.budgetbuddie.com/manageAccountLogin?userToken=${oneTimeOTP}&userUuid=${uuid}`,
                "_blank"
              )
            }
          >
            Update plan
          </div>
        </div>
      </div>
    </div>
  );
};

export const PopUpWhoops = ({ planDetails, oneTimeOTP, onClose, uuid }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="whoops-popup-content">
        <div className="whoops-popup-header">
          <h2 className="whoops-popup-title">Whoops! 😅</h2>
          <button onClick={onClose} className="whoops-close-button">
            <CrossIcon />
          </button>
        </div>
        <p className="whoops-popup-sub-text">
          It looks like you’ve exceeded the limits of your current plan.
        </p>
        <p className="whoops-popup-sub-text">
          Want to upgrade and unlock the full package of features we have to
          offer?
        </p>
        <p className="whoops-popup-sub-text">Upgrade your plan below.</p>
        <div
          className={`${
            planDetails && selectPlanBackground(planDetails.planType)
          }`}
          style={{ marginTop: "0px" }}
        >
          {/* <div className={`plan-standard`}> */}
          <span className="payment-desc" style={{ fontSize: "20px" }}>
            {planDetails &&
              selectPlanNameAmount(planDetails.planType, planDetails.amount)}
            <span className="payment-monthYear">
              {planDetails && planMonthYear(planDetails.planType)}
            </span>
          </span>
          <p className="pur-plan-para">
            {planDetails && selectPlanDescription(planDetails.planType)}
          </p>
          <div
            className="update-plan"
            style={{
              color: `${
                planDetails && selectPlanBtnColor(planDetails.planType)
              }`,
            }}
            onClick={() =>
              window.open(
                `https://account.budgetbuddie.com/manageAccountLogin?userToken=${oneTimeOTP}&userUuid=${uuid}`,
                "_blank"
              )
            }
          >
            {" "}
            Update plan
          </div>
        </div>
      </div>
    </div>
  );
};

export const PopUpWhoopsAccount = ({
  data,
  plan,
  handleAccountDisconnect,
  upgradePlan,
}) => {
  const [loadingIndex, setLoadingIndex] = useState(null);

  const handleDisconnect = async (account, index) => {
    setLoadingIndex(index);
    try {
      await handleAccountDisconnect(account);
    } catch (error) {
      console.error("Failed to disconnect account:", error);
    } finally {
      setLoadingIndex(null);
    }
  };

  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="whoops-account-popup-content">
        <div className="whoops-popup-header">
          <h2 className="whoops-popup-title">Whoops! 😅</h2>
        </div>
        <p className="whoops-popup-sub-text">
          It looks like you’ve exceeded more then {plan === "Basic" ? "2" : "5"}{" "}
          connections.
        </p>
        <p className="whoops-popup-sub-text">
          Upgrade your plan or disconnect some connections below.
        </p>
        <div>
          {data?.length > 0 &&
            data?.map((it, index) => (
              <div className="whoops-account-box">
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div
                    className="investment-connection-img"
                    style={{ width: "40px" }}
                  >
                    <img
                      src={it?.accounts[0]?.img}
                      alt=""
                      style={{
                        objectFit: "contain",
                        width: "45px",
                        borderRadius: "100%",
                      }}
                    />
                  </div>
                  <div className="whoops-lcs-inner">
                    <div className="whoops-live-connection">
                      {it?.akahuAccountName}
                    </div>
                  </div>
                </div>
                <div
                  className="whoops-account-number"
                  onClick={() => handleDisconnect(it, index)}
                  style={{ cursor: "pointer" }}
                >
                  {loadingIndex === index ? (
                    <div className="cupertino-loader"></div>
                  ) : (
                    "Disconnect"
                  )}
                </div>
              </div>
            ))}
        </div>
        <button className="whoops-comn-btn" onClick={() => upgradePlan()}>
          Upgrade plan
        </button>
      </div>
    </div>
  );
};

export const PopUpDeleteCutomAll = ({
  title,
  subTitle,
  deleteButtonName,
  handleDeleteConfirm,
  handleDeleteCancel,
}) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">{title}</h2>
        <p className="delete-popup-sub-text">{subTitle}</p>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={handleDeleteCancel}>
            Go back
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={handleDeleteConfirm}>
            {deleteButtonName}
          </button>
        </div>
      </div>
    </div>
  );
};
