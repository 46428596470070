import React, { useEffect, useState } from "react";
import PageTitle from "../PageTitle";
import UserImageInfoToggle from "../UserImageInfoToggle";
import AddIcon from "../../assests/images/icons/white-add-plus.svg";
import "./EditBudgetMonthly.css";
import OverlayContainer from "../OverlayContainer/OverlayContainer";
import { Loading } from "../Loading/Loading";
import GrayPencil from "../../assests/images/icons/gray-pencil.svg";
import AddIncomeExpenseModal from "../Innerpanel/BudgetSummery/CompactView/AddIncomeExpenseModal";
import { useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINTS } from "../../client/ApiEndpoints";
import HttpClient from "../../client/HttpClient";
import {
  getCustomCodeToName,
  lightBackgroundColor,
} from "../../utilities/Statics";
import { toast } from "react-toastify";
import ViewSetting from "../ViewSetting/ViewSetting";
import { useRef } from "react";

const EditBudgetMonthly = () => {
  const [incomeModal, setIncomeModal] = useState(false);
  const [expenseModal, setExpenseModal] = useState(false);
  const [isHovered, setIsHovered] = useState(null);
  const [loadingCount, setLoadingCount] = useState(0);

  const [amount, setAmount] = useState("$");
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [selectedColorOption, setSelectedColorOption] = useState("Red");
  const [mainSelectedCategory, setMainSelectedCategory] = useState();
  const [type, setType] = useState("");
  const [loadingSingleBudgets, setLoadingSingleBudgets] = useState({});

  const navigate = useNavigate();
  const { uuid } = useParams();

  const isLoading = loadingCount > 0;

  const currentDate = new Date();
  const startYear = currentDate.getFullYear();
  const startMonth = currentDate.getMonth() + 1;
  const totalMonths = 13;
  const monthsArray = [];

  for (let i = 0; i < totalMonths; i++) {
    let year = startYear + Math.floor((startMonth - 1 + i) / 12);
    let month = ((startMonth - 1 + i) % 12) + 1;
    monthsArray.push({ year, month });
  }
  function formatAmount(amount) {
    if (amount === 0) return "$0";

    const sign = amount > 0 ? "" : "-";
    const absoluteAmount = Math.abs(amount);

    if (absoluteAmount % 1 === 0) {
      return `${sign}$${absoluteAmount.toLocaleString()}`;
    } else {
      let [integerPart, decimalPart] = absoluteAmount.toFixed(2).split(".");
      return `${sign}$${Number(integerPart).toLocaleString()}.${decimalPart}`;
    }
  }
  // INCOME
  const [incomeBudgetAmounts, setIncomeBudgetAmounts] = useState({});
  const [incomeGroupedBudgets, setincomeGroupedBudgets] = useState({});
  const [incomeTotalBudgets, setIncomeTotalBudgets] = useState({});
  const [completeBudgetDataIncome, setCompleteBudgetDataIncome] = useState([]);

  // EXPENSES
  const [expenseBudgetAmounts, setExpenseBudgetAmounts] = useState({});
  const [expenseGroupedBudgets, setExpenseGroupedBudgets] = useState({});
  const [expenseTotalBudgets, setExpenseTotalBudgets] = useState({});
  const [tempBudgetAmount, setTempBudgetAmount] = useState({});
  const [completeBudgetDataExpense, setCompleteBudgetDataExpense] = useState(
    []
  );

  const fetchIncomeAllData = async () => {
    // Fetch income budget data
    const incomeResponse = await HttpClient.get(
      `${API_ENDPOINTS.GET_INCOME_BUDGET}${uuid}`
    );

    if (incomeResponse?.data) {
      const allIncome = incomeResponse.data.income.monthlyData;
      const groupedBudget = {};
      setCompleteBudgetDataIncome(allIncome);

      allIncome.forEach((budget) => {
        if (!groupedBudget[budget.income_uuid]) {
          groupedBudget[budget.income_uuid] = {
            name: budget.income_name,
            color: budget.category_colour,
            emoji: budget.category_emoji,
            entries: {},
          };
        }
        groupedBudget[budget.income_uuid].entries[
          `${budget.year}-${budget.month}`
        ] = budget;
      });
      setincomeGroupedBudgets(groupedBudget);

      const initialAmounts = {};
      const totals = {};

      Object.values(groupedBudget).forEach((budgetGroup) => {
        monthsArray.forEach(({ year, month }) => {
          const key = `${year}-${month}`;
          const entry = budgetGroup.entries[key];

          if (entry) {
            initialAmounts[`${entry.income_uuid}-${key}`] = entry.budget_amount;
          }

          if (!totals[key]) totals[key] = 0;
          totals[key] += entry?.budget_amount || 0;
        });
      });

      setIncomeBudgetAmounts(initialAmounts);
      setIncomeTotalBudgets(totals);
    }
  };

  const fetchExpenseAllData = async () => {
    // Fetch expense budget data
    const expenseResponse = await HttpClient.get(
      `${API_ENDPOINTS.GET_EXPENSE_BUDGET}${uuid}`
    );

    if (expenseResponse?.data) {
      const allExpenses = expenseResponse.data.expenses.monthlyData;
      setCompleteBudgetDataExpense(allExpenses);
      const groupedBudget = {};

      allExpenses.forEach((budget) => {
        if (!groupedBudget[budget.expense_uuid]) {
          groupedBudget[budget.expense_uuid] = {
            name: budget.expense_name,
            color: budget.category_colour,
            emoji: budget.category_emoji,
            entries: {},
          };
        }
        groupedBudget[budget.expense_uuid].entries[
          `${budget.year}-${budget.month}`
        ] = budget;
      });
      setExpenseGroupedBudgets(groupedBudget);

      const initialAmounts = {};
      const totals = {};

      Object.values(groupedBudget).forEach((budgetGroup) => {
        monthsArray.forEach(({ year, month }) => {
          const key = `${year}-${month}`;
          const entry = budgetGroup.entries[key];

          if (entry) {
            initialAmounts[`${entry.expense_uuid}-${key}`] =
              entry.budget_amount;
          }

          if (!totals[key]) totals[key] = 0;
          totals[key] += entry?.budget_amount || 0;
        });
      });

      setExpenseBudgetAmounts(initialAmounts);
      setExpenseTotalBudgets(totals);
    }
  };

  const fetchAllBudgetData = async () => {
    setLoadingCount(true);
    try {
      await fetchIncomeAllData();

      await fetchExpenseAllData();
    } finally {
      setLoadingCount(false);
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchAllBudgetData();
    }
  }, [uuid]);

  const handleBudgetAmountChange = (e, uuid, year, month) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    const key = `${uuid}-${year}-${month}`;
    setTempBudgetAmount((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleBudgetAmountSave = async (
    e,
    uuid,
    year,
    month,
    isIncomeExpense
  ) => {
    const key = `${uuid}-${year}-${month}`;
    const newValue = tempBudgetAmount[key];
    if (!newValue || isNaN(newValue)) return;
    setLoadingSingleBudgets((prev) => ({
      ...prev,
      [key]: true,
    }));
    let budget;
    if (isIncomeExpense === "income") {
      budget = incomeGroupedBudgets[uuid].entries[`${year}-${month}`];
    } else {
      budget = expenseGroupedBudgets[uuid].entries[`${year}-${month}`];
    }

    if (isIncomeExpense === "income") {
      await updateIncomeBudget(budget, newValue, year, month);
    } else {
      await updateExpenseBudget(budget, newValue, year, month);
    }
    setLoadingSingleBudgets((prev) => ({
      ...prev,
      [key]: false,
    }));
    setTempBudgetAmount((prev) => {
      const updatedTemp = { ...prev };
      delete updatedTemp[key];
      return updatedTemp;
    });
  };

  // Handle Blur → Revert to original if user clicks outside
  const handleBudgetAmountBlur = (uuid, year, month) => {
    const key = `${uuid}-${year}-${month}`;
    setTempBudgetAmount((prev) => {
      const updatedTemp = { ...prev };
      delete updatedTemp[key];
      return updatedTemp;
    });
  };

  const updateExpenseBudget = async (data, editAmount, year, month) => {
    try {
      const isCurrent = localStorage.getItem("isAllFutureMonth") || "allMonth";
      const formattedAmount = editAmount.replace(/^\$/, "").replace(",", "");
      const payload = {
        userUuid: uuid,
        expenseUuid: data.expense_uuid ?? "",
        expenseAmount: formattedAmount,
        lastPaymentDate: data.last_payment_date ?? "",
        howOften: "Monthly",
        colour: data.colour ?? "",
        emoji: data.emoji ?? "",
        expenseName: data.expense_name ?? "",
        user_category_id: (data.user_category_id ?? 0).toString(),
        needOrWant: data.needWant ?? "Want",
        isSpreadAcrossPeriods: data.is_spread_across_periods ?? true,
        isCashflow: true,
        isDefaultCategory: data.is_default_category ?? "",
        startDate: "",
        endDate: "",
        month: month,
        year: year,
        isCurrentMonth: isCurrent === "monthOnly",
        isAllFutureMonth: isCurrent === "allMonth",
      };

      const url = `${API_ENDPOINTS.UPDATE_EXPENSE_BUDGET}`;
      const response = await HttpClient.put(url, payload);

      if (response?.data?.code === 1) {
        await fetchExpenseAllData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(`Something went wrong while updating expense budget`);
    } finally {
    }
  };

  const updateIncomeBudget = async (data, editAmount, year, month) => {
    try {
      const isCurrent = localStorage.getItem("isAllFutureMonth") || "allMonth";
      const formattedAmount = editAmount.replace(/^\$/, "").replace(",", "");
      const payload = {
        incomeName: data.income_name ?? "",
        incomeAmount: formattedAmount,
        lastPaymentDate: data.last_payment_date ?? "",
        howOften: "Monthly",
        colour: data.colour ?? "",
        isSpreadAcrossPeriods: data.is_spread_across_periods ?? "",
        emoji: data.emoji ?? "",
        isCashflow: true,
        startDate: "",
        endDate: "",
        month: month,
        year: year,
        isCurrentMonth: isCurrent === "monthOnly",
        isAllFutureMonth: isCurrent === "allMonth",
      };

      const url = `${API_ENDPOINTS.UPDATE_INCOME_BUDGET}${uuid}/${data.income_uuid}`;
      const response = await HttpClient.put(url, payload);

      if (response?.data?.code === 1) {
        await fetchIncomeAllData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(`Something went wrong while updating income budget`);
    } finally {
    }
  };

  const addIncome = async () => {
    setLoadingCount(true);
    setIncomeModal(false);

    try {
      const payload = {
        incomeName: mainSelectedCategory?.categoryName,
        incomeAmount: amount.replace("$", ""),
        howOften: "Monthly",
        colour: selectedColorOption,
        isSpreadAcrossPeriods: true,
        emoji: chosenEmoji,
        type: "CUSTOM",
        user_category_id: mainSelectedCategory?.categoryId || 0,
        startDate: "",
        endDate: "",
      };
      const url = `${API_ENDPOINTS.ADD_INCOME_BUDGET}${uuid}`;

      const response = await HttpClient.post(`${url}`, payload);
      setAmount("$");
      setSelectedColorOption("Red");
      setChosenEmoji("😁");
      setMainSelectedCategory();
      if (response?.data?.code === 1) {
        await fetchIncomeAllData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(`Something went wrong while adding income category`);
    } finally {
      setLoadingCount(false);
    }
  };

  const addExpense = async () => {
    setLoadingCount(true);
    setExpenseModal(false);
    try {
      const payload = {
        user_category_id: mainSelectedCategory?.categoryId || 0,
        expenseAmount: amount.replace("$", ""),
        howOften: "Monthly",
        colour: selectedColorOption,
        emoji: chosenEmoji,
        expenseName: mainSelectedCategory.categoryName || "",
        isDefaultCategory: true,
        needOrWant: "Want",
        isSpreadAcrossPeriods: true,
        startDate: "",
        endDate: "",
        isAutoCategories: false,
        type: type,
      };

      const url = `${API_ENDPOINTS.ADD_EXPENSE_BUDGET}${uuid}`;

      const response = await HttpClient.post(`${url}`, payload);
      setAmount("$");
      setSelectedColorOption("Red");
      setChosenEmoji("😁");
      setMainSelectedCategory();
      if (response?.data?.code === 1) {
        await fetchExpenseAllData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(`Something went wrong while adding expense category`);
    } finally {
      setLoadingCount(false);
    }
  };

  return (
    <div className="app-main__outer budget-summary-main">
      <div className="row">
        <div className="header-budget-main-div">
          <div className="header-budget-text-div" style={{ marginTop: "0px" }}>
            <PageTitle
              title={"Edit your budgets"}
              description={
                "Edit your budgets here all in one place, it’s that easy."
              }
            />
          </div>

          <div
            className="header-budget-sub-div"
            style={{ gap: "18px", alignItems: "center" }}
          >
            <div>
              <ViewSetting />
            </div>
            <UserImageInfoToggle customStyle={true} />
          </div>
        </div>
      </div>

      <div
        className="mt-5 pe-3"
        style={{
          width: "100%",
          overflowX: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <div style={{ width: "100%", overflowX: "auto", overflow: "unset" }}>
          <div
            className="d-flex"
            style={{ width: "2290px", overflow: "auto", marginBottom: "-6px" }}
          >
            <div className="" style={{ width: "230px" }}>
              <span className="fs-20 fw-500 text-dark-gray">
                Income budgets
              </span>
            </div>
            {monthsArray.map(({ year, month }) => (
              <div className="text-center mx-3" style={{ width: "126px" }}>
                <span className="fs-18 fw-500 text-medium-gray">
                  {" "}
                  {new Date(year, month - 1).toLocaleString("en-US", {
                    month: "short",
                    year: "numeric",
                  })}
                </span>
              </div>
            ))}
          </div>

          {Object.entries(incomeGroupedBudgets).map(
            ([income_uuid, item], index) => {
              const categoryColor = getCustomCodeToName(item.color);
              const backgroundColor = lightBackgroundColor(categoryColor);
              return (
                <div
                  className=" d-flex align-items-center my-3 py-2"
                  style={{
                    width: "2290px",
                    height: "60px",
                    overflow: "auto",
                    backgroundColor: "white",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ width: "230px" }}>
                    <div
                      className="d-flex align-items-center gap-2 ps-3"
                      onMouseEnter={() => setIsHovered(index)}
                      onMouseLeave={() => setIsHovered(null)}
                    >
                      <div
                        className="budget-icon"
                        style={{
                          backgroundColor: backgroundColor,
                          width: "36px",
                          height: "36px",
                        }}
                      >
                        <span style={{ fontSize: "16px" }}>{item?.emoji}</span>
                      </div>
                      <div>
                        <span className="fs-16 fw-500 text-dark-steel-gray">
                          {item?.name}
                        </span>
                      </div>
                      {isHovered === index && (
                        <img
                          className="pointer-cursor"
                          style={{ width: "11px", height: "11px" }}
                          src={GrayPencil}
                          alt="Gray pencil icon"
                          onClick={() => {
                            const completeBudgetData = completeBudgetDataIncome;
                            const year = startYear;
                            const month = startMonth;
                            const fetchBudget =
                              item.entries[`${year}-${month}`];
                            const isCompactView =
                              String(
                                localStorage.getItem("budget_view") || "compact"
                              ) === "compact"
                                ? "true"
                                : "false";
                            const isAllFutureMonth =
                              localStorage.getItem("isAllFutureMonth") ||
                              "allMonth";
                            navigate(
                              `/budget/${uuid}/budgetdetails/income/${income_uuid}`,
                              {
                                state: {
                                  year,
                                  month,
                                  isCompactView,
                                  isAllFutureMonth,
                                  fetchBudget,
                                  completeBudgetData,
                                },
                              }
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {monthsArray.map(({ year, month }) => {
                    const key = `${income_uuid}-${year}-${month}`;
                    return (
                      <div
                        className="text-center mx-3"
                        key={key}
                        style={{ width: "126px" }}
                      >
                        {loadingSingleBudgets?.[key] ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // borderRadius: "5px",
                              // border: "1px solid #F0F0F0",
                              padding: "5px 10px",
                              textAlign: "center",
                            }}
                          >
                            <div
                              className="spinner-border text-light-gray mt-1"
                              role="status"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <input
                            className="fs-16 w-100"
                            type="text"
                            value={
                              tempBudgetAmount[key] ??
                              `${incomeBudgetAmounts[key] || "0"}`
                            }
                            onBlur={() => {
                              handleBudgetAmountBlur(
                                income_uuid,
                                year,
                                month,
                                "income"
                              );
                            }}
                            onChange={(e) =>
                              handleBudgetAmountChange(
                                e,
                                income_uuid,
                                year,
                                month,
                                "income"
                              )
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                                handleBudgetAmountSave(
                                  e,
                                  income_uuid,
                                  year,
                                  month,
                                  "income"
                                );
                              }
                            }}
                            style={{
                              height: "35px",
                              width: "100%",
                              textAlign: "center",
                              border: "none",
                              outline: "none",
                              borderRadius: "10px",
                              backgroundColor: "#EFEDED",
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            }
          )}

          <div
            className="d-flex align-items-center"
            style={{
              backgroundColor: "rgba(70, 155, 136, 0.25)",
              borderRadius: "8px",
              width: "2290px",
              height: "55px",
              overflow: "auto",
            }}
          >
            <div className="" style={{ width: "230px" }}>
              <span className="fs-18 fw-500 text-deep-teal ps-3">
                Total income
              </span>
            </div>
            {monthsArray.map(({ year, month }) => (
              <div
                key={`${year}-${month}`}
                className="text-center mx-3"
                style={{ width: "126px" }}
              >
                <span className="fs-18 fw-700 text-deep-teal">
                  {formatAmount(incomeTotalBudgets[`${year}-${month}`] || 0)}
                </span>
              </div>
            ))}
          </div>
          <button
            onClick={() => {
              setAmount("$");
              setSelectedColorOption("Red");
              setChosenEmoji("😁");
              setMainSelectedCategory();
              setIncomeModal(true);
            }}
            style={{ height: "43px", width: "156px", gap: "17px" }}
            className="mt-3 border-0 bg-teal-green br-10 py-2 px-3 text-white fs-15 fw-500 d-flex align-items-center"
          >
            Add Income
            <img
              style={{ width: "20px", height: "20px" }}
              src={AddIcon}
              alt="Add Icon"
            />
          </button>
          <div
            className="mt-5 pe-3"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{ width: "100%", overflowX: "auto", overflow: "unset" }}
            >
              <div
                className="d-flex"
                style={{
                  width: "2290px",
                  overflow: "auto",
                  marginBottom: "-6px",
                }}
              >
                <div className="" style={{ width: "230px" }}>
                  <span className="fs-20 fw-500 text-dark-gray">
                    Expense budgets
                  </span>
                </div>
                {monthsArray.map(({ year, month }) => (
                  <div className="text-center mx-3" style={{ width: "126px" }}>
                    <span className="fs-18 fw-500 text-medium-gray">
                      {" "}
                      {new Date(year, month - 1).toLocaleString("en-US", {
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                ))}
              </div>

              {Object.entries(expenseGroupedBudgets).map(
                ([expense_uuid, item], index) => {
                  const categoryColor = getCustomCodeToName(item.color);
                  const backgroundColor = lightBackgroundColor(categoryColor);
                  return (
                    <div
                      className=" d-flex align-items-center my-3 py-2"
                      style={{
                        width: "2290px",
                        height: "60px",
                        overflow: "auto",
                        backgroundColor: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <div style={{ width: "230px" }}>
                        <div
                          className="d-flex align-items-center gap-2 ps-3"
                          onMouseEnter={() => setIsHovered(index)}
                          onMouseLeave={() => setIsHovered(null)}
                        >
                          <div
                            className="budget-icon"
                            style={{
                              backgroundColor: backgroundColor,
                              width: "36px",
                              height: "36px",
                            }}
                          >
                            <span style={{ fontSize: "16px" }}>
                              {item?.emoji}
                            </span>
                          </div>
                          <div>
                            <span className="fs-16 fw-500 text-dark-steel-gray">
                              {item?.name}
                            </span>
                          </div>
                          {isHovered === index && (
                            <img
                              className="pointer-cursor"
                              style={{ width: "11px", height: "11px" }}
                              src={GrayPencil}
                              alt="Gray pencil icon"
                              onClick={() => {
                                const completeBudgetData =
                                  completeBudgetDataExpense;
                                const year = startYear;
                                const month = startMonth;
                                const fetchBudget =
                                  item.entries[`${year}-${month}`];
                                const isCompactView =
                                  String(
                                    localStorage.getItem("budget_view") ||
                                      "compact"
                                  ) === "compact"
                                    ? "true"
                                    : "false";
                                const isAllFutureMonth =
                                  localStorage.getItem("isAllFutureMonth") ||
                                  "allMonth";
                                navigate(
                                  `/budget/${uuid}/budgetdetails/expense/${expense_uuid}`,
                                  {
                                    state: {
                                      year,
                                      month,
                                      isCompactView,
                                      isAllFutureMonth,
                                      fetchBudget,
                                      completeBudgetData,
                                    },
                                  }
                                );
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {monthsArray.map(({ year, month }) => {
                        const key = `${expense_uuid}-${year}-${month}`;
                        return (
                          <div
                            className="text-center mx-3"
                            key={key}
                            style={{ width: "126px" }}
                          >
                            {loadingSingleBudgets?.[key] ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  // borderRadius: "5px",
                                  // border: "1px solid #F0F0F0",
                                  padding: "5px 10px",
                                  textAlign: "center",
                                }}
                              >
                                <div
                                  className="spinner-border text-light-gray mt-1"
                                  role="status"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <input
                                className="fs-16 w-100"
                                type="text"
                                value={
                                  tempBudgetAmount[key] ??
                                  `${expenseBudgetAmounts[key] || "0"}`
                                }
                                onBlur={() => {
                                  handleBudgetAmountBlur(
                                    expense_uuid,
                                    year,
                                    month,
                                    "expense"
                                  );
                                }}
                                onChange={(e) =>
                                  handleBudgetAmountChange(
                                    e,
                                    expense_uuid,
                                    year,
                                    month,
                                    "expense"
                                  )
                                }
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.target.blur();
                                    handleBudgetAmountSave(
                                      e,
                                      expense_uuid,
                                      year,
                                      month,
                                      "expense"
                                    );
                                  }
                                }}
                                style={{
                                  height: "35px",
                                  width: "100%",
                                  textAlign: "center",
                                  border: "none",
                                  outline: "none",
                                  borderRadius: "10px",
                                  backgroundColor: "#EFEDED",
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              )}

              <div
                className="d-flex align-items-center"
                style={{
                  backgroundColor: "rgba(208, 79, 79, 0.25)",
                  borderRadius: "8px",
                  width: "2290px",
                  height: "55px",
                  overflow: "auto",
                }}
              >
                <div className="" style={{ width: "230px" }}>
                  <span className="fs-18 fw-500 text-deep-red ps-3">
                    Total expenses
                  </span>
                </div>
                {monthsArray.map(({ year, month }) => (
                  <div
                    key={`${year}-${month}`}
                    className="text-center mx-3"
                    style={{ width: "126px" }}
                  >
                    <span className="fs-18 fw-700 text-deep-red">
                      {formatAmount(
                        expenseTotalBudgets[`${year}-${month}`] || 0
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              setAmount("$");
              setSelectedColorOption("Red");
              setChosenEmoji("😁");
              setMainSelectedCategory();
              setExpenseModal(true);
            }}
            style={{ height: "43px", width: "166px", gap: "17px" }}
            className="mt-3 border-0 bg-rose-red br-10 py-2 px-3 text-white fs-15 fw-500 d-flex align-items-center"
          >
            Add Expense
            <img
              style={{ width: "20px", height: "20px" }}
              src={AddIcon}
              alt="Add Icon"
            />
          </button>

          <div
            className="mt-5 pe-3"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{ width: "100%", overflowX: "auto", overflow: "unset" }}
            >
              <div
                className="d-flex"
                style={{
                  width: "2290px",
                  overflow: "auto",
                  marginBottom: "-6px",
                }}
              >
                <div className="" style={{ width: "230px" }}>
                  <span className="fs-20 fw-500 text-dark-gray">
                    Money left over
                  </span>
                </div>
                {monthsArray.map(({ year, month }) => (
                  <div className="text-center mx-3" style={{ width: "126px" }}>
                    <span className="fs-18 fw-500 text-medium-gray">
                      {" "}
                      {new Date(year, month - 1).toLocaleString("en-US", {
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                ))}
              </div>

              <div
                className=" d-flex align-items-center my-3 py-2"
                style={{
                  width: "2290px",
                  height: "60px",
                  overflow: "auto",
                  backgroundColor: "white",
                  borderRadius: "8px",
                }}
              >
                <div style={{ width: "230px" }}>
                  <div className="d-flex align-items-center gap-2 ps-3">
                    <p className="mb-0 fs-16 fw-500 text-dark-steel-gray">
                      Money left over
                    </p>
                  </div>
                </div>
                {monthsArray.map(({ year, month }) => {
                  const amount =
                    (incomeTotalBudgets[`${year}-${month}`] || 0) -
                    (expenseTotalBudgets[`${year}-${month}`] || 0);
                  return (
                    <div
                      className="text-center mx-3"
                      style={{ width: "126px" }}
                    >
                      <input
                        className="fs-16 w-100"
                        type="text"
                        readOnly={true}
                        value={formatAmount(amount)}
                        style={{
                          height: "35px",
                          width: "100%",
                          textAlign: "center",
                          border: "none",
                          outline: "none",
                          borderRadius: "10px",
                          fontWeight: 600,
                          color: amount < 0 ? "#BE3C39" : "#437E43",
                          backgroundColor: amount < 0 ? "#F4E2E2" : "#E3F7E1",
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {incomeModal && (
        <AddIncomeExpenseModal
          openCompactAddModal={incomeModal}
          setOpenCompactAddModal={setIncomeModal}
          mainHead="Income"
          lowerCaseHeading="income"
          amount={amount}
          chosenEmoji={chosenEmoji}
          selectedColorOption={selectedColorOption}
          setAmount={setAmount}
          setChosenEmoji={setChosenEmoji}
          setSelectedColorOption={setSelectedColorOption}
          handleSaveAndFinish={addIncome}
          setMainSelectedCategory={setMainSelectedCategory}
          setType={setType}
        />
      )}

      {expenseModal && (
        <AddIncomeExpenseModal
          openCompactAddModal={expenseModal}
          setOpenCompactAddModal={setExpenseModal}
          mainHead="Expense"
          lowerCaseHeading="expense"
          amount={amount}
          chosenEmoji={chosenEmoji}
          selectedColorOption={selectedColorOption}
          setAmount={setAmount}
          setChosenEmoji={setChosenEmoji}
          setSelectedColorOption={setSelectedColorOption}
          handleSaveAndFinish={addExpense}
          setMainSelectedCategory={setMainSelectedCategory}
          setType={setType}
        />
      )}

      {isLoading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </div>
  );
};

export default EditBudgetMonthly;
