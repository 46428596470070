import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./help_us_screen.css";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import { motion } from "framer-motion";
import HttpClient from "../../../client/HttpClient";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import { toast } from "react-toastify";

const PromoCode = () => {
  const [promoValue, setPromoValue] = useState("");
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const applyPromoCode = async () => {
    const payload = {
      email: userData?.username,
      promoCode: promoValue,
      isRegister: true,
    };

    const resp = await HttpClient.post(API_ENDPOINTS.APPLY_PROMO_CODE, payload);

    console.log(resp, "resp");

    if (resp?.data?.code === 1) {
      navigate("/sign-up/promo-applied", {
        state: resp?.data?.planMessage,
      });
    }
    if (Number(resp?.data?.code) === 0) {
      toast.error(`${resp?.data?.message}`);
    }
  };

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <motion.div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <div className="logoCss text-center mb-4">
                <WordLogoBlack />
              </div>
              <div
                className="loginFormWrp"
                style={{
                  borderRadius: "20px",
                  padding: "20px 30px 20px 30px",
                  width: "450px",
                }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
              >
                <div className="transaction-categories mt-2">
                  <p
                    style={{
                      fontSize: "30px",
                      marginBottom: "10px",
                      fontWeight: "700",
                      textAlign: "center",
                      color: "#240F51",
                    }}
                  >
                    Have a promo code?
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      width: "367px",
                      marginBottom: "20px",
                      fontWeight: "400",
                      textAlign: "center",
                      color: "#292B2D",
                    }}
                  >
                    Have a promo code to use? Well you’re in luck! Get access to
                    all the features we have to offer for free, for an extended
                    period of time.
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "20px",
                      fontWeight: "400",
                      width: "367px",
                      textAlign: "center",
                      color: "#292B2D",
                    }}
                  >
                    Simply enter your promo code below and we’ll take care of
                    everything for you.
                  </p>
                  <div className="fs-18 fw-500" style={{ color: "#040C22" }}>
                    Promo code
                  </div>
                  <div className="form-outline">
                    <div
                      className="welcome-form-input-box"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <div
                        className="input-text-box-margin"
                        style={{
                          paddingTop: "13px",
                          paddingBottom: "13px",
                          width: "100%",
                        }}
                      >
                        <input
                          value={promoValue}
                          onChange={(e) => setPromoValue(e.target.value)}
                          name="email"
                          type="text"
                          id="form3Example3"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            border: "none",
                            outline: "none",
                            width: "100%",
                            fontFamily: "Neurialgrotesk",
                          }}
                        />
                      </div>
                    </div>
                    <p className="mt-1 error-message">
                      {/* {errors.email?.message} */}
                    </p>
                  </div>
                  <div className="modal-popup-btn-wrp">
                    <button
                      type="button"
                      onClick={() => applyPromoCode(false)}
                      style={{
                        marginTop: "20px",
                        marginBottom: "10px",
                        boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
                        color: "#fff",
                        background: "rgba(70, 155, 136, 1)",
                        width: "272px",
                        height: "45px",
                        padding: "9px 24px",
                        borderRadius: "10px",
                        border: "none",
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      Confirm promo code
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <span
                  onClick={() => navigate("/sign-up/priorities")}
                  className="fs-14 fw-700 pointer-cursor"
                  style={{ color: "#B7B8C1" }}
                >
                  Skip this step
                </span>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PromoCode;
