import React from "react";
import { Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import PopupColorModal from "../../ModalForm/Popup/PopupColorModel.jsx";
import {
  customColourOrName,
  customColourOrNameColour,
  howOftenData,
  moduleInfoPopUpData,
} from "../../utilities/Statics/index.jsx";
import EmojiPicker from "emoji-picker-react";
import Cinput from "../../Components/Cinput/index.jsx";
import PageTitle from "../../Components/PageTitle/index.jsx";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle/index.jsx";
import SectionTitle from "../../Components/SectionTitle.jsx/index.jsx";
import useAddGoal from "../../hooks/Goals/useAddGoal/useAddGoal.jsx";
import NZLogo from "../../assests/images/dashboard/anz.svg";
import DatePickerModal from "./DatePickerModal/DatePickerModal.jsx";
import moment from "moment";
import { ConnectedAccountsAddGoal } from "./ConnectedAccountsAddGoal.jsx";

const AddNewFinancial = () => {
  const {
    isPopupOpenInfo,
    togglePopupInfo,
    handleSubmit,
    addGoal,
    control,
    errors,
    goalData,
    handleChange,
    handleTabChange,
    isLinkedToAccount,
    setIsLinkedToAccount,
    selectedTab,
    isPopupOpenColor,
    selectedAccounts,
    togglePopupColor,
    handleOptionColorClick,
    showPicker,
    setShowPicker,
    chosenEmoji,
    pickerRef,
    handleEmojiClick,
    accountData,
    handleToggleSwitch,
    loading,
    setOpenDatePicker,
    openDatePicker,
  } = useAddGoal();

  return (
    <>
      <div className="app-main__outer financial-goals-main">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="row">
          <PageTitle
            title={"Add a goal"}
            description={
              "Let's set some goals, it’s always good having goals and something to aim for."
            }
          />
          <UserImageInfoToggle
            title={moduleInfoPopUpData.goals.title}
            description={moduleInfoPopUpData.goals.description}
            isPopupOpenInfo={isPopupOpenInfo}
            togglePopupInfo={togglePopupInfo}
          />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 add-new-goal-wrpp">
            <div className="edit-goal-ami">
              <form onSubmit={handleSubmit(addGoal)}>
                <div>
                  <SectionTitle title={"New goal"} />
                  {/* <div className="bold sub-head-text">New goal</div> */}
                  <div
                    className="edit-goal-box d-flex justify-content-between align-items-center"
                    // style={{ height: "59px" }}
                  >
                    <div className="fw-bold">Name</div>
                    <Controller
                      name="goalName"
                      control={control}
                      render={({ field }) => (
                        <Cinput {...field} type="text" width={"336px"} />
                      )}
                    />
                  </div>
                  <p className="error-message m-0">
                    {errors.goalName?.message}
                  </p>
                  <div className="edit-goal-box d-flex justify-content-between align-items-center">
                    <div className="fw-bold">Goal dollar value</div>
                    <Controller
                      name="value"
                      control={control}
                      render={({ field }) => (
                        <Cinput {...field} type="number" width={"226px"} />
                      )}
                    />
                  </div>
                  <p className="error-message m-0">{errors.value?.message}</p>
                  <div className="edit-goal-box">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="fw-bold">Start Date</div>
                      <input
                        type="text"
                        name="startDate"
                        value={
                          goalData.startDate &&
                          moment(goalData.startDate).format("DD/MM/YYYY")
                        }
                        readOnly
                        onClick={() => setOpenDatePicker(!openDatePicker)}
                        className="edit-goal-house py-2 add-goal"
                        style={{
                          border: "none",
                          cursor: "pointer",
                          width: "200px",
                        }}
                      />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <div className="fw-bold mt-1">Contributions</div>
                      <Controller
                        name="contribution"
                        control={control}
                        render={({ field }) => (
                          <Cinput {...field} type="number" width={"226px"} />
                        )}
                      />
                    </div>
                    <p className="error-message">
                      {errors.contribution?.message}
                    </p>
                    <div className="d-flex justify-content-between mt-2 align-items-center">
                      <div className="fw-bold">How often?</div>
                      <div className="account-input">
                        <select
                          className="how-often-select"
                          name="howOften"
                          onChange={handleTabChange}
                          value={selectedTab}
                          style={{ width: "160PX", height: "33px" }}
                        >
                          <option value="">Select</option>
                          {howOftenData &&
                            howOftenData.length > 0 &&
                            howOftenData.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="edit-goal-box">
                    <div className="d-flex justify-content-between mt-1 align-items-center">
                      <div className="fw-bold">Link to an account?</div>
                      <div
                        className="account-input"
                        style={{ width: "105px", height: "33px" }}
                      >
                        {isLinkedToAccount ? (
                          <div
                            className="link-account"
                            onClick={() =>
                              setIsLinkedToAccount(!isLinkedToAccount)
                            }
                          >
                            Yes
                          </div>
                        ) : (
                          <div
                            className="not-link-account"
                            onClick={() =>
                              setIsLinkedToAccount(!isLinkedToAccount)
                            }
                          >
                            No
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3 align-items-center">
                      {isLinkedToAccount ? (
                        <>
                          <div className="fw-bold">Account</div>
                          <div className="account-input d-flex align-items-center">
                            <button
                              type="button"
                              className="d-flex justify-content-between align-items-center fw-400 mb-0 fs-15"
                              style={{
                                background: "#0477B5",
                                padding: "5px 32px",
                                height: "31px",
                                borderRadius: "40px",
                                color: "#fff",
                                border: "none",
                              }}
                              aria-haspopup="true"
                              aria-expanded={isPopupOpenColor}
                            >
                              {selectedAccounts.length > 1
                                ? `${selectedAccounts.length} connected accounts`
                                : `${selectedAccounts.length} connected account`}
                            </button>
                            <img
                              className="ms-2"
                              style={{ width: "32px" }}
                              src={NZLogo}
                              alt="logo"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="current-bal d-flex align-items-center">
                            <div className="fw-bold">Current balance</div>
                            <Controller
                              name="currentBalance"
                              control={control}
                              rules={{
                                required: !isLinkedToAccount
                                  ? "Current balance is required"
                                  : false,
                              }}
                              render={({ field }) => (
                                <Cinput
                                  {...field}
                                  type="number"
                                  width={"226px"}
                                />
                              )}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <p className="error-message">
                      {errors.currentBalance?.message}
                    </p>
                  </div>
                  <div className="edit-goal-box d-flex justify-content-between">
                    <div className="fw-bold">Personalized colour</div>
                    <div className="color-option">
                      <div className="dropdown">
                        <button
                          className={`border-0 ${customColourOrNameColour(
                            goalData.colour
                          )} popoup-color`} 
                          onClick={togglePopupColor}
                          aria-expanded={isPopupOpenColor}
                          type="button"
                          aria-haspopup="true"
                          id="colorPickerButtonNewGoals"
                          style={{
                            color: "white",
                            // width: "122px",
                            height: "33px",
                            backgroundColor: customColourOrNameColour(
                              goalData.colour
                            ),
                          }}
                        >
                          {customColourOrName(goalData.colour)}
                        </button>
                      </div>
                    </div>
                    {/* <div
                        className="color-option"
                        style={{ marginRight: "30px" }}
                      >
                        <div className="dropdown">
                          <button
                            type="button"
                            className={`group-button ${colorCss(
                              goalData.colour
                            )} popoup-color`}
                            onClick={togglePopupColor}
                            aria-haspopup="true"
                            aria-expanded={isPopupOpenColor}
                          >
                            {goalData.colour ? goalData.colour : "Red"}
                          </button>

                          {isPopupOpenColor && (
                            <PopupColorModal
                              isOpen={isPopupOpenColor}
                              onClose={togglePopupColor}
                              title="Date Range"
                              options={[
                                "Red",
                                "Yellow",
                                "Blue",
                                "Purple",
                                "Orange",
                                "Pink",
                                "Light blue",
                                "Green",
                              ]}
                              selectedOption={goalData?.colorOption}
                              handleOptionClick={handleOptionColorClick}
                            />
                          )}
                        </div>
                      </div> */}
                  </div>
                  <div className="edit-goal-box d-flex justify-content-between flex-column position-relative">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div className="fw-bold">Personalized emoji</div>
                      <div
                        className="py-2 add-goal show-emoji"
                        style={{ width: "261px", height: "35px" }}
                        onClick={() => setShowPicker(!showPicker)}
                      >
                        {chosenEmoji}
                      </div>
                    </div>
                    {showPicker && (
                      <div
                        ref={pickerRef}
                        className="position-absolute bottom-100 z-3"
                      >
                        <EmojiPicker onEmojiClick={handleEmojiClick} />
                      </div>
                    )}
                  </div>
                  <div className="profile-dashboard mt-3 ms-0">
                    <button
                      type="submit"
                      className="comn-btn add-goals-btn"
                      style={{ width: "190px", height: "50px" }}
                    >
                      Add Goal
                      <i className="fa-solid fa-plus plus-icon-style"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="mt-3 d-flex align-items-center">
              <p className="fw-700 fs-14">Tip:</p>

              <p className="fs-14 fw-500 ms-1">
                Create separate bank accounts for each goal to keep on track of
                your progress.
              </p>

              <br />
            </div>
          </div>

          <div className="col-lg-6 col-md-12 add-new-goal-wrpp">
            {isLinkedToAccount && (
              <div>
                <SectionTitle title={"Link to an account"} />
                <div className="row g-3" style={{ marginTop: "-3px" }}>
                  {accountData?.map((ele, index) => (
                    <ConnectedAccountsAddGoal
                      key={index}
                      avatar={ele?.avatar}
                      accountName={ele?.accountName}
                      value={ele?.value}
                      currency={ele?.currency}
                      accountType={ele?.accountType}
                      convertedAmount={ele?.convertedAmount}
                      accountId={ele?.accountId}
                      width={"49%"}
                      selectedAccount={selectedAccounts}
                      id={ele?.accountId}
                      handleToggleSwitch={handleToggleSwitch}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {/* {loading && (
            <OverlayContainer>
              <div>
                <Loading />
              </div>
            </OverlayContainer>
          )} */}
        </div>
      </div>

      {openDatePicker && (
        <DatePickerModal
          openDatePicker={openDatePicker}
          setOpenDatePicker={setOpenDatePicker}
          handleChange={handleChange}
        />
      )}

      {isPopupOpenColor && (
        <PopupColorModal
          isOpen={isPopupOpenColor}
          targetId="colorPickerButtonNewGoals"
          onClose={togglePopupColor}
          title="Pick your colour"
          options={[
            "Red",
            "Yellow",
            "Blue",
            "Purple",
            "Orange",
            "Pink",
            "Light blue",
            "Light green",
            "Green",
          ]}
          selectedOption={goalData.colour}
          handleOptionClick={handleOptionColorClick}
        />
      )}
    </>
  );
};

export default AddNewFinancial;
