import { motion } from "framer-motion";
import { ReactComponent as Add_round_cross } from "../../../assests/images/icons/Add_round_cross.svg";
import useBudgetSetting from "../../../hooks/Budget/useBudgetSetting/useBudgetSetting";
import "./SettingPopUp.css";

const SettingPopUp = (props) => {
  const { onClose } = props;

  const {
    budgetView,
    budgetViewHandle,
    // preferredPeriodHandle,
    // preferredPeriodView,
    applyToAllFutureMonths,
    allMonthHandle,
  } = useBudgetSetting();

  return (
    <div className="modal-overlay">
      <motion.div
        className="modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
        style={{ padding: "24px" }}
      >
        <div className="modal-header">
          <h5 className="fs-25 fw-700 text-dark-gray">Budget settings</h5>
          <span className="pointer-cursor" onClick={onClose}>
            <Add_round_cross />
          </span>
        </div>
        <div className="modal-body">
          <div className="mt-3">
            <h6 className="fs-14 fw-700 text-dark-gray">Preferred view</h6>
            <div className="mb-4">
              <button
                style={{
                  padding: "5px 15px",
                  marginRight: "9px",
                }}
                className={`btn rounded-pill text-dark fs-15 fw-400 ${
                  budgetView === "classic"
                    ? "bg-teal-green text-white "
                    : " text-dark light-gray-border"
                } shadow-none`}
                onClick={() => budgetViewHandle("classic")}
              >
                Classic
              </button>
              <button
                style={{ padding: "5px 15px" }}
                className={`btn rounded-pill text-dark fs-15 fw-400 ${
                  budgetView === "compact"
                    ? "bg-teal-green text-white "
                    : " text-dark light-gray-border"
                } shadow-none`}
                onClick={() => budgetViewHandle("compact")}
              >
                Compact
              </button>
            </div>
          </div>

          {/* <div className="mt-3">
            <h6 className="fs-14 fw-700 text-dark-gray mb-0">
              Preferred view period
            </h6>
            <p className="fs-12 fw-400 text-light-gray-color mb-2">
              Budgets can only be edited when you’re in the monthly view.
            </p>

            <div className="d-flex gap-2 pe-5 mb-4">
              <button
                style={{
                  padding: "5px 15px",
                }}
                className={`btn rounded-pill fs-15 fw-400 ${
                  preferredPeriodView === "weekly"
                    ? "bg-teal-green text-white"
                    : "light-gray-border text-dark"
                } shadow-none`}
                onClick={() => preferredPeriodHandle("weekly")}
              >
                Weekly
              </button>
              <button
                style={{ padding: "5px 15px" }}
                className={`btn rounded-pill fs-15 fw-400 ${
                  preferredPeriodView === "monthly"
                    ? "bg-teal-green text-white"
                    : "light-gray-border text-dark"
                } shadow-none`}
                onClick={() => preferredPeriodHandle("monthly")}
              >
                Monthly
              </button>
              <button
                style={{
                  padding: "5px 15px",
                }}
                className={`btn rounded-pill fs-15 fw-400 ${
                  preferredPeriodView === "quarterly"
                    ? "bg-teal-green text-white"
                    : "light-gray-border text-dark"
                } shadow-none`}
                onClick={() => preferredPeriodHandle("quarterly")}
              >
                Quarterly
              </button>
              <button
                style={{
                  padding: "5px 15px",
                }}
                className={`btn rounded-pill fs-15 fw-400 ${
                  preferredPeriodView === "annual"
                    ? "bg-teal-green text-white"
                    : "light-gray-border text-dark"
                } shadow-none`}
                onClick={() => preferredPeriodHandle("annual")}
              >
                Annual
              </button>
            </div>
          </div> */}

          <div className="mt-3">
            <h6 className="mb-3 fs-14 fw-700 text-dark-gray">
              Apply budget amount changes to:
            </h6>

            <div
              class="d-flex align-items-center gap-2 light-gray-border br-15"
              style={{ padding: "10px 15px" }}
              onClick={() => allMonthHandle("monthOnly")}
            >
              <input
                class="form-check-input shadow-none"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                checked={applyToAllFutureMonths === "monthOnly"}
                readOnly
                style={{ width: "20px", height: "20px" }}
              />
              <div>
                <label
                  class="form-check-label fs-15 fw-500"
                  for="flexRadioDefault1"
                >
                  Current month only
                </label>
                <p className="mb-0">
                  Your budget amount changes will only be applied to the month
                  you’re editing.
                </p>
              </div>
            </div>
            <div
              class="d-flex align-items-center gap-2 light-gray-border br-15 mt-2"
              style={{ padding: "10px 15px" }}
              onClick={() => allMonthHandle("allMonth")}
            >
              <input
                class="form-check-input shadow-none"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked={applyToAllFutureMonths === "allMonth"}
                readOnly
                style={{ width: "20px", height: "20px" }}
              />
              <div>
                <label
                  class="form-check-label fs-15 fw-500"
                  for="flexRadioDefault2"
                >
                  All future months
                </label>
                <p className="mb-0">
                  Your budget amount changes will be applied to the month you
                  change and all future months as well.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="modal-footer mt-4">
          <button
            type="button"
            className="btn bg-teal-green text-white br-10"
            onClick={onClose}
          >
            Confirm preferences
          </button>
        </div> */}
      </motion.div>
    </div>
  );
};

export default SettingPopUp;
