import React, { useEffect, useState } from "react";
import {
  getDateRange,
  getFormattedDateRangeString,
} from "../../utilities/Statics";
import HttpClient from "../../client/HttpClient";
import { API_ENDPOINTS } from "../../client/ApiEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import weburls from "../../Weburls/weburls";
import CustomDateRangePopup from "../../ModalForm/Popup/CustomDateRangePopup";

const bgColors = ["#DD7802", "#469B88", "#377CC8", "#EED868", "#E78C9D"];

const filterOptions = [
  { label: "This week", value: "thisweek" },
  { label: "This month", value: "thismonth" },
  { label: "This year", value: "thisyear" },
  { label: "Last week", value: "lastweek" },
  { label: "Last month", value: "lastmonth" },
  { label: "Custom date range", value: "custom" },
];

const TopExpenses = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [selectedTopExpensesOption, setSelectedTopExpensesOption] =
    useState("This month");
  const [expensesPopOver, setExpensesPopOver] = useState(false);
  const [topExpenses, setTopExpense] = useState([]);
  const [allExpenses, setAllExpenses] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState();

  const selectedRange = getFormattedDateRangeString(selectedDateRange);

  const handleIncomeClick = (option) => {
    setSelectedTopExpensesOption(option);
    setExpensesPopOver(false);
  };

  const navigateHandle = (item) => {
    const foundOption = filterOptions.find(
      (option) => option.label === selectedTopExpensesOption
    );

    const itemCategory = allExpenses.find(
      (expense) => expense.master_category_name === item?.name
    );

    navigate(`/CategoryBreakDownDetails/${uuid}`, {
      state: {
        item: itemCategory,
        isExpensesBreakDown: foundOption?.value,
        activeTab: "Expenses",
      },
    });
  };

  useEffect(() => {
    const foundOption = filterOptions.find(
      (option) => option.label === selectedTopExpensesOption
    );
    const dateRange = getDateRange(
      selectedTopExpensesOption,
      selectedDateRange
    );

    const getIncomeStats = async () => {
      try {
        const response = await HttpClient.post(
          `${API_ENDPOINTS.GET_TOP_FIVE_EXPENSE}${uuid}`,
          {
            filter: foundOption.value,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }
        );

        setTopExpense(response?.data?.data || []);
      } catch (err) {
        console.log(err.message);
      }
    };

    const getMoneyTrendsCategoryBreakdownDetails = async () => {
      try {
        const response = await HttpClient.post(
          `${weburls.Get_Money_Trends_CategoryBreakdown_Details}${uuid}`,
          {
            filter: foundOption.value,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }
        );

        setAllExpenses(response?.data?.data);
      } catch (err) {
        console.log(err);
      }
    };

    getMoneyTrendsCategoryBreakdownDetails();

    getIncomeStats();
  }, [selectedTopExpensesOption, selectedDateRange]);

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center mb-1 mt-1"
        style={{ width: "100%" }}
      >
        <h4 className="fs-18 fw-500 lh-34 text-charcoal mb-0">
          Top 5 expenses
        </h4>
        <span
          className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{
            padding: "2px 10px",
            height: "25px",
            borderRadius: "20px",
            cursor: "pointer",
          }}
          id="TopExpensesPopover"
          onClick={() => setExpensesPopOver(!expensesPopOver)}
          aria-haspopup="true"
          aria-expanded={expensesPopOver}
        >
          {selectedTopExpensesOption === "Custom date range"
            ? selectedRange
            : selectedTopExpensesOption}
        </span>

        {expensesPopOver && (
          <CustomDateRangePopup
            isOpen={expensesPopOver}
            onClose={() => setExpensesPopOver(!expensesPopOver)}
            targetId="TopExpensesPopover"
            title="Date range"
            options={[
              "This week",
              "This month",
              "This year",
              "Last week",
              "Last month",
              "Custom date range",
            ]}
            selectedOption={expensesPopOver}
            handleOptionClick={handleIncomeClick}
            setSelectedDateRange={setSelectedDateRange}
          />
        )}
      </div>
      <div className="row g-2">
        {topExpenses && topExpenses.length > 0 ? (
          topExpenses.map((item, index) => (
            <div className="col-md-6" key={index}>
              <div
                className="br-10 d-flex justify-content-center align-items-center flex-column "
                onClick={() => navigateHandle(item)}
                style={{
                  backgroundColor: bgColors[index],
                  height: "107px",
                  boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <span
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.75)" }}
                    className="fs-16 fw-600 d-flex justify-content-center align-items-center w-32 h-32 rounded-circle p-2 border-none mx-2 text-dark-gray"
                  >
                    {index + 1}
                  </span>
                  <span className="fs-12 fw-400 lh-18 white-text mt-1">
                    {item?.name}
                  </span>
                  <h3 className="fs-18 fw-700 lh-25 white-text mb-0">
                    $
                    {item?.total_income_amount
                      ? Number(item.total_income_amount).toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )
                      : "0.00"}
                  </h3>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            className="selected-account text-center"
            style={{ marginTop: "12px" }}
          >
            <h4 className="fs-18 fw-600 text-dark">
              No expenses recorded yet{" "}
              <span role="img" label="thinking">
                🤔
              </span>
            </h4>
            <p className="fs-14 text-dark-gray">
              Connect your accounts to start tracking your spending. If you
              already have, your transactions may still be loading.
            </p>
            <button
              className="btn btn-primary cursor-pointer"
              style={{
                backgroundColor: "#6A36FF", // Match purple color
                color: "white",
                padding: "10px 20px",
                borderRadius: "10px",
                fontSize: "14px",
                fontWeight: "500",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/connectaccounts/${uuid}`)} // Handle navigation
            >
              Connect your accounts
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default TopExpenses;
