import React, { useState } from "react";
import "./CalendarMonthPopOver.css";
import { ReactComponent as LeftArrow } from "../../../assests/images/left_black_arrow.svg";
import { ReactComponent as RightArrow } from "../../../assests/images/right_black_arrow.svg";
import { Popover, PopoverBody } from "reactstrap";

const MonthYearCalendarPopover = ({
  target,
  tabMonth,
  tabYear,
  setTabMonth,
  setTabYear,
  baseMonth,
  baseYear,
  showPopover,
  setShowPopover,
  isEditScreen = false,
}) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [year, setYear] = useState(tabYear);

  const isMonthDisabled = (year, month) => {
    const targetDate = new Date(year, month - 1);
    const minDate = isEditScreen
      ? new Date(baseYear, baseMonth - 1)
      : new Date(baseYear - 1, baseMonth - 1);
    const maxDate = new Date(baseYear + 1, baseMonth - 1);

    return targetDate >= minDate && targetDate <= maxDate;
  };

  const handleMonthClick = (monthIndex) => {
    if (!isMonthDisabled(monthIndex)) {
      setTabMonth(monthIndex + 1);
      setShowPopover(false);
      setTabYear(year);
    }
  };
  const lastValidMonth = {};

  const handleYearChange = (newYear) => {
    if (newYear >= baseYear - 1 && newYear <= baseYear + 1) {
      lastValidMonth[year] = tabMonth;
      setYear(newYear);

      if (!isMonthDisabled(newYear, tabMonth)) {
        setTabMonth(lastValidMonth[newYear] ?? baseMonth);
      }
    }
  };

  return (
    <Popover
      placement="bottom"
      isOpen={showPopover}
      target={target}
      className="custom-popover-w-30"
    >
      <PopoverBody>
        <div>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              cursor: "pointer",
            }}
            // onClick={() => setShowPopover(!showPopover)}
          >
            <div className="popover-header">
              <span
                className={`year-change ${
                  year > baseYear - 1 ? "" : "disabled"
                }`}
                onClick={() => handleYearChange(year - 1)}
              >
                <LeftArrow style={{ height: "15px", width: "15px" }} />
              </span>
              <span className="current-year">{year}</span>
              <span
                className={`year-change ${
                  year < baseYear + 1 ? "" : "disabled"
                }`}
                onClick={() => handleYearChange(year + 1)}
              >
                <RightArrow style={{ height: "15px", width: "15px" }} />
              </span>
            </div>

            {/* Months Grid */}
            <div className="months-grid">
              {months.map((month, index) => {
                const isEnabled = isMonthDisabled(year, index);
                return (
                  <span
                    key={index}
                    className={`month-item ${
                      isEnabled
                        ? index + 1 === tabMonth
                          ? ""
                          : ""
                        : "disabled-month"
                    } ${index + 1 === tabMonth && isEnabled ? "selected" : ""}`}
                    onClick={() => handleMonthClick(index)}
                  >
                    {month}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default MonthYearCalendarPopover;
