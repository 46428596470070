import React, { useState } from "react";
import { motion } from "framer-motion";
import { ReactComponent as UnselectedCheck } from "../../../assests/images/icons/unselected-check.svg";
import { ReactComponent as SelectedCheck } from "../../../assests/images/icons/selected-check.svg";
import "./feedback_popUp.css";
import FeatureTextareaPopUp from "./FeatureTextareaPopUp";

const SelectFeaturePopUp = ({ isOpen, onClose, heading, desc,setIsModalTypeFeedbackOpen,selectedOption }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);

  const options = [
    { id: "dashboard", label: "Dashboard" },
    { id: "budget", label: "Budget" },
    // { id: "cashflow", label: "Cashflow" },
    { id: "money-insights", label: "Money insights" },
    // { id: "net-worth", label: "Net Worth" },
    // { id: "goals", label: "Goals" },
    // { id: "retirement-planner", label: "Retirement planner" },
    { id: "merchants", label: "Merchants" },
    { id: "transactions", label: "Transactions" },
    { id: "connecting-accounts", label: "Connecting accounts" },
    { id: "accounts", label: "Accounts" },
    { id: "settings", label: "Settings" },
  ];

  const handleOptionClick = (id) => {
    if (selectedOptions.includes(id)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== id));
    } else {
      setSelectedOptions([...selectedOptions, id]);
    }
  };

  const handleNext = () => {
    if (selectedOptions.length > 0) {
      setShowFeedbackPopup(true);
    }
  };

  const handleFeedbackNext = () => {
    if (currentIndex < selectedOptions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setShowFeedbackPopup(false);
      setCurrentIndex(0);
      setSelectedOptions([]);
      onClose();
    }
  };

  const handleFeedbackBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setShowFeedbackPopup(false);
    }
  };

  return (
    <>
      {!showFeedbackPopup ? (
        <div className="modal-overlay" isOpen={isOpen} toggle={onClose}>
          <motion.div
            className="modal-container"
            style={{
              padding: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "20px",
              maxWidth:"450px"
            }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
          >
            <div className="transaction-categories mt-2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="bold popover-live" style={{ fontSize: "24px" }}>
                  {heading}
                </div>
              </div>
              <p style={{ fontSize: "14px", marginBottom: "10px" }}>{desc}</p>
              <div
                className="feedback-options"
                style={{
                  maxHeight: "600px",
                  overflow: "auto",
                }}
              >
                {options.map((option) => (
                  <div
                    key={option.id}
                    className="feedback-option"
                    onClick={() => handleOptionClick(option.id)}
                    style={{
                      border: `2px solid ${
                        selectedOptions.includes(option.id)
                          ? "#14B8A6"
                          : "#E4E4E7"
                      }`,
                      cursor: "pointer",
                    }}
                  >
                    <p
                      className=""
                      style={{
                        margin: 0,
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#18181B",
                      }}
                    >
                      {option.label}
                    </p>
                    {selectedOptions.includes(option.id) ? (
                      <SelectedCheck />
                    ) : (
                      <UnselectedCheck />
                    )}
                  </div>
                ))}
              </div>
              <div
                className="flex-row d-flex justify-content-between align-items-center mb-2"
                style={{ width: "100%", gap:"10px" }}
              >
                <button
                  className="revoke-red-btn cursor-pointer"
                  onClick={onClose}
                  style={{
                    width: "210px",
                    marginTop: "20px",
                    marginBottom: "10px",
                    height: "44px",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Back
                </button>
                <button
                  className="change-password-btn cursor-pointer"
                  type="submit"
                  onClick={handleNext}
                  style={{
                    width: "210px",
                    marginTop: "20px",
                    marginBottom: "10px",
                    height: "44px",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      ) : (
        <FeatureTextareaPopUp
          heading={
            options.find((o) => o.id === selectedOptions[currentIndex])?.label
          }
          desc={`Provide your feedback below on the ${
            options.find((o) => o.id === selectedOptions[currentIndex])?.label
          } feature and we’ll get back to you.`}
          onClose={handleFeedbackBack}
          onNext={handleFeedbackNext}
          isLastStep={currentIndex === selectedOptions.length - 1}
          setIsModalTypeFeedbackOpen={setIsModalTypeFeedbackOpen}
          feedbackType={selectedOption}
          feedbackFeature={selectedOptions}
        />
      )}
    </>
  );
};

export default SelectFeaturePopUp;