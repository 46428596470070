import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axiosService from "../../../client/AxiosInstance";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
} from "../../../utilities/helper";
import { isCurrentPastMonth } from "../../../utilities/Statics";

const useBudgetDetails = () => {
  const { uuid } = useParams();
  const location = useLocation();
  const {
    year,
    month,
    isCompactView,
    applyToAllFutureMonths,
    fetchBudget,
    completeBudgetData,
  } = location.state || {};

  const navigate = useNavigate();
  const [allIncomeExpenseData, setAllIncomeExpenseData] =
    useState(completeBudgetData);
  const [budget, setBudget] = useState(fetchBudget);
  const [selectedColorOption, setSelectedColorOption] = useState("Red");
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);
  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [showPicker, setShowPicker] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState({});
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [needOrWant, setNeedOrWant] = useState("");
  const [chosenEmoji, setChosenEmoji] = useState(undefined);
  const [editAmount, setEditAmount] = useState(undefined);
  const [editName, setEditName] = useState(undefined);
  const [selectedFrequency, setSelectedFrequency] = useState(undefined);
  const [isSpreadToggled, setIsSpreadToggled] = useState(false);
  const [search, setSearch] = useState("");
  const [isPopupOpenTopExpenses, setPopupOpenTopExpenses] = useState(false);

  const [isFrequencyModalOpen, setFrequencyModalOpen] = useState(false);
  const [modals, setModals] = useState({
    merchantDetails: false,
    viewTransaction: false,
    addCategory: false,
  });
  const userId = localStorage.getItem("userUuid");
  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);
  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };
  const updateLoadingState = (isLoading) => {
    setLoadingCount((prevCount) =>
      isLoading ? prevCount + 1 : Math.max(prevCount - 1, 0)
    );
  };

  const [hasMore, setHasMore] = useState(true);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [oneOffCategory, setOneOffCategory] = useState([]);
  const [spliModalData, setSplitModalData] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isCashflowDelete, setIsCashflowDelete] = useState(false);
  const [isGrouped, setIsGrouped] = useState(true);
  const [progress, setProgress] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [adjustedAmount, setAdjustedAmount] = useState(0);

  const scrollContainerRef = useRef(null);
  const [page, setPage] = useState(1);
  const [showPopover, setShowPopover] = useState(false);

  const [tabYear, setTabYear] = useState(year);
  const [tabMonth, setTabMonth] = useState(month);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleBottomArrowClick = () => {
    setShowPopover((prev) => !prev);
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.SPLIT_TRANSACTION}${uuid}/${transactionId}/${type}`,
        splits
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_EXPENSE_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_INCOME_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_ALL_CATEGORIES}${uuid}`
      );
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
      setOneOffCategory(response?.data?.data?.transaction?.ONEOFF);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const currentUrl = window.location.href;

  const urlSegments = currentUrl.split("/");

  const expenseOrIncome = urlSegments[urlSegments.length - 2];

  const budgetId = urlSegments[urlSegments.length - 1].split("?")[0];

  const fetchIncomeBudgetData = useCallback(async () => {
    updateLoadingState(true);
    try {
      const response = await axiosService.get(
        `${API_ENDPOINTS.GET_INCOME_BUDGET}${uuid}`
      );

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        const allIncome = response.data.income.monthlyData;

        setAllIncomeExpenseData(allIncome);
        const data = allIncome.find(
          (b) =>
            b.month === tabMonth &&
            b.year === tabYear &&
            b.income_uuid === budgetId
        );
        setBudget(data);
      }
    } catch (error) {
      console.error("Error fetching income budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      updateLoadingState(false);
    }
  }, [uuid, budgetId]);

  const fetchExpenseBudgetData = useCallback(async () => {
    updateLoadingState(true);
    try {
      const response = await axiosService.get(
        `${API_ENDPOINTS.GET_EXPENSE_BUDGET}${uuid}`
      );

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        const allExpenses = response.data.expenses.monthlyData;

        const data = allExpenses.find(
          (b) =>
            b.month === tabMonth &&
            b.year === tabYear &&
            b.expense_uuid === budgetId
        );
        setBudget(data);
      }
    } catch (error) {
      console.error("Error fetching Expense budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      updateLoadingState(false);
    }
  }, [uuid, budgetId]);

  const fetchOneOffBudgetData = useCallback(async () => {
    updateLoadingState(true);
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_ONE_OFF_BUDGET}${uuid}`
      );

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        const allIncome = response?.data?.oneOffBudget.monthly;

        const data = allIncome.find((b) => b.uuid === budgetId);
        setBudget(data);
      }
    } catch (error) {
      console.error("Error fetching Expense budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      updateLoadingState(false);
    }
  }, [uuid, budgetId]);

  const handleOptionColorClick = (option, event) => {
    event.preventDefault();
    setSelectedColorOption(option);
    setPopupOpenColor(false);
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
  };

  const toggleFrequencyModal = () => {
    setFrequencyModalOpen((prev) => !prev);
  };

  const handleFrequencySelection = (frequency) => {
    setSelectedFrequency(frequency);
    setFrequencyModalOpen(false);
  };

  const togglePopupTopExpenses = () =>
    setPopupOpenTopExpenses(!isPopupOpenTopExpenses);

  const togglePopover = (transactionId) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [transactionId]: !prevState[transactionId],
    }));
  };

  const handleAddCategory = (transactionId) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [transactionId]: false,
    }));
    setShowAddCategory(!showAddCategory);
  };

  const updateTransactionCategory = async (transactionId, categoryId, type) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.UPDATE_CATEGORY}${uuid}`,
        data
      );
      toast.success(`${response?.data?.message}`);
      fetchTransactionsData(1, expenseOrIncome);
    } catch (error) {}
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;

    // Remove any characters that are not digits or decimal points
    const numericValue = value.replace(/[^0-9.]/g, "");

    // Ensure the value is a valid number or empty string
    if (/^\d*\.?\d*$/.test(numericValue)) {
      // Set the state with the clean numeric value (no commas)
      setEditAmount(numericValue);
    }
  };

  const handleNameChange = (e) => {
    setEditName(e.target.value);
  };

  const handleEditToggleClick = () => {
    setIsSpreadToggled(!isSpreadToggled);
  };

  const handleNeedToggleClick = () => {
    setNeedOrWant(!needOrWant);
  };

  const fetchTransactionsData = async (page, type) => {
    setLoading(true);
    try {
      let response;
      if (type === "income") {
        response = await HttpClient.get(
          `${API_ENDPOINTS.GET_INCOME_TRANSACTIONS}${uuid}/${budgetId}/10/${page}?search=${search}`
        );
      } else if (type === "expense") {
        response = await HttpClient.get(
          `${API_ENDPOINTS.GET_EXPENSE_TRANSACTIONS}${uuid}/${budgetId}/10/${page}?search=${search}`
        );
      } else {
        response = await HttpClient.get(
          `${API_ENDPOINTS.GET_ONE_OFF_TRANSACTIONS}${uuid}/${budgetId}/10/${page}?search=${search}`
        );
      }

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          autoClose: 2000,
        });
      } else if (response?.data) {
        const transactionsThisMonth =
          response?.data?.data?.transactions?.thisMonth || [];
        const transactionsRemainingMonths =
          response?.data?.data?.transactions?.remainingMonths || [];

        // Append the new transactions to the previous ones
        const groupedCurrentMonth = groupTransactionsByDate(
          transactionsThisMonth
        );
        const groupedRemainingMonths = groupTransactionsByMonth(
          transactionsRemainingMonths,
          remainingMonthsGrouped
        );

        // Use functional updates to merge new transactions with previous ones
        setCurrentMonthGrouped((prev) => ({
          ...prev,
          ...groupedCurrentMonth.data,
        }));

        setRemainingMonthsGrouped((prev) => ({
          ...prev,
          ...groupedRemainingMonths.data,
        }));

        // Check if more data exists
        setHasMore(
          transactionsThisMonth.length > 0 ||
            transactionsRemainingMonths.length > 0
        );
      }
    } catch (error) {
      toast.error("Error fetching transactions data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (uuid && page === 1) {
      fetchTransactionsData(1, expenseOrIncome);
    }
  }, [uuid, expenseOrIncome]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setPage((prev) => prev + 1);
          fetchTransactionsData(page + 1, expenseOrIncome);
        }
      }
    };
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore, page, expenseOrIncome]);

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  const flattenAndFilterTransactions = (groupedTransactions) => {
    let filteredTransactions = {};

    for (let month in groupedTransactions) {
      if (Array.isArray(groupedTransactions[month])) {
        if (!search.trim()) {
          filteredTransactions[month] = groupedTransactions[month];
        } else {
          const filteredForMonth = groupedTransactions[month].filter(
            (transaction) =>
              transaction.transactionName
                ?.toLowerCase()
                .includes(search.toLowerCase())
          );

          if (filteredForMonth.length > 0) {
            filteredTransactions[month] = filteredForMonth;
          }
        }
      }
    }

    return filteredTransactions;
  };

  const filteredCurrentMonthTransactions =
    flattenAndFilterTransactions(currentMonthGrouped);
  const filteredRemainingMonthTransactions = flattenAndFilterTransactions(
    remainingMonthsGrouped
  );

  useEffect(() => {
    let data;
    if (expenseOrIncome === "income") {
      data = allIncomeExpenseData.find(
        (b) =>
          b.month === tabMonth &&
          b.year === tabYear &&
          b.income_uuid === budgetId
      );
      setBudget(data);
    } else if (expenseOrIncome === "expense") {
      data = allIncomeExpenseData.find(
        (b) =>
          b.month === tabMonth &&
          b.year === tabYear &&
          b.expense_uuid === budgetId
      );
      setBudget(data);
    } else {
      data = budget;
    }
   
    const categoryType = data.category_type?.toLowerCase();

    const newAdjustedAmount =
      categoryType === "income" || categoryType === "expense"
        ? (data.budget_amount ?? 0) - (data.total_transaction_amount ?? 0)
        : (data?.amount ?? 0) - (data?.total_transaction_amount ?? 0);

    const newTotalAmount = data.total_transaction_amount ?? 0;

    let newProgress = 0;

    if (categoryType === "income" || categoryType === "expense") {
      if ((data.budget_amount ?? 0) === 0) {
        newProgress = newTotalAmount === 0 ? 0.0 : 100;
      } else {
        newProgress = (newTotalAmount / (data.budget_amount ?? 1)) * 100;
      }
    } else if (categoryType === "oneoff") {
      if ((data.amount ?? 0) === 0) {
        newProgress = newTotalAmount === 0 ? 0.0 : 100;
      } else {
        newProgress = (newTotalAmount / (data?.amount ?? 1)) * 100;
      }
    }

    newProgress = newProgress > 100 ? 100 : newProgress;

    // Update state variables
    setAdjustedAmount(newAdjustedAmount);
    setTotalAmount(newTotalAmount);
    setProgress(newProgress);

    // Set other UI states
    setChosenEmoji(data.category_emoji);
    const amount =
      data.category_type?.toLowerCase() === "expense"
        ? data.budget_amount
        : data.category_type?.toLowerCase() === "income"
        ? data.budget_amount
        : data.amount;
    setEditAmount(
      amount !== undefined ? `$${formatAmount(Math.abs(amount))}` : ""
    );

    const name =
      data.category_type?.toLowerCase() === "oneoff" ? data.name : "";
    setEditName(name);

    setSelectedColorOption(data.colour);
  }, [budget, tabMonth, tabYear]);

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [updateOrDeleteText, setUpdateOrDeleteText] = useState("");
  const [deleteCashFlowPopup, setDeleteCashFlowPopup] = useState(false);
  const [deleteCategoryPopup, setDeleteCategoryPopup] = useState(false);

  const handleSaveChanges = async () => {
    if (expenseOrIncome === "oneoff") {
      await updateIncomeOrExpenseBudget(true);
    } else {
      setUpdateOrDeleteText("Update");
      setShowUpdatePopup(true);
    }
  };

  function formatAmount(amount) {
    if (amount % 1 === 0) {
      return amount.toLocaleString();
    } else {
      let [integerPart, decimalPart] = amount.toFixed(2).split(".");
      return `${Number(integerPart).toLocaleString()}.${decimalPart}`;
    }
  }

  function formatIncomeAmount(amount) {
    if (amount === 0) return "$0";

    const sign = amount > 0 ? "" : "+";
    const absoluteAmount = Math.abs(amount);

    if (absoluteAmount % 1 === 0) {
      return `${sign}$${absoluteAmount.toLocaleString()}`;
    } else {
      let [integerPart, decimalPart] = absoluteAmount.toFixed(2).split(".");
      return `${sign}$${Number(integerPart).toLocaleString()}.${decimalPart}`;
    }
  }

  const handleDeleteBudget = () => {
    setShowDeletePopup(true);
  };

  const handleDeleteConfirm = async () => {
    setShowDeletePopup(false);
    if (expenseOrIncome === "income") {
      // setDeleteCashFlowPopup(true);
      const payload = {
        IsDeleteCategory: false,
        isCashflow: true,
      };
      deleteIncome(payload);
    } else if (expenseOrIncome === "expense") {
      // setDeleteCashFlowPopup(true);
      const payload = {
        isCashflow: true,
      };
      deleteExpense(payload);
    } else if (expenseOrIncome === "oneoff") {
      await deleteOneOff();
      setShowUpdatePopup(false);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };

  const handleUpdateCashflowYes = async () => {
    setShowUpdatePopup(false);
    if (updateOrDeleteText === "Delete") {
      await deleteOneOff();
    } else {
      if (expenseOrIncome === "income") {
        await updateIncomeOrExpenseBudget(true);
      } else if (expenseOrIncome === "expense") {
        updateExpenseBudget(true);
      }
    }
  };

  const handleUpdateCashflowNo = async () => {
    if (expenseOrIncome === "income") {
      await updateIncomeOrExpenseBudget(false);
    } else if (expenseOrIncome === "expense") {
      updateExpenseBudget(false);
    }
    setShowUpdatePopup(false);
  };

  const handleCashflowDeleteNo = (confirm) => {
    setDeleteCashFlowPopup(false);
    setIsCashflowDelete(confirm);
    setDeleteCategoryPopup(true);
  };

  const handleCategoryDeleteNo = (confirm) => {
    setDeleteCategoryPopup(false);
    if (expenseOrIncome === "income") {
      const payload = {
        IsDeleteCategory: confirm,
        isCashflow: isCashflowDelete,
      };
      deleteIncome(payload);
    } else if (expenseOrIncome === "expense") {
      const payload = {
        isCashflow: isCashflowDelete,
      };
      if (confirm) {
        deleteExpenseCategoryAndTransaction(payload);
      } else {
        deleteExpense(payload);
      }
    }
  };

  const updateIncomeOrExpenseBudget = async (isCashflow) => {
    try {
      const formattedAmount = editAmount.replace(/^\$/, "").replace(",", "");
      updateLoadingState(true);

      const checkMonthYear = isCurrentPastMonth(tabMonth, tabYear);

      const payload =
        expenseOrIncome === "income"
          ? {
              userUuid: uuid,
              incomeUuid: budgetId,
              lastPaymentDate: budget.last_payment_date ?? "",
              isSpreadAcrossPeriods: budget.is_spread_across_periods ?? "",
              incomeName: budget.income_name,
              emoji: chosenEmoji,
              colour: selectedColorOption,
              incomeAmount: formattedAmount,
              howOften: "Monthly",
              isCashflow: isCashflow,
              startDate: "",
              endDate: "",
              month: tabMonth,
              year: tabYear,
              isCurrentMonth: checkMonthYear
                ? applyToAllFutureMonths === "monthOnly"
                : true,
              isAllFutureMonth: checkMonthYear
                ? applyToAllFutureMonths === "allMonth"
                : false,
            }
          : {
              name: editName,
              amount: formattedAmount,
              colour: selectedColorOption,
              emoji: chosenEmoji,
            };

      const url =
        expenseOrIncome === "income"
          ? `${API_ENDPOINTS.UPDATE_INCOME_BUDGET}${uuid}/${budgetId}`
          : `${API_ENDPOINTS.UPDATE_ONE_OFF_BUDGET}${budgetId}/${uuid}`;

      const response = await HttpClient.put(url, payload);

      if (response?.data?.code === 1) {
        if (expenseOrIncome === "income") {
          await fetchIncomeBudgetData();
        }
        if (expenseOrIncome === "oneoff") {
          await fetchOneOffBudgetData();
        }
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      updateLoadingState(false);
    } catch (error) {
      toast.error(
        `Something went wrong while updating ${expenseOrIncome} category`
      );
    } finally {
      updateLoadingState(false);
    }
  };

  const updateExpenseBudget = async (isCashflow) => {
    try {
      updateLoadingState(true);
      const formattedAmount = editAmount.replace(/^\$/, "").replace(",", "");
      const checkMonthYear = isCurrentPastMonth(tabMonth, tabYear);

      const payload = {
        userUuid: uuid,
        expenseUuid: budgetId,
        expenseAmount: formattedAmount,
        lastPaymentDate: budget.last_payment_date ?? "",
        howOften: "Monthly",
        emoji: chosenEmoji,
        colour: selectedColorOption,
        expenseName: budget.expense_name ?? "",
        user_category_id: (budget.user_category_id ?? 0).toString(),
        needOrWant: budget.needWant ?? "Want",
        isSpreadAcrossPeriods: budget.is_spread_across_periods ?? true,
        isCashflow: isCashflow,
        isDefaultCategory: budget.is_default_category ?? "",
        startDate: "",
        endDate: "",
        month: tabMonth,
        year: tabYear,
        isCurrentMonth: checkMonthYear
          ? applyToAllFutureMonths === "monthOnly"
          : true,
        isAllFutureMonth: checkMonthYear
          ? applyToAllFutureMonths === "allMonth"
          : false,
      };
      const url = `${API_ENDPOINTS.UPDATE_EXPENSE_BUDGET}`;

      const response = await HttpClient.put(url, payload);

      if (response?.data?.code === 1) {
        await fetchExpenseBudgetData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      updateLoadingState(false);
    } catch (error) {
      toast.error(
        `Something went wrong while updating ${expenseOrIncome} category`
      );
    } finally {
      updateLoadingState(false);
    }
  };

  const deleteIncome = async (payload) => {
    try {
      const url = `${API_ENDPOINTS.DELETE_INCOME_BUDGET}${uuid}/${budgetId}`;

      const response = await HttpClient.delete(url, {
        data: payload,
      });

      if (response?.data?.code === 1) {
        navigate(`/budget/${uuid}`, { replace: true });
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${expenseOrIncome} category`
      );
    }
  };

  const deleteExpense = async (payload) => {
    try {
      const url = `${API_ENDPOINTS.DELETE_EXPENSE_BUDGET}${uuid}/${budgetId}`;
      const response = await HttpClient.delete(url, {
        data: payload,
      });

      if (response?.data?.code === 1) {
        navigate(`/budget/${uuid}`, { replace: true });
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${expenseOrIncome} category`
      );
    }
  };

  const deleteExpenseCategoryAndTransaction = async (payload) => {
    try {
      const url = `${API_ENDPOINTS.DELETE_EXPENSE_CATEGORY_TRANSACTION_BUDGET}${uuid}/${budgetId}`;
      const response = await HttpClient.delete(url, {
        data: payload,
      });

      if (response?.data?.code === 1) {
        navigate(`/budget/${uuid}`, { replace: true });
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${expenseOrIncome} category`
      );
    }
  };

  const deleteOneOff = async () => {
    try {
      const url = `${API_ENDPOINTS.DELETE_ONE_OFF_BUDGET}${budgetId}/${uuid}`;

      const response = await HttpClient.delete(url);

      if (response?.data?.code === 1) {
        navigate(`/budget/${uuid}`, { replace: true });
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(
        `Something went wrong while deleting ${expenseOrIncome} category`
      );
    }
  };
  const isLoading = loadingCount > 0;
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  return {
    scrollContainerRef,
    handleSaveChanges,
    handleDeleteBudget,
    isPopupOpenInfo,
    togglePopupInfo,
    showDeletePopup,
    handleDeleteCancel,
    handleDeleteConfirm,
    showUpdatePopup,
    updateOrDeleteText,
    handleUpdateCashflowNo,
    handleUpdateCashflowYes,
    deleteCashFlowPopup,
    handleCashflowDeleteNo,
    deleteCategoryPopup,
    handleCategoryDeleteNo,
    expenseOrIncome,
    togglePopupTopExpenses,
    isPopupOpenTopExpenses,
    formatAmount,
    totalAmount,
    adjustedAmount,
    progress,
    search,
    handleSearch,
    currentMonthGrouped,
    filteredCurrentMonthTransactions,
    remainingMonthsGrouped,
    filteredRemainingMonthTransactions,
    openModal,
    category,
    updateTransactionCategory,
    addNewCategory,
    icomecat,
    oneOffCategory,
    togglePopover,
    popoverOpen,
    showAddCategory,
    handleAddCategory,
    editAmount,
    handleAmountChange,
    selectedFrequency,
    toggleFrequencyModal,
    isSpreadToggled,
    handleEditToggleClick,
    handleNeedToggleClick,
    needOrWant,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleNameChange,
    editName,
    isFrequencyModalOpen,
    selectedColorOption,
    togglePopupColor,
    isPopupOpenColor,
    handleOptionColorClick,
    setShowPicker,
    showPicker,
    chosenEmoji,
    handleEmojiClick,
    handleFrequencySelection,
    modals,
    spliModalData,
    closeModal,
    splitTransaction,
    isLoading,
    loading,
    isGrouped,
    setIsGrouped,
    isCompactView,
    applyToAllFutureMonths,
    budget,
    fetchBudget,
    formatIncomeAmount,
    showPopover,
    setShowPopover,
    handleBottomArrowClick,
    setTabYear,
    setTabMonth,
    tabYear,
    tabMonth,
  };
};

export default useBudgetDetails;
