import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";

const useSettings = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [data, setData] = useState();
  const [toggleTab, setToggleTab] = useState("details");
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [oneTimeOTP, setOneTimeOTP] = useState();

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const editPending = JSON.parse(
    localStorage.getItem("edit_pending_transactions")
  );

  const [revokeConfirmPassword, setRevokeConfirmPassword] = useState("");
  const [revokeConfirmEmail, setRevokeConfirmEmail] = useState("");
  const [accountDeleted, setAccoundDeleted] = useState(false);
  const [modalActionCompleted, setModalActionCompleted] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [remainingTime, setRemainingTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);
  const [planDetails, setPlanDetails] = useState();
  const [emailOtp, setEmailOtp] = useState("");
  const [EditAddCatogory, setEditAddCatogory] = useState(false);
  const [expenseCat, setExpenseCat] = useState([]);
  const [incomeCat, setIncomeCat] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [settingCategory, setSettingCategory] = useState(false);
  const [editPendingTransactions, setEditPendingTransactions] =
    useState(editPending);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showPromoCode, setShowPromoCode] = useState(false);

  const editCategory = (data, categoryId) => {
    editIncomeExpensesCategory(data, categoryId);
  };

  const editCategoryOnTap = () => {
    setEditAddCatogory(!EditAddCatogory);
    setSettingCategory(!settingCategory);
  };

  const editIncomeExpensesCategory = async (data, categoryId) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.EDIT_CATEGORY}${uuid}/${categoryId}`,
        data
      );
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      if (response?.data?.code === 1) {
        toast.success(`Category updated successfully.`);
      }
      getCategory();
    } catch (error) {
      toast.error("Something went wrong while edit category");
    }
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_ALL_CATEGORIES}${uuid}`
      );
      setExpenseCat(response?.data?.data?.Expense);
      setIncomeCat(response?.data?.data?.INCOME);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const deleteCategory = (selectedCategory) => {
    if (selectedCategory.isDefalutCategory) {
      deleteExpenseCategoryAndBudget(selectedCategory);
    } else {
      removeCategoryByUserUuidAndCategoryId(selectedCategory);
    }
  };

  const deleteExpenseCategoryAndBudget = async (selectedCategory) => {
    try {
      const response = await HttpClient.delete(
        `${API_ENDPOINTS.DELETE_EXPENSE_CATEGORY_AND_BUDGET}${uuid}/${selectedCategory.userCategoryId}`
      );

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }

      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const removeCategoryByUserUuidAndCategoryId = async (selectedCategory) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.REMOVE_CATEGORY_BY_USER_UUID_AND_CATEGORY_ID}${uuid}/${selectedCategory.userCategoryId}`,
        {}
      );

      if (response?.data.code === 1) {
        toast.success("Category deleted", {
          position: "top-right",
          autoClose: 2000,
        });
      }

      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const handleSettingToggle = () => {
    setSettingCategory(!settingCategory);
  };

  const handleCategoryClick = (val) => {
    setSelectedCategory(val);
    setSettingCategory(!settingCategory);
    setEditAddCatogory(!EditAddCatogory);
  };

  useEffect(() => {
    getCategory();
  }, [getCategory]);

  const [modals, setModals] = useState({
    Otp: false,
    deleteAccount: false,
    changePassword: false,
  });
  const userId = localStorage.getItem("userUuid");
  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userData.username;

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const settingApi = useCallback(async () => {
    setLoading(true);
    const response = await HttpClient.get(
      API_ENDPOINTS.GET_CLIENTS_BY_UUID + `${uuid}`
    );
    if (response?.data) {
      setData(response?.data);
    } else {
      toast.error(response?.data?.errors?.detail || "No Role Found", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    setLoading(false);
  }, [uuid]);

  const oneTimeLogin = useCallback(async () => {
    setLoading(true);
    const response = await HttpClient.get(
      API_ENDPOINTS.ONETIME_LOGIN + `${uuid}`
    );
    if (response?.data) {
      setOneTimeOTP(response?.data.oneTimeToken);
    } else {
      toast.error(response?.data?.errors?.detail || "No Role Found", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    setLoading(false);
  }, [uuid]);

  const fetchedPlanData = useCallback(async () => {
    const body = { email: userEmail };
    try {
      const response = await HttpClient.post(
        API_ENDPOINTS.GET_PLAN_DETAILS,
        body
      );

      setPlanDetails(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [userEmail]);

  useEffect(() => {
    fetchedPlanData();
    settingApi();
    getUserDetails();
    oneTimeLogin();
  }, [settingApi]);

  const getUserDetails = async () => {
    try {
      const response = await HttpClient.get(
        API_ENDPOINTS.GET_USER_DETAILS + `${uuid}`
      );
      setUserDetails(response?.data);
    } catch (error) {
      toast.error("Something went wrong while fetching user details", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };
  const changePassword = async () => {
    if (currentPassword === "" || currentPassword === undefined) {
      toast.error("Please Insert Current Password", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    if (currentPassword === newPassword) {
      toast.error(
        "Your current password and new password are same, please try again",
        {
          position: "top-right",
          autoClose: 2000,
        }
      );
      return;
    }
    const hasNumber = /\d/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

    if (!hasNumber || !hasSpecialChar) {
      toast.error(
        "New password must contain at least one number and one special character.",
        {
          position: "top-right",
          autoClose: 2000,
        }
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("New password and Confirm password do not match.", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    const data = {
      userUuid: uuid,
      oldPassword: currentPassword,
      newPassword: newPassword,
    };
    try {
      const response = await HttpClient.post(
        API_ENDPOINTS.CHANGE_PASSWORD,
        data
      );
      if (
        response.data.code === 0 &&
        response.data.message === "Your password incorrect"
      ) {
        toast.error("Your current password incorrect", {
          position: "top-right",
          autoClose: 2000,
        });
        setToggleTab("security");
      } else if (response.data.message === "Unauthorized") {
        toast.success("Your password changed successfully", {
          position: "top-right",
          autoClose: 2000,
        });
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        // setToggleTab('security');
        setModalActionCompleted(true);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const updateUser = async () => {
    const userData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
    };
    if (data?.firstName == "") {
      toast.error("First name cannot be empty", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    try {
      const response = await HttpClient.put(
        `${API_ENDPOINTS.UPDATE_USER}${uuid}`,
        userData
      );
      if (response.data.code === 1) {
        toast.success("User details have been updated successfully", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("There was a problem updating user details.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const deleteAccount = async () => {
    if (revokeConfirmEmail === "" || revokeConfirmEmail === undefined) {
      toast.error("Please Insert Email Address", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }

    if (revokeConfirmPassword === "" || revokeConfirmPassword === undefined) {
      toast.error("Please Insert Confirm Password", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    closeModal("deleteAccount");
    openModalFeedback("submitFeedBack");
  };

  const onDeleteAccountWithFeedback = async (
    feedbackReason,
    feedbackSelection
  ) => {
    const body = {
      email: revokeConfirmEmail,
      password: revokeConfirmPassword,
      name: data?.firstName,
      planName: planDetails.planType,
      message: feedbackReason,
      reasons: [feedbackSelection],
    };
    try {
      const responseToken = await HttpClient.get(
        `${API_ENDPOINTS.CHECK_AKAHU_TOKEN}${uuid}`
      );
      const response = await HttpClient.put(
        responseToken.data?.status
          ? `${API_ENDPOINTS.UPDATE_USER_STATUS}${uuid}`
          : `${API_ENDPOINTS.USER_DISABLED}${uuid}`,
        body
      );
      if (response.data.code === 0) {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        closeModalFeedbacks("submitFeedBack");
      } else if (response.data.code === 1) {
        closeModalFeedbacks("submitFeedBack");
        toast.success("Your account de-activated successfully", {
          position: "top-right",
          autoClose: 2000,
        });
        setAccoundDeleted(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      toast.error("Something went wrong.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const uploadImage = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("userProfile", file);

      const response = await HttpClient.post(
        API_ENDPOINTS.UPLOAD_USER_IMAGE + `${uuid}`,
        formData
      );

      if (response.status === 200) {
        settingApi();
        toast.success("Profile image updated successfully", {
          position: "top-right",
          autoClose: 2000,
        });
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      toast.error("Something went wrong while updating profile image", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  const submitOtp = async () => {
    if (emailOtp.length !== 6) {
      return toast.error("Please enter 6 digit code", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    const data = {
      userUuid: uuid,
      otp: emailOtp,
    };
    try {
      const response = await HttpClient.post(
        API_ENDPOINTS.ENABLE_2FACTOR_AUTHENTICATION,
        data
      );
      if (response.data.code === 0) {
        toast.error("Incorrect code", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response.data.code === 1) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        getUserDetails();
        setModalActionCompleted(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateOtp = async () => {
    setModals({ ...modals, Otp: true });
    try {
      await HttpClient.post(API_ENDPOINTS.SET_2FACTOR_SETUP_AUTHENTICATION, {
        email: data?.email,
      });
    } catch (error) {
      toast.error("Something went wrong.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const revokeEmail2FactorAuthentication = async () => {
    try {
      await HttpClient.post(API_ENDPOINTS.REVOKE_2FACTOR_AUTHENTICATION, {
        userUuid: uuid,
      });
      getUserDetails();
    } catch (error) {
      toast.error("Something went wrong.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const toggle2FAVerify = async () => {
    if (userDetails?.emailVefried === "true") {
      revokeEmail2FactorAuthentication();
    } else {
      generateOtp();
    }
  };

  const editPendingTransactionsModal = async () => {
    if (editPendingTransactions) {
      localStorage.setItem("edit_pending_transactions", false);
      setEditPendingTransactions(false);
    } else {
      setEditPendingTransactions(true);
      setShowPendingModal(!showPendingModal);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadImage(file);
      setImage(file);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleOtpSubmit = (otp) => {
    setEmailOtp(otp);
  };

  const openModal = (modal) => setModals({ ...modals, [modal]: true });
  const closeModal = (modal) => {
    if (modal === "Otp") {
      setEmailOtp("");
    }
    setModals({ ...modals, [modal]: false });
    setModalActionCompleted(false);
  };

  const [modalsFeedback, setModalsFeedback] = useState({
    addTransactionFeedbacks: false,
  });
  const openModalFeedback = (modal) => {
    setModalsFeedback({ ...modalsFeedback, [modal]: true });
  };
  const closeModalFeedbacks = (modal) => {
    setModalsFeedback({ ...modalsFeedback, [modal]: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (planDetails && planDetails.endDate) {
      const interval = setInterval(() => {
        const endDate = new Date(planDetails.endDate);
        const now = new Date();

        // Calculate the difference in milliseconds
        const difference = endDate - now;

        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          // Format the remaining time
          setRemainingTime(`${days}d ${hours}H ${minutes}m ${seconds}s`);
        } else {
          // Countdown finished
          setRemainingTime("Expired");
          clearInterval(interval);
        }
      }, 1000);

      // Clean up interval when component is unmounted
      return () => clearInterval(interval);
    }
  }, [planDetails]);

  return {
    updateUser,
    isPopupOpenInfo,
    togglePopupInfo,
    data,
    handleChange,
    fileInputRef,
    handleFileChange,
    handleIconClick,
    userDetails,
    toggle2FAVerify,
    openModal,
    handleSettingToggle,
    planDetails,
    remainingTime,
    oneTimeOTP,
    uuid,
    modals,
    setNewPassword,
    setConfirmPassword,
    setCurrentPassword,
    currentPassword,
    confirmPassword,
    newPassword,
    changePassword,
    closeModal,
    modalActionCompleted,
    revokeConfirmEmail,
    setRevokeConfirmEmail,
    revokeConfirmPassword,
    setRevokeConfirmPassword,
    deleteAccount,
    accountDeleted,
    setAccoundDeleted,
    modalsFeedback,
    closeModalFeedbacks,
    onDeleteAccountWithFeedback,
    handleOtpSubmit,
    submitOtp,
    setModalActionCompleted,
    settingCategory,
    incomeCat,
    expenseCat,
    handleCategoryClick,
    EditAddCatogory,
    editCategory,
    selectedCategory,
    editCategoryOnTap,
    setEditAddCatogory,
    deleteCategory,
    loading,
    getCategory,
    editPendingTransactionsModal,
    editPendingTransactions,
    setEditPendingTransactions,
    setShowPendingModal,
    showPendingModal,
    showPromoCode,
    setShowPromoCode,
    fetchedPlanData,
  };
};

export default useSettings;
