import React, { useEffect, useState } from "react";
import { API_ENDPOINTS } from "../../client/ApiEndpoints";
import HttpClient from "../../client/HttpClient";

const useDashboardDetails = ({ uuid }) => {
 
  const [dashboardDetails, setDashboardDetails] = useState([]);
  const [allDashboardDetails, setAllDashboardDetails] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCashDetails = async () => {
      try {
        const response = await HttpClient.get(
          `${API_ENDPOINTS.GET_CASH_ACCOUNT}${uuid}`
        );

        setDashboardDetails(response?.data?.accounts);
      } catch (err) {
        setError(err.message);
      }
    };

    getCashDetails();
  }, []);

  useEffect(() => {
    const getAllDetails = async () => {
      try {
        const response = await HttpClient.get(
          `${API_ENDPOINTS.GET_ALL_DETAILS}${uuid}`
        );

        setAllDashboardDetails(response?.data);
      } catch (err) {
        setError(err.message);
      }
    };

    getAllDetails();
  }, []);

  return { dashboardDetails, allDashboardDetails };
};

export default useDashboardDetails;
