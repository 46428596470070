export const API_ENDPOINTS = {
  // Promo
  APPLY_PROMO_CODE: "api/developer-apis/clients/v1/user/verifyPromoCode",
  // budget_endpoints
  GET_INCOME_BUDGET: "api/developer-apis/v1/budgetSummary/getIncomeVsBudgetDetails/",
  GET_EXPENSE_BUDGET: "api/developer-apis/v1/budgetSummary/getSpendVsExpenseDetails/",
  GET_ONE_OFF_BUDGET: "api/developer-apis/oneOffBudget/getOneOffBudget/",
  GET_INCOME_TRANSACTIONS: "api/developer-apis/v1/budgetSummary/getIncomeTransactions/",
  GET_EXPENSE_TRANSACTIONS: "api/developer-apis/v1/budgetSummary/getExpenseTransactions/",
  GET_ONE_OFF_TRANSACTIONS: "api/developer-apis/oneOffBudget/getOneOffTransactions/",
  ADD_INCOME_BUDGET: "api/developer-apis/v1/budgetSummary/addIncome/",
  ADD_EXPENSE_BUDGET: "api/developer-apis/v1/budgetSummary/addExpenses/",
  CREATE_ONE_OFF_BUDGET: "api/developer-apis/oneOffBudget/createOneOffBudget/",
  AUTO_SINGLE_BUDGET_EXPENSES: "api/developer-apis/v1/budgetSummary/autoSingleBudgetExpenses/",
  GET_EXPENSE_REMAINING_BY_UUID: "api/developer-apis/category/getExpenseRemainingByUserUuid/",
  GET_INCOME_REMAINING_BY_UUID: "api/developer-apis/category/getIncomeRemainingByUserUuid/",
  ADD_EXPENSE_CATEGORY: "api/developer-apis/category/addCustomExpenseCategoryByUserUuid/",
  ADD_INCOME_CATEGORY: "api/developer-apis/category/addCustomIncomeCategoryByUserUuid/",
  DELETE_ONE_OFF_BUDGET: "api/developer-apis/oneOffBudget/deleteOneOffBudget/",
  DELETE_EXPENSE_CATEGORY_TRANSACTION_BUDGET: "api/developer-apis/v1/budgetSummary/deleteExpenseCategoryAndTransaction/",
  DELETE_EXPENSE_BUDGET: "api/developer-apis/v1/budgetSummary/expenses/",
  DELETE_INCOME_BUDGET: "api/developer-apis/v1/budgetSummary/deleteIncomes/",
  UPDATE_EXPENSE_BUDGET: "api/developer-apis/v1/budgetSummary/updateExpenses/",
  UPDATE_CATEGORY: "api/developer-apis/transactions/updateTransactionsCategory/",
  UPDATE_INCOME_BUDGET: "api/developer-apis/v1/budgetSummary/updateIncome/",
  UPDATE_ONE_OFF_BUDGET: "api/developer-apis/oneOffBudget/updateOneOffBudget/",

  // Transactions
  ADD_TRANSACTION: "api/developer-apis/transactions/addTransactions",
  GET_LATEST_TRANSACTIONS: "akahuApi/transaction/getAkahuLatestTransactions/",
  GET_TRANSACTION_BY_CLIENT_ID: "api/developer-apis/transactions/getTransactionsByClientIdWithFilter/",
  GET_TRANSACTIONS_RULE_CATEGORIZED: "api/developer-apis/transactions/getTransactionsRuleAndIncomeCategorizedByUserUuid/",
  DELETE_DUPLICATE_TRANSACTIONS: "api/developer-apis/transactions/deleteDuplicateTransaction/",
  GET_ALL_TRANSACTIONS_COUNT: "api/developer-apis/transactions/getTransactionsCategorizedByUserUuid/",
  SPLIT_TRANSACTION: "api/developer-apis/transactions/splitTransaction/",
  DELETE_CUSTOM_TRANSACTIONS: "api/developer-apis/transactions/deleteTransaction/",
  SPLIT_BACK_TO_MAIN_TRANSACTION: "api/developer-apis/transactions/splitbackToMainTransaction/",
  SPLIT_SINGLE_BACK_TO_MAIN_TRANSACTION: "api/developer-apis/transactions/splitSingleBackToMainTransaction/",
  UPDATE_CATEGORY_IN_BULK: "api/developer-apis/transactions/updateBulkTransactionsCategory/",
  AKAHU_BASIC_PLAN_ALL_TRANSACTIONS_LOAD: "akahuApi/transaction/getWebVersionAkahuBasicUserLoadAllTransactions/",
  AKAHU_ALL_TRANSACTIONS_LOAD: "akahuApi/transaction/getWebVersionUserLoadAllTransactions/",

  // Goals
  ADD_GOALS: "api/developer-apis/goals/addGoals/",
  GET_ALL_GOALS: "api/developer-apis/goals/getGoals/",
  GET_GOALS_BY_ID: "api/developer-apis/goals/getGoalsByGoalUuid/",
  UPDATE_GOALS: "api/developer-apis/goals/updateGoals/",
  DELETE_GOALS: "api/developer-apis/goals/deleteGoals/",

  // Cash Account
  GET_CASH_ACCOUNT: "api/developer-apis/cashAccount/getCashAccount/",
  GET_CASH_FLOW_BREAK_DOWN: "api/developer-apis/cashflow/getCashflowBreakdownData/",

  // Client ApIs
  GET_USER_DETAILS: "api/developer-apis/clients/v1/user/userAllDetails/",
  GET_CLIENTS_BY_UUID: "api/clients/getClientByUuid/",
  UPDATE_USER: "api/developer-apis/user/updateUserDetails/",

  // Categories
  GET_ALL_CATEGORIES: "api/developer-apis/category/getAllCategoryByUserUuid/",
  DELETE_EXPENSE_CATEGORY_AND_BUDGET: "api/developer-apis/v1/budgetSummary/deleteExpenseCategoryAndBudget/",
  REMOVE_CATEGORY_BY_USER_UUID_AND_CATEGORY_ID: "api/developer-apis/category/removeCategoryByUserUuidAndCategoryId/",
  EDIT_CATEGORY: "api/developer-apis/category/updateCustomCategoryByUserUuidAndCategoryId/",

  // Sign-Up Email
  ONETIME_LOGIN: "api/developer-apis/clients/v1/user/userPlanManageOneTimeLogin/",

  //  Setting APIs
  GET_PLAN_DETAILS: "api/developer-apis/plan/getPlanDetails",
  CHANGE_PASSWORD: "api/developer-apis/clients/v1/user/changePassword",
  CHECK_AKAHU_TOKEN: "api/developer-apis/auth/akahu/checkAkahuToken/",
  UPDATE_USER_STATUS: "api/developer-apis/user/updateUserStatus/",
  USER_DISABLED: "api/developer-apis/user/userDisabled/",
  UPLOAD_USER_IMAGE: "api/developer-apis/user/changeUserProfile/",
  ENABLE_2FACTOR_AUTHENTICATION: "api/developer-apis/clients/v1/user/enabledEmail2faVerification",
  SET_2FACTOR_SETUP_AUTHENTICATION: "api/developer-apis/clients/v1/user/email2faSetupVerification",
  REVOKE_2FACTOR_AUTHENTICATION: "api/developer-apis/clients/v1/user/revokeEmail2faVerificationCode",

  // Forget password for all roles
  EMAIL_2FA_VERIFICATION: "api/clients/email2faVerification",

  // Money Insights
  GET_CREDIT_TRANSACTION: "api/auth/akahu/getCreditTransactions/",
  Get_MONEY_TRENDS_MERCHANTS_HISTORY_DETAILS: 'api/developer-apis/v1/budgetSummary/getMoneyTrendsMerchantHistoryDetails/',
  GET_MONEY_TRENDS_CATEGORY_BREAKDOWN_DETAILS: 'api/developer-apis/v1/budgetSummary/getMoneyTrendsCategoryBreakdownDetails/',
  GET_MONEY_TRENDS_INCOME_BREAKDOWN_DETAILS: 'api/developer-apis/v1/budgetSummary/getMoneyTrendsIncomeBreakdownDetails/',

  // Merchant
  GET_MERCHANTS_BY_CLIENT_ID: "api/developer-apis/merchants/getMerchantsByClientIdWithFilter/",

  // Dashboard
  GET_ALL_DETAILS: "api/developer-apis/dashboard/getAllDetails/", 
  GET_INCOME_TOTAL: "api/developer-apis/dashboard/getIncomeTotalData/",
  GET_EXPENSE_TOTAL: "api/developer-apis/dashboard/getExpensesTotalData/",
  GET_TOP_FIVE_EXPENSE: "api/developer-apis/dashboard/getTopFiveExpensesTotalData/",
  GET_TOP_FIVE_MERCHANTS: "api/developer-apis/dashboard/getTopFiveMerchantData/",
  GET_EXPENSES_BREAK_DOWN: "api/developer-apis/dashboard/getExpensesCategoryBreakDownData/"
};