/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "./BudgetSummary.css";
import { ReactComponent as Arrow } from "../../../assests/images/Arrow_right_black.svg";
import { ReactComponent as Arrow_right } from "../../../assests/images/Arrow_left_black.svg";
import { ReactComponent as Arrow_bottom } from "../../../assests/images/Arrow_bottom.svg";
import BudgetBreakdown from "./BudgetBreakdown";
import OverlayContainer from "../../OverlayContainer/OverlayContainer";
import { Loading } from "../../Loading/Loading";
import OneOffBudget from "./OneOffBudget";
import UserImageInfoToggle from "../../UserImageInfoToggle";
import PageTitle from "../../PageTitle";
import useBudget from "../../../hooks/Budget/useBudget/useBudget";
import IncomeBudgetCompactView from "./CompactView/IncomeBudgetCompactView";
import ExpenseBudgetCompactView from "./CompactView/expenseBudgetCompactView";
import IncomeBudgetComponent from "./IncomeBudgetComponent";
import ExpenseBudgetComponent from "./ExpenseBudgetComponent";
import ViewSetting from "../../ViewSetting/ViewSetting";
import MonthYearCalendarPopover from "../CalendarMonthPopOver/CalendarMonthPopOver";
import { getMonthName } from "../../../utilities/Statics";
import OneOffBudgetCompactView from "./CompactView/OneOffBudgetCompactView";

const BudgetSummary = () => {
  // Custom hook for business logic
  const {
    uuid,
    handleLeftClick,
    handleRightClick,
    handleBottomArrowClick,
    fetchAllBudgetData,
    incomeBudgetsData,
    completeBudgetDataIncome,
    completeBudgetDataExpense,
    expenseBudgetsData,
    isBudgetBreakToggled,
    setIsBudgetBreakToggled,
    totalAmount,
    remainingAmount,
    oneOffBudgetsData,
    isLoading,
    preferredView,
    addIncome,
    addExpense,
    tabYear,
    tabMonth,
    autoSingleBudgetExpenses,
    updateIncomeBudget,
    updateExpenseBudget,
    updateOneOffBudget,
    loadingSingleBudgets,
    fetchIncomeAllData,
    fetchExpenseAllData,
    setTabYear,
    setTabMonth,
    showPopover,
    setShowPopover,
  } = useBudget();
  const currentDate = new Date();

  return (
    <>
      <div className="app-main__outer budget-summary-main">
        <div className="row">
          <div className="header-budget-main-div">
            <div
              className="header-budget-text-div"
              style={{ marginTop: "0px" }}
            >
              <PageTitle
                title={"Budget summary"}
                description={
                  "Here`s your summary of your budget and how you`re tracking."
                }
              />
            </div>

            <div
              className=""
              id="monthYearPicker"
              style={{ alignItems: "center", marginRight: "20px" }}
            >
              <div
                className="edit-btn"
                style={{
                  width: "327px",
                  height: "53px",
                  borderRadius: "360px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#001F3F",
                }}
              >
                <div
                  className="left"
                  onClick={handleLeftClick}
                  style={{ cursor: "pointer", zIndex: 12, marginLeft: "-17px" }}
                >
                  <p>
                    <Arrow_right />
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <h4 style={{ margin: 0 }}>{getMonthName(tabMonth)}</h4>
                      <div
                        onClick={handleBottomArrowClick}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Arrow_bottom
                          style={{
                            width: "16px",
                            height: "16px",
                            color: "#FFFFFF",
                          }}
                        />
                      </div>
                    </div>

                    {/* Year (Below Month & Arrow) */}
                    <p style={{ color: "#04BAFF", margin: 0 }}>{tabYear}</p>
                  </div>
                </div>

                <div
                  className="right"
                  onClick={handleRightClick}
                  style={{
                    cursor: "pointer",
                    zIndex: 12,
                    marginRight: "-17px",
                    color: "#000000",
                  }}
                >
                  <Arrow />
                </div>
              </div>
            </div>

            <div
              className="header-budget-sub-div"
              style={{
                gap: "18px",
                alignItems: "center",
                width: "10%",
              }}
            >
              <div>
                <ViewSetting />
              </div>
              <UserImageInfoToggle customStyle={true} />
            </div>
          </div>
        </div>

        <div className="row " style={{ marginTop: "60px" }}>
          <div className="col">
            {/* Income Budgets Section */}

            {preferredView === "classic" ? (
              <IncomeBudgetComponent
                fetchBudgetData={fetchAllBudgetData}
                mainHeading="Income"
                incomeExpenseBudgetsData={incomeBudgetsData}
                completeBudgetData={completeBudgetDataIncome}
                showFields={true}
                uuid={uuid}
                addIncome={addIncome}
                year={tabYear}
                month={tabMonth}
                isCompactView={"false"}
                applyToAllFutureMonths={
                  localStorage.getItem("isAllFutureMonth") || "allMonth"
                }
              />
            ) : (
              <IncomeBudgetCompactView
                incomeData={incomeBudgetsData}
                addIncome={addIncome}
                fetchIncomeAllData={fetchIncomeAllData}
                completeBudgetData={completeBudgetDataIncome}
                updateIncomeBudget={updateIncomeBudget}
                year={tabYear}
                month={tabMonth}
                isCompactView={"true"}
                applyToAllFutureMonths={
                  localStorage.getItem("isAllFutureMonth") || "allMonth"
                }
                loadingSingleBudgets={loadingSingleBudgets}
              />
            )}

            {preferredView === "classic" ? (
              <div className="mt-4">
                <ExpenseBudgetComponent
                  fetchBudgetData={fetchAllBudgetData}
                  mainHeading="Expense "
                  incomeExpenseBudgetsData={expenseBudgetsData}
                  showFields={true}
                  uuid={uuid}
                  addExpense={addExpense}
                  autoSingleBudgetExpenses={autoSingleBudgetExpenses}
                  year={tabYear}
                  month={tabMonth}
                  isCompactView={"false"}
                  applyToAllFutureMonths={
                    localStorage.getItem("isAllFutureMonth") || "allMonth"
                  }
                  completeBudgetData={completeBudgetDataExpense}
                />
              </div>
            ) : (
              <div className="mt-4">
                <ExpenseBudgetCompactView
                  expenseData={expenseBudgetsData}
                  addExpense={addExpense}
                  fetchExpenseAllData={fetchExpenseAllData}
                  autoSingleBudgetExpenses={autoSingleBudgetExpenses}
                  updateExpenseBudget={updateExpenseBudget}
                  year={tabYear}
                  month={tabMonth}
                  isCompactView={"true"}
                  applyToAllFutureMonths={
                    localStorage.getItem("isAllFutureMonth") || "allMonth"
                  }
                  loadingSingleBudgets={loadingSingleBudgets}
                  completeBudgetData={completeBudgetDataExpense}
                />
              </div>
            )}
          </div>

          {/* Budget Breakdown Card */}
          <BudgetBreakdown
            handleToggleClick={() =>
              setIsBudgetBreakToggled(!isBudgetBreakToggled)
            }
            isBudgetBreakToggled={isBudgetBreakToggled}
            totalAmount={totalAmount}
            remainingAmount={remainingAmount}
          />
        </div>
        {/* End of Income Budgets Section */}

        {preferredView === "classic" ? (
          <div className="row mt-4">
            <OneOffBudget
              fetchBudgetData={fetchAllBudgetData}
              mainHeading="One off "
              showFields={false}
              oneOffBudgetsData={oneOffBudgetsData.oneOffBudget}
              uuid={uuid}
              isCompactView={"false"}
            />
          </div>
        ) : (
          <div className="row mt-4">
            <OneOffBudgetCompactView
              fetchBudgetData={fetchAllBudgetData}
              mainHeading="One off "
              showFields={false}
              oneOffBudgetsData={oneOffBudgetsData.oneOffBudget}
              uuid={uuid}
              loadingSingleBudgets={loadingSingleBudgets}
              updateOneOffBudget={updateOneOffBudget}
              isCompactView={"true"}
            />
          </div>
        )}
      </div>

      {showPopover && (
        <MonthYearCalendarPopover
          target={"monthYearPicker"}
          tabMonth={tabMonth}
          tabYear={tabYear}
          setTabMonth={setTabMonth}
          setTabYear={setTabYear}
          setShowPopover={setShowPopover}
          showPopover={showPopover}
          baseYear={currentDate.getFullYear()}
          baseMonth={currentDate.getMonth()}
          // onClose={togglePopover}
        />
      )}

      {isLoading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default BudgetSummary;
