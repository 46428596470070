import React, { forwardRef } from "react";
import "./index.css";

const Cinput = forwardRef(({ onChange, name, type = "text", value, width }, ref) => {
  const formatNumber = (num) => {
    if (!num) return "";
    return new Intl.NumberFormat().format(num); // Adds commas
  };

  const handleChange = (e) => {
    let inputValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    if (type === "number") {
      if (inputValue) {
        inputValue = `$${formatNumber(inputValue)}`; // Add dollar sign + comma formatting
      } else {
        inputValue = "$"; // Keep "$" when empty
      }

      onChange({ target: { name, value: inputValue.replace(/[^0-9]/g, "") } }); // Send raw number to parent
    } else {
      onChange(e);
    }
  };

  return (
    <input
      style={{ width }}
      type={type === "number" ? "text" : type} // Keep text for number formatting
      name={name}
      value={type === "number" ? value ? `$${formatNumber(value)}` : "$" : value} // Always show "$"
      ref={ref}
      className="custom-input"
      onChange={handleChange}
    />
  );
});

export default Cinput;
