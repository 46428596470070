import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowAccoor } from "../../../assests/images/icons/accordionArrow.svg";
import "./BudgetSummary.css";
import IncomeExpenseBudgetModal from "./IncomeExpenseBudget/IncomeExpenseBudgetModal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as OverBudget } from "../../../assests/images/over_budget.svg";
import {
  getCustomCodeToName,
  progressbarColor,
} from "../../../utilities/Statics";
import { lightBackgroundColor } from "../../../utilities/Statics";
import { motion } from "framer-motion";
import EditSvg from "../../../assests/images/icons/dark-pencil.svg";
import QuickAddSvg from "../../../assests/images/icons/dark-quick.svg";
import { useSelector } from "react-redux";
import SelectCreateCategory from "../../SelectCreateCategory/SelectCreateCategory";
import CrossIcon from "../../../assests/images/icons/cross-gray.svg";

const ExpenseBudgetComponent = ({
  incomeExpenseBudgetsData = [],
  mainHeading,
  selectedPeriod,
  showFields,
  uuid,
  fetchBudgetData,
  addExpense,
  autoSingleBudgetExpenses,
  year,
  month,
  isCompactView,
  applyToAllFutureMonths,
  completeBudgetData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quickAddBudget, setQuickAddBudget] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [quickValue, setQuickValue] = useState("$0");
  const [categoryType, setCategoryType] = useState("");
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [selectedColorOption, setSelectedColorOption] = useState("Red");
  const navigate = useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { preferredPeriod } = useSelector((state) => state.popupsetting);

  const handleCardClick = (budgetId, fetchBudget) => {
    navigate(`/budget/${uuid}/budgetdetails/expense/${budgetId}`, {
      state: {
        year,
        month,
        isCompactView,
        applyToAllFutureMonths,
        fetchBudget,
        completeBudgetData,
      },
    });
  };

  let totalMonthly = 0.0;
  let totalRemainingMonthly = 0.0;
  let totalAdjustedMonthly = 0.0;

  totalMonthly = incomeExpenseBudgetsData?.length
    ? incomeExpenseBudgetsData.reduce(
        (total, budget) => total + budget.total_transaction_amount,
        0
      )
    : 0;

  totalAdjustedMonthly = incomeExpenseBudgetsData?.length
    ? incomeExpenseBudgetsData.reduce(
        (total, budget) => total + budget.budget_amount,
        0
      )
    : 0;

  totalRemainingMonthly = incomeExpenseBudgetsData?.reduce((total, budget) => {
    const adjustedAmount =
      budget.budget_amount - budget.total_transaction_amount;
    return total + adjustedAmount;
  }, 0);

  let progress;

  if (totalAdjustedMonthly === 0) {
    progress = 0;
  } else {
    progress = (totalMonthly / totalAdjustedMonthly) * 100;
  }
  progress = progress > 100 ? 100 : progress;

  function formatAmount(amount) {
    if (amount % 1 === 0) {
      return amount.toLocaleString();
    } else {
      let [integerPart, decimalPart] = amount.toFixed(2).split(".");
      return `${Number(integerPart).toLocaleString()}.${decimalPart}`;
    }
  }

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/^\$/, "");

    if (/^\d*\.?\d*$/.test(value)) {
      setQuickValue(`$${value}`);
    }
  };

  const saveQuickBudget = async () => {
    const cleanedValue = quickValue.replace(/^\$/, "").trim();

    if (!selectedCategory?.categoryName || !cleanedValue) {
      console.warn("Category or value is missing, API not called.");
      return;
    }

    if (parseFloat(cleanedValue) === 0) {
      console.warn("Quick value is 0, API not called.");
      return;
    }

    await addExpense(selectedCategory, quickValue, categoryType);
    deleteQuickAddBudget();
  };

  const deleteQuickAddBudget = () => {
    setQuickAddBudget(false);
    setSelectedCategory();
    setQuickValue("$0");
  };

  const updateQuickSelectedBudget = async (category, type) => {
    setSelectedCategory(category);
    setCategoryType(type);

    if (type !== "CUSTOM") {
      const value = await autoSingleBudgetExpenses(category?.categoryId);
      await addExpense(category, value.toString(), type);
      deleteQuickAddBudget();
    }
  };

  return (
    <div className="col-md-12">
      <div className="budget-breakdown-header">
        <h4>{mainHeading} budgets</h4>
        {preferredPeriod === "monthly" && (
          <div className="col-md-6 right-section">
            <img
              onClick={() => navigate(`/editbudgetmonthly/${uuid}`)}
              className="pointer-cursor"
              style={{ width: "16px", height: "16px" }}
              src={EditSvg}
              alt="Pencil Icon"
            />
            <img
              onClick={() => {
                if (quickAddBudget) {
                  deleteQuickAddBudget();
                } else {
                  setQuickAddBudget(true);
                }
              }}
              className="pointer-cursor"
              style={{ width: "21px", height: "21px", marginInline: "5px" }}
              src={QuickAddSvg}
              alt="Quick Add Icon"
            />
            <i
              className="fa-solid fa-plus"
              style={{
                width: "20px",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={toggleModal}
            ></i>
          </div>
        )}
      </div>

      {/* Modal */}
      <IncomeExpenseBudgetModal
        isModalOpen={isModalOpen}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        mainHead={mainHeading}
        showFields={showFields}
        isIncome={false}
        fetchBudgetData={fetchBudgetData}
        selectedPeriod={selectedPeriod}
      />

      {incomeExpenseBudgetsData && (
        <div className="budget-item-card">
          {quickAddBudget && (
            <div className="budget-item" style={{ cursor: "pointer" }}>
              <div className="d-flex justify-content-between pb-3">
                <div className="d-flex align-items-center">
                  <SelectCreateCategory
                    title="Select or create a category"
                    type="expense"
                    handleCategoryClick={(value, type) => {
                      updateQuickSelectedBudget(value, type);
                    }}
                    targetId="popUpoverTargetQuickAddExpenseClassic"
                    popoverHeading="Expense"
                    style={{
                      color: "#A7A7A7",
                      borderRadius: "360px",
                      border: "1px solid #A7A7A7",
                      padding: "4px 8px",
                      fontSize: "14px",
                    }}
                    setChosenEmoji={setChosenEmoji}
                    setSelectedColorOption={setSelectedColorOption}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <h3 className="fs-15 fw-500 text-dark-gray mb-0 me-2">
                    Amount:
                  </h3>
                  <input
                    className="toggle-button grouped-input"
                    placeholder="Enter monthly amount"
                    value={quickValue}
                    onChange={(e) => handleAmountChange(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.target.blur();
                        saveQuickBudget();
                      }
                    }}
                    type="text"
                    style={{
                      height: "35px",
                      width: "224px",
                      textAlign: "center",
                      border: "none",
                      outline: "none",
                      background: "#EFEDED",
                      borderRadius: "10px",
                    }}
                  />
                  <div className=" text-center" style={{ marginLeft: "10px" }}>
                    <img
                      onClick={() => deleteQuickAddBudget()}
                      className="float-end"
                      style={{ width: "28px", height: "28px" }}
                      src={CrossIcon}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                className="budget-progress-container"
                style={{ backgroundColor: "#BFBFBF" }}
              >
                <motion.div
                  className="budget-progress"
                  style={{
                    width: `${100}%`,
                    backgroundColor: "#BFBFBF",
                  }}
                  initial={{ width: 0 }}
                  animate={{ width: `${100}%` }}
                  //   aria-valuenow={progress.toFixed(2)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></motion.div>
              </div>
            </div>
          )}
          {incomeExpenseBudgetsData?.map((budget, index) => {
            let progress;

            const adjustedAmount =
              budget.budget_amount - budget.total_transaction_amount;

            const totalAmount = budget.total_transaction_amount;

            progress =
              (budget.budget_amount ?? 0) === 0
                ? (budget.total_transaction_amount ?? 0) === 0.0
                  ? 0.0
                  : 100
                : ((budget.total_transaction_amount ?? 0) /
                    (budget.budget_amount ?? 0)) *
                  100;
            progress = progress > 100 ? 100 : progress;
            const categoryColor = getCustomCodeToName(budget.colour);
            const progressBarColor = progressbarColor(categoryColor);
            const backgroundColor = lightBackgroundColor(categoryColor);

            return (
              <>
                <div
                  key={index}
                  className="budget-item"
                  onClick={() => handleCardClick(budget.expense_uuid, budget)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="budget-label">
                    <div className="pay-budget-sec">
                      <div
                        className="budget-icon"
                        style={{
                          backgroundColor: backgroundColor,
                        }}
                      >
                        {budget.emoji}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "7px",
                          marginLeft: "5px",
                        }}
                      >
                        <h6>{budget.category_name}</h6>
                        <h4 style={{ marginTop: "-5px" }}>
                          ${formatAmount(totalAmount)}
                        </h4>
                      </div>
                    </div>
                    <p className="header-p" style={{ cursor: "pointer" }}>
                      <div className="account-number inter text-16px">
                        <div className="button-blck">
                          <ArrowAccoor />
                        </div>
                      </div>
                    </p>
                  </div>
                  <div className="budget-remaining">
                    {adjustedAmount < 0 ? (
                      <>
                        {`$${formatAmount(
                          Math.abs(adjustedAmount)
                        )} over budget`}
                        <OverBudget
                          style={{
                            marginLeft: "1px",
                            marginTop: "2px",
                          }}
                        />
                      </>
                    ) : (
                      `$${formatAmount(adjustedAmount)} remaining`
                    )}
                  </div>
                  <div
                    className="budget-progress-container"
                    style={{ backgroundColor: backgroundColor }}
                  >
                    <motion.div
                      className="budget-progress"
                      style={{
                        width: `${progress}%`,
                        backgroundColor: progressBarColor,
                      }}
                      initial={{ width: 0 }}
                      animate={{ width: `${progress}%` }}
                      aria-valuenow={progress.toFixed(2)}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></motion.div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
      <div className="budget-item-card" style={{ marginTop: "25px" }}>
        <div className="budget-item">
          <div className="budget-label">
            <div className="pay-budget-sec">
              <div>
                <h6>Total {"Expenses"}</h6>
                <h4>${Math.abs(totalMonthly).toLocaleString()}</h4>
              </div>
            </div>
          </div>
          <div className="budget-remaining">
            {totalRemainingMonthly < 0 ? (
              <>
                {`$${formatAmount(
                  Math.abs(totalRemainingMonthly)
                )} over budget`}
                <OverBudget
                  style={{
                    marginLeft: "1px",
                    marginTop: "2px",
                  }}
                />
              </>
            ) : (
              `$${formatAmount(Math.abs(totalRemainingMonthly))} remaining`
            )}
          </div>
          <div
            className={`budget-progress-container`}
            style={{
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          >
            <motion.div
              className={`budget-progress `}
              style={{
                width: `${progress}%`,
                backgroundColor: "#FF0000",
              }}
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.5 }}
              aria-valuemin="0"
              aria-valuemax="100"
            ></motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpenseBudgetComponent;
