import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import {
  categoryColorForAll,
  getCustomCodeToName,
} from "../../utilities/Statics";

const SelectCreatePopOver = (props) => {
  const {
    popoverOpen,
    targetId,
    onClose,
    popoverHeading,
    togglePopover,
    defaultCategory,
    setChosenEmoji,
    setSelectedColorOption,
    type,
    handleChange,
    handleCategoryClick,
    setMainSelectedCategory,
    createCategory,
  } = props;
  return (
    popoverOpen && (
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target={targetId}
        toggle={onClose}
        className="custom-popover-w-30"
      >
        <PopoverBody>
          <div className="transaction-categories mt-2">
            <div className="bold popover-live">{popoverHeading} Categories</div>
            <button
              onClick={() => togglePopover()}
              className="close-pass-button"
            >
              <i className="fa fa-times"></i>
            </button>

            {type === "income" && (
              <>
                <div
                  className="bold popover-live mt-3 mb-1"
                  style={{ fontSize: "14px" }}
                >
                  Your created categories
                </div>
                <div className="categories group-btn green-btn">
                  {defaultCategory?.income &&
                  defaultCategory.income.length > 0 ? (
                    defaultCategory.income.map((category) => (
                      <div
                        style={{ margin: "0px 8px 0px 0px" }}
                        key={category.categoryId}
                        className="button-blck"
                        onClick={() => {
                          setChosenEmoji(category.categoryEmoji);
                          setSelectedColorOption(
                            getCustomCodeToName(category.categoryColour)
                          );
                          setMainSelectedCategory(category);
                          let selectedItem = handleCategoryClick(category);
                          togglePopover();
                          return selectedItem;
                        }}
                      >
                        <div className="icon-tag">
                          <div
                            className="icon-transaction-img"
                            style={{
                              backgroundColor: categoryColorForAll(
                                category.categoryColour
                              ),
                            }}
                          >
                            {category.categoryEmoji}
                          </div>
                          <div className="content">
                            <h6 className="icon-content">
                              {category.categoryName}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                    // <p>There are no categories available.</p>
                  )}
                  {createCategory && (
                    <button
                      className="new-category-btn green"
                      onClick={handleChange}
                    >
                      + New category
                    </button>
                  )}
                </div>
              </>
            )}

            {type === "expense" && (
              <>
                <div>
                  <div
                    className="bold popover-live mt-3 mb-1"
                    style={{ fontSize: "14px" }}
                  >
                    Default categories
                  </div>
                  <div className="categories group-btn green-btn">
                    {defaultCategory?.expense?.map((category) => (
                      <div
                        style={{ margin: "0px 8px 0px 0px" }}
                        key={category.categoryId}
                        className="button-blck"
                        onClick={() => {
                          setChosenEmoji(category.categoryEmoji);
                          setSelectedColorOption(
                            getCustomCodeToName(category.categoryColour)
                          );
                          setMainSelectedCategory(category);

                          let selectedItem = handleCategoryClick(category, "");
                          togglePopover();
                          return selectedItem;
                        }}
                      >
                        <div className="icon-tag">
                          <div
                            className="icon-transaction-img"
                            style={{
                              backgroundColor: categoryColorForAll(
                                category.categoryColour
                              ),
                            }}
                          >
                            {category.categoryEmoji}
                          </div>
                          <div className="content">
                            <h6 className="icon-content">
                              {category.categoryName}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <div
                    className="bold popover-live mt-2 mb-1"
                    style={{ fontSize: "14px" }}
                  >
                    Your created categories
                  </div>
                  <div className="categories group-btn green-btn">
                    {defaultCategory?.yourExpense?.map((category) => (
                      <div
                        style={{ margin: "0px 8px 0px 0px" }}
                        key={category.categoryId}
                        className="button-blck"
                        onClick={() => {
                          //   setType("CUSTOM");
                          setChosenEmoji(category.categoryEmoji);
                          setSelectedColorOption(
                            getCustomCodeToName(category.categoryColour)
                          );
                          setMainSelectedCategory(category);
                          let selectedItem = handleCategoryClick(
                            category,
                            "CUSTOM"
                          );
                          togglePopover();
                          return selectedItem;
                        }}
                      >
                        <div className="icon-tag">
                          <div
                            className="icon-transaction-img"
                            style={{
                              backgroundColor: categoryColorForAll(
                                category.categoryColour
                              ),
                            }}
                          >
                            {category.categoryEmoji}
                          </div>
                          <div className="content">
                            <h6 className="icon-content">
                              {category.categoryName}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      className="new-category-btn green"
                      onClick={handleChange}
                    >
                      + New category
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </PopoverBody>
      </Popover>
    )
  );
};

export default SelectCreatePopOver;
