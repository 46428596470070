import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useExpenseCompactView = (
  expenseData,
  addExpense,
  autoSingleBudgetExpenses,
  updateExpenseBudget
) => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  // Expense calculations
  const [totalExpenseMonthly, setTotalExpenseMonthly] = useState(0.0);
  const [totalExpenseBudget, setTotalExpenseBudget] = useState(0.0);
  const [
    totalExpenseBudgetRemainingMonthly,
    setTotalExpenseBudgetRemainingMonthly,
  ] = useState(0.0);
  const [expenseTotalPercentage, setExpensePercentage] = useState(0.0);

  const [isEdit, setIsEdit] = useState(false);
  const [openCompactAddModal, setOpenCompactAddModal] = useState(false);
  const [quickAddBudget, setQuickAddBudget] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [quickValue, setQuickValue] = useState("$0");
  const [quickLoader, setQuickLoader] = useState(false);
  const [categoryType, setCategoryType] = useState("");
  const [tempBudgetAmount, setTempBudgetAmount] = useState({});

  // Format amount with $ sign
  function formatAmount(amount) {
    if (amount === 0) return "$0";

    const sign = amount > 0 ? "" : "-";
    const absoluteAmount = Math.abs(amount);

    if (absoluteAmount % 1 === 0) {
      return `${sign}$${absoluteAmount.toLocaleString()}`;
    } else {
      let [integerPart, decimalPart] = absoluteAmount.toFixed(2).split(".");
      return `${sign}$${Number(integerPart).toLocaleString()}.${decimalPart}`;
    }
  }

  // Expense calculations
  useEffect(() => {
    if (!expenseData || expenseData.length === 0) {
      setTotalExpenseMonthly(0.0);
      setTotalExpenseBudget(0.0);
      setTotalExpenseBudgetRemainingMonthly(0.0);
      setExpensePercentage(0.0);
      return;
    }

    let expenseMonthly = 0.0;
    let expenseBudget = 0.0;
    let budgetRemainingMonthly = 0.0;

    expenseData.forEach((element) => {
      const transactionAmount = element.total_transaction_amount || 0;
      const budgetAmount = element.budget_amount || 0;

      expenseMonthly += transactionAmount;
      expenseBudget += budgetAmount;
      budgetRemainingMonthly += budgetAmount - transactionAmount;
    });

    setTotalExpenseMonthly(parseFloat(expenseMonthly.toFixed(2)));
    setTotalExpenseBudget(parseFloat(expenseBudget.toFixed(2)));
    setTotalExpenseBudgetRemainingMonthly(
      parseFloat(budgetRemainingMonthly.toFixed(2))
    );

    const percentage =
      expenseBudget === 0
        ? 0
        : Math.min((expenseMonthly / expenseBudget) * 100, 100);

    setExpensePercentage(parseFloat(percentage.toFixed(2)));
  }, [expenseData]);

  // Compact view functions
  const addQuickBudget = () => {
    setQuickAddBudget(true);
  };

  const updateQuickSelectedBudget = async (category, type) => {
    setSelectedCategory(category);
    setCategoryType(type);

    if (type !== "CUSTOM") {
      setQuickLoader(true);
      const value = await autoSingleBudgetExpenses(category?.categoryId, false);
      await addExpense(category, value.toString(), type, false);
      deleteQuickAddBudget();
      setQuickLoader(false);
    }
  };

  const deleteQuickAddBudget = () => {
    setQuickAddBudget(false);
    setSelectedCategory();
    setQuickValue("$0");
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/^\$/, "");

    if (/^\d*\.?\d*$/.test(value)) {
      setQuickValue(`$${value}`);
    }
  };

  const saveQuickBudget = async () => {
    const cleanedValue = quickValue.replace(/^\$/, "").trim();

    if (!selectedCategory?.categoryName || !cleanedValue) {
      console.warn("Category or value is missing, API not called.");
      return;
    }

    if (parseFloat(cleanedValue) === 0) {
      console.warn("Quick value is 0, API not called.");
      return;
    }

    setQuickLoader(true);
    await addExpense(selectedCategory, quickValue, categoryType, false);
    deleteQuickAddBudget();
    setQuickLoader(false);
  };

  const handleBudgetAmountChange = (e, budgetId) => {
    const newValue = e.target.value.replace(/[^0-9.]/g, "");
    setTempBudgetAmount((prev) => ({
      ...prev,
      [budgetId]: newValue,
    }));
  };

  const handleBudgetAmountSave = async (budget) => {
    const newValue = tempBudgetAmount[budget.expense_uuid];

    if (!newValue || isNaN(newValue)) return;

    await updateExpenseBudget(budget, newValue);

    setTempBudgetAmount((prev) => {
      const updatedTemp = { ...prev };
      delete updatedTemp[budget.expense_uuid];
      return updatedTemp;
    });
  };

  // Handle Blur → Revert to original if user clicks outside
  const handleBudgetAmountBlur = (budget) => {
    setTempBudgetAmount((prev) => {
      const updatedTemp = { ...prev };
      delete updatedTemp[budget.expense_uuid];
      return updatedTemp;
    });
  };

  return {
    uuid,
    isEdit,
    navigate,
    setIsEdit,
    openCompactAddModal,
    setOpenCompactAddModal,
    addQuickBudget,
    quickAddBudget,
    deleteQuickAddBudget,
    updateQuickSelectedBudget,
    handleAmountChange,
    totalExpenseMonthly,
    totalExpenseBudget,
    totalExpenseBudgetRemainingMonthly,
    expenseTotalPercentage,
    selectedCategory,
    formatAmount,
    quickValue,
    saveQuickBudget,
    setSelectedCategory,
    handleBudgetAmountChange,
    handleBudgetAmountSave,
    tempBudgetAmount,
    handleBudgetAmountBlur,
    quickLoader,
  };
};

export default useExpenseCompactView;
