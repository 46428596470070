import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import EmojiPicker from "emoji-picker-react";
import "../IncomeExpenseBudget/IncomeExpenseBudgetModal.css";
import {
  colorCss,
  colorHexMap,
  hexToColorMap,
  selectionColor,
} from "../../../../utilities/Statics";
import PopupColorModal from "../../../../ModalForm/Popup/PopupColorModel";
import { ReactComponent as Add_round_cross } from "../../../../assests/images/icons/Add_round_cross.svg";
import "./IncomeBudgetCompactView.css";
import SelectCreateCategory from "../../../SelectCreateCategory/SelectCreateCategory";
import DarkAddIcon from "../../../../assests/images/plus.svg";
import HttpClient from "../../../../client/HttpClient";
import { API_ENDPOINTS } from "../../../../client/ApiEndpoints";
import { useParams } from "react-router-dom";

const AddIncomeExpenseModal = (props) => {
  const {
    openCompactAddModal,
    setOpenCompactAddModal,
    mainHead,
    lowerCaseHeading,
    amount,
    chosenEmoji,
    selectedColorOption,
    setAmount,
    setChosenEmoji,
    setSelectedColorOption,
    handleSaveAndFinish,
    setMainSelectedCategory,
    setType,
  } = props;

  const [isGrouped, setIsGrouped] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);

  const [categories, setCategories] = useState([
    {
      categoryId: 1282,
      categoryName: "",
      categoryEmoji: "😁",
      categoryColour: "",
      name: "",
    },
  ]);

  const pickerRef = useRef(null);

  const handleAmountChange = (e) => {
    const value = e.target.value;

    const numericValue = value.replace(/^\$/, "");

    if (/^\d*\.?\d*$/.test(numericValue)) {
      setAmount(`$${numericValue}`);
    }
  };

  const addCategory = () => {
    const newCategory = {
      categoryId: 123,
      categoryName: "",
      categoryEmoji: "😊",
      categoryColour: "",
      name: "",
    };
    setCategories([...categories, newCategory]);
  };

  const handleOptionColorClick = (option) => {
    setSelectedColorOption(colorHexMap[option] || option);
    setPopupOpenColor(false);
  };

  const togglePopupColor = () => {
    if (openCompactAddModal) {
      setPopupOpenColor(!isPopupOpenColor);
    }
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);
    setShowPicker(false);
  };
  const { uuid } = useParams();
  const autoSingleBudgetExpenses = async (id) => {
    try {
      const payload = {
        howOften: "Monthly",
        categoryId: id,
      };
      const response = await HttpClient.post(
        `${API_ENDPOINTS.AUTO_SINGLE_BUDGET_EXPENSES}${uuid}`,
        payload
      );

      if (response?.data?.code === 1) {
        setAmount(`$${response?.data?.data?.amount || "0"}`);
      } else {
        return "0";
      }
    } catch (error) {
      console.error("Error fetching budget expenses:", error);
      return "0";
    } finally {
    }
  };

  return (
    <div className="modal-overlay">
      <motion.div
        className="modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <button
          className="close-button"
          onClick={() => {
            setOpenCompactAddModal(false);
          }}
        >
          <p style={{ width: "31.13px", height: "31.13px" }}>
            <Add_round_cross />
          </p>
        </button>
        <h2 style={{ marginBottom: "0px" }}>Add {mainHead} budget</h2>
        <p>Enter in your details for your monthly {lowerCaseHeading} budget.</p>

        <h2 className="fs-20 fw-500 text-dark-gray">Edit details</h2>
        <div className="modal-section-card">
          {/* <div className="modal-input-group">
            <label className="text-dark-gray">Is this a group budget?</label>
            <button
              className="toggle-button"
              type="button"
              style={{
                borderRadius: "30px",
                background: isGrouped ? "#479985" : "#E0533D",
                color: "white",
                width: "94px",
                height: "31px",
                padding: "0px",
              }}
              onClick={() => setIsGrouped(!isGrouped)}
            >
              {isGrouped ? "Yes" : "No"}
            </button>
          </div> */}

          {!isGrouped && (
            <>
              <div className="modal-input-group">
                <label>Category</label>

                <SelectCreateCategory
                  createCategory={true}
                  title="Select or create a category"
                  type={mainHead === "Income" ? "income" : "expense"}
                  handleCategoryClick={(category, type) => {
                    setMainSelectedCategory(category);
                    setType(type);
                    if (mainHead !== "Income" && category?.defaultCategory) {
                      autoSingleBudgetExpenses(category?.categoryId);
                    }
                  }}
                  setChosenEmoji={setChosenEmoji}
                  setSelectedColorOption={setSelectedColorOption}
                  targetId="popUpoverTargetQuickAddIncomeCompact"
                  popoverHeading={mainHead === "Income" ? "Income" : "Expense"}
                  style={{
                    color: "#A7A7A7",
                    borderRadius: "360px",
                    border: "1px solid #A7A7A7",
                    padding: "4px 8px",
                    fontSize: "14px",
                  }}
                />
              </div>
              <div
                className="modal-input-group mb-0"
                style={
                  {
                    // marginBottom: expenseOrIncome === "oneoff" ? "0px" : "15px",
                  }
                }
              >
                <label>Amount</label>
                <input
                  className="toggle-button"
                  type="text"
                  style={{
                    height: "35px",
                    width: "225px",
                    textAlign: "center",
                    border: "none",
                    outline: "none",
                    background: "#EFEDED",
                    borderRadius: "10px",
                  }}
                  value={amount}
                  onChange={handleAmountChange}
                />
              </div>
            </>
          )}

          {isGrouped && (
            <div
              className="modal-input-group mb-0"
              style={
                {
                  // marginBottom: expenseOrIncome === "oneoff" ? "0px" : "15px",
                }
              }
            >
              <label>Name</label>
              <input
                className="toggle-button"
                type="text"
                style={{
                  height: "35px",
                  width: "225px",
                  textAlign: "center",
                  border: "none",
                  outline: "none",
                  background: "#EFEDED",
                  borderRadius: "10px",
                }}
                // value={amount}
                // onChange={handleAmountChange}
              />
            </div>
          )}
        </div>

        {isGrouped && (
          <div className="mt-3">
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: "-6px" }}
            >
              <h2 className="fs-20 fw-500 text-dark-gray">
                Add categories & amounts
              </h2>
              <img
                onClick={addCategory}
                className="pointer-cursor"
                style={{ width: "24px", height: "24px" }}
                src={DarkAddIcon}
                alt="Plus Icon"
              />
            </div>
            {categories?.map(() => {
              return (
                <>
                  <div
                    className="modal-section-card my-2"
                    style={{ padding: "10px" }}
                  >
                    <div className="modal-input-group mb-0">
                      <div className="d-flex align-items-center">
                        <SelectCreateCategory
                          title="Select a category"
                          createCategory={false}
                          type="income"
                          handleCategoryClick={(value) => {}}
                          targetId="popUpoverTargetAddIncome"
                          popoverHeading="Income"
                          style={{
                            color: "#A7A7A7",
                            borderRadius: "36px",
                            border: "1px solid #A7A7A7",
                            padding: "6px 12px",
                          }}
                        />
                      </div>

                      <input
                        className="toggle-button grouped-input"
                        placeholder="Enter monthly amount"
                        type="text"
                        style={{
                          height: "35px",
                          width: "185px",
                          textAlign: "center",
                          border: "none",
                          outline: "none",
                          background: "#EFEDED",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        )}

        <h2 className="mt-3 fs-20 fw-500 text-dark-gray">Personalise</h2>
        <div className="modal-section-card">
          <div className="modal-input-group " style={{ marginBottom: "0px" }}>
            <label>Personalised Color</label>
            <div className="color-option">
              <div className="dropdown">
                <button
                  className={`group-button ${colorCss(
                    selectedColorOption
                  )} popoup-color`}
                  onClick={togglePopupColor}
                  aria-expanded={isPopupOpenColor}
                  type="button"
                  aria-haspopup="true"
                  id="colorPickerButton"
                  style={{
                    color: "white",
                    backgroundColor: selectedColorOption?.startsWith("#")
                      ? selectedColorOption
                      : selectionColor(selectedColorOption),
                    padding: "4px 32px 5px 32px",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  {selectedColorOption?.startsWith("#") &&
                  hexToColorMap[selectedColorOption]
                    ? hexToColorMap[selectedColorOption]
                    : selectedColorOption?.startsWith("#")
                    ? "Custom"
                    : selectedColorOption}
                </button>
                {isPopupOpenColor && (
                  <PopupColorModal
                    isOpen={isPopupOpenColor}
                    targetId="colorPickerButton"
                    onClose={togglePopupColor}
                    title="Pick your colour"
                    options={[
                      "Red",
                      "Yellow",
                      "Blue",
                      "Purple",
                      "Orange",
                      "Pink",
                      "Light blue",
                      "Light green",
                      "Green",
                    ]}
                    selectedOption={selectedColorOption}
                    handleOptionClick={handleOptionColorClick}
                  />
                )}
              </div>
            </div>
          </div>

          <div
            className="modal-input-group mt-3 position-relative"
            style={{ marginBottom: "0px", marginTop: "5px" }}
          >
            <label>Personalised emoji</label>
            <div className="d-flex flex-column">
              <div
                className="py-2 inter add-goal show-emoji"
                onClick={() => setShowPicker(!showPicker)}
                style={{ width: "150px" }}
              >
                {chosenEmoji}
              </div>
              {showPicker && (
                <div
                  ref={pickerRef}
                  className="position-absolute bottom-100 z-3"
                >
                  <EmojiPicker onEmojiClick={handleEmojiClick} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between gap-2 mt-4">
          <button
            type="button"
            className="save-button"
            onClick={() =>
              handleSaveAndFinish(amount, chosenEmoji, selectedColorOption)
            }
          >
            Save & Finish
          </button>
          <button type="button" className="next-button">
            Next {mainHead}
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default AddIncomeExpenseModal;
