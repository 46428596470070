import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import weburls from "../../Weburls/weburls";
import { toast, ToastContainer } from "react-toastify";
import UserImageInfoToggle from "../UserImageInfoToggle";
import {
  categoryBackgroundCss,
  categoryColorForAll,
  moduleInfoPopUpData,
} from "../../utilities/Statics";
import AccountBalancesCards from "../Dashboard/AccountBalances.jsx";
import IncomeBarChart from "../Dashboard/IncomeBarChart.jsx";
import CashFlowBarChart from "../Dashboard/CashFlowBarChart.jsx";
import IncomeExpenses from "../Dashboard/IncomeExpenses.jsx";
import TopExpenses from "../Dashboard/TopExpenses.jsx";
import TopMerchents from "../Dashboard/TopMerchents.jsx";
import SpentBudget from "../Dashboard/SpentBudget.jsx";
import MyBalancePopOver from "../Dashboard/MyBalancePopOver.jsx";
import RemoveWidgets from "../Dashboard/RemoveWidghets.jsx";
import RightArrow from "../../assests/images/icons/right-arrow-dark.svg";
import { Loading } from "../Loading/Loading.jsx";
import OverlayContainer from "../OverlayContainer/OverlayContainer.jsx";
import useDashboardDetails from "../../hooks/DashboardCards/useDashboardDetails.jsx";
import Amount from "../Amount.jsx/index.jsx";
import ExpenseBreakDown from "../Dashboard/ExpenseBreakDown.jsx";
import { ReactComponent as Split } from "../../assests/images/split.svg";
import HttpClient from "../../client/HttpClient.js";
import { API_ENDPOINTS } from "../../client/ApiEndpoints.js";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal.jsx";
import RecentTransactions from "../Dashboard/RecentTransactions.jsx";
import { motion, AnimatePresence } from "framer-motion"; // Import framer-motion

const Dashboard = () => {
  //params
  const { uuid } = useParams();
  const { dashboardDetails, allDashboardDetails } = useDashboardDetails({
    uuid,
  });

  // states
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const companyId = JSON.parse(localStorage.getItem("userData"));
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [balancePopOver, setBalancePopOver] = useState(false);
  const [removeWidgets, setRemoveWidgets] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);
  const [balanceData, setBalanceData] = useState(dashboardDetails);
  const [activeBalanceAccount, setActiveBalanceAccount] = useState([]);
  const [totalBalance, setTotalBalance] = useState();
  

  const location = useLocation();
  const isFromLogin = useMemo(() => location.state?.fromLogin || false, [location.state]);
  
  // functions
  const settingApi = useCallback(async () => {
    setLoading(true);
    try {
      const response = await HttpClient.get(API_ENDPOINTS.GET_CLIENTS_BY_UUID + `${uuid}`);
      setData(response?.data || null);
    } catch (error) {
      toast.error("Error fetching client data", { position: "top-right", autoClose: 2000 });
    } finally {
      setLoading(false); // Ensure loading stops regardless of success or failure
    }
  }, [uuid]);
  

  // hooks
  useEffect(() => {
    if (!data) settingApi(); // Only fetch if `data` is missing
  }, [settingApi, data]);
  

  // hooks
  useEffect(() => {
    if (!dashboardDetails || activeBalanceAccount.length > 0) return;
  
    const storedAccounts = JSON.parse(localStorage.getItem("accounts")) || [];
    const updatedAccounts = dashboardDetails.map((account) => {
      const storedAccount = storedAccounts.find((acc) => acc?.accountUuid === account?.accountUuid);
      return storedAccount || { ...account, isActive: true };
    });
  
    const activeAccounts = updatedAccounts.filter((account) => account.isActive);
  
    setActiveBalanceAccount(activeAccounts);
    setBalanceData(updatedAccounts);
    setTotalBalance(activeAccounts.reduce((sum, acc) => sum + acc.value, 0));
  }, [dashboardDetails]);
  
  

  useEffect(() => {
    if (!activeBalanceAccount.length) return;
  
    const totalBalanceAccounts = activeBalanceAccount.reduce(
      (pre, acc) => acc.value + pre,
      0
    );
  
    if (totalBalance !== totalBalanceAccounts) {
      setTotalBalance(totalBalanceAccounts);
    }
  }, [activeBalanceAccount]);
  

  // const fetchedData = useCallback(async () => {
  //   try {
  //     // const response = await axios.get(
  //     //   weburls.Get_clients + `${companyId.companyId}`
  //     // );
  //     // localStorage.setItem(
  //     //   "client-userUuid",
  //     //   JSON.stringify(response.data.clients)
  //     // );
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [companyId.companyId]);

  // useEffect(() => {
  //   fetchedData();
  // }, [fetchedData]);

  const updateLoadingState = (isLoading) => {
    setLoadingCount((prevCount) =>
      isLoading ? prevCount + 1 : Math.max(prevCount - 1, 0)
    );
  };

  const isLoading = loadingCount > 0;

  useEffect(() => {
    if (loadingCount > 0) return;
    updateLoadingState(true);
    const timer = setTimeout(() => updateLoadingState(false), 2000);
    return () => clearTimeout(timer);
  }, []);
  
  
  

  return (
    <>
      <div className="app-main__outer">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fs-32 fw-400 text-dark-blue mb-0">
              {(() => {
                const currentHour = new Date().getHours();
                if (currentHour >= 0 && currentHour < 12) {
                  return "Good Morning";
                } else if (currentHour >= 12 && currentHour < 17) {
                  return "Good Afternoon";
                } else {
                  return "Good Evening";
                }
              })()}
              ,<span className="fw-700 ">{data?.firstName}</span> 👋🏻
            </h5>
            <p className="fs-14 fw-400 text-muted-blue mb-0">
              Here’s an update on your financial position.
            </p>
          </div>
          <div>
            <UserImageInfoToggle
              title={moduleInfoPopUpData.goals.title}
              description={moduleInfoPopUpData.goals.description}
              isPopupOpenInfo={isPopupOpenInfo}
              togglePopupInfo={togglePopupInfo}
              customStyle={{ display: "flex" }}
            />
          </div>
        </div>

        {/* Conditionally apply motion effect */}
        {isFromLogin ? (
          <AnimatePresence>
            {/* Total Balance and IncomeExpenses */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="row g-4 mt-2"
            >
              <div className="col-md-6">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4 }}
                  className="amount-dashboard-wrp bg-deep-blue"
                  style={{ boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)" }}
                >
                  <div className="balance-wrp-heading">
                    <h4 className="fs-25 white-text fw-400 lh-36">Total Balance</h4>
                    <h2 className="big-head">
                      <h3 className="fs-50 fw-500 lh-62">
                        {totalBalance
                          ? totalBalance < 0
                            ? `-$${Math.abs(totalBalance)?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}`
                            : `$${totalBalance?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                          : ""}
                      </h3>
                    </h2>
                    <div
                      className="d-flex position-absolute bottom-0 end-0 mb-3 mx-3"
                      onClick={() => setBalancePopOver(true)}
                    >
                      <span
                        className="white-text d-flex align-items-center fs-12 fw-400 lh-18 pe-2 pointer-cursor"
                        id="exampleModalToggle"
                      >
                        My Balances
                      </span>

                      <button
                        style={{ width: "32px", height: "32px" }}
                        className="border-0 bg-white rounded-circle d-flex justify-content-center align-items-center"
                      >
                        <img
                          style={{ width: "16px", height: "16px" }}
                          src={RightArrow}
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </motion.div>
              </div>

              {/* IncomeExpenses Component */}
              <div className="col-md-6">
                <IncomeExpenses />
              </div>
            </motion.div>

            {/* IncomeBarChart, TopExpenses, and TopMerchants */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="row g-4 mt-1"
            >
              <div className="col-md-6">
                <IncomeBarChart allDashboardDetails={allDashboardDetails} />
              </div>

              <div className="col-md-3">
                <TopExpenses />
              </div>

              <div className="col-md-3">
                <TopMerchents />
              </div>
            </motion.div>

            {/* SpentBudget, RecentTransactions, and CashFlowBarChart */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              className="mt-2"
            >
              <SpentBudget allDashboardDetails={allDashboardDetails} />

              <div className="row">
                <div className="col-md-6">
                  <div
                    className="d-flex justify-content-between align-items-center mb-1 mt-1"
                    style={{ width: "100%" }}
                  >
                    <h4 className="fs-18 fw-500 lh-34 text-charcoal mb-0">
                      Recent Transactions
                    </h4>
                    <Link to={`/transactions/${uuid}`}>
                      <span
                        className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
                        style={{
                          width: "93px",
                          height: "25px",
                          borderRadius: "50px",
                        }}
                      >
                        See all
                      </span>
                    </Link>
                  </div>

                  <RecentTransactions allDashboardDetails={allDashboardDetails} />
                </div>
                <div className="col-md-6">
                  <CashFlowBarChart allDashboardDetails={allDashboardDetails} />
                </div>
              </div>

              {/* Account Balances */}
              <AccountBalancesCards dashboardDetails={dashboardDetails} />

              {/*  Expense breakdown */}
              <div className="row mt-2">
                <ExpenseBreakDown
                  activeTab="Expenses"
                  heading="Expense breakdown"
                />
              </div>

              <div className="row mt-3">
                <ExpenseBreakDown activeTab="Income" heading="Income breakdown" />
              </div>
            </motion.div>
          </AnimatePresence>
        ) : (
          // Non-animated version for sidebar navigation
          <>
            <div className="row g-4 mt-2">
              <div className="col-md-6">
                <div
                  className="amount-dashboard-wrp bg-deep-blue"
                  style={{ boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)" }}
                >
                  <div className="balance-wrp-heading">
                    <h4 className="fs-25 white-text fw-400 lh-36">Total Balance</h4>
                    <h2 className="big-head">
                      <h3 className="fs-50 fw-500 lh-62">
                        {totalBalance
                          ? totalBalance < 0
                            ? `-$${Math.abs(totalBalance)?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}`
                            : `$${totalBalance?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                          : ""}
                      </h3>
                    </h2>
                    <div
                      className="d-flex position-absolute bottom-0 end-0 mb-3 mx-3"
                      onClick={() => setBalancePopOver(true)}
                    >
                      <span
                        className="white-text d-flex align-items-center fs-12 fw-400 lh-18 pe-2 pointer-cursor"
                        id="exampleModalToggle"
                      >
                        My Balances
                      </span>

                      <button
                        style={{ width: "32px", height: "32px" }}
                        className="border-0 bg-white rounded-circle d-flex justify-content-center align-items-center"
                      >
                        <img
                          style={{ width: "16px", height: "16px" }}
                          src={RightArrow}
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* IncomeExpenses Component */}
              <div className="col-md-6">
                <IncomeExpenses />
              </div>
            </div>

            <div className="row g-4 mt-1">
              <div className="col-md-6">
                <IncomeBarChart allDashboardDetails={allDashboardDetails} />
              </div>

              <div className="col-md-3">
                <TopExpenses />
              </div>

              <div className="col-md-3">
                <TopMerchents />
              </div>
            </div>

            <div className="mt-2">
              <SpentBudget allDashboardDetails={allDashboardDetails} />

              <div className="row">
                <div className="col-md-6">
                  <div
                    className="d-flex justify-content-between align-items-center mb-1 mt-1"
                    style={{ width: "100%" }}
                  >
                    <h4 className="fs-18 fw-500 lh-34 text-charcoal mb-0">
                      Recent Transactions
                    </h4>
                    <Link to={`/transactions/${uuid}`}>
                      <span
                        className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
                        style={{
                          width: "93px",
                          height: "25px",
                          borderRadius: "50px",
                        }}
                      >
                        See all
                      </span>
                    </Link>
                  </div>

                  <RecentTransactions allDashboardDetails={allDashboardDetails} />
                </div>
                <div className="col-md-6">
                  <CashFlowBarChart allDashboardDetails={allDashboardDetails} />
                </div>
              </div>

              {/* Account Balances */}
              <AccountBalancesCards dashboardDetails={dashboardDetails} />

              {/*  Expense breakdown */}
              <div className="row mt-2">
                <ExpenseBreakDown
                  activeTab="Expenses"
                  heading="Expense breakdown"
                />
              </div>

              <div className="row mt-3">
                <ExpenseBreakDown activeTab="Income" heading="Income breakdown" />
              </div>
            </div>
          </>
        )}
      </div>

      {balancePopOver && (
        <MyBalancePopOver
          balanceData={balanceData}
          setBalanceData={setBalanceData}
          totalBalance={totalBalance}
          setTotalBalance={setTotalBalance}
          setActiveBalanceAccount={setActiveBalanceAccount}
          isOpen={balancePopOver}
          onClose={() => setBalancePopOver(false)}
        />
      )}

      {removeWidgets && (
        <RemoveWidgets
          isOpen={removeWidgets}
          onClose={() => setRemoveWidgets(false)}
        />
      )}

      {isLoading && !data && (
        <OverlayContainer>
          <Loading />
        </OverlayContainer>
      )}
    </>
  );
};
export default Dashboard;