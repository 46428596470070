import "../../index.css";
import Logo from "../../assests/images/icons/Black Logo 1.png";
import { isEmpty } from "lodash";
import Amount from "../../Components/Amount.jsx";
import { ToggleSwitch } from "../../Components/ToggleSwitch/index.jsx";
import { amountMinusWithSign } from "../../utilities/helper.jsx";

export const ConnectedAccountsAddGoal = ({
  avatar,
  accountName,
  value,
  accountId,
  handleToggleSwitch,
  id,
  selectedAccount,
  isDrawer = false,
  currency,
  accountType,
  convertedAmount,
  width,
}) => {
  const isChecked = isDrawer
    ? selectedAccount === id
    : selectedAccount.includes(id);

  const currencyCode = localStorage.getItem("currencyCode");

  return (
    <div
      className="col-md-6"
      style={{
        height: "63px",
      }}
    >
      <div
        className="p-2 w-100 bg-white d-flex align-items-center"
        style={{
          boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.15)",
          height: "65px",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="accordion-header w-100">
          <div>
            <img
              className="img-fluid rounded-circle"
              style={{
                width: "40px",
              }}
              src={!isEmpty(avatar) ? avatar : Logo}
              alt=""
            />
          </div>
          <div className="account-text mx-2">
            <div className="fs-16 fw-600 text-ellipsis">{accountName}</div>
            <div className="text-fresh-green fs-14 fw-700">
              {currencyCode !== currency ? (
                <div className="d-flex align-items-center gap-1">
                  <div className="account-number">
                    <span
                      className={value < 0 ? "amount-red" : "amount-green"}
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      {accountType === "INVESTMENT" ||
                      accountType === "WALLET" ||
                      accountType === "FOREIGN"
                        ? `${amountMinusWithSign(
                            convertedAmount,
                            currencyCode
                          )} ${currencyCode}`
                        : amountMinusWithSign(convertedAmount, currencyCode)}
                    </span>
                  </div>

                  <span
                    style={{
                      color: "#B8B8B8",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                    className=""
                  >
                    |
                  </span>

                  <div
                    className="d-flex align-items-center"
                    style={{
                      color: "#B8B8B8",
                      fontSize: "14px",
                      fontWeight: "700",
                      float: "right",
                    }}
                  >
                    {amountMinusWithSign(value, currency)} {currency}
                  </div>
                </div>
              ) : (
                <>
                  <div className={`account-number mb-0`}>
                    <span
                      className={value < 0 ? "amount-red" : "amount-green"}
                      style={{ fontSize: "14px", fontWeight: "700" }}
                    >
                      {accountType === "INVESTMENT" ||
                      accountType === "WALLET" ||
                      accountType === "FOREIGN"
                        ? `${amountMinusWithSign(value, currency)} ${currency}`
                        : amountMinusWithSign(value, currency)}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            style={{
              transform: "scale(0.9)",
              display: "inline-block",
            }}
          >
            <ToggleSwitch
              onChange={() =>
                handleToggleSwitch({
                  accountId,
                  avatar,
                  id,
                  accountName,
                  value,
                })
              }
              value={isChecked}
            />
          </div>
        </div>
      </div>
    </div>
    // <div
    //   className="connected-account-box d-flex justify-content-between"
    //   style={{ width: width }}
    // >
    //   <div
    //     className="d-flex align-items-center"
    //     style={{ gap: "12px", width: "80%" }}
    //   >
    //     <div>
    //       <img className="account-img"  src={!isEmpty(avatar) ? avatar : Logo} alt="" />
    //     </div>
    //     <div className="lcs-inner w-50">
    //       <div className="connected-title account-elipses">{accountName}</div>
    //       <div className="connected-amount">{<Amount amount={value} />}</div>
    //     </div>
    //   </div>
    //   <ToggleSwitch
    //     onChange={() =>
    //       handleToggleSwitch({ accountId, avatar, id, accountName,value })
    //     }
    //     value={isChecked}
    //   />
    // </div>
  );
};
