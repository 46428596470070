import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import "./Moneyinsights.css";
import ChartDataLabels from "chartjs-plugin-datalabels";
import BarChartSection from "./BarChartSection";
import CategoryBreakdownSection from "./CategoryBreakdownSection";
import ExpenseHistorySection from "./ExpenseHistorySection";
import NoDataFound from "../NoDataFound";
import {
  ExpensesCategoryBreakDownList,
  MerchantCategoryBreakDownList,
} from "./AccordionMoneyInsights";
import PageTitle from "../PageTitle";
import UserImageInfoToggle from "../UserImageInfoToggle";
import axios from "axios";
import weburls from "../../Weburls/weburls";
import { toast } from "react-toastify";
import OverlayContainer from "../OverlayContainer/OverlayContainer";
import { Loading } from "../Loading/Loading";
import MerchantHistorySection from "./MerchantHistorySection";
import MerchantBarChartSection from "./MerchantBarChartSection";
import {
  formatNumberWithCommas,
  formatValue,
  pieChartColor,
} from "../../utilities/helper";
import MerchantCategoryBreakdownSection from "./MerchantCategoryBreakdownSection";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const Moneyinsights = () => {
  const token = localStorage.getItem("budget-token");
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Expenses");
  const [isPopupOpenMonthly, setPopupOpenMonthly] = useState(false);
  const [isPopupOpenMerchantMonthly, setPopupOpenMerchantMonthly] =
    useState(false);
  const [selectedMonthlyOption, setSelectedMonthlyOption] =
    useState("Last 12 months");
  const [isPopupOpenMonthlyIncome, setPopupOpenMonthlyIncome] = useState(false);
  const [selectedMonthlyOptionIncome, setSelectedMonthlyOptionIncome] =
    useState("Last 12 months");
  const [selectedMonthlyMerchantOption, setSelectedMonthlyMerchantOption] =
    useState("Last 12 months");
  const [selectedBarExpensesTitle, setSelectedBarExpensesTitle] =
    useState("Last 12 months");
  const [selectedBarIncomeTitle, setSelectedBarIncomeTitle] =
    useState("Last 12 months");
  const [selectedBarMerchantTitle, setSelectedBarMerchantTitle] =
    useState("Last 12 months");

  const [isPopupOpenTopExpenses, setPopupOpenTopExpenses] = useState(false);
  const [isPopupOpenMerchantCategory, setPopupOpenMerchantCategory] =
    useState(false);
  const [isPieExpensesCategoryData, setPieExpensesCategoryData] = useState();
  const [isExpensesBreakDown, setExpensesBreakDown] = useState("Last month");
  const [isMerchantBreakDown, setMerchantBreakDown] = useState("Last month");
  const [isExpenseCategoryData, setExpensesCategoryData] = useState([]);
  const [isPieIncomeCategoryData, setPieIncomeCategoryData] = useState();
  const [isMerchantCategoryData, setMerchantCategoryData] = useState([]);
  const [isIncomeCategoryData, setIncomeCategoryData] = useState([]);
  const [isIncomeBreakDown, setIncomeBreakDown] = useState("Last month");
  const [isTotalIncomeBreakDownPie, setTotalIncomeBreakDownPie] = useState(0);
  const [isNameIncomeBreakDownPie, setNameIncomeBreakDownPie] =
    useState("Total Income");
  const [isTotalExpensesBreakDownPie, setTotalExpensesBreakDownPie] =
    useState(0);
  const [isNameExpensesBreakDownPie, setNameExpensesBreakDownPie] =
    useState("Total Expenses");

  const [selectedMerchantHistoryData, setSelectedMerchantHistoryData] =
    useState([]);
  const [selectedExpenseHistoryData, setSelectedExpenseHistoryData] = useState(
    []
  );
  const [selectedIncomeHistoryData, setSelectedIncomeHistoryData] = useState(
    []
  );
  const [selectedMerchantBarAmount, setSelectedMerchantBarAmount] = useState(0);
  const [selectedBarAmount, setSelectedBarAmount] = useState(0);
  const [selectedBarIncomeAmount, setSelectedBarIncomeAmount] = useState(0);
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [merchatsList, setMerchatsList] = useState([]);
  const [oneOffCategory, setOneOffCategory] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverMerchantOpen, setPopoverMerchantOpen] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState(
    []
  );
  const [selectedMerchantList, setSelectedMerchantList] = useState([]);
  const [selectedIncomeCategories, setSelectedIncomeCategories] = useState([]);
  const [merchantHistoryData, setMerchantHistoryData] = useState({});
  const [expenseMoneyHistory, setExpenseMoneyHistory] = useState({});
  const [incomeMoneyHistory, setIncomeMoneyHistory] = useState({});
  const [isGroupChart, setGroupedChart] = useState(false);

  const navigate = useNavigate();
  const userId = localStorage.getItem("userUuid");

  let isIncomeBreakDownApi = "Last month";
  let isExpensesBreakDownApi = "Last month";
  let isExpensesStartDate = "";
  let isExpensesEndDate = "";
  let isIncomeStartDate = "";
  let isIncomeEndDate = "";
  let isMerchantBreakDownApi = "Last month";
  let isMerchantStartDate = "Last month";
  let isMerchantEndDate = "Last month";

  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);

  const handleTagMerchantClick = (id) => {
    setSelectedMerchantList((prev) => {
      const newSelection = prev.includes(id)
        ? prev.filter((tag) => tag !== id)
        : [...prev, id];
      return newSelection.length > 0 ? newSelection : [];
    });
  };
  const handleOptionMonthlyClick = (option) => {
    setSelectedMonthlyOption(option);
    const historyDataMapping = {
      "Last 3 months": expenseMoneyHistory?.lastThreeMonth,
      "Last 6 months": expenseMoneyHistory?.lastSixMonth,
      "Last 12 months": expenseMoneyHistory?.lastTwelvMonth,
    };
    setSelectedExpenseHistoryData(historyDataMapping[option]);
    const dataToProcess = historyDataMapping[option];
    const totalAmount = dataToProcess.reduce(
      (acc, e) => acc + e.total_income_amount,
      0
    );
    setSelectedBarAmount(totalAmount);
    activeTab === "Expenses"
      ? setSelectedBarExpensesTitle(option)
      : setSelectedBarIncomeTitle(option);
    setPopupOpenMonthly(false);
  };

  const handleOptionMerchantMonthlyClick = (option) => {
    setSelectedMonthlyMerchantOption(option);
    const historyDataMapping = {
      "Last 3 months": merchantHistoryData?.lastThreeMonth,
      "Last 6 months": merchantHistoryData?.lastSixMonth,
      "Last 12 months": merchantHistoryData?.lastTwelvMonth,
    };
    setSelectedMerchantHistoryData(historyDataMapping[option]);
    const dataToProcess = historyDataMapping[option];
    const totalAmount = dataToProcess.reduce(
      (acc, e) => acc + e.total_income_amount,
      0
    );
    setSelectedMerchantBarAmount(totalAmount);
    setSelectedBarMerchantTitle(option);
    setPopupOpenMerchantMonthly(false);
  };

  const handleOptionMonthlyIncomeClick = (option) => {
    setSelectedMonthlyOptionIncome(option);
    const historyDataMapping = {
      "Last 3 months": incomeMoneyHistory?.lastThreeMonth,
      "Last 6 months": incomeMoneyHistory?.lastSixMonth,
      "Last 12 months": incomeMoneyHistory?.lastTwelvMonth,
    };
    setSelectedIncomeHistoryData(historyDataMapping[option]);
    const dataToProcess = historyDataMapping[option];
    const totalAmount = dataToProcess.reduce(
      (acc, e) => acc + e.total_income_amount,
      0
    );
    setSelectedBarIncomeAmount(totalAmount);
    setPopupOpenMonthlyIncome(false);
  };

  const getCategory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
      setOneOffCategory(response?.data?.data?.transaction?.ONEOFF);
      setLoading(false);
    } catch (err) {
      toast.error(err);
    }
  };

  const getMerchantCategory = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${weburls.Get_Merchant_List}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      const sortedMerchants = response?.data?.merchant.sort((a, b) => {
        const nameA = a.name?.toLowerCase() ?? "";
        const nameB = b.name?.toLowerCase() ?? "";

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      setMerchatsList(sortedMerchants);
      setLoading(false);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const getExpenseHistoryData = async (category) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${weburls.Get_Money_Insight_History_Expense_data}${uuid}`,
        { categoryId: category },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const expenseData = response?.data?.expenses;
      setExpenseMoneyHistory(expenseData);

      const historyDataMapping = {
        "Last 3 months": expenseData?.lastThreeMonth,
        "Last 6 months": expenseData?.lastSixMonth,
        "Last 12 months": expenseData?.lastTwelvMonth,
      };

      setSelectedExpenseHistoryData(historyDataMapping[selectedMonthlyOption]);
      const dataToProcess = historyDataMapping[selectedMonthlyOption];
      const totalAmount = dataToProcess.reduce(
        (acc, e) => acc + e.total_income_amount,
        0
      );
      setSelectedBarAmount(totalAmount);
      setSelectedBarExpensesTitle(selectedMonthlyOption);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const getMerchantHistoryData = async (merchant) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${weburls.Get_Money_Trends_Merchant_History_Details}${uuid}`,
        { merchantId: merchant },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const merchantData = response?.data?.expenses;
      setMerchantHistoryData(merchantData);

      const historyDataMapping = {
        "Last 3 months": merchantData?.lastThreeMonth,
        "Last 6 months": merchantData?.lastSixMonth,
        "Last 12 months": merchantData?.lastTwelvMonth,
      };

      setSelectedMerchantHistoryData(
        historyDataMapping[selectedMonthlyMerchantOption]
      );
      const dataToProcess = historyDataMapping[selectedMonthlyMerchantOption];
      const totalAmount = dataToProcess.reduce(
        (acc, e) => acc + e.total_income_amount,
        0
      );
      setSelectedMerchantBarAmount(totalAmount);
      setSelectedBarMerchantTitle(selectedMonthlyMerchantOption);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const getMoneyTrendsCategoryBreakdownDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${weburls.Get_Money_Trends_CategoryBreakdown_Details}${uuid}`,
        {
          filter: isExpensesBreakDownApi.toLowerCase().replace(/\s+/g, ""),
          startDate: moment(isExpensesStartDate).format("YYYY-MM-DD"),
          endDate: moment(isExpensesEndDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const expenseCategoryData = response?.data?.data.sort(
        (a, b) => (b.total ?? 0) - (a.total ?? 0)
      );

      if (expenseCategoryData) {
        const labels = [];
        const data = [];
        const expenseData = [];
        const backgroundColors = [];
        let totalPie = 0;

        expenseCategoryData.forEach((element) => {
          totalPie += element.total || 0;
        });
        setTotalExpensesBreakDownPie(totalPie);
        expenseCategoryData.forEach((element) => {
          labels.push(element.master_category_name || "Unknown");
          expenseData.push(element);
          data.push(Math.round(((element.total || 0) / totalPie) * 100));
          backgroundColors.push(
            pieChartColor({
              colorCode: element.master_category_colour || "",
              name: element.master_category_name || "Unknown",
            })
          );
        });
        setExpensesCategoryData(expenseData);

        const chartData = {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColors,
              hoverBackgroundColor: backgroundColors,
              borderRadius: 5,
              cutout: "50%",
              borderWidth: 3,
            },
          ],
        };

        setPieExpensesCategoryData(chartData);
      }
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const getMoneyTrendsIncomeBreakdownDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${weburls.Get_Money_Trends_Income_Breakdown_Details}${uuid}`,
        {
          filter: isIncomeBreakDownApi.toLowerCase().replace(/\s+/g, ""),
          startDate: moment(isIncomeStartDate).format("YYYY-MM-DD"),
          endDate: moment(isIncomeEndDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const categoryData = response?.data?.data.sort(
        (a, b) => (b.total ?? 0) - (a.total ?? 0)
      );
      if (categoryData) {
        const labels = [];
        const data = [];
        const incomeData = [];
        const backgroundColors = [];
        let totalPie = 0;

        categoryData.forEach((element) => {
          totalPie += element.total || 0;
        });
        setTotalIncomeBreakDownPie(totalPie);
        categoryData.forEach((element) => {
          labels.push(element.master_category_name || "Unknown");
          incomeData.push(element);
          data.push(Math.round(((element.total || 0) / totalPie) * 100));
          backgroundColors.push(
            pieChartColor({
              colorCode: element.master_category_colour || "",
              name: element.master_category_name || "Unknown",
            })
          );
        });
        setIncomeCategoryData(incomeData);
        const chartData = {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColors,
              hoverBackgroundColor: backgroundColors,
              borderWidth: 0,
            },
          ],
        };
        setPieIncomeCategoryData(chartData);
      }
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const getMoneyTrendsMerchantBreakdownDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${weburls.Get_Money_Trends_Merchant_Breakdown_Details}${uuid}`,
        {
          filter: isMerchantBreakDownApi.toLowerCase().replace(/\s+/g, ""),
          startDate: moment(isMerchantStartDate).format("YYYY-MM-DD"),
          endDate: moment(isMerchantEndDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const merchantCategoryData = response?.data?.data.sort(
        (a, b) => (b.total_amount ?? 0) - (a.total_amount ?? 0)
      );
      setMerchantCategoryData(merchantCategoryData);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const getExpenseHistoryIncomeData = async (category) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${weburls.Get_Money_Insight_History_Income_data}${uuid}`,
        { categoryId: category },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const expenseData = response?.data?.expenses;
      setIncomeMoneyHistory(expenseData);
      const historyDataMapping = {
        "Last 3 months": expenseData?.lastThreeMonth,
        "Last 6 months": expenseData?.lastSixMonth,
        "Last 12 months": expenseData?.lastTwelvMonth,
      };
      setSelectedIncomeHistoryData(
        historyDataMapping[selectedMonthlyOptionIncome]
      );
      const dataToProcess = historyDataMapping[selectedMonthlyOptionIncome];
      const totalAmount = dataToProcess.reduce(
        (acc, e) => acc + e.total_income_amount,
        0
      );
      setSelectedBarIncomeAmount(totalAmount);
      setSelectedBarIncomeTitle(selectedMonthlyOptionIncome);
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataSequentially();
  }, []);

  const fetchDataSequentially = async () => {
    try {
      await getCategory();
      await getMerchantCategory();
      await getExpenseHistoryData("All");
      await getMoneyTrendsCategoryBreakdownDetails();
      await getMoneyTrendsIncomeBreakdownDetails();
      await getMerchantHistoryData("All");
      await getExpenseHistoryIncomeData("All");
      await getMoneyTrendsMerchantBreakdownDetails();
    } catch (err) {
      console.error("Error in sequential API calls:", err);
    }
  };

  const togglePopupMonthly = () => setPopupOpenMonthly(!isPopupOpenMonthly);
  const togglePopupMerchantMonthly = () =>
    setPopupOpenMerchantMonthly(!isPopupOpenMerchantMonthly);
  const togglePopupMonthlyIncome = () =>
    setPopupOpenMonthlyIncome(!isPopupOpenMonthlyIncome);
  const togglePopupTopExpenses = () =>
    setPopupOpenTopExpenses(!isPopupOpenTopExpenses);
  const togglePopupMerchantCategory = () =>
    setPopupOpenMerchantCategory(!isPopupOpenMerchantCategory);

  const handleToggle = (tab) => {
    setActiveTab(tab);
  };

  const labelValue = () => {
    let value = [];
    const dataToProcess =
      activeTab === "Expenses"
        ? selectedExpenseHistoryData
        : selectedIncomeHistoryData;
    if (dataToProcess.length > 0) {
      dataToProcess.map((e) => {
        const monthName = e.month;
        const year = 2024;
        const date = new Date(`${monthName} ${year}`);

        if (!isNaN(date.getTime())) {
          const monthShortName = date.toLocaleString("default", {
            month: "short",
          });
          value.push(monthShortName);
        } else {
          console.error("Invalid date:", `${monthName} ${year}`);
        }
      });
    }
    return value;
  };
  const labelMerchantValue = () => {
    let value = [];
    const dataToProcess = selectedMerchantHistoryData;
    if (dataToProcess.length > 0) {
      dataToProcess.map((e) => {
        const monthName = e.month;
        const year = 2024;
        const date = new Date(`${monthName} ${year}`);

        if (!isNaN(date.getTime())) {
          const monthShortName = date.toLocaleString("default", {
            month: "short",
          });
          value.push(monthShortName);
        } else {
          console.error("Invalid date:", `${monthName} ${year}`);
        }
      });
    }
    return value;
  };

  const barAmountValue = () => {
    let value = [];
    const dataToProcess =
      activeTab === "Expenses"
        ? selectedExpenseHistoryData
        : selectedIncomeHistoryData;
    if (dataToProcess.length > 0) {
      dataToProcess.map((e) => value.push(e.total_income_amount));
    }
    return value;
  };

  const barMerchantAmountValue = () => {
    let value = [];
    const dataToProcess = selectedMerchantHistoryData;
    if (dataToProcess.length > 0) {
      dataToProcess.map((e) => value.push(e.total_income_amount));
    }
    return value;
  };

  const barThicknessValue = () => {
    const labelCount = labelValue().length;
    if (labelCount === 1) return 500;

    const availableWidthPercentage = 600;
    const barWidth = availableWidthPercentage / labelCount;
    return barWidth;
  };

  const barMerchantThicknessValue = () => {
    const labelCount = labelMerchantValue().length;
    if (labelCount === 1) return 500;

    const availableWidthPercentage = 600;
    const barWidth = availableWidthPercentage / labelCount;
    return barWidth;
  };

  const handleBarClick = (event, chartElements) => {
    if (chartElements.length > 0) {
      const dataIndex = chartElements[0].index;
      const dataToProcess =
        activeTab === "Expenses"
          ? selectedExpenseHistoryData
          : selectedIncomeHistoryData;

      const totalAmount = dataToProcess[dataIndex].total_income_amount;
      activeTab === "Expenses"
        ? setSelectedBarAmount(totalAmount)
        : setSelectedBarIncomeAmount(totalAmount);
      activeTab === "Expenses"
        ? setSelectedBarExpensesTitle(dataToProcess[dataIndex].date)
        : setSelectedBarIncomeTitle(dataToProcess[dataIndex].date);
    } else {
      const dataToProcess =
        activeTab === "Expenses"
          ? selectedExpenseHistoryData
          : selectedIncomeHistoryData;
      const totalAmount = dataToProcess.reduce(
        (acc, e) => acc + e.total_income_amount,
        0
      );
      activeTab === "Expenses"
        ? setSelectedBarAmount(totalAmount)
        : setSelectedBarIncomeAmount(totalAmount);
      activeTab === "Expenses"
        ? setSelectedBarExpensesTitle(selectedMonthlyOption)
        : setSelectedBarIncomeTitle(selectedMonthlyOptionIncome);
    }
  };

  const handleMerchantBarClick = (event, chartElements) => {
    if (chartElements.length > 0) {
      const dataIndex = chartElements[0].index;
      const dataToProcess = selectedMerchantHistoryData;

      const totalAmount = dataToProcess[dataIndex].total_income_amount;
      setSelectedMerchantBarAmount(totalAmount);
      setSelectedBarMerchantTitle(dataToProcess[dataIndex].date);
    } else {
      const dataToProcess = selectedMerchantHistoryData;
      const totalAmount = dataToProcess.reduce(
        (acc, e) => acc + e.total_income_amount,
        0
      );
      setSelectedMerchantBarAmount(totalAmount);
      setSelectedBarMerchantTitle(selectedMonthlyMerchantOption);
    }
  };

  const dataBarChart = {
    labels: labelValue(),
    datasets: [
      {
        label: activeTab,
        data: barAmountValue(),
        backgroundColor: activeTab === "Expenses" ? "#FF8484" : "#469B88",
        borderColor: activeTab === "Expenses" ? "#FF8484" : "#469B88",
        borderWidth: 1,
        borderRadius: 5,
        barThickness: barThicknessValue(),
        maxBarThickness: barThicknessValue(),
        minBarLength: 2,
      },
    ],
  };

  const dataBarChartMerchant = {
    labels: labelMerchantValue(),
    datasets: [
      {
        label: activeTab,
        data: barMerchantAmountValue(),
        backgroundColor: "#8684FF",
        borderColor: "#8684FF",
        borderWidth: 1,
        borderRadius: 5,
        barThickness: barMerchantThicknessValue(),
        maxBarThickness: barMerchantThicknessValue(),
        minBarLength: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, chartElements) => handleBarClick(event, chartElements),
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositioner",
        enabled: true,
        backgroundColor: "#ffffff",
        bodyColor: "#000000",
        titleColor: "#707070",
        displayColors: false,
        bodyFont: {
          size: 15,
          weight: "700",
          family: "Neurialgrotesk",
        },
        labelFont: {
          size: 12,
          weight: "500",
          family: "Neurialgrotesk",
        },
        titleFont: {
          size: 12,
          weight: "bold",
          family: "Neurialgrotesk",
        },
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;

            let month = "";
            if (activeTab === "Expenses") {
              month = selectedExpenseHistoryData[index].month || "";
            } else {
              month = selectedIncomeHistoryData[index].month || "";
            }
            return `${month} total`;
          },

          label: function (tooltipItems) {
            return `$${formatNumberWithCommas(tooltipItems.raw)}`; /// GOPAL
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            family: "Arial, sans-serif",
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
      },
    },
  };

  Tooltip.positioners.myCustomPositioner = function (elements, eventPosition) {
    return {
      x: eventPosition.x,
      y: eventPosition.y,
      xAlign: "right",
      yAlign: "center",
    };
  };

  const optionsMerchant = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, chartElements) =>
      handleMerchantBarClick(event, chartElements),
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: "myCustomPositioner",
        enabled: true,
        backgroundColor: "#ffffff",
        bodyColor: "#000000",
        titleColor: "#707070",
        displayColors: false,
        bodyFont: {
          size: 15,
          weight: "700",
          family: "Neurialgrotesk",
        },
        labelFont: {
          size: 12,
          weight: "500",
          family: "Neurialgrotesk",
        },
        titleFont: {
          size: 12,
          weight: "bold",
          family: "Neurialgrotesk",
        },
        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            return `${selectedMerchantHistoryData[index].month || ""} total`;
          },

          label: function (tooltipItems) {
            return `$${formatNumberWithCommas(tooltipItems.raw)}`; /// GOPAL
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            family: "Arial, sans-serif",
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
      },
    },
  };

  const defaultData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
        borderWidth: 0,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    cutout: "55%",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 14,
          },
          color: "#838383",
        },
        onClick: (e, legendItem, legend) => {
          const index = legendItem.index;
          if (activeTab === "Expenses") {
            setNameExpensesBreakDownPie(
              isExpenseCategoryData[index].master_category_name
            );
            setTotalExpensesBreakDownPie(isExpenseCategoryData[index].total);
            var totalPie = 0;
            isIncomeCategoryData.forEach((element) => {
              totalPie += element.total || 0;
            });
            setTotalIncomeBreakDownPie(totalPie);
            setNameIncomeBreakDownPie("Total Income");
          } else {
            setNameIncomeBreakDownPie(
              isIncomeCategoryData[index].master_category_name
            );
            setTotalIncomeBreakDownPie(isIncomeCategoryData[index].total);

            var totalPie = 0;
            isExpenseCategoryData.forEach((element) => {
              totalPie += element.total || 0;
            });
            setTotalExpensesBreakDownPie(totalPie);
            setNameExpensesBreakDownPie("Total Expenses");
          }
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#ffffff",
        bodyColor: "#000000",
        footerColor: "#707070",
        titleColor: "#707070",
        displayColors: false,
        bodyFont: {
          size: 15,
          weight: "700",
          family: "Neurialgrotesk",
        },
        labelFont: {
          size: 12,
          weight: "500",
          family: "Neurialgrotesk",
        },
        titleFont: {
          size: 12,
          weight: "bold",
          family: "Neurialgrotesk",
        },
        footerFont: {
          size: 12,
          weight: "500",
          family: "Neurialgrotesk",
        },

        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            let emoji = "";
            if (activeTab === "Expenses") {
              emoji = isExpenseCategoryData[index].master_category_emoji || "";
            } else {
              emoji = isIncomeCategoryData[index].master_category_emoji || "";
            }
            const label = tooltipItems[0].label;
            return `${label} ${emoji}`;
          },

          label: function (tooltipItems) {
            const index = tooltipItems.dataIndex;

            let total = "";
            if (activeTab === "Expenses") {
              total = isExpenseCategoryData[index].total.toFixed(2) || "";
            } else {
              total = isIncomeCategoryData[index].total.toFixed(2) || "";
            }
            return `$${formatNumberWithCommas(total)}`; /// GOPAL
          },

          footer: function (tooltipItems) {
            const dataset = tooltipItems[0].dataset;
            const currentValue = dataset.data[tooltipItems[0].dataIndex];
            const total = dataset.data.reduce((acc, value) => acc + value, 0);
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${percentage}% of total`;
          },
        },
      },
      datalabels: {
        color: "#fff",
        formatter: (value) => `${value}%`,
        font: {
          size: 14,
          weight: "bold",
        },
        align: "center",
        anchor: "center",
      },
    },
    // onClick: (event, chartElements, chart) => {
    //   const index = chartElements[0].index;
    //       if (activeTab === "Expenses") {
    //         setNameExpensesBreakDownPie(
    //           isExpenseCategoryData[index].master_category_name
    //         );
    //         setTotalExpensesBreakDownPie(isExpenseCategoryData[index].total);
    //         var totalPie = 0;
    //         isIncomeCategoryData.forEach((element) => {
    //           totalPie += element.total || 0;
    //         });
    //         setTotalIncomeBreakDownPie(totalPie);
    //         setNameIncomeBreakDownPie("Total Incomes");
    //       } else {
    //         setNameIncomeBreakDownPie(
    //           isIncomeCategoryData[index].master_category_name
    //         );
    //         setTotalIncomeBreakDownPie(isIncomeCategoryData[index].total);

    //         var totalPie = 0;
    //         isExpenseCategoryData.forEach((element) => {
    //           totalPie += element.total || 0;
    //         });
    //         setTotalExpensesBreakDownPie(totalPie);
    //         setNameExpensesBreakDownPie("Total Expenses");
    //       }
    // },
  };

  const handleOptionTopExpensesClick = (option, name, startDate, endDate) => {
    if (activeTab === "Expenses") {
      if (option === "Custom date range") {
        isExpensesStartDate = startDate;
        isExpensesEndDate = endDate;
        isExpensesBreakDownApi = "custom";
        setExpensesBreakDown(name);
      } else {
        setExpensesBreakDown(option);
        isExpensesBreakDownApi = option;
      }
    } else {
      if (option === "Custom date range") {
        isIncomeStartDate = startDate;
        isIncomeEndDate = endDate;
        isIncomeBreakDownApi = "custom";
        setIncomeBreakDown(name);
      } else {
        setIncomeBreakDown(option);
        isIncomeBreakDownApi = option;
      }
    }
    setPopupOpenTopExpenses(false);
    activeTab === "Expenses"
      ? getMoneyTrendsCategoryBreakdownDetails()
      : getMoneyTrendsIncomeBreakdownDetails();
  };

  const handleOptionMerchantClick = async (
    option,
    name,
    startDate,
    endDate
  ) => {
    if (option === "Custom date range") {
      isMerchantStartDate = startDate;
      isMerchantEndDate = endDate;
      isMerchantBreakDownApi = "custom";
      setMerchantBreakDown(name);
    } else {
      setMerchantBreakDown(option);
      isMerchantBreakDownApi = option;
    }
    setPopupOpenMerchantCategory(false);
    await getMoneyTrendsMerchantBreakdownDetails();
  };

  const handleTagClick = (id, type) => {
    if (type === "expense") {
      setSelectedExpenseCategories((prev) => {
        const newSelection = prev.includes(id)
          ? prev.filter((tag) => tag !== id)
          : [...prev, id];
        return newSelection.length > 0 ? newSelection : [];
      });
      if (selectedIncomeCategories.length === 0) {
        setSelectedIncomeCategories([]);
      }
    } else if (type === "income") {
      setSelectedIncomeCategories((prev) => {
        const newSelection = prev.includes(id)
          ? prev.filter((tag) => tag !== id)
          : [...prev, id];
        return newSelection.length > 0 ? newSelection : [];
      });

      if (selectedExpenseCategories.length === 0) {
        setSelectedExpenseCategories([]);
      }
    }
  };

  const togglePopover = (type) => {
    setPopoverOpen(!popoverOpen);
    if (popoverOpen) {
      if (type === "expense") {
        const categoryId =
          selectedExpenseCategories.length === 0
            ? "All"
            : selectedExpenseCategories;
        getExpenseHistoryData(categoryId);
      } else if (type === "income") {
        const categoryId =
          selectedIncomeCategories.length === 0
            ? "All"
            : selectedIncomeCategories;
        getExpenseHistoryIncomeData(categoryId);
      }
    }
  };

  const toggleMerchantPopover = () => {
    setPopoverMerchantOpen(!popoverMerchantOpen);
    if (popoverMerchantOpen) {
      const categoryId =
        selectedMerchantList.length === 0 ? "All" : selectedMerchantList;
      getMerchantHistoryData(categoryId);
    }
  };

  const handleAddCategory = (transactionId) => {
    setPopoverOpen((prevState) => ({
      ...prevState,
      [transactionId]: false,
    }));
    setShowAddCategory(!showAddCategory);
  };

  const transaction = {
    transactionUuid: 1,
    amount: 100,
  }; // Dummy data for transaction

  console.log(isGroupChart, "isGroupChart");

  return (
    <>
      <div className="app-main__outer edit-cashflow-container-main">
        <div className="row">
          <div className="header-budget-main-div">
            <div
              className="header-budget-text-div"
              style={{ marginTop: "0px" }}
            >
              <PageTitle
                title={"Money insights"}
                description={
                  "Here’s your breakdown of where you spend & earn your money."
                }
              />
            </div>

            <div
              className="header-budget-sub-div"
              style={{ gap: "30px", alignItems: "center" }}
            >
              <div
                style={{
                  width: "327px",
                  height: "53px",
                  borderRadius: "360px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div className="top-header-switch-box">
                  <button
                    className={`toggle-button-btn ${
                      activeTab === "Expenses" ? "active active-expenses" : ""
                    }`}
                    onClick={() => handleToggle("Expenses")}
                  >
                    Expenses
                  </button>
                  <button
                    className={`toggle-button-btn ${
                      activeTab === "Income" ? "active active-income" : ""
                    }`}
                    onClick={() => handleToggle("Income")}
                  >
                    Income
                  </button>
                </div>
              </div>
              <UserImageInfoToggle customStyle={true} />
            </div>
          </div>
        </div>
        <MerchantHistorySection
          isGroupChart={isGroupChart}
          setGroupedChart={setGroupedChart}
          togglePopupMonthly={
            activeTab === "Expenses"
              ? togglePopupMonthly
              : togglePopupMonthlyIncome
          }
          isPopupOpenMonthly={
            activeTab === "Expenses"
              ? isPopupOpenMonthly
              : isPopupOpenMonthlyIncome
          }
          selectedMonthlyOption={
            activeTab === "Expenses"
              ? selectedMonthlyOption
              : selectedMonthlyOptionIncome
          }
          handleOptionMonthlyClick={
            activeTab === "Expenses"
              ? handleOptionMonthlyClick
              : handleOptionMonthlyIncomeClick
          }
          heading={
            activeTab === "Expenses" ? "Expense history" : "Income history"
          }
        />

        <BarChartSection
          isGroupChart={isGroupChart}
          activeTab={activeTab}
          transaction={transaction}
          togglePopover={togglePopover}
          expenseCategories={category}
          incomeCategories={icomecat}
          oneOffCategory={oneOffCategory}
          popoverOpen={popoverOpen}
          showAddCategory={showAddCategory}
          handleAddCategory={handleAddCategory}
          data={dataBarChart}
          options={options}
          selectedCategories={
            activeTab === "Expenses"
              ? selectedExpenseCategories
              : selectedIncomeCategories
          }
          setSelectedCategories={
            activeTab === "Expenses"
              ? setSelectedExpenseCategories
              : setSelectedIncomeCategories
          }
          handleTagClick={handleTagClick}
          type={activeTab === "Expenses" ? "expense" : "income"}
          totalAmount={
            activeTab === "Expenses"
              ? selectedBarAmount
              : selectedBarIncomeAmount
          }
          selectedBarTitle={
            activeTab === "Expenses"
              ? selectedBarExpensesTitle
              : selectedBarIncomeTitle
          }
        />

        <CategoryBreakdownSection
          togglePopupTopExpenses={togglePopupTopExpenses}
          isPopupOpenTopExpenses={isPopupOpenTopExpenses}
          selectedTopExpensesOption={
            activeTab === "Expenses" ? isExpensesBreakDown : isIncomeBreakDown
          }
          handleOptionTopExpensesClick={handleOptionTopExpensesClick}
          heading={"Category breakdown"}
        />
        <div
          className="category-breakdown-row"
          style={{
            height: "600px",
          }}
        >
          <div
            className="doughnut-card"
            onClick={() => {
              if (activeTab !== "Expenses") {
                var totalPie = 0;
                isIncomeCategoryData.forEach((element) => {
                  totalPie += element.total || 0;
                });
                setTotalIncomeBreakDownPie(totalPie);
                setNameIncomeBreakDownPie("Total Income");
              } else {
                var totalPie = 0;
                isExpenseCategoryData.forEach((element) => {
                  totalPie += element.total || 0;
                });
                setTotalExpensesBreakDownPie(totalPie);
                setNameExpensesBreakDownPie("Total Expenses");
              }
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <div
              style={{
                fontSize: "22px",
                fontWeight: 500,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "40px",
              }}
            >
              <span>
                {activeTab === "Expenses"
                  ? isNameExpensesBreakDownPie
                  : isNameIncomeBreakDownPie}
                :
              </span>
              <span
                style={{
                  color: activeTab === "Expenses" ? "#FF0A0A" : "#469B88",
                }}
              >
                $
                {activeTab === "Expenses"
                  ? formatValue(isTotalExpensesBreakDownPie)
                  : formatValue(isTotalIncomeBreakDownPie)}
              </span>
            </div>
            <Doughnut
              data={
                (activeTab === "Expenses"
                  ? isPieExpensesCategoryData
                  : isPieIncomeCategoryData) || defaultData
              }
              options={doughnutOptions}
              style={{ maxHeight: "444px" }}
            />
          </div>
          <div className="category-card">
            <div
              className="acccount-balances-wrappper-left-side"
              style={{
                height: "100%",
                overflowY: "auto",
                width: "100%",
                alignContent: "start",
              }}
            >
              {activeTab === "Expenses" ? (
                isExpenseCategoryData.length > 0 ? (
                  isExpenseCategoryData.map((item) => (
                    <ExpensesCategoryBreakDownList
                      item={item}
                      isExpensesBreakDown={isExpensesBreakDown}
                      activeTab={activeTab}
                    />
                  ))
                ) : (
                  <NoDataFound />
                )
              ) : isIncomeCategoryData.length > 0 ? (
                isIncomeCategoryData.map((item) => (
                  <ExpensesCategoryBreakDownList
                    item={item}
                    isExpensesBreakDown={isIncomeBreakDown}
                    activeTab={activeTab}
                  />
                ))
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
        {activeTab === "Expenses" && (
          <>
            <MerchantHistorySection
              isGroupChart={isGroupChart}
              setGroupedChart={setGroupedChart}
              togglePopupMonthly={togglePopupMerchantMonthly}
              isPopupOpenMonthly={isPopupOpenMerchantMonthly}
              selectedMonthlyOption={selectedMonthlyMerchantOption}
              handleOptionMonthlyClick={handleOptionMerchantMonthlyClick}
              heading={"Merchant history"}
            />
            <MerchantBarChartSection
              togglePopover={toggleMerchantPopover}
              merchatsList={merchatsList}
              popoverOpen={popoverMerchantOpen}
              data={dataBarChartMerchant}
              options={optionsMerchant}
              selectedCategories={selectedMerchantList}
              setSelectedCategories={setSelectedMerchantList}
              handleTagClick={handleTagMerchantClick}
              totalAmount={selectedMerchantBarAmount}
              selectedBarTitle={selectedBarMerchantTitle}
            />
            <MerchantCategoryBreakdownSection
              togglePopupTopExpenses={togglePopupMerchantCategory}
              isPopupOpenTopExpenses={isPopupOpenMerchantCategory}
              selectedTopExpensesOption={isMerchantBreakDown}
              handleOptionTopExpensesClick={handleOptionMerchantClick}
              heading={"Merchant breakdown"}
            />
            <div
              className="merchants-wrappper-left-side"
              style={{
                width: "100%",
              }}
            >
              {isMerchantCategoryData.length > 0 ? (
                isMerchantCategoryData.map((item) => (
                  <MerchantCategoryBreakDownList
                    item={item}
                    isMerchantBreakDown={isMerchantBreakDown}
                    activeTab={activeTab}
                    merchatsList={merchatsList}
                  />
                ))
              ) : (
                <NoDataFound />
              )}
            </div>
          </>
        )}
      </div>
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default Moneyinsights;
