import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";
import { useDispatch, useSelector } from "react-redux";
import { getUserPreferredView } from "../../../store/slices/SettingPopUpSlice/SettingPopUpSlice";

const useBudget = () => {
  const { uuid } = useParams();
  const [isBudgetBreakToggled, setIsBudgetBreakToggled] = useState(false);
  const [incomeBudgetsData, setIncomeBudgetsData] = useState([]);
  const [expenseBudgetsData, setExpenseBudgetsData] = useState([]);
  const [oneOffBudgetsData, setOneOffBudgetsData] = useState([]);
  const [loadingCount, setLoadingCount] = useState(0);
  const [completeBudgetDataIncome, setCompleteBudgetDataIncome] = useState([]);
  const [completeBudgetDataExpense, setCompleteBudgetDataExpense] = useState(
    []
  );
  const [loadingSingleBudgets, setLoadingSingleBudgets] = useState({});
  const [remainingAmount, setRemainingAmount] = useState({
    income: 0,
    expense: 0,
  });
  const [totalAmount, setTotalAmount] = useState({
    income: 0,
    expense: 0,
  });
  const navigate = useNavigate();
  const userId = localStorage.getItem("userUuid");

  const { preferredView } = useSelector((state) => state.popupsetting);

  const now = new Date();
  const baseYear = now.getFullYear();
  const baseMonth = now.getMonth() + 1;

  const [tabYear, setTabYear] = useState(baseYear);
  const [tabMonth, setTabMonth] = useState(baseMonth);
  const [showPopover, setShowPopover] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);

  const updateLoadingState = (isLoading) => {
    setLoadingCount((prevCount) =>
      isLoading ? prevCount + 1 : Math.max(prevCount - 1, 0)
    );
  };

  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);

  useEffect(() => {
    dispatch(getUserPreferredView());
  }, [dispatch, preferredView]);

  const fetchAllBudgetData = async () => {
    updateLoadingState(true);
    try {
      await fetchIncomeAllData();

      await fetchExpenseAllData();

      await fetchOneOffAllData();
    } catch (error) {
      console.error("Error fetching budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      updateLoadingState(false);
    }
  };

  const fetchOneOffAllData = async () => {
    // Fetch income budget data
    const oneOffResponse = await HttpClient.get(
      `${API_ENDPOINTS.GET_ONE_OFF_BUDGET}${uuid}`
    );

    if (oneOffResponse?.data) {
      setOneOffBudgetsData(oneOffResponse.data);
    }
  };

  const fetchIncomeAllData = async () => {
    // Fetch income budget data
    const incomeResponse = await HttpClient.get(
      `${API_ENDPOINTS.GET_INCOME_BUDGET}${uuid}`
    );

    if (incomeResponse?.data) {
      const allIncome = incomeResponse.data.income.monthlyData;
      setCompleteBudgetDataIncome(allIncome);

      const filteredData = allIncome.filter(
        (budget) => budget.month === tabMonth && budget.year === tabYear
      );
      setIncomeBudgetsData(filteredData);
    }
  };

  const fetchExpenseAllData = async () => {
    // Fetch expense budget data
    const expenseResponse = await HttpClient.get(
      `${API_ENDPOINTS.GET_EXPENSE_BUDGET}${uuid}`
    );

    if (expenseResponse?.data) {
      const allExpenses = expenseResponse.data.expenses.monthlyData;
      setCompleteBudgetDataExpense(allExpenses);

      const filteredData = allExpenses.filter(
        (budget) => budget.month === tabMonth && budget.year === tabYear
      );
      setExpenseBudgetsData(filteredData);
    }
  };

  const addIncome = async (category, amount, isFullLoading = true) => {
    updateLoadingState(isFullLoading);
    try {
      const payload = {
        incomeName: category?.categoryName,
        incomeAmount: amount.replace("$", ""),
        howOften: "Monthly",
        colour: category?.categoryColour,
        isSpreadAcrossPeriods: true,
        emoji: category?.categoryEmoji,
        type: "CUSTOM",
        user_category_id: category?.categoryId || 0,
        startDate: "",
        endDate: "",
      };
      const url = `${API_ENDPOINTS.ADD_INCOME_BUDGET}${uuid}`;

      const response = await HttpClient.post(`${url}`, payload);

      if (response?.data?.code === 1) {
        await fetchIncomeAllData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(`Something went wrong while adding income category`);
    } finally {
      updateLoadingState(false);
    }
  };

  const updateOneOffBudget = async (budget, newValue) => {
    try {
      const formattedAmount = newValue.replace(/^\$/, "").replace(",", "");
      setLoadingSingleBudgets((prev) => ({
        ...prev,
        [budget.uuid]: true,
      }));
      const payload = {
        name: budget.category_name,
        amount: formattedAmount,
        colour: budget.colour,
        emoji: budget.emoji,
      };

      const url = `${API_ENDPOINTS.UPDATE_ONE_OFF_BUDGET}${budget.uuid}/${uuid}`;

      const response = await HttpClient.put(url, payload);

      if (response?.data?.code === 1) {
        await fetchOneOffAllData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(`Something went wrong while updating oneoff category`);
    } finally {
      setLoadingSingleBudgets((prev) => ({
        ...prev,
        [budget.uuid]: false,
      }));
    }
  };

  const updateIncomeBudget = async (data, editAmount) => {
    try {
      setLoadingSingleBudgets((prev) => ({
        ...prev,
        [data.income_uuid]: true,
      }));
      const isCurrent = localStorage.getItem("isAllFutureMonth") || "allMonth";
      const formattedAmount = editAmount.replace(/^\$/, "").replace(",", "");
      const payload = {
        incomeName: data.income_name ?? "",
        incomeAmount: formattedAmount,
        lastPaymentDate: data.last_payment_date ?? "",
        howOften: "Monthly",
        colour: data.colour ?? "",
        isSpreadAcrossPeriods: data.is_spread_across_periods ?? "",
        emoji: data.emoji ?? "",
        isCashflow: true,
        startDate: "",
        endDate: "",
        month: tabMonth,
        year: tabYear,
        isCurrentMonth: isCurrent === "monthOnly",
        isAllFutureMonth: isCurrent === "allMonth",
      };

      const url = `${API_ENDPOINTS.UPDATE_INCOME_BUDGET}${uuid}/${data.income_uuid}`;
      const response = await HttpClient.put(url, payload);

      if (response?.data?.code === 1) {
        await fetchIncomeAllData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(`Something went wrong while updating income budget`);
    } finally {
      setLoadingSingleBudgets((prev) => ({
        ...prev,
        [data.income_uuid]: false,
      }));
    }
  };
  const updateExpenseBudget = async (data, editAmount) => {
    try {
      setLoadingSingleBudgets((prev) => ({
        ...prev,
        [data.expense_uuid]: true,
      }));
      const isCurrent = localStorage.getItem("isAllFutureMonth") || "allMonth";
      const formattedAmount = editAmount.replace(/^\$/, "").replace(",", "");
      const payload = {
        userUuid: uuid,
        expenseUuid: data.expense_uuid ?? "",
        expenseAmount: formattedAmount,
        lastPaymentDate: data.last_payment_date ?? "",
        howOften: "Monthly",
        colour: data.colour ?? "",
        emoji: data.emoji ?? "",
        expenseName: data.expense_name ?? "",
        user_category_id: (data.user_category_id ?? 0).toString(),
        needOrWant: data.needWant ?? "Want",
        isSpreadAcrossPeriods: data.is_spread_across_periods ?? true,
        isCashflow: true,
        isDefaultCategory: data.is_default_category ?? "",
        startDate: "",
        endDate: "",
        month: tabMonth,
        year: tabYear,
        isCurrentMonth: isCurrent === "monthOnly",
        isAllFutureMonth: isCurrent === "allMonth",
      };

      const url = `${API_ENDPOINTS.UPDATE_EXPENSE_BUDGET}`;
      const response = await HttpClient.put(url, payload);

      if (response?.data?.code === 1) {
        await fetchExpenseAllData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(`Something went wrong while updating expense budget`);
    } finally {
      setLoadingSingleBudgets((prev) => ({
        ...prev,
        [data.expense_uuid]: false,
      }));
    }
  };

  const addExpense = async (
    category,
    amount,
    categoryType,
    isFullLoading = true
  ) => {
    updateLoadingState(isFullLoading);
    try {
      const payload = {
        user_category_id: category?.categoryId || 0,
        expenseAmount: amount.replace("$", ""),
        howOften: "Monthly",
        colour: category?.categoryColour,
        emoji: category?.categoryEmoji,
        expenseName: category.categoryName || "",
        isDefaultCategory: true,
        needOrWant: "Want",
        isSpreadAcrossPeriods: true,
        startDate: "",
        endDate: "",
        isAutoCategories: false,
        type: categoryType,
      };

      const url = `${API_ENDPOINTS.ADD_EXPENSE_BUDGET}${uuid}`;

      const response = await HttpClient.post(`${url}`, payload);

      if (response?.data?.code === 1) {
        await fetchExpenseAllData();
      } else if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
    } catch (error) {
      toast.error(`Something went wrong while adding expense category`);
    } finally {
      updateLoadingState(false);
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchAllBudgetData();
    }
  }, [uuid]);

  const handleCheckScreenData = () => {
    setExpenseBudgetsData(filteredExpenses);
    setIncomeBudgetsData(filteredIncome);
  };

  const filteredExpenses = () => {
    return completeBudgetDataExpense.filter(
      (budget) => budget.month === tabMonth && budget.year === tabYear
    );
  };

  const filteredIncome = () => {
    return completeBudgetDataIncome.filter(
      (budget) => budget.month === tabMonth && budget.year === tabYear
    );
  };

  const autoSingleBudgetExpenses = async (id, isFullLoading = true) => {
    updateLoadingState(isFullLoading);
    try {
      const payload = {
        howOften: "Monthly",
        categoryId: id,
      };
      const response = await HttpClient.post(
        `${API_ENDPOINTS.AUTO_SINGLE_BUDGET_EXPENSES}${uuid}`,
        payload
      );

      if (response?.data?.code === 1) {
        return response?.data?.data?.amount || "0";
      } else {
        return "0";
      }
    } catch (error) {
      console.error("Error fetching budget expenses:", error);
      return "0";
    } finally {
      updateLoadingState(false);
    }
  };

  const handleLeftClick = () => {
    if (tabYear === baseYear - 1 && tabMonth === baseMonth) {
      console.warn("Reached 12 months back limit");
      return;
    }

    if (tabMonth === 1) {
      setTabMonth(12);
      setTabYear((prevYear) => prevYear - 1);
    } else {
      setTabMonth((prevMonth) => prevMonth - 1);
    }
  };

  const handleRightClick = () => {
    if (tabYear === baseYear + 1 && tabMonth === baseMonth) {
      console.warn("Reached 12 months forward limit");
      return;
    }

    if (tabMonth === 12) {
      setTabMonth(1);
      setTabYear((prevYear) => prevYear + 1);
    } else {
      setTabMonth((prevMonth) => prevMonth + 1);
    }
  };

  const handleBottomArrowClick = () => {
    setShowPopover((prev) => !prev);
  };

  useEffect(() => {
    const totalMonthly = expenseBudgetsData?.length
      ? expenseBudgetsData.reduce(
          (total, budget) => total + budget.total_transaction_amount,
          0
        )
      : 0;
    const totalIncome = expenseBudgetsData.reduce(
      (total, budget) => total + (budget.budget_amount || 0),
      0
    );
    setRemainingAmount((prevState) => ({
      ...prevState,
      expense: totalIncome,
    }));
    setTotalAmount((prevState) => ({
      ...prevState,
      expense: totalMonthly,
    }));
  }, [expenseBudgetsData]);

  useEffect(() => {
    const totalMonthly = incomeBudgetsData?.length
      ? incomeBudgetsData.reduce(
          (total, budget) => total + budget.total_transaction_amount,
          0
        )
      : 0;
    const totalIncome = incomeBudgetsData.reduce(
      (total, budget) => total + (budget.budget_amount || 0),
      0
    );
    setRemainingAmount((prevState) => ({
      ...prevState,
      income: totalIncome,
    }));
    setTotalAmount((prevState) => ({
      ...prevState,
      income: totalMonthly,
    }));
  }, [incomeBudgetsData, setTotalAmount]);

  useEffect(() => {
    handleCheckScreenData();
  }, [tabMonth, tabYear]);

  const isLoading = loadingCount > 0;

  return {
    uuid,
    handleLeftClick,
    handleRightClick,
    handleBottomArrowClick,
    fetchAllBudgetData,
    incomeBudgetsData,
    completeBudgetDataIncome,
    completeBudgetDataExpense,
    expenseBudgetsData,
    isBudgetBreakToggled,
    setIsBudgetBreakToggled,
    totalAmount,
    remainingAmount,
    oneOffBudgetsData,
    isLoading,
    preferredView,
    addIncome,
    addExpense,
    tabYear,
    tabMonth,
    autoSingleBudgetExpenses,
    updateIncomeBudget,
    updateExpenseBudget,
    updateOneOffBudget,
    loadingSingleBudgets,
    fetchIncomeAllData,
    fetchExpenseAllData,
    setTabYear,
    setTabMonth,
    showPopover,
    setShowPopover,
  };
};

export default useBudget;
