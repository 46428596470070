import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { planType, planTypeGoals } from "../../../utilities/helper";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";

const useGoals = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [whoopsPopUp, setWhoopsPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [oneTimeOTP, setOneTimeOTP] = useState();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userData?.username;

  const oneTimeLogin = useCallback(async () => {
    const response = await HttpClient.get(
      API_ENDPOINTS.ONETIME_LOGIN + `${uuid}`
    );
    if (response?.data) {
      setOneTimeOTP(response?.data.oneTimeToken);
    } else {
      toast.error(response?.data?.errors?.detail || "No Role Found", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const fetchGoalDetails = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_ALL_GOALS}${uuid}`
      );
      if (response?.data) {
        setGoals(response?.data?.goals);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      setIsLoading(false);
    }
  }, [uuid]);

  const fetchedPlanData = async () => {
    const body = { email: userEmail };
    try {
      const response = await HttpClient.post(
        API_ENDPOINTS.GET_PLAN_DETAILS,
        body
      );
      setPlanDetails(response.data);
    } catch (error) {
      console.error("Error fetching plan details:", error);
    }
  };

  useEffect(() => {
    fetchedPlanData();
  }, []);

  useEffect(() => {
    fetchGoalDetails();
  }, [fetchGoalDetails]);

  const handleEditGoal = (goal) => {
    setSelectedGoal(goal);
    setActive(true);
  };

  function shouldNavigateToWhoopsScreen(plan, goalsCount) {
    const goalLimits = {
      Standard: 4,
      Basic: 2,
      Freemium: 1,
    };

    if (!(plan in goalLimits)) {
      return false;
    }

    return goalsCount >= goalLimits[plan];
  }

  const handleNavigate = () => {
    if (!planDetails) return;

    const plan = planTypeGoals(planDetails.planType);
    const goalLength = goals.length;

    if (shouldNavigateToWhoopsScreen(plan, goalLength)) {
      setWhoopsPopUp(true);
      oneTimeLogin();
    } else {
      navigate(`/goals/addnewgoal/${uuid}`);
    }
  };

  return {
    handleNavigate,
    isPopupOpenInfo,
    togglePopupInfo,
    goals,
    uuid,
    handleEditGoal,
    whoopsPopUp,
    planDetails,
    setWhoopsPopUp,
    isLoading,
    oneTimeOTP,
  };
};

export default useGoals;
