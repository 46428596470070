import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useExpenseCard from "../../hooks/DashboardCards/useExpenseCard";
import useIncomeCard from "../../hooks/DashboardCards/useIncomeCard";
import CustomDateRangePopup from "../../ModalForm/Popup/CustomDateRangePopup";
import { getFormattedDateRangeString } from "../../utilities/Statics";

const IncomeExpenses = () => {
  // states
  const [selectedExpensesOption, setSelectedExpensesOption] =
    useState("This month");
  const [selectedIncomeOption, setSelectedIncomeOption] =
    useState("This month");
  const [incomePopOver, setIncomePopOver] = useState(false);
  const [expensesPopOver, setExpensesPopOver] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState();
  const [selectedDateRangeExpense, setSelectedDateRangeExpense] = useState();

  const selectedIncomeRange = getFormattedDateRangeString(selectedDateRange);
  const selectedExpenseRange = getFormattedDateRangeString(
    selectedDateRangeExpense
  );

  //params
  const { uuid } = useParams();

  // hooks
  const { data } = useIncomeCard({
    selectedIncomeOption,
    uuid,
    selectedDateRange,
  });

  const { data: expenseData } = useExpenseCard({
    selectedExpensesOption,
    uuid,
    selectedDateRangeExpense,
  });

  const handleIncomeClick = (option) => {
    setSelectedIncomeOption(option);
    setIncomePopOver(false);
  };
  const handleExpensesClick = (option) => {
    setSelectedExpensesOption(option);
    setExpensesPopOver(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "16px",
          width: "100%",
          height: "100%",
        }}
      >
        {/* Income Box */}
        <div
          className="br-10 bg-white h-100 d-flex align-content-between flex-wrap px-3 py-2"
          style={{
            flex: 1,
            boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <h4 className="fs-20 fw-500 lh-34 text-charcoal mb-0">Income</h4>
            <div className="dropdown">
              <span
                className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
                style={{
                  padding: "2px 10px",
                  height: "25px",
                  borderRadius: "20px",
                  cursor: "pointer",
                }}
                id="Popover1"
                onClick={() => setIncomePopOver(!incomePopOver)}
                aria-haspopup="true"
                aria-expanded={incomePopOver}
              >
                {selectedIncomeOption === "Custom date range"
                  ? selectedIncomeRange
                  : selectedExpensesOption}
              </span>
              {incomePopOver && (
                <CustomDateRangePopup
                  isOpen={incomePopOver}
                  onClose={() => setIncomePopOver(!incomePopOver)}
                  targetId="Popover1"
                  title="Date range"
                  options={[
                    "This week",
                    "This month",
                    "This year",
                    "Last week",
                    "Last month",
                    "Custom date range",
                  ]}
                  selectedOption={selectedIncomeOption}
                  handleOptionClick={handleIncomeClick}
                  setSelectedDateRange={setSelectedDateRange}
                />
              )}
            </div>
          </div>

          <div className="">
            <h3 className="fs-32 fw-500 lh-34">
              $
              {data?.data
                ? Number(data.data).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : "0.00"}
            </h3>
            <span className="fs-15 fw-400 lh-34 text-medium-gray">
              {selectedIncomeOption}'s income
            </span>
          </div>
        </div>

        {/* Expenses Box */}
        <div
          className="br-10 bg-white h-100 d-flex align-content-between flex-wrap px-3 py-2"
          style={{
            flex: 1,
            boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <h4 className="fs-20 fw-500 lh-34 text-charcoal mb-0">Expenses</h4>
            <span
              id="Popover2"
              className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
              style={{
                padding: "2px 10px",
                height: "25px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
              onClick={() => setExpensesPopOver(!expensesPopOver)}
              aria-haspopup="true"
              aria-expanded={expensesPopOver}
            >
              {selectedExpensesOption === "Custom date range"
                ? selectedExpenseRange
                : selectedExpensesOption}
            </span>
            {expensesPopOver && (
              <CustomDateRangePopup
                isOpen={expensesPopOver}
                onClose={() => setExpensesPopOver(!expensesPopOver)}
                targetId="Popover2"
                title="Date range"
                options={[
                  "This week",
                  "This month",
                  "This year",
                  "Last week",
                  "Last month",
                  "Custom date range",
                ]}
                selectedOption={selectedExpensesOption}
                handleOptionClick={handleExpensesClick}
                setSelectedDateRange={setSelectedDateRangeExpense}
              />
            )}
          </div>

          <div className="">
            <h3 className="fs-32 fw-500 lh-34">
              $
              {expenseData?.data
                ? Number(expenseData.data).toLocaleString(undefined)
                : "0.00"}
            </h3>
            <span className="fs-15 fw-400 lh-34 text-medium-gray">
              {selectedExpensesOption}'s expenses
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeExpenses;
