import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./DatePickerModal.css";
import moment from "moment";

const DatePickerModal = (props) => {
  const { openDatePicker, setOpenDatePicker, handleChange } = props;
  const [selectedDate, setSelectedDate] = useState(new Date());

  const selectDate = (date) => {
    setSelectedDate(date);
    const formattedDate = moment(date).format("YYYY-MM-DD");

    const event = {
      target: {
        name: "startDate",
        value: formattedDate,
      },
    };
    handleChange(event);
  };

  // Apply selected date range
  const handleApply = () => {
    setOpenDatePicker(false);
  };

  return (
    <>
      <div
        className={`modal-background ${openDatePicker ? "open-modal" : ""}`}
        onClick={handleApply}
      >
        <div
          className="bg-white br-10 pb-3 px-2"
          onClick={(e) => e.stopPropagation()}
        >
          <Calendar
            date={selectedDate}
            onChange={(date) => selectDate(date)}
            color="#469B88"
          />
          <div className="button-container me-2">
            <button className="text-button" onClick={handleApply}>
              Cancel
            </button>
            <button className="text-button" onClick={handleApply}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatePickerModal;
