import moment from "moment/moment";
import React from "react";
import { EditGoal } from "../../Components/Innerpanel/EditGoal";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer";
import { Loading } from "../../Components/Loading/Loading";
import { ConnectedAccounts } from "../../Components/ConnectedAccountsCard";
import ConfirmationModal from "../../ModalForm/Popup/ConfirmationModal";
import { moduleInfoPopUpData } from "../../utilities/Statics";
import PageTitle from "../../Components/PageTitle";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle";
import {
  adjustLightness,
  pickColorItemList,
  progressColorItemList,
} from "../../utilities/color-generator";
import useGoalDetails from "../../hooks/Goals/useGoalDetails/useGoalDetails";
import { ConnectedAccountsAddGoal } from "../AddNewGoal/ConnectedAccountsAddGoal";
import {
  amountMinusWithSign,
  formatNumberWithCommas,
} from "../../utilities/helper";

const GoalDetails = () => {
  const {
    isPopupOpenInfo,
    togglePopupInfo,
    active,
    goals,
    handleCancelEdit,
    handleSaveEdit,
    handleDeleteGoal,
    isLinkedToAccount,
    setIsLinkedToAccount,
    setShowPicker,
    showPicker,
    chosenEmoji,
    updateGoal,
    setShowConfirmationModal,
    showConfirmationModal,
    selectedAccounts,
    setChosenEmoji,
    accountData,
    handleToggleSwitch,
    loading,
    isUpdadting,
  } = useGoalDetails();

  return (
    <>
      <div className="app-main__outer financial-goals-main">
        <div className="row">
          <PageTitle
            title={"Goal details"}
            description={
              "Let's set some goals, it’s always good having goals and something to aim for."
            }
          />
          <UserImageInfoToggle
            title={moduleInfoPopUpData.goals.title}
            description={moduleInfoPopUpData.goals.description}
            isPopupOpenInfo={isPopupOpenInfo}
            togglePopupInfo={togglePopupInfo}
          />
        </div>
        {/* <div className="row new-finance"> */}
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="">
              {active && (
                <EditGoal
                  goal={goals}
                  onCancel={handleCancelEdit}
                  handleSaveEdit={handleSaveEdit}
                  onDelete={handleDeleteGoal}
                  isLinkedToAccount={isLinkedToAccount}
                  setIsLinkedToAccount={setIsLinkedToAccount}
                  setShowPicker={setShowPicker}
                  showPicker={showPicker}
                  chosenEmoji={chosenEmoji}
                  updateGoal={updateGoal}
                  setShowConfirmationModal={setShowConfirmationModal}
                  showConfirmationModal={showConfirmationModal}
                  selectedAccounts={selectedAccounts}
                  setChosenEmoji={setChosenEmoji}
                  isUpdadting={isUpdadting}
                />
              )}
            </div>
          </div>

          <div className="col-md-6 " key={goals?.goalUuid}>
            <div
              className="black sub-head-text lh-26"
              style={{ maginTop: "10px", marginBottom: "10px" }}
            >
              Your goal
            </div>

            <div
              className={`financial-holiday financial cursor-pointer d-flex flex-column justify-content-center`}
              style={{
                backgroundColor:
                  pickColorItemList?.[goals?.colour] ?? goals?.colour,
                minHeight: "175px",
              }}
            >
              <div className="">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className={` d-flex justify-content-center align-items-center rounded-circle w-42 h-42`}
                      style={{
                        backgroundColor: pickColorItemList.hasOwnProperty(
                          goals?.colour
                        )
                          ? adjustLightness(
                              pickColorItemList[goals?.colour],
                              10
                            )
                          : adjustLightness(goals?.colour, 14),
                        borderRadius: "50px",
                      }}
                    >
                      {goals?.icon}
                    </div>
                    <div className="ms-2 fw-600 fs-18 lh-24 text-white">
                      {goals?.goalName}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="financial-icon"
                    style={{ marginRight: "15px" }}
                  ></div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                <div className="financial-text">Balance</div>
                <div className="financial-text">
                  {parseInt(goals?.goalComplete)}%
                </div>
              </div>
              <div
                className="progress red-progress"
                style={{
                  height: "7px",
                  backgroundColor: "rgba(255, 255, 255, 0.4)",
                }}
              >
                <div
                  className={`progress-bar`}
                  role="progressbar"
                  aria-valuenow={goals?.goalComplete}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{
                    width: goals?.goalComplete,
                    opacity: "2 !important",
                    backgroundColor: progressColorItemList.hasOwnProperty(
                      goals?.colour
                    )
                      ? progressColorItemList[goals?.colour]
                      : adjustLightness(goals?.colour, -16),
                  }}
                ></div>
              </div>
              <div className="amount-top d-flex justify-content-between align-items-start">
                <div>
                  <span className="bold text-white">
                    {amountMinusWithSign(goals?.currentBalance)}
                  </span>
                  <span className="financial-text">
                    &nbsp; of &nbsp;{amountMinusWithSign(goals?.value)}
                  </span>
                </div>
                <div className="financial-text">
                  {parseInt(goals?.goalComplete) >= 100
                    ? "Goal Achieved 🎉"
                    : goals?.endDate
                    ? moment(goals?.endDate).format("Do MMMM YYYY")
                    : ""}
                </div>
              </div>
            </div>
            {isLinkedToAccount && (
              <>
                <div className="black sub-head-text mt-4 mb-2">
                  Linked accounts
                </div>

                <div className="row g-3">
                  {accountData?.map((ele, index) => (
                    <ConnectedAccountsAddGoal
                      key={index}
                      avatar={ele?.avatar}
                      accountName={ele?.accountName}
                      value={ele?.value}
                      currency={ele?.currency}
                      accountType={ele?.accountType}
                      convertedAmount={ele?.convertedAmount}
                      accountId={ele?.accountId}
                      width={"49%"}
                      selectedAccount={selectedAccounts}
                      id={ele?.accountId}
                      handleToggleSwitch={handleToggleSwitch}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        {/* </div> */}
        {loading && (
          <OverlayContainer>
            <div>
              <Loading />
            </div>
          </OverlayContainer>
        )}
      </div>
      {showConfirmationModal && (
        <ConfirmationModal
          textDesc1="Are you sure?"
          textDesc="This cannot be un-done."
          buttonText1="Go back"
          buttonText2="Delete"
          isOpen={showConfirmationModal}
          onConfirm={handleDeleteGoal}
          onCancel={() => setShowConfirmationModal(!showConfirmationModal)}
          isLoading={loading}
        />
      )}
    </>
  );
};

export default GoalDetails;
