import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const getChartOptions = (
  selectedOption,
  setIncomeSum,
  setExpenseSum,
  barData,
  setSelectedMonth,
  months
) => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
      ticks: { display: true },
      barPercentage: selectedOption === "Last 6 months" ? 0.6 : 0.4,
      categoryPercentage: selectedOption === "Last 6 months" ? 0.8 : 0.6,
      border: { display: false },
    },
    y: {
      grid: { display: false },
      ticks: { display: false },
      border: { display: false },
    },
  },
  plugins: {
    legend: { display: false, position: "top" },
    title: { display: false },
    datalabels: { display: false },
    tooltip: {
      callbacks: {
        label: function (context) {
          let value = context.raw || 0;
          let formattedValue = `$${Math.abs(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
          return value < 0 ? `-${formattedValue}` : formattedValue;
        },
      },
      backgroundColor: "rgba(0, 0, 0, 0.8)", // Dark background
      titleColor: "#ffffff", // White title text
      bodyColor: "#ffffff", // White text inside tooltip
      titleFont: { weight: "bold" }, // Bold title
      padding: 10,
      displayColors: false, // Removes color box from tooltip
    },
  },
  onClick: (event, elements) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index;
      const month = months[dataIndex].date;
      setSelectedMonth(month);
      const incomeValue = barData.datasets[0].data[dataIndex];
      const expenseValue = barData.datasets[1].data[dataIndex];
      setIncomeSum(incomeValue);
      setExpenseSum(expenseValue);
    }
  },
});

const CashFlowBarChart = ({ allDashboardDetails }) => {
  const [selectedCashFlowOption, setSelectedCashFlowOption] =
    useState("Last 6 months");
  const [cashFlowPopOver, setCashFlowPopOver] = useState(false);
  const [barData, setBarData] = useState({ labels: [], datasets: [] });
  const [expenseSum, setExpenseSum] = useState(0);
  const [IncomeSum, setIncomeSum] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState("Last 6 months");
  const [months, setMonths] = useState([]);
  const [isActual, setIsActual] = useState(true);

  const handleIncomeClick = (option) => {
    setSelectedCashFlowOption(option);
    setSelectedMonth("Last 6 months");
    setIsActual(!isActual);
  };

  useEffect(() => {
    const cashFilteredData =
      selectedCashFlowOption === "Last 6 months"
        ? allDashboardDetails?.cashflowSummary?.actual
        : allDashboardDetails?.cashflowSummary?.forecasted;

    const { income = [], expense = [] } = cashFilteredData || {};

    // Function to get unique sorted labels
    const getSortedLabels = (expense, income) => {
      const allDates = [
        ...expense.map((e) => e.date),
        ...income.map((i) => i.date),
      ];
      const uniqueDates = [...new Set(allDates)];

      // Define month order and abbreviations
      const monthOrder = {
        January: "Jan",
        February: "Feb",
        March: "Mar",
        April: "Apr",
        May: "May",
        June: "Jun",
        July: "Jul",
        August: "Aug",
        September: "Sep",
        October: "Oct",
        November: "Nov",
        December: "Dec",
      };

      // Sorting function
      uniqueDates.sort((a, b) => {
        const [monthA, yearA] = a.split(", ");
        const [monthB, yearB] = b.split(", ");
        return (
          yearA - yearB ||
          Object.keys(monthOrder).indexOf(monthA) -
            Object.keys(monthOrder).indexOf(monthB)
        );
      });

      // Convert to abbreviations
      return uniqueDates.map((date) => {
        const [month] = date.split(", ");
        return monthOrder[month];
      });
    };

    const sortedLabels = getSortedLabels(expense, income);

    const incomeAndExpense = [...income, ...expense];

    const seen = new Set();
    const uniqueData = incomeAndExpense.filter((obj) => {
      if (seen.has(obj.month)) {
        return false;
      }
      seen.add(obj.month);
      return true;
    });

    setIncomeSum(
      income?.reduce((acc, curr) => curr?.total_income_amount + acc, 0)
    );
    setExpenseSum(
      expense?.reduce((acc, curr) => curr?.total_income_amount + acc, 0)
    );

    setMonths(uniqueData);

    const filterBarData = {
      labels:sortedLabels,
      datasets: [
        {
          label: "Dataset 1",
          data: income?.map((item) => item?.total_income_amount),
          backgroundColor: "#469B88",
          borderRadius: 5,
        },
        {
          label: "Dataset 2",
          data: expense?.map((item) => item?.total_income_amount),
          backgroundColor: "#FF8484",
          borderRadius: 5,
        },
      ],
    };
    setBarData(filterBarData);
  }, [selectedCashFlowOption, allDashboardDetails]);

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center mb-1 mt-1"
        style={{ width: "100%" }}
      >
        <h4 className="fs-20 fw-500 lh-34 text-charcoal mb-0">
          Monthly cashflow
        </h4>
        <div className="d-flex align-items-center gap-3">
          {isActual ? (
            <span
              className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
              style={{
                width: "70px",
                height: "25px",
                cursor: "pointer",
                borderRadius: "30px",
              }}
              id="CashFlowPopOver"
              onClick={() => handleIncomeClick("Next 6 months")}
            >
              Actual
            </span>
          ) : (
            <span
              className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
              style={{
                width: "100px",
                height: "25px",
                cursor: "pointer",
                borderRadius: "30px",
              }}
              id="CashFlowPopOver"
              onClick={() => handleIncomeClick("Last 6 months")}
            >
              Forecasted
            </span>
          )}
        </div>
      </div>
      <div
        className="bg-white br-10 px-3 pt-3 mt-2"
        style={{
          height: "377px",
          paddingBottom: "70px",
          boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div>
            <h4 className="fs-18 fw-700 m-0">{selectedMonth}</h4>
          </div>

          <div className="d-flex">
            <div className="d-flex align-items-center">
              <div
                className="rounded-circle"
                style={{
                  width: " 12px",
                  height: "12px",
                  backgroundColor: "#469B88",
                }}
              ></div>
              <span className="fs-12 fw-500 lh-16 ms-2">Money In</span>
            </div>

            <div className="d-flex align-items-center ms-3">
              <div
                className="rounded-circle"
                style={{
                  width: " 12px",
                  height: "12px",
                  backgroundColor: "#FF8585",
                }}
              ></div>
              <span className="fs-12 fw-500 lh-16 ms-2">Money Out</span>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <h4 className="fs-14 lh-20 fw-500">
            Money in:
            <span className="fs-14 lh-20 fw-500 text-slate-blue ms-1">
              $
              {IncomeSum?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </h4>
          <h4 className="fs-14 lh-20 fw-500 ms-3">
            Money out:
            <span className="fs-14 lh-20 fw-500 text-bright-red ms-1">
              $
              {expenseSum?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </h4>
          <h4 className="fs-14 lh-20 fw-500 ms-3">
            Savings:
            <span className="fs-14 lh-20 fw-500 text-fresh-green ms-1">
              $
              {(IncomeSum ?? 0) - (expenseSum ?? 0) <= 0
                ? "0.00"
                : ((IncomeSum ?? 0) - (expenseSum ?? 0)).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
            </span>
          </h4>
        </div>
        <Bar
          options={getChartOptions(
            selectedCashFlowOption,
            setIncomeSum,
            setExpenseSum,
            barData,
            setSelectedMonth,
            months
          )}
          data={barData}
          width={577}
        />
      </div>
    </>
  );
};

export default CashFlowBarChart;
