import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import { ReactComponent as Success } from "../../../assests/images/icons/passwordSucess.svg";
import "../Login.css";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

const PromoApplied = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const plan = location?.state;

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <motion.div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <div className="logoCss text-center mb-4">
                <WordLogoBlack />
              </div>
              <div
                className="verifyFormWrapCard"
                style={{ borderRadius: "20px", padding: "20px 30px 20px 30px" }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
              >
                <div className="transaction-categories mt-2">
                  <div style={{ textAlign: "center" }}>
                    <Success />
                  </div>
                  <p
                    className="bold popover-live"
                    style={{
                      textAlign: "center",
                      marginBottom: "5px",
                      color: "#240F51",
                    }}
                  >
                    <span className="fs-24 fw-500 text-deep-violet">
                      Promo code applied
                    </span>
                  </p>

                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginBottom: "10px",
                    }}
                  >
                    {plan} 🎉
                  </p>
                  <div className="modal-popup-btn-wrp">
                    <button
                      className="cursor-pointer"
                      type="button"
                      onClick={() => navigate("/sign-up/priorities")}
                      style={{
                        marginTop: "20px",
                        marginBottom: "10px",
                        boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
                        color: "#fff",
                        background: "rgba(70, 155, 136, 1)",
                        width: "295px",
                        height: "45px",
                        padding: "9px 24px",
                        borderRadius: "10px",
                        border: "none",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Next step
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PromoApplied;
