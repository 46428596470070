import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import "../Login.css";
import { ReactComponent as Success } from "../../../assests/images/icons/passwordSucess.svg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const AllSetUp = () => {
  const navigate = useNavigate();

  const response = {
    data: {
      userUuid: localStorage.getItem("userUuid") || "mock-uuid",
      roleId: localStorage.getItem("userRole") || 5,
    },
  };

  const handleFinishSetup = () => {
    localStorage.setItem("2fa-done", true);
    localStorage.setItem("setUp", true);
    const userRole = response.data.roleId;
    const userUuid = response.data.userUuid;

    const path = userRole == 1 ? "/SuperAdmin" : `/dashboard/${userUuid}`;
    navigate(path, { replace: true });
  };

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <motion.div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <div className="logoCss text-center mb-4">
                <WordLogoBlack />
              </div>
              <div
                className="loginFormWrp"
                style={{ borderRadius: "20px", padding: "30px" }}
              >
                <div className="transaction-categories bg-white w-100 top-0 br-10 mt-2">
                  <div className="success-img" style={{ textAlign: "center" }}>
                    <Success />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      marginBottom: "5px",
                      color: "#240F51",
                      fontWeight: "700",
                    }}
                  >
                    You’re all set up 🎉
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      marginBottom: "10px",
                    }}
                  >
                    You’re now all ready to go, your new personal finance
                    companion awaits!
                  </p>
                  <div className="modal-popup-btn-wrp">
                    <button
                      className="change-password-btn cursor-pointer"
                      type="button"
                      onClick={handleFinishSetup}
                      style={{
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      Finish set up
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AllSetUp;
