import React, { useEffect, useState } from "react";
import PopupModal from "../../ModalForm/Popup/PopupModel";
import { useNavigate, useParams } from "react-router-dom";
import { API_ENDPOINTS } from "../../client/ApiEndpoints";
import HttpClient from "../../client/HttpClient";
import {
  getDateRange,
  getFormattedDateRangeString,
} from "../../utilities/Statics";
import CustomDateRangePopup from "../../ModalForm/Popup/CustomDateRangePopup";

const filterOptions = [
  { label: "This week", value: "thisweek" },
  { label: "This month", value: "thismonth" },
  { label: "This year", value: "thisyear" },
  { label: "Last week", value: "lastweek" },
  { label: "Last month", value: "lastmonth" },
  { label: "Custom date range", value: "custom" },
];

const TopMerchents = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [selectedTopMerchantsOption, setSelectedTopMerchantsOption] =
    useState("This month");
  const [merchantsPopOver, setMerchantsPopOver] = useState(false);
  const [topMerchants, setTopMerchants] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState();

  const selectedRange = getFormattedDateRangeString(selectedDateRange);

  const handleIncomeClick = (option) => {
    setSelectedTopMerchantsOption(option);
    setMerchantsPopOver(false);
  };

  const handleClick = (item) => {

    navigate(`/merchantdetails/${uuid}`, {
      state: {
        merchantName: item?.name,
        merchantLogo: item?.logo,
        merchantUuid: item?.uuid,
        merchantCategoryName: item?.categorie_name,
        merchantCategoryEmoji: item?.categorie_emoji,
        merchantCategoryColour: item?.categorie_colour,
        merchantAmount: item?.total_transaction_amount,
        merchantCount: item?.total_transactions,
      },
    });
  };

  useEffect(() => {
    const foundOption = filterOptions.find(
      (option) => option.label === selectedTopMerchantsOption
    );
    const dateRange = getDateRange(
      selectedTopMerchantsOption,
      selectedDateRange
    );

    const getTopMerchants = async () => {
      try {
        const response = await HttpClient.post(
          `${API_ENDPOINTS.GET_TOP_FIVE_MERCHANTS}${uuid}`,
          {
            filter: foundOption.value,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }
        );
        // console.log(response, "responseMerchants");

        setTopMerchants(response?.data?.data?.merchants || []);
      } catch (err) {
        console.log(err.message);
      }
    };

    getTopMerchants();
  }, [selectedTopMerchantsOption, selectedDateRange]);

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center mb-1 mt-1"
        style={{ width: "100%" }}
      >
        <h4 className="fs-18 fw-500 lh-34 text-charcoal mb-0">
          Top 5 Merchants
        </h4>
        <span
          className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{ padding: "2px 10px", height: "25px", borderRadius: "20px" }}
          id="TopMerchantsPopover"
          onClick={() => setMerchantsPopOver(!merchantsPopOver)}
          aria-haspopup="true"
          aria-expanded={merchantsPopOver}
        >
          {selectedTopMerchantsOption === "Custom date range"
            ? selectedRange
            : selectedTopMerchantsOption}
        </span>
        {merchantsPopOver && (
          <CustomDateRangePopup
            isOpen={merchantsPopOver}
            onClose={() => setMerchantsPopOver(!merchantsPopOver)}
            targetId="TopMerchantsPopover"
            title="Date range"
            options={[
              "This week",
              "This month",
              "This year",
              "Last week",
              "Last month",
              "Custom date range",
            ]}
            selectedOption={merchantsPopOver}
            handleOptionClick={handleIncomeClick}
            setSelectedDateRange={setSelectedDateRange}
          />
        )}
      </div>

      {topMerchants && topMerchants?.length > 0 ? (
        topMerchants?.map((item, index) => {
          return (
            <>
              <div
                key={item.id}
                onClick={() => handleClick(item)}
                className="br-10 bg-white d-flex justify-content-between align-items-center mb-2"
                style={{
                  height: "62px",
                  padding: "12px",
                  boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    style={{ width: "40px" }}
                    className="rounded-circle"
                    key={index}
                    src={item?.logo}
                    alt="merchant"
                  />
                  <div
                    className="d-flex flex-column"
                    style={{ marginLeft: "10px" }}
                  >
                    <span className="fw-600" style={{ fontSize: ".8vw" }}>
                      {item?.name}
                    </span>
                    <span className="fs-14 fw-400 text-medium-gray">
                      {item?.total_transactions
                        ? `${item.total_transactions} Transaction${
                            item.total_transactions > 1 ? "s" : ""
                          }`
                        : "0 Transactions"}
                    </span>
                  </div>
                </div>
                <span className="text-soft-red fs-16 fw-600 ">
                  {item?.total_transaction_amount
                    ? item.total_transaction_amount < 0
                      ? `$${Math.abs(
                          item.total_transaction_amount
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                      : `-$${item.total_transaction_amount.toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}`
                    : "$0.00"}
                </span>
              </div>
            </>
          );
        })
      ) : (
        <div
          className="selected-account text-center"
          style={{ marginTop: "10px" }}
        >
          <h4 className="fs-18 fw-600 text-dark">
            No merchants recorded yet{" "}
            <span role="img" label="thinking">
              🤔
            </span>
          </h4>
          <p className="fs-14 text-dark-gray">
            Connect your accounts to view your top 5 merchants. If you already
            have, your transactions may still be loading.
          </p>
          <button
            className="btn btn-primary cursor-pointer"
            style={{
              backgroundColor: "#6A36FF", // Match purple color
              color: "white",
              padding: "10px 20px",
              borderRadius: "10px",
              fontSize: "14px",
              fontWeight: "500",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/connectaccounts/${uuid}`)} // Handle navigation
          >
            Connect your accounts
          </button>
        </div>
      )}
    </>
  );
};

export default TopMerchents;
