import React from "react";
import { motion } from "framer-motion";

export const EditPendingTransactions = (props) => {
  const { isOpen, onClose } = props;

  const acknowledgeEditPendingTransactions = () => {
    localStorage.setItem("edit_pending_transactions", true);
    // setEditPendingTransactions(false);
    onClose();
  };
  return (
    <>
      <div className="modal-overlay" isOpen={isOpen} toggle={onClose}>
        <motion.div
          className="modal-container"
          style={{
            maxWidth: "327px",
            padding: "16px 13px",
            borderRadius: "20px",
          }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5 }}
        >
          <div className="text-center">
            <div className="transaction-categories ">
              <h1 className="fs-24 fw-700 dark-slate-gray">Just a heads up!</h1>
              <p className="fs-12 fw-400 dark-slate-gray">
                Now that you have turned this on, you’ll be able to change the
                category of any pending transactions.
              </p>
              <p>
                <span className="fs-12 fw-500 dark-slate-gray">Note:</span> If
                you edit a pending transaction, it's possible your category
                changes will be lost each time you refresh transactions.
              </p>
              <p className="fs-12 fw-400 dark-slate-gray">
                This is because we refresh these transactions regularly to make
                sure everything's up to date.
              </p>
            </div>
            <button
              className="border-0 bg-teal-green text-white w-100 fs-16 fw-500 mt-2"
              style={{ borderRadius: "12px", padding: "10px 24px" }}
              onClick={acknowledgeEditPendingTransactions}
            >
              Acknowledge
            </button>
          </div>
        </motion.div>
      </div>
    </>
  );
};
