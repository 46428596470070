import React from "react";
import { formatValue, getFormattedCurrency } from "../../utilities/helper";

export const amountMinusWithSign = (data, currencyCode) => {
  const formatWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formattedValue = formatWithCommas(formatValue(Math.abs(data)));

  if (data >= 0) {
    return getFormattedCurrency(currencyCode, formattedValue);
  } else {
    return `-${getFormattedCurrency(currencyCode, formattedValue)}`;
  }
};

const amountPlusMinus = (data) => {
  const formatWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const amount = formatWithCommas(Math.abs(data).toFixed(2));
  return data < 0 ? `-$${amount}` : `$${amount}`;
};

const AccountBalance = ({
  accountType,
  currency,
  convertedAmount,
  accountBalance,
  userCurrencyCode,
}) => {
  return (
    <div>
      {userCurrencyCode !== currency ? (
        <div
          className="amount-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <span
            className={`fs-18 fw-700 ${
              convertedAmount < 0 ? "amount-red" : "amount-green"
            }`}
            style={{
              fontSize: "16px",
            }}
          >
            {accountType === "INVESTMENT" ||
            accountType === "WALLET" ||
            accountType === "FOREIGN"
              ? `${amountMinusWithSign(
                  convertedAmount,
                  userCurrencyCode
                )} ${userCurrencyCode}`
              : `${amountMinusWithSign(convertedAmount, userCurrencyCode)}`}
          </span>
          <span
            className="account-number"
            style={{
              fontSize: "14px",
              color: "#b8b8b8",
            }}
          >{`${amountMinusWithSign(
            accountBalance,
            currency
          )} ${currency}`}</span>
        </div>
      ) : (
        <span
          className={`fs-18 fw-700 ${
            accountBalance < 0 ? "amount-red" : "amount-green"
          }`}
        >
          {accountType === "INVESTMENT" ||
          accountType === "WALLET" ||
          accountType === "FOREIGN"
            ? `${amountMinusWithSign(accountBalance, currency)} ${currency}`
            : amountPlusMinus(accountBalance)}
        </span>
      )}
    </div>
  );
};

export default AccountBalance;
