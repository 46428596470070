import React, { useState } from "react";
import { ReactComponent as ArrowAccoor } from "../../../../assests/images/icons/accordionArrow.svg";
import PlusIcon from "../../../../assests/images/icons/plus-add-icon.svg";

import "../BudgetSummary.css";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";
import IncomeExpenseBudgetModal from "../IncomeExpenseBudget/IncomeExpenseBudgetModal";
import {
  lightBackgroundColor,
  progressbarColor,
} from "../../../../utilities/Statics";
import { API_ENDPOINTS } from "../../../../client/ApiEndpoints";
import HttpClient from "../../../../client/HttpClient";
import { toast } from "react-toastify";

const OneOffBudgetCompactView = ({
  oneOffBudgetsData = [],
  mainHeading,
  showFields,
  uuid,
  fetchBudgetData,
  loadingSingleBudgets,
  updateOneOffBudget,
  isCompactView,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [tempBudgetAmount, setTempBudgetAmount] = useState({});
  const handleCardClick = (budgetId, fetchBudget) => {
    navigate(`/budget/${uuid}/budgetdetails/oneoff/${budgetId}`, {
      state: {
        fetchBudget,
        isCompactView,
      },
    });
  };

  function formatAmount(amount) {
    if (amount === 0) return "$0";

    const sign = amount > 0 ? "" : "-";
    const absoluteAmount = Math.abs(amount);

    if (absoluteAmount % 1 === 0) {
      return `${sign}$${absoluteAmount.toLocaleString()}`;
    } else {
      let [integerPart, decimalPart] = absoluteAmount.toFixed(2).split(".");
      return `${sign}$${Number(integerPart).toLocaleString()}.${decimalPart}`;
    }
  }

  const handleBudgetAmountBlur = (budget) => {
    setTempBudgetAmount((prev) => {
      const updatedTemp = { ...prev };
      delete updatedTemp[budget.uuid];
      return updatedTemp;
    });
  };

  const handleBudgetAmountChange = (e, index, budgetId) => {
    const newValue = e.target.value.replace(/[^0-9.]/g, "");
    setTempBudgetAmount((prev) => ({
      ...prev,
      [budgetId]: newValue,
    }));
  };

  const handleBudgetAmountSave = async (budget) => {
    const newValue = tempBudgetAmount[budget.uuid];

    if (!newValue || isNaN(newValue)) return;

    await updateOneOffBudget(budget, newValue);

    setTempBudgetAmount((prev) => {
      const updatedTemp = { ...prev };
      delete updatedTemp[budget.id];
      return updatedTemp;
    });
  };

  return (
    <div className="col-md-8">
      <div className="row mb-2">
        <div className="col-md-7">
          <h4
            className="fs-20 fw-500 lh-34 mb-0"
            style={{ color: "#0E132F", letterSpacing: "-0.22px" }}
          >
            One off budgets
          </h4>
        </div>
        <div className="col-md-5 pe-5">
          <div className="row">
            <div className="col-md-4 text-center">
              <span className="fs-15 fw-500 lh-34 text-medium-Stone-gray">
                Budget
              </span>
            </div>
            <div className="col-md-4 text-center">
              <span className="fs-15 fw-500 lh-34 text-medium-Stone-gray">
                Actual
              </span>
            </div>
            <div className="col-md-4 text-center">
              <span className="fs-15 fw-500 lh-34 text-medium-Stone-gray">
                Remaining
              </span>
            </div>
          </div>
        </div>
      </div>

      <IncomeExpenseBudgetModal
        isModalOpen={isModalOpen}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        mainHead={mainHeading}
        fetchBudgetData={fetchBudgetData}
        showFields={showFields}
      />

      {oneOffBudgetsData.monthly && (
        <div className="budget-item-card">
          {oneOffBudgetsData?.monthly?.map((budget, index) => {
            let progress;
            const adjustedAmount =
              budget?.amount - budget?.total_transaction_amount;
            if (budget?.amount === 0) {
              progress = 0;
            } else {
              progress =
                (budget?.total_transaction_amount / budget?.amount) * 100;
            }
            progress = progress > 100 ? 100 : progress;
            return (
              <div
                key={index}
                className="position-relative"
                onClick={(e) => {
                  if (!isEditing) {
                    handleCardClick(budget.uuid, budget);
                  }
                }}
              >
                <div className="budget-item" style={{ cursor: "pointer" }}>
                  <div className="row align-items-center mb-2">
                    <div className="col-md-7">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="budget-icon"
                          style={{
                            width: "38px",
                            height: "38px",
                            backgroundColor: lightBackgroundColor(
                              budget.colour
                            ),
                          }}
                        >
                          <span style={{ fontSize: "15px" }}>
                            {budget.emoji}
                          </span>
                        </div>
                        <div>
                          <span className="fs-18 fw-500 text-dark-steel-gray">
                            {budget.category_name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="row align-items-center">
                        <div className="col-md-4 text-center">
                          {loadingSingleBudgets?.[budget.uuid] ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // borderRadius: "5px",
                                // border: "1px solid #F0F0F0",
                                padding: "5px 10px",
                                textAlign: "center",
                              }}
                            >
                              <div
                                className="spinner-border text-light-gray mt-1"
                                role="status"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            <input
                              value={
                                tempBudgetAmount[budget.uuid] ??
                                `${formatAmount(Math.abs(budget.amount))}`
                              }
                              className="fs-16"
                              type="text"
                              size={8}
                              onBlur={() => {
                                setIsEditing(false);
                                handleBudgetAmountBlur(budget);
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(true);
                              }}
                              onChange={(e) =>
                                handleBudgetAmountChange(e, index, budget.uuid)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.target.blur();
                                  handleBudgetAmountSave(budget, index);
                                }
                              }}
                              style={{
                                height: "31px",
                                textAlign: "center",
                                border: "1px solid #D9D9D9",
                                padding: "5px 10px",
                                outline: "none",
                                borderRadius: "5px",
                                fontWeight: 500,
                              }}
                            />
                          )}
                        </div>
                        <div className="col-md-4 text-center">
                          <span className="fs-16 fw-500">
                            {formatAmount(budget?.total_transaction_amount)}
                          </span>
                        </div>
                        <div className="col-md-4 text-center ">
                          <span
                            className="badge rounded-pill py-2 px-2 fs-16 fw-500"
                            style={{
                              backgroundColor:
                                adjustedAmount <= 1 ? "#E3F7E1" : "#F4E2E2",
                              color:
                                adjustedAmount <= 1 ? "#437E43" : "#BE3C39",
                              padding: "5px",
                            }}
                          >
                            {formatAmount(adjustedAmount)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="position-absolute"
                  style={{
                    backgroundColor: lightBackgroundColor(budget.colour),
                    bottom: "0px",
                    width: "100% ",
                    height: "8px",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  <motion.div
                    style={{
                      width: `${progress}%`,
                      backgroundColor: progressbarColor(budget.colour),
                      height: "8px",
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px",
                      borderTopRightRadius: "7px",
                    }}
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%` }}
                    aria-valuenow={progress.toFixed(2)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></motion.div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {oneOffBudgetsData.monthly && (
        <div className="d-flex justify-content-end gap-2 mt-2 pe-3">
          <div className="pointer-cursor" onClick={toggleModal}>
            <span className="fs-14 fw-500 me-1" style={{ color: "#636363" }}>
              Add new
            </span>
            <img
              style={{ width: "14px", height: "14px" }}
              src={PlusIcon}
              alt="Edit Icon"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OneOffBudgetCompactView;
