import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import PopupModal from "../../ModalForm/Popup/PopupModel";

const getChartOptions = (
  selectedOption,
  setIncomeSum,
  setExpenseSum,
  barData
) => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
      ticks: { display: true },
      barPercentage: selectedOption === "Last 6 months" ? 0.6 : 0.4,
      categoryPercentage: selectedOption === "Last 6 months" ? 0.8 : 0.6,
      border: { display: false },
    },
    y: {
      grid: { display: false },
      ticks: { display: false },
      border: { display: false },
    },
  },
  plugins: {
    legend: { display: false, position: "top" },
    title: { display: false },
    datalabels: { display: false },
    tooltip: {
      callbacks: {
        label: function (context) {
          let value = context.raw || 0;
          let formattedValue = `$${Math.abs(value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
          return value < 0 ? `-${formattedValue}` : formattedValue;
        },
      },
      backgroundColor: "rgba(0, 0, 0, 0.8)", // Dark background
      titleColor: "#ffffff", // White title text
      bodyColor: "#ffffff", // White text inside tooltip
      titleFont: { weight: "bold" }, // Bold title
      padding: 10,
      displayColors: false, // Removes color box from tooltip
    },
  },
  onClick: (event, elements) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index;
      const incomeValue = barData.datasets[0].data[dataIndex];
      const expenseValue = barData.datasets[1].data[dataIndex];
      setIncomeSum(incomeValue);
      setExpenseSum(expenseValue);
    }
  },
});

const IncomeBarChart = ({ allDashboardDetails }) => {
  const [selectedIncomeOption, setSelectedIncomeOption] =
    useState("Last 12 months");
  const [incomePopOver, setIncomePopOver] = useState(false);
  const [barData, setBarData] = useState({ labels: [], datasets: [] });
  const [expenseSum, setExpenseSum] = useState(0);
  const [incomeSum, setIncomeSum] = useState(0);

  const handleIncomeClick = (option) => {
    setSelectedIncomeOption(option);
    setIncomePopOver(false);
  };

  useEffect(() => {
    const { income = {}, expense = {} } =
      allDashboardDetails?.incomeVsExpense || {};

    const selectedDataKey =
      selectedIncomeOption === "Last 3 months"
        ? "lastThreeMonth"
        : selectedIncomeOption === "Last 6 months"
        ? "lastSixMonth"
        : "lastTwelveMonth";

    const filteredIncomeData = income[selectedDataKey] || [];
    const filteredExpenseData = expense[selectedDataKey] || [];


    // Function to get unique sorted labels
    const getSortedLabels = (expense, income) => {
      const allDates = [
        ...expense.map((e) => e.date),
        ...income.map((i) => i.date),
      ];
      const uniqueDates = [...new Set(allDates)];

      // Define month order and abbreviations
      const monthOrder = {
        January: "Jan",
        February: "Feb",
        March: "Mar",
        April: "Apr",
        May: "May",
        June: "Jun",
        July: "Jul",
        August: "Aug",
        September: "Sep",
        October: "Oct",
        November: "Nov",
        December: "Dec",
      };

      uniqueDates.sort((a, b) => {
        const [monthA, yearA] = a.split(", ");
        const [monthB, yearB] = b.split(", ");
        return (
          yearA - yearB ||
          Object.keys(monthOrder).indexOf(monthA) -
            Object.keys(monthOrder).indexOf(monthB)
        );
      });

      // Convert to abbreviations
      return uniqueDates.map((date) => {
        const [month] = date.split(", ");
        return monthOrder[month];
      });
    };

    const sortedLabels = getSortedLabels(
      filteredExpenseData,
      filteredIncomeData
    );

    setIncomeSum(
      filteredIncomeData.reduce(
        (acc, curr) => curr?.total_income_amount + acc,
        0
      )
    );
    setExpenseSum(
      filteredExpenseData.reduce(
        (acc, curr) => curr?.total_income_amount + acc,
        0
      )
    );

    // Get all unique dates from both income and expense data
    const allDates = [
      ...new Set([
        ...filteredIncomeData.map((item) => item.date),
        ...filteredExpenseData.map((item) => item.date),
      ]),
    ].sort((a, b) => new Date(a) - new Date(b)); // Sort by date

    // Map income & expenses using date as the key
    const incomeMap = new Map(
      filteredIncomeData.map((item) => [item.date, item.total_income_amount])
    );
    const expenseMap = new Map(
      filteredExpenseData.map((item) => [item.date, item.total_income_amount])
    );

    // Get values in correct order
    const incomeValues = allDates.map((date) => incomeMap.get(date) || 0);
    const expenseValues = allDates.map((date) => expenseMap.get(date) || 0);

    const filterBarData = {
      labels: sortedLabels,
      datasets: [
        {
          label: "Income",
          data: incomeValues,
          backgroundColor: "#469B88",
          borderRadius: 5,
        },
        {
          label: "Expense",
          data: expenseValues,
          backgroundColor: "#FF8484",
          borderRadius: 5,
        },
      ],
    };

    setBarData(filterBarData);
  }, [selectedIncomeOption, allDashboardDetails]);

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center mb-1 mt-1"
        style={{ width: "100%" }}
      >
        <h4 className="fs-18 fw-500 mb-0 lh-34 text-charcoal">
          Income vs Expenses
        </h4>
        <span
          className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{
            width: "120px",
            height: "25px",
            borderRadius: "20px",
            cursor: "pointer",
          }}
          id="IncomeExpenses"
          onClick={() => setIncomePopOver(!incomePopOver)}
          aria-haspopup="true"
          aria-expanded={incomePopOver}
        >
          {selectedIncomeOption}
        </span>
        {incomePopOver && (
          <PopupModal
            isOpen={incomePopOver}
            onClose={() => setIncomePopOver(!incomePopOver)}
            targetId="IncomeExpenses"
            title="Date range"
            options={["Last 3 months", "Last 6 months", "Last 12 months"]}
            selectedOption={selectedIncomeOption}
            handleOptionClick={handleIncomeClick}
          />
        )}
      </div>
      <div
        className="bg-white br-10 px-3 pt-3 pb-5"
        style={{
          height: "340px",
          boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex">
            <h4 className="fs-16 lh-20 fw-500 mb-0">
              Income:
              <span className="fs-16 lh-20 fw-500 text-slate-blue ms-1">
                $
                {incomeSum.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </h4>
            <h4 className="fs-16 lh-20 fw-500 ms-3 mb-0">
              Expenses:
              <span className="fs-16 lh-20 fw-500 text-bright-red ms-1">
                $
                {expenseSum.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </h4>
          </div>
          {/* Add the Money In and Money Out legend */}
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <div
                className="rounded-circle"
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#469B88",
                }}
              ></div>
              <span className="fs-12 fw-500 lh-16 ms-2">Income</span>
            </div>

            <div className="d-flex align-items-center ms-3">
              <div
                className="rounded-circle"
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#FF8585",
                }}
              ></div>
              <span className="fs-12 fw-500 lh-16 ms-2">Expenses</span>
            </div>
          </div>
        </div>

        <Bar
          options={getChartOptions(
            selectedIncomeOption,
            setIncomeSum,
            setExpenseSum,
            barData
          )}
          data={barData}
          width={577}
        />
      </div>
    </>
  );
};

export default IncomeBarChart;
