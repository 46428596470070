import React from "react";
import "./BudgetDetails.css";
import { ReactComponent as Arrow_bottom_color } from "../../assests/images/Arrow_bottom_color.svg";
import { ReactComponent as OverBudget } from "../../assests/images/over_budget.svg";
import PersonalisedColorEmoji from "./PersonalisedColorEmoji";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal";
import EditDetails from "../Innerpanel/BudgetSummery/IncomeExpenseBudget/EditDetails.jsx";
import SectionTitle from "../SectionTitle.jsx";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import { TransactionListing } from "../../Views/Transactions/TransactionListing.jsx";
import OverlayContainer from "../OverlayContainer/OverlayContainer.jsx";
import { Loading } from "../Loading/Loading.jsx";
import { isEmpty } from "lodash";
import { motion } from "framer-motion";
import NoDataFound from "../NoDataFound/index.jsx";
import {
  getCustomCodeToName,
  getMonthName,
  lightBackgroundColor,
  moduleInfoPopUpData,
  progressbarColor,
} from "../../utilities/Statics/index.jsx";
import {
  PopUpDelete,
  PopUpForUpdateDelete,
  PopUpNoYes,
  PopUpYesNoRow,
} from "./PopUpForUpdateDelete.jsx";
import HowOften from "../Innerpanel/BudgetSummery/IncomeExpenseBudget/HowOften.jsx";
import UserImageInfoToggle from "../UserImageInfoToggle/index.jsx";
import { ReactComponent as DoneWithBackground } from "../../assests/images/doneWithBackground.svg";
import useBudgetDetails from "../../hooks/Budget/useBudgetDetails/useBudgetDetails.jsx";
import MonthYearCalendarPopover from "../Innerpanel/CalendarMonthPopOver/CalendarMonthPopOver.jsx";

const BudgetDetails = () => {
  const {
    scrollContainerRef,
    handleSaveChanges,
    handleDeleteBudget,
    isPopupOpenInfo,
    togglePopupInfo,
    showDeletePopup,
    handleDeleteCancel,
    handleDeleteConfirm,
    showUpdatePopup,
    updateOrDeleteText,
    handleUpdateCashflowNo,
    handleUpdateCashflowYes,
    deleteCashFlowPopup,
    handleCashflowDeleteNo,
    deleteCategoryPopup,
    handleCategoryDeleteNo,
    expenseOrIncome,
    formatAmount,
    totalAmount,
    adjustedAmount,
    progress,
    search,
    handleSearch,
    currentMonthGrouped,
    filteredCurrentMonthTransactions,
    remainingMonthsGrouped,
    filteredRemainingMonthTransactions,
    openModal,
    category,
    updateTransactionCategory,
    addNewCategory,
    icomecat,
    oneOffCategory,
    setSelectionMode,
    togglePopover,
    popoverOpen,
    showAddCategory,
    handleAddCategory,
    editAmount,
    handleAmountChange,
    selectedFrequency,
    toggleFrequencyModal,
    isSpreadToggled,
    handleEditToggleClick,
    handleNeedToggleClick,
    needOrWant,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleNameChange,
    editName,
    isFrequencyModalOpen,
    selectedColorOption,
    togglePopupColor,
    isPopupOpenColor,
    handleOptionColorClick,
    setShowPicker,
    showPicker,
    chosenEmoji,
    handleEmojiClick,
    handleFrequencySelection,
    modals,
    spliModalData,
    closeModal,
    splitTransaction,
    isLoading,
    loading,
    isGrouped,
    setIsGrouped,
    isCompactView,
    applyToAllFutureMonths,
    budget,
    fetchBudget,
    formatIncomeAmount,
    showPopover,
    setShowPopover,
    handleBottomArrowClick,
    setTabYear,
    setTabMonth,
    tabYear,
    tabMonth,
  } = useBudgetDetails();
  const currentDate = new Date();
  return (
    <div
      className="app-main__outer edit-cashflow-container-main"
      ref={scrollContainerRef}
    >
      <div className="row top-nav">
        <div className="col-md-4 edit-cashflow-title-heading">
          <div className="bold big-head-new">
            {fetchBudget.category_name} budget
          </div>
          <div className="fst-normal setting_text_color mt-1">
            Make any changes to personalise your budget.
          </div>
        </div>
        <div className="col-md-8 right-section back-portal-edit-cashflow">
          <div className="right-section" style={{ gap: "30px" }}>
            <div className="modal-actions" style={{ marginTop: "0px" }}>
              <button
                type="button"
                className="save-button-budget-detail"
                onClick={handleSaveChanges}
              >
                Save changes
              </button>
              <button
                type="button"
                className="next-button-budget-detail"
                onClick={handleDeleteBudget}
              >
                Delete budget
              </button>
            </div>
            <div className="">
              <UserImageInfoToggle
                isPopupOpenInfo={isPopupOpenInfo}
                togglePopupInfo={togglePopupInfo}
                title={moduleInfoPopUpData.connectAccounts.title}
                description={moduleInfoPopUpData.connectAccounts.description}
                customStyle={true}
              />
            </div>
          </div>
        </div>
        {showDeletePopup && (
          <PopUpDelete
            handleDeleteCancel={handleDeleteCancel}
            handleDeleteConfirm={handleDeleteConfirm}
          />
        )}

        {showUpdatePopup && (
          <PopUpForUpdateDelete
            updateOrDeleteText={updateOrDeleteText}
            handleUpdateCashflowNo={handleUpdateCashflowNo}
            handleUpdateCashflowYes={handleUpdateCashflowYes}
          />
        )}

        {deleteCashFlowPopup && (
          <PopUpNoYes
            title={"Delete from cashflow?"}
            subtitle={"Would you like to delete this from your cashflow too?"}
            handleChange={handleCashflowDeleteNo}
          />
        )}

        {deleteCategoryPopup && (
          <PopUpYesNoRow
            title={"Delete category?"}
            subtitle={
              "Along with deleting the budget, would you like to delete the category used for this budget which will remove it from all merchants and transactions?"
            }
            handleChange={handleCategoryDeleteNo}
          />
        )}
      </div>

      {/* Expense Form */}

      <div className="row mt-4">
        {/* Income Budgets Section */}
        <div className="col-md-8">
          <div className="col-md-12">
            <div className="row mb-2">
              <div className="col-md-7">
                {isCompactView === "false" && (
                  <h4
                    className="fs-20 fw-500 lh-34 mb-0"
                    style={{ color: "#0E132F" }}
                  >
                    {expenseOrIncome === "expense"
                      ? "Spend vs budget"
                      : expenseOrIncome === "income"
                      ? "Income vs budget"
                      : "One off budget"}
                  </h4>
                )}
                {expenseOrIncome === "oneoff" && isCompactView === "true" && (
                  <h4
                    className="fs-20 fw-500 lh-34 mb-0"
                    style={{ color: "#0E132F" }}
                  >
                    {"One off budget"}
                  </h4>
                )}
                {isCompactView === "true" && expenseOrIncome !== "oneoff" && (
                  <div className="d-flex justify-content-start ">
                    <span
                      id="monthYearPickerDetails"
                      className="lh-34"
                      style={{
                        lineHeight: "1",
                        display: "flex",
                        fontSize: "20px",
                        fontWeight: 500,
                        paddingLeft: "10px",
                        alignItems: "center",
                      }}
                    >
                      {getMonthName(tabMonth)} {tabYear}
                    </span>
                    <div
                      onClick={handleBottomArrowClick}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Arrow_bottom_color
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {isCompactView === "true" && (
                <div className="col-md-5 pe-5">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <span className="fs-15 fw-500 lh-34 text-medium-Stone-gray">
                        Budget
                      </span>
                    </div>
                    <div className="col-md-4 text-center">
                      <span className="fs-15 fw-500 lh-34 text-medium-Stone-gray">
                        Actual
                      </span>
                    </div>
                    <div className="col-md-4 text-center">
                      <span
                        className="fs-15 fw-500 lh-34 text-medium-Stone-gray"
                        style={{ paddingLeft: "20px" }}
                      >
                        Remaining
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {isCompactView === "false" && expenseOrIncome !== "oneoff" && (
                <div className="col-md-5 d-flex justify-content-end pe-3">
                  <span
                    id="monthYearPickerDetails"
                    className="lh-34"
                    style={{
                      lineHeight: "1",
                      display: "flex",
                      fontSize: "20px",
                      fontWeight: 500,
                      paddingLeft: "10px",
                      alignItems: "center",
                    }}
                  >
                    {getMonthName(tabMonth)} {tabYear}
                  </span>
                  <div
                    onClick={handleBottomArrowClick}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Arrow_bottom_color
                      style={{
                        width: "16px",
                        height: "16px",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            {isCompactView === "true" ? (
              <div className="position-relative">
                <div className="budget-item" style={{ cursor: "pointer" }}>
                  <div className="row align-items-center mb-2">
                    <div className="col-md-7">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          className="budget-icon"
                          style={{
                            width: "38px",
                            height: "38px",
                            backgroundColor: lightBackgroundColor(
                              getCustomCodeToName(budget.colour ?? "#FFF")
                            ),
                          }}
                        >
                          <span style={{ fontSize: "15px" }}>
                            {budget.emoji}
                          </span>
                        </div>
                        <div>
                          <span className="fs-18 fw-500 text-dark-steel-gray">
                            {fetchBudget.category_name}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="row align-items-center">
                        <div className="col-md-4 text-center">
                          <input
                            readOnly={true}
                            value={`$${formatAmount(
                              Math.abs(
                                expenseOrIncome === "oneoff"
                                  ? budget.amount
                                  : budget.budget_amount
                              )
                            )}`}
                            className="fs-16"
                            type="text"
                            size={8}
                            style={{
                              height: "31px",
                              textAlign: "center",
                              border: "none",
                              padding: "5px 10px",
                              outline: "none",
                              borderRadius: "5px",
                              fontWeight: 500,
                            }}
                          />
                        </div>
                        <div className="col-md-4 text-center">
                          <span className="fs-16 fw-500">
                            {`$${formatAmount(
                              Math.abs(budget.total_transaction_amount)
                            )}`}
                          </span>
                        </div>
                        <div className="col-md-4 text-center ">
                          <span
                            class="badge rounded-pill py-2 px-2 fs-16 fw-500"
                            style={{
                              backgroundColor:
                                expenseOrIncome === "expense"
                                  ? adjustedAmount >= 0
                                    ? "#E3F7E1"
                                    : "#F4E2E2"
                                  : adjustedAmount <= 1
                                  ? "#E3F7E1"
                                  : "#F4E2E2",
                              color:
                                expenseOrIncome === "expense"
                                  ? adjustedAmount >= 0
                                    ? "#437E43"
                                    : "#BE3C39"
                                  : adjustedAmount <= 1
                                  ? "#437E43"
                                  : "#BE3C39",

                              padding: "5px",
                            }}
                          >
                            {expenseOrIncome === "expense"
                              ? `$${formatAmount(Math.abs(adjustedAmount))}`
                              : formatIncomeAmount(adjustedAmount)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="position-absolute"
                  style={{
                    backgroundColor: lightBackgroundColor(
                      getCustomCodeToName(budget.colour ?? "#FFF")
                    ),
                    bottom: "0px",
                    width: "100% ",
                    height: "8px",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}
                >
                  <motion.div
                    style={{
                      width: `${progress}%`,
                      backgroundColor: progressbarColor(
                        getCustomCodeToName(budget.colour)
                      ),
                      height: "8px",
                      borderBottomLeftRadius: "15px",
                      borderBottomRightRadius: "15px",
                      borderTopRightRadius: "7px",
                    }}
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%` }}
                    aria-valuenow={progress.toFixed(2)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></motion.div>
                </div>
              </div>
            ) : (
              <div className="budget-item-card">
                <div className="budget-item">
                  <div className="budget-label">
                    <div className="pay-budget-sec">
                      <div
                        className={`budget-icon }`}
                        style={{
                          backgroundColor: lightBackgroundColor(
                            getCustomCodeToName(budget.colour ?? "#FFF")
                          ),
                        }}
                      >
                        {budget.category_emoji}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "7px",
                          marginLeft: "5px",
                        }}
                      >
                        <h6>{fetchBudget.category_name}</h6>
                        <h4 style={{ marginTop: "-5px" }}>
                          ${formatAmount(Math.abs(totalAmount))}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="budget-remaining">
                    {expenseOrIncome === "oneoff" ? (
                      `$${formatAmount(Math.abs(adjustedAmount))} remaining`
                    ) : (
                      <>
                        {adjustedAmount < 0 ? (
                          budget.category_type === "INCOME" ? (
                            <>
                              {`You've earned $${formatAmount(
                                Math.abs(adjustedAmount)
                              )} more`}
                              <DoneWithBackground
                                style={{
                                  marginTop: "4px",
                                  marginLeft: "3px",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              {`$${formatAmount(
                                Math.abs(adjustedAmount)
                              )} over budget`}
                              <OverBudget
                                style={{
                                  marginLeft: "1px",

                                  marginTop: "2px",
                                }}
                              />
                              {/* Display the icon */}
                            </>
                          )
                        ) : (
                          `$${formatAmount(Math.abs(adjustedAmount))} remaining`
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className={`budget-progress-container`}
                    style={{
                      backgroundColor: lightBackgroundColor(
                        getCustomCodeToName(budget.colour ?? "#FFF")
                      ),
                    }}
                  >
                    <motion.div
                      className={`budget-progress`}
                      style={{
                        width: `${progress}%`,
                        backgroundColor: progressbarColor(
                          getCustomCodeToName(budget.colour)
                        ),
                      }}
                      initial={{ width: 0 }}
                      animate={{ width: `${progress}%` }}
                      transition={{ duration: 0.5 }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></motion.div>
                  </div>
                </div>
              </div>
            )}
            <div className="search-spacing filter-merchants-wrp-side mt-4">
              <div className="row">
                <div className="col-md-7 col-lg-12">
                  <SectionTitle title={"Transactions"} />
                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input
                          type="text"
                          value={search}
                          className="search search-color-white"
                          onChange={(e) => handleSearch(e)}
                        />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="account-detail-left-wrapper mt-3">
                {!isEmpty(currentMonthGrouped) ||
                !isEmpty(remainingMonthsGrouped) ? (
                  <TransactionListing
                    data={{
                      ...filteredCurrentMonthTransactions,
                      ...filteredRemainingMonthTransactions,
                    }}
                    modalOpen={openModal}
                    expenseCategories={category}
                    updateTransactionCategory={updateTransactionCategory}
                    addNewCategory={addNewCategory}
                    incomeCategories={icomecat}
                    oneOffCategory={oneOffCategory}
                    setSelectionMode={setSelectionMode}
                    isAccountModule={true}
                    // isSelectionMode={isSelectionMode}
                    // setSelectedForBulkUpdate={setSelectedForBulkUpdate}
                  />
                ) : (
                  <NoDataFound />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <EditDetails
            togglePopover={togglePopover}
            updateTransactionCategory={updateTransactionCategory}
            popoverOpen={popoverOpen}
            showAddCategory={showAddCategory}
            handleAddCategory={handleAddCategory}
            amount={editAmount}
            handleAmountChange={handleAmountChange}
            selectedFrequency={selectedFrequency}
            toggleFrequencyModal={toggleFrequencyModal}
            isSpreadToggled={isSpreadToggled}
            handleToggleClick={handleEditToggleClick}
            handleNeedToggleClick={handleNeedToggleClick}
            needOrWant={needOrWant}
            incomeExpenseBudgetsName={budget.category_type
              ?.trim()
              .toLowerCase()}
            incomeExpenseBudgetsData={budget}
            showCategory={false}
            expenseOrIncome={expenseOrIncome}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            autoExpense={false}
            handleNameChange={handleNameChange}
            editName={editName}
            isFrequencyModalOpen={isFrequencyModalOpen}
            EditDetails={true}
            isGrouped={isGrouped}
            setIsGrouped={setIsGrouped}
          />

          {expenseOrIncome !== "oneoff" && (
            <div
              className="trans-udpade-rule-note mt-2 justify-content-between"
              style={{
                padding: "0px 1px 0px 4px !important",
                marginBottom: "0px",
              }}
            >
              <span className="trans-500">Note:</span> When you edit the above
              amount, your budget amounts per month will be updated based on
              your budget amount change preferences in budget settings.
            </div>
          )}

          {/* {isGrouped && (
            <EditGroupedAmountCategory expenseOrIncome={expenseOrIncome} />
          )} */}

          <PersonalisedColorEmoji
            selectedColorOption={selectedColorOption}
            togglePopupColor={togglePopupColor}
            isPopupOpenColor={isPopupOpenColor}
            handleOptionColorClick={handleOptionColorClick}
            setShowPicker={setShowPicker}
            showPicker={showPicker}
            chosenEmoji={chosenEmoji}
            handleEmojiClick={handleEmojiClick}
          />
        </div>
      </div>

      {showPopover && (
        <MonthYearCalendarPopover
          target={"monthYearPickerDetails"}
          tabMonth={tabMonth}
          tabYear={tabYear}
          setTabMonth={setTabMonth}
          setTabYear={setTabYear}
          setShowPopover={setShowPopover}
          showPopover={showPopover}
          baseYear={currentDate.getFullYear()}
          baseMonth={currentDate.getMonth()}
          // onClose={togglePopover}
        />
      )}

      {isFrequencyModalOpen && (
        <HowOften
          isOpen={isFrequencyModalOpen}
          targetId="frequencyPickerButton"
          onClose={toggleFrequencyModal}
          toggleFrequencyModal={toggleFrequencyModal}
          handleFrequencySelection={handleFrequencySelection}
        />
      )}
      {modals.viewTransaction && (
        <TransactionViewModal
          data={spliModalData}
          modalOpen={modals.viewTransaction}
          closeModal={closeModal}
          splitTransaction={splitTransaction}
          expenseCategories={category}
          incomeCategories={icomecat}
          oneOffCategory={oneOffCategory}
        />
      )}
      {isLoading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </div>
  );
};

export default BudgetDetails;
