import axios from "axios";
import moment from "moment/moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  colorCss,
  customColourOrName,
  customColourOrNameColour,
  howOftenData,
} from "../../utilities/Statics";
import EmojiPicker from "emoji-picker-react";
import Cinput from "../Cinput";
import PopupColorModal from "../../ModalForm/Popup/PopupColorModel";
import weburls from "../../Weburls/weburls";
import NZLogo from "../../assests/images/dashboard/anz.svg";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import DatePickerModal from "../../Views/AddNewGoal/DatePickerModal/DatePickerModal";

export const EditGoal = ({
  goal,
  handleSaveEdit,
  isLinkedToAccount,
  setIsLinkedToAccount,
  setShowPicker,
  showPicker,
  setShowConfirmationModal,
  showConfirmationModal,
  selectedAccounts,
  chosenEmoji,
  setChosenEmoji,
  isUpdadting = false,
}) => {
  const [editedGoal, setEditedGoal] = useState({ ...goal });
  const [accountData, setAccountData] = useState([]);
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const [selectedTab, setSelectedTab] = useState(goal.howOften);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const pickerRef = useRef(null);

  useOutsideClick(pickerRef, () => setShowPicker(false));
  const { uuid } = useParams();

  useEffect(() => {
    setEditedGoal({ ...goal });
  }, [goal]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedGoal({ ...editedGoal, [name]: value });
  };

  const handleEmojiClick = (event) => {
    const { emoji } = event;
    setChosenEmoji(emoji);

    setShowPicker(false);
    setEditedGoal({ ...editedGoal, icon: emoji });
  };

  const handleTabChange = (tabName) => {
    const { value } = tabName.target;
    setSelectedTab(value);
    setEditedGoal({ ...editedGoal, howOften: value });
  };

  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);

  const handleOptionColorClick = (option) => {
    setEditedGoal({ ...editedGoal, colour: option });
    setPopupOpenColor(false);
  };

  const handleSave = () => {
    handleSaveEdit(editedGoal);
  };

  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
      if (response?.data) {
        setAccountData(response?.data?.accounts);
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const removeGoalHandler = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  useEffect(() => {
    fetchCashAccount();
  }, [fetchCashAccount]);
  return (
    <>
      <div className="edit-goal-ami active-edit-goal">
        <div>
          <div className="black sub-head-text lh-26">Edit your Goal</div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold">Name</div>
            <Cinput
              type={"text"}
              name="goalName"
              value={editedGoal.goalName}
              onChange={handleInputChange}
              width={"333px"}
            />
          </div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold">Goal Dollar Value</div>
            <Cinput
              type={"number"}
              name="value"
              value={editedGoal.value}
              onChange={handleInputChange}
              width={"260px"}
            />
          </div>
          <div className="edit-goal-box ">
            <div className="d-flex align-items-center justify-content-between">
              <div className="fw-bold">Start Date</div>
              <input
                type="text"
                name="startDate"
                value={
                  editedGoal.startDate &&
                  moment(editedGoal.startDate).format("DD/MM/YYYY")
                }
                readOnly
                onClick={() => setOpenDatePicker(!openDatePicker)}
                className="edit-goal-house py-2 add-goal"
                style={{ border: "none" }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="fw-bold">Contributions</div>
              <Cinput
                type="number"
                name="contribution"
                value={editedGoal.contribution}
                onChange={handleInputChange}
                width={"260px"}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="fw-bold">How Often?</div>
              <div className="account-input">
                <select
                  className="how-often-select"
                  name="accountType"
                  value={selectedTab}
                  onChange={handleTabChange}
                  // {...register('accountType', {
                  //   onChange: (e) => {
                  //     handleChange(e);
                  //   },
                  // })}
                >
                  <option value="">Select</option>
                  {howOftenData &&
                    howOftenData.length > 0 &&
                    howOftenData.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="edit-goal-box">
            <div className="d-flex justify-content-between align-items-center">
              <div className="fw-bold">Link to an account?</div>
              <div className="account-input">
                {isLinkedToAccount ? (
                  <div
                    className="link-account"
                    onClick={() => setIsLinkedToAccount(!isLinkedToAccount)}
                  >
                    Yes
                  </div>
                ) : (
                  <div
                    className="not-link-account"
                    onClick={() => setIsLinkedToAccount(!isLinkedToAccount)}
                  >
                    No
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3 align-items-center">
              {isLinkedToAccount ? (
                <>
                  <div className="fw-bold">Account</div>
                  <div className="account-input d-flex align-items-center">
                    <button
                      type="button"
                      className={`group-button ${colorCss(
                        editedGoal.colour
                      )} popoup-color`}
                      style={{ background: "#0477B5", height: "33px" }}
                    >
                      {selectedAccounts?.length > 1
                        ? `${selectedAccounts?.length} connected accounts`
                        : `${selectedAccounts?.length} connected account`}
                    </button>
                  </div>
                </>
              ) : (
                <div className="current-bal d-flex align-items-center">
                  <div className="fw-bold">Current balance</div>
                  <Cinput
                    type="number"
                    name="currentBalance"
                    value={editedGoal.currentBalance}
                    onChange={handleInputChange}
                    width={"260px"}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="edit-goal-box d-flex justify-content-between align-items-center">
            <div className="fw-bold">Personalized colour</div>
            <button
              onClick={togglePopupColor}
              aria-haspopup="true"
              aria-expanded={isPopupOpenColor}
              id="colorPickerButtonPersonalized"
              className={`border-0 rounded-pill text-white px-4 fs-18 fw-400 ${customColourOrNameColour(
                editedGoal?.colour
              )}`}
              style={{
                height: "33px",
                backgroundColor: customColourOrNameColour(editedGoal?.colour),
              }}
            >
              {customColourOrName(editedGoal.colour)}
            </button>
            {/* <div className="color-option">
              <div className="dropdown">
              <button
                type="button"
                className={`group-button fs-18 fw-400 ${customColourOrNameColour(
                  editedGoal?.colour
                )} popoup-color`}
                onClick={togglePopupColor}
                aria-haspopup="true"
                aria-expanded={isPopupOpenColor}
                id="colorPickerButton"
                style={{
                  height: "33px",
                  backgroundColor: customColourOrNameColour(editedGoal?.colour),
                }}
              >
                {customColourOrName(editedGoal.colour)}
              </button>
              </div>
            </div> */}
          </div>
          <div className="edit-goal-box d-flex justify-content-between flex-column position-relative">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="fw-bold">Personalized emoji</div>
              <div
                className="py-2 add-goal show-emoji"
                onClick={() => setShowPicker(!showPicker)}
                style={{ width: "261px", height: "35px" }}
              >
                {chosenEmoji}
              </div>
            </div>
            {showPicker && (
              <div ref={pickerRef} className="position-absolute bottom-100 z-3">
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              </div>
            )}
          </div>
          <div className="d-flex mt-3">
            <button className="save-green-btn lh-34" onClick={handleSave}>
              {isUpdadting ? (
                <div
                  style={{ width: "23px", height: "23px" }}
                  className="spinner-border  text-light mt-1"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Save changes"
              )}
            </button>
            <button
              className="remove-red-btn fw-500"
              onClick={removeGoalHandler}
            >
              Remove goal
            </button>
          </div>
        </div>
      </div>

      {openDatePicker && (
        <DatePickerModal
          openDatePicker={openDatePicker}
          setOpenDatePicker={setOpenDatePicker}
          handleChange={handleInputChange}
        />
      )}

      {isPopupOpenColor && (
        <PopupColorModal
          isOpen={isPopupOpenColor}
          onClose={togglePopupColor}
          targetId="colorPickerButtonPersonalized"
          title="Date Range"
          options={[
            "Red",
            "Yellow",
            "Blue",
            "Purple",
            "Orange",
            "Pink",
            "Light blue",
            "Light green",
            "Green",
          ]}
          selectedOption={editedGoal.colorOption}
          handleOptionClick={handleOptionColorClick}
        />
      )}
    </>
  );
};
