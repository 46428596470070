import React, { useEffect, useState } from "react";
import SelectCreatePopOver from "./SelectPopOver";
import { API_ENDPOINTS } from "../../client/ApiEndpoints";
import HttpClient from "../../client/HttpClient";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddCategory from "./AddCategory";
import { categoryColorForAll } from "../../utilities/Statics";

const SelectCreateCategory = (props) => {
  const {
    title = "",
    style,
    targetId,
    popoverHeading,
    type,
    handleCategoryClick,
    createCategory = false,
    setChosenEmoji,
    setSelectedColorOption,
  } = props;

  const { uuid } = useParams();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [mainSelectedCategory, setMainSelectedCategory] = useState();

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const defaultCategoriesData =
    type === "expense"
      ? {
          expense: [...(categoryData?.expense || [])],
          yourExpense: [...(categoryData?.yourExpense || [])],
        }
      : { income: categoryData?.income || [] };

  // API Calls starts
  const incomeExpenseCategory = async () => {
    try {
      const url =
        type === "expense"
          ? `${API_ENDPOINTS.GET_EXPENSE_REMAINING_BY_UUID}${uuid}`
          : `${API_ENDPOINTS.GET_INCOME_REMAINING_BY_UUID}${uuid}`;

      const response = await HttpClient.get(url);

      if (response.status === 200) {
        if (response?.data?.code === 1) {
          setCategoryData(response?.data);
          // toast.success(response?.data?.message);
        } else if (response?.data?.code === 0) {
          toast.error(response?.data?.message);
        } else {
          toast.warn("Unexpected response from server");
        }
      } else {
        toast.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("Something went wrong while fetching data");
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_EXPENSE_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      incomeExpenseCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_INCOME_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      incomeExpenseCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewCategory = (data, type) => {
    if (popoverHeading === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  const handleAddCategory = () => {
    // setPopoverOpen((prevState) => ({
    //   ...prevState,
    // }));

    setShowAddCategory(!showAddCategory);
    setPopoverOpen({});
    togglePopover();
  };

  useEffect(() => {
    incomeExpenseCategory();
  }, []);

  return (
    <>
      {mainSelectedCategory ? (
        <div
          className="icon-tag"
          id={targetId}
          onClick={togglePopover}
          aria-haspopup="true"
          style={{
            marginBottom: "0px",
          }}
        >
          <div
            className="icon-transaction-img"
            style={{
              backgroundColor: categoryColorForAll(
                mainSelectedCategory?.categoryColour
              ),
            }}
          >
            {mainSelectedCategory?.categoryEmoji}
          </div>
          <div className="content">
            <h6 className="icon-content">
              {mainSelectedCategory?.categoryName}
            </h6>
          </div>
        </div>
      ) : (
        <span
          aria-haspopup="true"
          id={targetId}
          onClick={togglePopover}
          style={{ ...style, borderRadius: "36px" }}
        >
          {title}
        </span>
      )}

      <SelectCreatePopOver
        popoverOpen={popoverOpen}
        targetId={targetId}
        popoverHeading={popoverHeading}
        togglePopover={togglePopover}
        handleChange={handleAddCategory}
        defaultCategory={defaultCategoriesData}
        setChosenEmoji={setChosenEmoji}
        setSelectedColorOption={setSelectedColorOption}
        type={type}
        handleCategoryClick={handleCategoryClick}
        setMainSelectedCategory={setMainSelectedCategory}
        createCategory={createCategory}
      />

      {showAddCategory && (
        <AddCategory
          modalOpen={showAddCategory}
          addNewCategory={addNewCategory}
          transactionType={popoverHeading}
          closeModal={handleAddCategory}
        />
      )}
    </>
  );
};

export default SelectCreateCategory;
