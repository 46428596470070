import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import weburls from "../../../Weburls/weburls";
import { ReactComponent as Logo } from "../../../assests/images/icons/akahulogo.svg";
import axios from "axios";
import { motion } from "framer-motion";
import BodyAkahuConnection from "./BodyAkahuConnection";
import MainSidebar from "../../MainSidebar";

const AkahuConnection = () => {
  const uuid = localStorage.getItem("userUuid");
  const token = localStorage.getItem("budget-token");

  const setUp = localStorage.getItem("setUp");
  const isAccountSet = localStorage.getItem("isAccountSet");

  const location = useLocation();
  const logoRef = useRef(null);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const error = queryParams.get("error");
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(10);
  const apiCalled = useRef(false);

  console.log(error, "error");

  const onSubmitHandler = async () => {
    if (apiCalled.current) return;
    apiCalled.current = true;

    setLoading(true);
    try {
      const response = await axios.get(
        `${weburls.Akahu_Connection}${uuid}?code=${code}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        localStorage.setItem("trnas-load", true);
      }
      toast.success(`${response?.data?.message}`);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong...");
      setLoading(false);
    }
  };

  // http://localhost:3000/akahu?error=access_denied&error_description=The+user+cancelled+the+request&state=htfkf

  useEffect(() => {
    if (error === "access_denied") {
      navigate(`/connectaccounts/${uuid}`);
      return;
    }
    
    onSubmitHandler();
    const setUp = localStorage.getItem("setUp");
    const isAccountSet = localStorage.getItem("isAccountSet");

    const navigateTimeout = setTimeout(() => {
      if (setUp === "false" && isAccountSet === "false") {
        navigate(`/sign-up/all-connected`);
      } else {
        navigate(`/connectaccounts/${uuid}`);
      }
    }, 10000);

    // Cleanup timeout
    return () => clearTimeout(navigateTimeout);
  }, [navigate, uuid]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slide-up");
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      {setUp === "false" && isAccountSet === "false" ? (
        <section className="login-section loginSectionWelcome">
          <div className="container-fluid h-custom">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div
                className="col-md-8 col-lg-6 col-xl-4"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{ duration: 0.5 }}
                  style={{ width: "100%" }}
                >
                  <BodyAkahuConnection formRef={formRef} counter={counter} />
                </motion.div>
              </div>
            </div>
          </div>
        </section>
      ) : error === "access_denied" ? (
        ""
      ) : (
        <div className="container-scroller">
          <div className="container-fluid">
            <div
              className="appmain mainsidebar main-wrapper"
              style={{ width: "100%" }}
            >
              <MainSidebar uuid={uuid} />
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{ duration: 0.5 }}
                >
                  <BodyAkahuConnection formRef={formRef} counter={counter} />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AkahuConnection;
