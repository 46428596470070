import React, { useState } from "react";
import { ReactComponent as ArrowAccoor } from "../../../assests/images/icons/accordionArrow.svg";
import "./BudgetSummary.css";
import IncomeExpenseBudgetModal from "./IncomeExpenseBudget/IncomeExpenseBudgetModal";
import { useNavigate } from "react-router-dom";
import {
  lightBackgroundColor,
  progressbarColor,
} from "../../../utilities/Statics";
import { motion } from "framer-motion";

const OneOffBudget = ({
  oneOffBudgetsData = [],
  mainHeading,
  showFields,
  uuid,
  fetchBudgetData,
  isCompactView,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCardClick = (budgetId, fetchBudget) => {
    navigate(`/budget/${uuid}/budgetdetails/oneoff/${budgetId}`, {
      state: {
        fetchBudget,
        isCompactView,
      },
    });
  };

  function formatAmount(amount) {
    if (amount % 1 === 0) {
      return amount.toLocaleString();
    } else {
      let [integerPart, decimalPart] = amount.toFixed(2).split(".");
      return `${Number(integerPart).toLocaleString()}.${decimalPart}`;
    }
  }

  return (
    <div className="col-md-8">
      <div className="budget-breakdown-header">
        <h4>{mainHeading} budgets</h4>
        <div
          className="col-md-6 right-section"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "6px",
            alignItems: "center",
          }}
        >
          <i
            className="fa-solid fa-plus"
            style={{
              width: "20px",
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={toggleModal}
          ></i>
        </div>
      </div>

      <IncomeExpenseBudgetModal
        isModalOpen={isModalOpen}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        mainHead={mainHeading}
        fetchBudgetData={fetchBudgetData}
        showFields={showFields}
      />

      {oneOffBudgetsData.monthly && (
        <div className="budget-item-card">
          {oneOffBudgetsData?.monthly?.map((budget, index) => {
            let progress;
            const adjustedAmount =
              budget?.amount - budget?.total_transaction_amount;
            if (budget?.amount === 0) {
              progress = 0;
            } else {
              progress =
                (budget?.total_transaction_amount / budget?.amount) * 100;
            }
            progress = progress > 100 ? 100 : progress;
            return (
              <div
                key={index}
                className="budget-item"
                onClick={() => handleCardClick(budget.uuid, budget)}
                style={{ cursor: "pointer" }}
              >
                <div className="budget-label">
                  <div className="pay-budget-sec">
                    <div
                      className={`budget-icon `}
                      style={{
                        backgroundColor: lightBackgroundColor(budget.colour),
                      }}
                    >
                      {budget.emoji}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "7px",
                        marginLeft: "5px",
                      }}
                    >
                      <h6>{budget.name}</h6>
                      <h4 style={{ marginTop: "-5px" }}>
                        $
                        {formatAmount(
                          Math.abs(budget.total_transaction_amount)
                        )}
                      </h4>
                    </div>
                  </div>
                  <p className="header-p" style={{ cursor: "pointer" }}>
                    <div className="account-number inter text-16px">
                      <div className="button-blck">
                        <ArrowAccoor />
                      </div>
                    </div>
                  </p>
                </div>

                <div className="budget-remaining">
                  {/* ${Math.max(0, adjustedAmount)} remaining */}$
                  {formatAmount(Math.abs(adjustedAmount))} left
                </div>

                <div
                  className={`budget-progress-container 
                  )}`}
                  style={{
                    backgroundColor: lightBackgroundColor(budget.colour),
                  }}
                >
                  <motion.div
                    className={`budget-progress 
                    )}`}
                    style={{
                      width: `${progress}%`,
                      backgroundColor: progressbarColor(budget.colour),
                    }}
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%` }}
                    transition={{ duration: 0.5 }}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></motion.div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OneOffBudget;
