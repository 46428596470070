import React, { useCallback, useEffect, useRef, useState } from "react";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer";
import { Loading } from "../../Components/Loading/Loading";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal";
import { isEmpty } from "lodash";
import NoDataFound from "../../Components/NoDataFound/index.jsx";
import { DashboardTransactionsListing } from "./DashboardTransactionsListing.jsx";
import HttpClient from "../../client/HttpClient.js";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../../client/ApiEndpoints.js";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
  parseYourAccountBalancesData,
} from "../../utilities/helper.jsx";
import HttpAkahuClient from "../../client/HttpAkahuClient.js";
import useDebounce from "../../hooks/useDebouce.jsx";
import { useNavigate, useParams } from "react-router-dom";

const RecentTransactions = ({ allDashboardDetails }) => {

  const { uuid } = useParams();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userUuid");
  const startDateTrans = localStorage.getItem("startDate");
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [oneOffCategory, setOneOffCategory] = useState([]);
  const [filterByDate, setFilterByDate] = useState("");
  const [selectedIncomeCategories, setSelectedIncomeCategories] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [loading, setLoading] = useState(false);
  const [spliModalData, setSplitModalData] = useState();
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 1000);
  const [isSelectionMode, setSelectionMode] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [unPress, setUnpress] = useState(false);
  const [plan, setPlan] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const scrollContainerRef = useRef(null);
  const [resetDone, setResetDone] = useState(false);
  const [seeAll, setSeeAll] = useState(false);
  const [selectedForBulkUpdate, setSelectedForBulkUpdate] = useState({
    Expense: [],
    Income: [],
  });
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState(
    []
  );
  const [modals, setModals] = useState({
    addTransaction: false,
    viewTransaction: false,
    changePassword: false,
    addTransactionRules: false,
  });

  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);

  const handleResetStatesAfterChange = () => {
    setPage(1);
    setTotalIncomes(0);
    setTotalExpense(0);
    setCurrentMonthGrouped([]);
    setRemainingMonthsGrouped([]);
    setResetDone(true);
  };

  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_ALL_CATEGORIES}${uuid}`
      );
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
      setOneOffCategory(response?.data?.data?.transaction?.ONEOFF);
    } catch (err) {
      //   toast.error(err);
    }
  }, [uuid]);

  const fetchTransactions = async (page) => {
    setLoading(true);
    const categorizedFilter = localStorage.getItem("filter-trns");
    let data = {
      expensecategoryIds: [...selectedExpenseCategories],
      incomecategoryIds: [...selectedIncomeCategories],
      dateRageFilter: filterByDate,
    };
    if (
      categorizedFilter === "Categorised" ||
      categorizedFilter === "Uncategorised"
    ) {
      data = {
        expensecategoryIds: [],
        incomecategoryIds: [],
        dateRageFilter: "",
        categoryFilter: categorizedFilter,
      };
    }
    try {
      await HttpAkahuClient.get(
        `${API_ENDPOINTS.GET_LATEST_TRANSACTIONS}${uuid}`
      );
    } catch (error) {
      console.log(error);
    }

    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.GET_TRANSACTION_BY_CLIENT_ID}${uuid}/50/${page}?name=${search}`,
        data
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.data?.transactions?.thisMonth,
        currentMonthGrouped
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.data?.transactions?.remainingMonths,
        remainingMonthsGrouped
      );
      const transactionsThisMonth =
        response?.data?.data?.transactions?.thisMonth || [];
      const transactionsRemainingMonths =
        response?.data?.data?.transactions?.remainingMonths || [];
      const allTransactions = [
        ...transactionsThisMonth,
        ...transactionsRemainingMonths,
      ];
      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      setTotalIncomes(
        totalIncomes +
          groupedCurrentMonth.incomeCount +
          groupedRemainingMonths.incomeCount
      );
      setTotalExpense(
        totalExpense +
          groupedCurrentMonth.expenseCount +
          groupedRemainingMonths.expenseCount
      );
      if (page > 1) {
        setSelectAll(false);
      }
      setHasMore(allTransactions > 0);
      setLoading(false);
    } catch (error) {
      //   toast.error("Something went wrong, please try again.", {
      //     position: "top-right",
      //     autoClose: 2000,
      //   });
      setLoading(false);
      setHasMore(false);
    }
  };
  const getTrasnsactionRileCategorized = useCallback(async () => {
    try {
      await HttpClient.get(
        `${API_ENDPOINTS.GET_TRANSACTIONS_RULE_CATEGORIZED}${uuid}`
      );
    } catch (err) {
      //   toast.error(err);
    }
  }, [uuid]);

  const deleteDuplicateTransactions = useCallback(async () => {
    try {
      await HttpClient.delete(
        `${API_ENDPOINTS.DELETE_DUPLICATE_TRANSACTIONS}${uuid}`
      );
    } catch (error) {
      console.error("Error deleting duplicate transactions:", error);
    }
  }, [uuid]);
  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setPage((prev) => prev + 1);
          fetchTransactions(page + 1);
        }
      }
    };
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore, page]);

  useEffect(() => {
    getCategory();
    fetchCashAccount();
    fetchAllTransactionsCount();
    getTrasnsactionRileCategorized();
    deleteDuplicateTransactions();
  }, [getCategory]);

  useEffect(() => {
    if (resetDone) {
      fetchTransactions(1);
      setResetDone(false);
    }
  }, [resetDone]);

  useEffect(() => {
    if (seeAll) {
      localStorage.setItem("filter-trns", "");
      fetchTransactionAfterAnyUpdate(page);
      setSeeAll(false);
    }
  }, [seeAll]);

  useEffect(() => {
    handleResetStatesAfterChange();
  }, [
    debouncedSearch,
    filterByDate,
    selectedExpenseCategories,
    selectedIncomeCategories,
  ]);

  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_CASH_ACCOUNT}${uuid}`
      );
      if (response?.data) {
        const parsedData = parseYourAccountBalancesData(
          response?.data?.accounts
        );
        setAccountData(parsedData);
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const fetchAllTransactionsCount = async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_ALL_TRANSACTIONS_COUNT}${uuid}`
      );
    } catch (error) {}
  };

  const addTransaction = async (data) => {
    const payload = {
      userUuid: uuid,
      trans_name: data.name,
      trans_date: data.date,
      trans_amount: data.amount,
      accound_id: data.id,
      avatar: data.avatar,
      trans_type: data.transactionType.toUpperCase(),
    };
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_TRANSACTION}`,
        payload
      );

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      fetchTransactionAfterAnyUpdate();
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.SPLIT_TRANSACTION}${uuid}/${transactionId}/${type}`,
        splits
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactionAfterAnyUpdate();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const deleteCustomTransaction = async (id) => {
    try {
      const response = await HttpClient.delete(
        `${API_ENDPOINTS.DELETE_CUSTOM_TRANSACTIONS}${uuid}/${id}`
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactionAfterAnyUpdate();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  const editCategory = (data, categoryId) => {
    editIncomeExpensesCategory(data, categoryId);
  };

  const deleteCategory = (selectedCategory) => {
    if (selectedCategory.isDefalutCategory) {
      deleteExpenseCategoryAndBudget(selectedCategory);
    } else {
      removeCategoryByUserUuidAndCategoryId(selectedCategory);
    }
  };

  const deleteExpenseCategoryAndBudget = async (selectedCategory) => {
    try {
      const response = await HttpClient.delete(
        `${API_ENDPOINTS.DELETE_EXPENSE_CATEGORY_AND_BUDGET}${uuid}/${selectedCategory.userCategoryId}`
      );

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      getCategory();
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const removeCategoryByUserUuidAndCategoryId = async (selectedCategory) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.REMOVE_CATEGORY_BY_USER_UUID_AND_CATEGORY_ID}${uuid}/${selectedCategory.userCategoryId}`,
        {}
      );

      if (response?.data.code === 1) {
        toast.success("Category deleted", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      getCategory();
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const editIncomeExpensesCategory = async (data, categoryId) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.EDIT_CATEGORY}${uuid}/${categoryId}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category updated successfully.`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while edit category");
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_EXPENSE_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_INCOME_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Something went wrong while adding expense category");
    }
  };

  const updateTransactionCategory = async (
    transactionId,
    categoryId,
    type,
    transactionType
  ) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: transactionType === "Expense" ? "Expense" : type,
    };
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.UPDATE_CATEGORY}${uuid}`,
        data
      );
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {
      toast.error(
        "Something went wrong while updating the transaction category"
      );
    }
  };

  const updateSingleCategory = async (categoryData) => {
    const data = {
      transactionUuid: categoryData.transactionId,
      categoryId: categoryData.userCategoryId,
      type: categoryData.type,
    };
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.UPDATE_CATEGORY}${uuid}`,
        data
      );
      // toast.success(`${response?.data?.message}`);
      setSplitModalData((prev) => ({
        ...prev,
        masterCategoryColour: categoryData.colour,
        masterCategoryEmoji: categoryData.emoji,
        masterCategoryName: categoryData.name,
      }));
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {}
  };

  const splitbackToMainTransaction = async (transactionUUid) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.SPLIT_BACK_TO_MAIN_TRANSACTION}${uuid}/${transactionUUid}`,
        {}
      );
      toast.success(`${response?.data?.message}`);
      closeModal("viewTransaction");
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {}
  };

  const splitSingleBackToMainTransaction = async (transactionUUid) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.SPLIT_SINGLE_BACK_TO_MAIN_TRANSACTION}${uuid}/${transactionUUid}`,
        {}
      );
      toast.success(`${response?.data?.message}`);
      closeModal("viewTransaction");
      fetchTransactionAfterAnyUpdate(page);
    } catch (error) {}
  };

  const fetchTransactionAfterAnyUpdate = async () => {
    // setLoading(true);
    const categorizedFilter = localStorage.getItem("filter-trns");
    let data = {
      expensecategoryIds: [...selectedExpenseCategories],
      incomecategoryIds: [...selectedIncomeCategories],
      dateRageFilter: filterByDate,
    };
    if (
      categorizedFilter === "Categorised" ||
      categorizedFilter === "Uncategorised"
    ) {
      data = {
        expensecategoryIds: [],
        incomecategoryIds: [],
        dateRageFilter: "",
        categoryFilter: categorizedFilter,
      };
    }
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.GET_TRANSACTION_BY_CLIENT_ID}${uuid}/${
          totalExpense + totalIncomes
        }/${1}?name=${search}`,
        data
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.data?.transactions?.thisMonth
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.data?.transactions?.remainingMonths
      );
      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      // setLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      // setLoading(false);
      setHasMore(false);
    }
  };

  const updateCategoriesInBulk = async (categoryId, type) => {
    setLoading(true);
    const data = {
      expenseTransactionUuids: [...selectedForBulkUpdate.Expense],
      incomeTransactionUuids: [...selectedForBulkUpdate.Income],
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.UPDATE_CATEGORY_IN_BULK}${uuid}`,
        data
      );
      if (response?.data.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactionAfterAnyUpdate();
      setSelectedForBulkUpdate({
        Expense: [],
        Income: [],
      });
      setSelectionMode(false);
      setSelectAll(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const loadAllAkahuTransactions = async (startDate) => {
    if (String(startDate).toUpperCase() !== "NULL") {
      try {
        const response = await HttpClient.post(
          `${
            plan === "Basic"
              ? API_ENDPOINTS.AKAHU_BASIC_PLAN_ALL_TRANSACTIONS_LOAD
              : API_ENDPOINTS.AKAHU_ALL_TRANSACTIONS_LOAD
          }${uuid}`,
          { startDate: startDate }
        );
        if (response.data && response.data.data.length > 0) {
          const newStartDate = response.data.startDate;
          const endDate = response.data.endDate;

          localStorage.setItem("startDate", response.data.startDate);
          loadAllAkahuTransactions(newStartDate);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    }
  };

  const getCreditTransactions = async () => {
    try {
      await HttpClient.get(`${API_ENDPOINTS.GET_CREDIT_TRANSACTION}${uuid}`);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    // fetchedPlanData();
    loadAllAkahuTransactions(startDateTrans);
    getCreditTransactions();
  }, []);

  return (
    <>
      {!isEmpty(currentMonthGrouped) || !isEmpty(remainingMonthsGrouped) ? (
        <DashboardTransactionsListing
          data={{
            ...currentMonthGrouped,
            ...remainingMonthsGrouped,
          }}
          allDashboardDetails={allDashboardDetails}
          modalOpen={openModal}
          updateTransactionCategory={updateTransactionCategory}
          addNewCategory={addNewCategory}
          editCategory={editCategory}
          expenseCategories={category}
          incomeCategories={icomecat}
          setSelectionMode={setSelectionMode}
          oneOffCategory={oneOffCategory}
          isSelectionMode={isSelectionMode}
          setSelectedForBulkUpdate={setSelectedForBulkUpdate}
          selectedForBulkUpdate={selectedForBulkUpdate}
          updateCategoriesInBulk={updateCategoriesInBulk}
          selectAll={selectAll}
          unPress={unPress}
          deleteCategoryConfirm={deleteCategory}
        />
      ) : (
        <NoDataFound />
      )}

      {modals.viewTransaction && (
        <TransactionViewModal
          data={spliModalData}
          modalOpen={modals.viewTransaction}
          closeModal={closeModal}
          addTransaction={addTransaction}
          accountData={accountData}
          splitTransaction={splitTransaction}
          expenseCategories={category}
          incomeCategories={icomecat}
          oneOffCategory={oneOffCategory}
          updateSingleCategory={updateSingleCategory}
          deleteCustomTransaction={deleteCustomTransaction}
          splitbackToMainTransaction={splitbackToMainTransaction}
          splitSingleBackToMainTransaction={splitSingleBackToMainTransaction}
          customPopoverClassName={"custom-popover-trans"}
        />
      )}
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default RecentTransactions;
