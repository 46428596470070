import React from "react";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import { ReactComponent as PLusIcon } from "../../assests/images/plus.svg";
import { ReactComponent as BulkIcon } from "../../assests/images/bulk-icon.svg";
import { ReactComponent as RulesIcon } from "../../assests/images/rules-icon.svg";
import { ReactComponent as SelectAllIcon } from "../../assests/images/icons/selectall.svg";
import PageTitle from "../../Components/PageTitle";
import SectionTitle from "../../Components/SectionTitle.jsx";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle";
import AddTransactionModal from "../../ModalForm/TransactionModals/AddTransactionModal";
import { TransactionListing } from "./TransactionListing";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer";
import { Loading } from "../../Components/Loading/Loading";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal";
import { dateOptions } from "../../utilities/Statics";
import { isEmpty } from "lodash";
import NoDataFound from "../../Components/NoDataFound/index.jsx";
import FilterTags from "../../Components/FilterTags/index.jsx";
import AddTransactionRuleModel from "../../ModalForm/TransactionModals/AddTransactionRuleModel.jsx";
import useTransactions from "../../hooks/Transactions/useTransactions/useTransactions.jsx";

const Transactions = () => {
  const {
    scrollContainerRef,
    isSelectionMode,
    selectedForBulkUpdate,
    seeAllShow,
    setSeeAll,
    setSumbitFilter,
    categorizedTransactionCount,
    unCategorizedTransactionCount,
    setSelectionMode,
    openModal,
    selectAll,
    setSelectAll,
    SelectAll,
    handleDoneWithBulkUpdate,
    currentMonthGrouped,
    remainingMonthsGrouped,
    updateTransactionCategory,
    addNewCategory,
    editCategory,
    category,
    icomecat,
    oneOffCategory,
    setSelectedForBulkUpdate,
    updateCategoriesInBulk,
    unPress,
    deleteCategory,
    search,
    handleSearch,
    selectedExpenseCategories,
    setSelectedExpenseCategories,
    handleTagClick,
    selectedIncomeCategories,
    setSelectedIncomeCategories,
    filterByDate,
    setFilterByDate,
    modals,
    closeModal,
    addTransaction,
    accountData,
    spliModalData,
    splitTransaction,
    updateSingleCategory,
    deleteCustomTransaction,
    splitbackToMainTransaction,
    splitSingleBackToMainTransaction,
    loading,
    navigate,
    uuid,
  } = useTransactions();

  return (
    <>
      <div
        className="app-main__outer your-merchants-main-wrapper"
        ref={scrollContainerRef}
      >
        <div className="row">
          <PageTitle
            title={"Your Transactions"}
            description={"Here’s all of your transactions from all accounts"}
          />
          <UserImageInfoToggle />
        </div>
        <div className="d-flex gap-4 mt-5">
          <div className="account-detail-left">
            <div className="d-flex align-items-center justify-content-between">
              <SectionTitle
                title={
                  isSelectionMode
                    ? `${
                        selectedForBulkUpdate.Expense.length +
                        selectedForBulkUpdate.Income.length
                      } Selected`
                    : "Transactions"
                }
              />
              <div className="d-flex gap-1">
                {!isSelectionMode ? (
                  <>
                    {seeAllShow && (
                      <span
                        className="see-all cursor-pointer"
                        onClick={() => setSeeAll(true)}
                      >
                        See all
                      </span>
                    )}

                    <span
                      className="transaction-count transaction-count-green d-flex align-items-center justify-content-center"
                      onClick={() => setSumbitFilter("Categorised")}
                    >
                      {categorizedTransactionCount}
                    </span>
                    <span
                      className="transaction-count transaction-count-red  d-flex align-items-center justify-content-center"
                      onClick={() => setSumbitFilter("Uncategorised")}
                      style={{ marginRight: "10px" }}
                    >
                      {unCategorizedTransactionCount}
                    </span>
                    <span
                      onClick={() => setSelectionMode(true)}
                      className="cursor-pointer"
                      style={{ marginRight: "5px" }}
                    >
                      <BulkIcon />
                    </span>
                    <span
                      onClick={() => openModal("addTransaction")}
                      className="cursor-pointer"
                      style={{ marginRight: "5px" }}
                    >
                      <PLusIcon />
                    </span>
                    <span
                      onClick={() => openModal("addTransactionRules")}
                      className="cursor-pointer"
                      style={{ marginRight: "5px" }}
                    >
                      <RulesIcon />
                    </span>
                  </>
                ) : (
                  <div className="selection-flex">
                    <div
                      className="d-flex align-items-center gap-1 cursor-pointer"
                      onClick={() => setSelectAll(!selectAll)}
                    >
                      <SelectAllIcon />
                      <SelectAll />
                      {/* <span className="select-all">Select all</span> */}
                    </div>
                    <span
                      className="select-done cursor-pointer"
                      onClick={handleDoneWithBulkUpdate}
                    >
                      Done
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="account-detail-left-wrapper">
              {!isEmpty(currentMonthGrouped) ||
              !isEmpty(remainingMonthsGrouped) ? (
                <TransactionListing
                  data={{
                    ...currentMonthGrouped,
                    ...remainingMonthsGrouped,
                  }}
                  modalOpen={openModal}
                  updateTransactionCategory={updateTransactionCategory}
                  addNewCategory={addNewCategory}
                  editCategory={editCategory}
                  expenseCategories={category}
                  incomeCategories={icomecat}
                  setSelectionMode={setSelectionMode}
                  oneOffCategory={oneOffCategory}
                  isSelectionMode={isSelectionMode}
                  setSelectedForBulkUpdate={setSelectedForBulkUpdate}
                  selectedForBulkUpdate={selectedForBulkUpdate}
                  updateCategoriesInBulk={updateCategoriesInBulk}
                  selectAll={selectAll}
                  unPress={unPress}
                  deleteCategoryConfirm={deleteCategory}
                />
              ) : loading ? (
                <></>
              ) : (
                <div
                  className="text-center"
                  style={{ marginTop: "47px", marginBottom: "47px" }}
                >
                  <h4
                    className="fs-18 fw-600 text-dark"
                    style={{ color: "#000000" }}
                  >
                    No transactions yet{" "}
                    <span role="img" label="thinking">
                      😕
                    </span>
                  </h4>
                  <p className="fs-14 fw-400" style={{ color: "#000000" }}>
                    Connect your accounts via Akahu to <br /> see your
                    transactions here.
                  </p>
                  <button
                    className="btn btn-primary cursor-pointer br-10 fs-14 fw-500 border-0 pointer-cursor text-white"
                    style={{
                      backgroundColor: "#6A36FF", // Match purple color
                      color: "white",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      fontSize: "14px",
                      fontWeight: "500",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/connectaccounts/${uuid}`)} // Handle navigation
                  >
                    Connect your accounts
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="account-detail-right sticky-sidebar">
            <div
              className="
                 search-spacing filter-merchants-wrp-side"
            >
              <div className="row">
                <div className="col-md-7 col-lg-12">
                  <SectionTitle title={"Search for transactions"} />

                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input
                          type="text"
                          value={search}
                          className="search-box-input py-2 inter add-goal full-width-transaction"
                          onChange={(e) => handleSearch(e)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              if (search.trim()) {
                                handleSearch(e);
                                e.target.blur();
                              }
                            }
                          }}
                        />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3 search-mb">
                <SectionTitle title={"Filter transactions"} />
              </div>

              <div className="merchants-background-color">
                <FilterTags
                  title={"Expense categories"}
                  categories={category}
                  selectedCategories={selectedExpenseCategories}
                  setSelectedCategories={setSelectedExpenseCategories}
                  handleTagClick={handleTagClick}
                  type={"expense"}
                  oneOffCategory={oneOffCategory}
                />

                <FilterTags
                  title={"Income categories"}
                  categories={icomecat}
                  selectedCategories={selectedIncomeCategories}
                  setSelectedCategories={setSelectedIncomeCategories}
                  handleTagClick={handleTagClick}
                  type={"income"}
                  oneOffCategory={[]}
                />

                <div className="bold live-connection mt-3">Date range</div>
                <div className="account-date-group green-btn">
                  {dateOptions.map((option) => (
                    <button
                      key={option}
                      className={`account-date-btn ${
                        filterByDate === option ? "green" : ""
                      }`}
                      onClick={() =>
                        setFilterByDate(filterByDate === option ? "" : option)
                      }
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modals.addTransaction && (
        <AddTransactionModal
          modalOpen={modals.addTransaction}
          closeModal={closeModal}
          addTransaction={addTransaction}
          accountData={accountData}
        />
      )}
      {modals.addTransactionRules && (
        <AddTransactionRuleModel
          modalOpen={modals.addTransactionRules}
          closeModal={closeModal}
          expenseCategories={category}
          incomeCategories={icomecat}
          oneOffCategory={oneOffCategory}
        />
      )}
      {modals.viewTransaction && (
        <TransactionViewModal
          data={spliModalData}
          modalOpen={modals.viewTransaction}
          closeModal={closeModal}
          addTransaction={addTransaction}
          accountData={accountData}
          splitTransaction={splitTransaction}
          expenseCategories={category}
          incomeCategories={icomecat}
          oneOffCategory={oneOffCategory}
          updateSingleCategory={updateSingleCategory}
          deleteCustomTransaction={deleteCustomTransaction}
          splitbackToMainTransaction={splitbackToMainTransaction}
          splitSingleBackToMainTransaction={splitSingleBackToMainTransaction}
          customPopoverClassName={"custom-popover-trans"}
        />
      )}
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default Transactions;
