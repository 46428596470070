// Function to convert Hex to RGB
function hexToRgb(hex) {
  const bigint = parseInt(hex?.slice(1), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
}

// Function to convert RGB to HSL
function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h, s, l };
}

// Function to convert HSL to RGB
function hslToRgb(h, s, l) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
  };
}

// Function to convert RGB to Hex
function rgbToHex(r, g, b) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}

// Function to generate lighter or darker colors
export function adjustLightness(hex, percentage) {
  const { r, g, b } = hexToRgb(hex);
  const { h, s, l } = rgbToHsl(r, g, b);

  // Adjust lightness
  const newL = Math.min(Math.max(l + percentage / 100, 0), 1);
  const { r: newR, g: newG, b: newB } = hslToRgb(h, s, newL);

  return rgbToHex(newR, newG, newB);
}

export const pickColorItemList = {
  Red: "#E0533D",
  "Light green": "#3DE0B9",
  Blue: "#3D6BE0",
  Green: "#3DE06B",
  "Light blue": "#3DAFE0",
  Orange: "#E09F3D",
  Yellow: "#DDE03D",
  Purple: "#983DE0",
  Pink: "#DD3DE0",
};

export const progressColorItemList = {
  Red: "#DD3D25",
  "Light green": "#25DCB0",
  Blue: "#2458DC",
  Green: "#24DC58",
  "Light blue": "#24A5DC",
  Orange: "#DC9324",
  Yellow: "#D9DC24",
  Purple: "#8B24DC",
  Pink: "#D924DC",
};
