import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import "../Moneyinsights/Moneyinsights.css";
import ChartDataLabels from "chartjs-plugin-datalabels";
import NoDataFound from "../NoDataFound";
import axios from "axios";
import weburls from "../../Weburls/weburls";
import { toast } from "react-toastify";
import moment from "moment";
import { ExpensesCategoryBreakDownList } from "../Moneyinsights/AccordionMoneyInsights";
import {
  formatNumberWithCommas,
  formatValue,
  pieChartColor,
} from "../../utilities/helper";
import PopupModalDialog from "../Moneyinsights/PopupModalDialog";
import { getDisplayOption } from "../../utilities/Statics";
import { API_ENDPOINTS } from "../../client/ApiEndpoints";
import HttpClient from "../../client/HttpClient";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const ExpenseBreakDown = (props) => {
  const { activeTab, heading } = props;
  const token = localStorage.getItem("budget-token");
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);

  const [isPopupOpenTopExpenses, setPopupOpenTopExpenses] = useState(false);
  const [isPieExpensesCategoryData, setPieExpensesCategoryData] = useState();
  const [isExpensesBreakDown, setExpensesBreakDown] = useState("Last month");
  const [isExpenseCategoryData, setExpensesCategoryData] = useState([]);
  const [isPieIncomeCategoryData, setPieIncomeCategoryData] = useState();
  const [isIncomeCategoryData, setIncomeCategoryData] = useState([]);
  const [isIncomeBreakDown, setIncomeBreakDown] = useState("Last month");
  const [isTotalIncomeBreakDownPie, setTotalIncomeBreakDownPie] = useState(0);
  const [isNameIncomeBreakDownPie, setNameIncomeBreakDownPie] =
    useState("Total Income");
  const [isTotalExpensesBreakDownPie, setTotalExpensesBreakDownPie] =
    useState(0);
  const [isNameExpensesBreakDownPie, setNameExpensesBreakDownPie] =
    useState("Total Expenses");
  const navigate = useNavigate();
  const userId = localStorage.getItem("userUuid");

  let isIncomeBreakDownApi = "Last month";
  let isExpensesBreakDownApi = "Last month";
  let isExpensesStartDate = "";
  let isExpensesEndDate = "";
  let isIncomeStartDate = "";
  let isIncomeEndDate = "";

  useEffect(() => {
    if (uuid !== userId) {
      navigate("*");
    }
  }, [uuid, userId, navigate]);

  const getMoneyTrendsCategoryBreakdownDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${weburls.Get_Money_Trends_CategoryBreakdown_Details}${uuid}`,
        {
          filter: isExpensesBreakDownApi.toLowerCase().replace(/\s+/g, ""),
          startDate: moment(isExpensesStartDate).format("YYYY-MM-DD"),
          endDate: moment(isExpensesEndDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const expenseCategoryData = response?.data?.data.sort(
        (a, b) => (b.total ?? 0) - (a.total ?? 0)
      );

      if (expenseCategoryData) {
        const labels = [];
        const data = [];
        const expenseData = [];
        const backgroundColors = [];
        let totalPie = 0;

        expenseCategoryData.forEach((element) => {
          totalPie += element.total || 0;
        });
        setTotalExpensesBreakDownPie(totalPie);
        expenseCategoryData.forEach((element) => {
          labels.push(element.master_category_name || "Unknown");
          expenseData.push(element);
          data.push(Math.round(((element.total || 0) / totalPie) * 100));
          backgroundColors.push(
            pieChartColor({
              colorCode: element.master_category_colour || "",
              name: element.master_category_name || "Unknown",
            })
          );
        });
        setExpensesCategoryData(expenseData);

        const chartData = {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColors,
              hoverBackgroundColor: backgroundColors,
              borderRadius: 5,
              cutout: "50%",
              borderWidth: 3,
            },
          ],
        };

        setPieExpensesCategoryData(chartData);
      }
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  const getMoneyTrendsIncomeBreakdownDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${weburls.Get_Money_Trends_Income_Breakdown_Details}${uuid}`,
        {
          filter: isIncomeBreakDownApi.toLowerCase().replace(/\s+/g, ""),
          startDate: moment(isIncomeStartDate).format("YYYY-MM-DD"),
          endDate: moment(isIncomeEndDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const categoryData = response?.data?.data.sort(
        (a, b) => (b.total ?? 0) - (a.total ?? 0)
      );
      if (categoryData) {
        const labels = [];
        const data = [];
        const incomeData = [];
        const backgroundColors = [];
        let totalPie = 0;

        categoryData.forEach((element) => {
          totalPie += element.total || 0;
        });
        setTotalIncomeBreakDownPie(totalPie);
        categoryData.forEach((element) => {
          labels.push(element.master_category_name || "Unknown");
          incomeData.push(element);
          data.push(Math.round(((element.total || 0) / totalPie) * 100));
          backgroundColors.push(
            pieChartColor({
              colorCode: element.master_category_colour || "",
              name: element.master_category_name || "Unknown",
            })
          );
        });
        setIncomeCategoryData(incomeData);
        const chartData = {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColors,
              hoverBackgroundColor: backgroundColors,
              borderWidth: 0,
            },
          ],
        };
        setPieIncomeCategoryData(chartData);
      }
      setLoading(false);
    } catch (err) {
      toast.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataSequentially();
  }, []);

  const fetchDataSequentially = async () => {
    try {
      if (activeTab === "Expenses") {
        await getMoneyTrendsCategoryBreakdownDetails();
      } else {
        await getMoneyTrendsIncomeBreakdownDetails();
      }
    } catch (err) {
      console.error("Error in sequential API calls:", err);
    }
  };

  const togglePopupTopExpenses = () =>
    setPopupOpenTopExpenses(!isPopupOpenTopExpenses);

  Tooltip.positioners.myCustomPositioner = function (elements, eventPosition) {
    return {
      x: eventPosition.x,
      y: eventPosition.y,
      xAlign: "right",
      yAlign: "center",
    };
  };

  const defaultData = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
        borderWidth: 0,
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    cutout: "55%",
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 14,
          },
          color: "#838383",
        },
        onClick: (e, legendItem, legend) => {
          const index = legendItem.index;
          if (activeTab === "Expenses") {
            setNameExpensesBreakDownPie(
              isExpenseCategoryData[index].master_category_name
            );
            setTotalExpensesBreakDownPie(isExpenseCategoryData[index].total);
            var totalPie = 0;
            isIncomeCategoryData.forEach((element) => {
              totalPie += element.total || 0;
            });
            setTotalIncomeBreakDownPie(totalPie);
            setNameIncomeBreakDownPie("Total Income");
          } else {
            setNameIncomeBreakDownPie(
              isIncomeCategoryData[index].master_category_name
            );
            setTotalIncomeBreakDownPie(isIncomeCategoryData[index].total);

            var totalPie = 0;
            isExpenseCategoryData.forEach((element) => {
              totalPie += element.total || 0;
            });
            setTotalExpensesBreakDownPie(totalPie);
            setNameExpensesBreakDownPie("Total Expenses");
          }
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#ffffff",
        bodyColor: "#000000",
        footerColor: "#707070",
        titleColor: "#707070",
        displayColors: false,
        bodyFont: {
          size: 15,
          weight: "700",
          family: "Neurialgrotesk",
        },
        labelFont: {
          size: 12,
          weight: "500",
          family: "Neurialgrotesk",
        },
        titleFont: {
          size: 12,
          weight: "bold",
          family: "Neurialgrotesk",
        },
        footerFont: {
          size: 12,
          weight: "500",
          family: "Neurialgrotesk",
        },

        callbacks: {
          title: function (tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            let emoji = "";
            if (activeTab === "Expenses") {
              emoji = isExpenseCategoryData[index].master_category_emoji || "";
            } else {
              emoji = isIncomeCategoryData[index].master_category_emoji || "";
            }
            const label = tooltipItems[0].label;
            return `${label} ${emoji}`;
          },

          label: function (tooltipItems) {
            const index = tooltipItems.dataIndex;

            let total = "";
            if (activeTab === "Expenses") {
              total = isExpenseCategoryData[index].total.toFixed(2) || "";
            } else {
              total = isIncomeCategoryData[index].total.toFixed(2) || "";
            }
            return `$${formatNumberWithCommas(total)}`; /// GOPAL
          },

          footer: function (tooltipItems) {
            const dataset = tooltipItems[0].dataset;
            const currentValue = dataset.data[tooltipItems[0].dataIndex];
            const total = dataset.data.reduce((acc, value) => acc + value, 0);
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${percentage}% of total`;
          },
        },
      },
      datalabels: {
        color: "#fff",
        formatter: (value) => `${value}%`,
        font: {
          size: 14,
          weight: "bold",
        },
        align: "center",
        anchor: "center",
      },
    },
  };

  const handleOptionTopExpensesClick = (option, name, startDate, endDate) => {
    if (activeTab === "Expenses") {
      if (option === "Custom date range") {
        isExpensesStartDate = startDate;
        isExpensesEndDate = endDate;
        isExpensesBreakDownApi = "custom";
        setExpensesBreakDown(name);
      } else {
        setExpensesBreakDown(option);
        isExpensesBreakDownApi = option;
      }
    } else {
      if (option === "Custom date range") {
        isIncomeStartDate = startDate;
        isIncomeEndDate = endDate;
        isIncomeBreakDownApi = "custom";
        setIncomeBreakDown(name);
      } else {
        setIncomeBreakDown(option);
        isIncomeBreakDownApi = option;
      }
    }
    setPopupOpenTopExpenses(false);
    activeTab === "Expenses"
      ? getMoneyTrendsCategoryBreakdownDetails()
      : getMoneyTrendsIncomeBreakdownDetails();
  };

  return (
    <>
      <div
        className="expense-summary-section mb-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <h2
            className="fs-20 fw-500"
            style={{
              margin: 0,
              marginBottom: "4px",
            }}
          >
            {heading}
          </h2>
        </div>
        <div className="col-md-6 right-section">
          <div className="">
            <button
              className="group-button green"
              onClick={togglePopupTopExpenses}
              aria-haspopup="true"
              aria-expanded={isPopupOpenTopExpenses}
              id="Open_Popup_Modal"
            >
              {getDisplayOption(
                activeTab === "Expenses"
                  ? isExpensesBreakDown
                  : isIncomeBreakDown
              )}
            </button>
            {isPopupOpenTopExpenses && (
              <PopupModalDialog
                targetId="Open_Popup_Modal"
                isOpen={isPopupOpenTopExpenses}
                onClose={togglePopupTopExpenses}
                title="Date Range"
                options={[
                  "This week",
                  "This month",
                  "This quarter",
                  "This year",
                  "Last week",
                  "Last month",
                  "Last quarter",
                  "Custom date range",
                ]}
                selectedOption={getDisplayOption(
                  activeTab === "Expenses"
                    ? isExpensesBreakDown
                    : isIncomeBreakDown
                )}
                handleOptionClick={handleOptionTopExpensesClick}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="category-breakdown-row"
        style={{
          height: "600px",
        }}
      >
        <div
          className="doughnut-card"
          onClick={() => {
            if (activeTab !== "Expenses") {
              var totalPie = 0;
              isIncomeCategoryData.forEach((element) => {
                totalPie += element.total || 0;
              });
              setTotalIncomeBreakDownPie(totalPie);
              setNameIncomeBreakDownPie("Total Income");
            } else {
              var totalPie = 0;
              isExpenseCategoryData.forEach((element) => {
                totalPie += element.total || 0;
              });
              setTotalExpensesBreakDownPie(totalPie);
              setNameExpensesBreakDownPie("Total Expenses");
            }
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <div
            style={{
              fontSize: "22px",
              fontWeight: 500,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: "40px",
            }}
          >
            <span>
              {activeTab === "Expenses"
                ? isNameExpensesBreakDownPie
                : isNameIncomeBreakDownPie}
              :
            </span>
            <span
              style={{
                color: activeTab === "Expenses" ? "#FF0A0A" : "#469B88",
              }}
            >
              $
              {activeTab === "Expenses"
                ? formatValue(isTotalExpensesBreakDownPie)
                : formatValue(isTotalIncomeBreakDownPie)}
            </span>
          </div>
          <Doughnut
            data={
              (activeTab === "Expenses"
                ? isPieExpensesCategoryData
                : isPieIncomeCategoryData) || defaultData
            }
            options={doughnutOptions}
            style={{ maxHeight: "444px" }}
          />
        </div>
        <div className="category-card">
          <div
            className="acccount-balances-wrappper-left-side"
            style={{
              height: "100%",
              overflowY: "auto",
              width: "100%",
              alignContent: "start",
            }}
          >
            {activeTab === "Expenses" ? (
              isExpenseCategoryData.length > 0 ? (
                isExpenseCategoryData.map((item) => (
                  <ExpensesCategoryBreakDownList
                    item={item}
                    isExpensesBreakDown={isExpensesBreakDown}
                    activeTab={activeTab}
                  />
                ))
              ) : (
                <NoDataFound />
              )
            ) : isIncomeCategoryData.length > 0 ? (
              isIncomeCategoryData.map((item) => (
                <ExpensesCategoryBreakDownList
                  item={item}
                  isExpensesBreakDown={isIncomeBreakDown}
                  activeTab={activeTab}
                />
              ))
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseBreakDown;
