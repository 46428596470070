import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./IncomeBudgetCompactView.css";
import { adjustLightness } from "../../../../utilities/color-generator";
import EditIcon from "../../../../assests/images/icons/pencil-icon.svg";
import QuickIcon from "../../../../assests/images/icons/quick-icon.svg";
import PlusIcon from "../../../../assests/images/icons/plus-add-icon.svg";
import SelectCreateCategory from "../../../SelectCreateCategory/SelectCreateCategory";
import AddIncomeExpenseModal from "./AddIncomeExpenseModal";
import CrossIcon from "../../../../assests/images/icons/cross-gray.svg";
import useExpenseCompactView from "../../../../hooks/Budget/useExpenseCompactView/useExpenseCompactView";
import {
  getCustomCodeToName,
  isCurrentPastMonth,
  lightBackgroundColor,
  progressbarColor,
} from "../../../../utilities/Statics";
import IncomeExpenseBudgetModal from "../IncomeExpenseBudget/IncomeExpenseBudgetModal";
import { ReactComponent as OverBudget } from "../../../../assests/images/over_budget.svg";

const ExpenseBudgetCompactView = ({
  expenseData,
  addExpense,
  autoSingleBudgetExpenses,
  updateExpenseBudget,
  year,
  month,
  isCompactView,
  applyToAllFutureMonths,
  loadingSingleBudgets,
  fetchExpenseAllData,
  completeBudgetData,
}) => {
  const {
    uuid,
    navigate,
    isEdit,
    openCompactAddModal,
    setOpenCompactAddModal,
    addQuickBudget,
    quickAddBudget,
    deleteQuickAddBudget,
    updateQuickSelectedBudget,
    handleAmountChange,
    totalExpenseMonthly,
    totalExpenseBudget,
    totalExpenseBudgetRemainingMonthly,
    expenseTotalPercentage,
    formatAmount,
    selectedCategory,
    quickValue,
    saveQuickBudget,
    handleBudgetAmountChange,
    handleBudgetAmountSave,
    tempBudgetAmount,
    handleBudgetAmountBlur,
    quickLoader,
  } = useExpenseCompactView(
    expenseData,
    addExpense,
    autoSingleBudgetExpenses,
    updateExpenseBudget
  );
  const [isEditing, setIsEditing] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState("😁");
  const [selectedColorOption, setSelectedColorOption] = useState("Red");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCardClick = (budgetId, fetchBudget) => {
    navigate(`/budget/${uuid}/budgetdetails/expense/${budgetId}`, {
      state: {
        year,
        month,
        isCompactView,
        applyToAllFutureMonths,
        fetchBudget,
        completeBudgetData,
      },
    });
  };

  return (
    <div className="col-md-12">
      <div className="row mb-2">
        <div className="col-md-7">
          <h4 className="fs-20 fw-500 lh-34 mb-0" style={{ color: "#0E132F" }}>
            Expense budgets
          </h4>
        </div>
        <div className="col-md-5 pe-5">
          <div className="row">
            <div className="col-md-4 text-center">
              <span className="fs-15 fw-500 lh-34 text-medium-Stone-gray">
                Budget
              </span>
            </div>
            <div className="col-md-4 text-center">
              <span className="fs-15 fw-500 lh-34 text-medium-Stone-gray">
                Actual
              </span>
            </div>
            <div className="col-md-4 text-center">
              <span className="fs-15 fw-500 lh-34 text-medium-Stone-gray">
                Remaining
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="budget-item-card ">
        {expenseData?.map((budget, index) => {
          const adjustedAmount =
            budget.budget_amount - budget.total_transaction_amount;
          const categoryColor = getCustomCodeToName(budget.colour);
          const backgroundColor = lightBackgroundColor(categoryColor);
          let progress;
          const progressBarColor = progressbarColor(categoryColor);
          const actualAmount = budget.total_transaction_amount;
          progress =
            (budget.budget_amount ?? 0) === 0
              ? (budget.total_transaction_amount ?? 0) === 0.0
                ? 0.0
                : 100
              : ((budget.total_transaction_amount ?? 0) /
                  (budget.budget_amount ?? 0)) *
                100;
          progress = progress > 100 ? 100 : progress;
          return (
            <div
              className="position-relative"
              onClick={(e) => {
                if (!isEditing) {
                  handleCardClick(budget.expense_uuid, budget);
                }
              }}
            >
              <div className="budget-item" style={{ cursor: "pointer" }}>
                <div className="row align-items-center mb-2">
                  <div className="col-md-7">
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="budget-icon"
                        style={{
                          width: "38px",
                          height: "38px",
                          backgroundColor: backgroundColor,
                        }}
                      >
                        <span style={{ fontSize: "15px" }}>{budget.emoji}</span>
                      </div>
                      <div>
                        <span className="fs-18 fw-500 text-dark-steel-gray">
                          {budget.category_name}
                          {adjustedAmount < 0 ? (
                            <>
                              <OverBudget
                                style={{
                                  marginLeft: "1px",
                                  marginTop: "0px",
                                  height:"15px",
                                  width:"15px"
                                }}
                              />
                            </>
                          ) : (
                            ``
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="row align-items-center">
                      <div className="col-md-4 text-center">
                        {loadingSingleBudgets?.[budget.expense_uuid] ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // borderRadius: "5px",
                              // border: "1px solid #F0F0F0",
                              padding: "5px 10px",
                              textAlign: "center",
                            }}
                          >
                            <div
                              className="spinner-border text-light-gray mt-1"
                              role="status"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <input
                            readOnly={
                              !isCurrentPastMonth(budget?.month, budget?.year)
                            }
                            value={
                              tempBudgetAmount[budget.expense_uuid] ??
                              `${formatAmount(Math.abs(budget.budget_amount))}`
                            }
                            className="fs-16"
                            type="text"
                            size={8}
                            onBlur={() => {
                              setIsEditing(false);
                              handleBudgetAmountBlur(budget);
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsEditing(true);
                            }}
                            onChange={(e) =>
                              handleBudgetAmountChange(e, budget.expense_uuid)
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.target.blur();
                                handleBudgetAmountSave(budget);
                              }
                            }}
                            style={{
                              height: "31px",
                              textAlign: "center",
                              border: !isCurrentPastMonth(
                                budget?.month,
                                budget?.year
                              )
                                ? "none"
                                : "1px solid #D9D9D9",
                              padding: "5px 10px",
                              outline: "none",
                              borderRadius: "5px",
                              fontWeight: 500,
                            }}
                          />
                        )}
                      </div>
                      <div className="col-md-4 text-center">
                        <span className="fs-16 fw-500">
                          {formatAmount(Math.abs(actualAmount))}
                        </span>
                      </div>
                      <div className="col-md-4 text-center ">
                        <span
                          class="badge rounded-pill py-2 px-2 fs-16 fw-500"
                          style={{
                            backgroundColor:
                              adjustedAmount >= 0 ? "#E3F7E1" : "#F4E2E2",
                            color: adjustedAmount >= 0 ? "#437E43" : "#BE3C39",
                            padding: "5px",
                          }}
                        >
                          {formatAmount(adjustedAmount)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="position-absolute"
                style={{
                  backgroundColor: backgroundColor,
                  bottom: "0px",
                  width: "100% ",
                  height: "8px",
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                <motion.div
                  style={{
                    width: `${progress}%`,
                    backgroundColor: progressBarColor,
                    height: "8px",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                    borderTopRightRadius: "7px",
                  }}
                  initial={{ width: 0 }}
                  animate={{ width: `${progress}%` }}
                  aria-valuenow={progress.toFixed(2)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></motion.div>
              </div>
            </div>
          );
        })}

        {quickAddBudget && (
          <div className="position-relative">
            <div className="budget-item" style={{ cursor: "pointer" }}>
              <div className="row align-items-center mb-2">
                <div className="col-md-7">
                  {selectedCategory?.categoryName ? (
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="budget-icon"
                        style={{
                          width: "38px",
                          height: "38px",
                          backgroundColor: selectedCategory?.categoryColour,
                        }}
                      >
                        {selectedCategory?.categoryEmoji}
                      </div>
                      <div>
                        <span className="fs-18 fw-500 text-dark-steel-gray">
                          {selectedCategory?.categoryName}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <SelectCreateCategory
                        title="Select or create a category"
                        createCategory={true}
                        type="expense"
                        handleCategoryClick={(value, type) => {
                          updateQuickSelectedBudget(value, type);
                        }}
                        targetId="popUpoverTargetQuickAdd"
                        popoverHeading="Expense"
                        style={{
                          color: "#A7A7A7",
                          borderRadius: "36px",
                          border: "1px solid #A7A7A7",
                          padding: "4px 8px",
                        }}
                        setChosenEmoji={setChosenEmoji}
                        setSelectedColorOption={setSelectedColorOption}
                      />
                    </div>
                  )}
                </div>

                <div className="col-md-5">
                  <div className="row align-items-center">
                    <div className="col-md-4 text-center">
                      {quickLoader ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // borderRadius: "5px",
                            // border: "1px solid #F0F0F0",
                            padding: "5px 10px",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="spinner-border text-light-gray mt-1"
                            role="status"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <input
                          value={quickValue}
                          className="fs-16"
                          onChange={(e) => handleAmountChange(e)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.target.blur();
                              saveQuickBudget();
                            }
                          }}
                          type="text"
                          size={8}
                          style={{
                            height: "31px",
                            textAlign: "center",
                            border: "1px solid #D9D9D9",
                            padding: "5px 10px",
                            outline: "none",
                            borderRadius: "5px",
                            fontWeight: 500,
                          }}
                        />
                      )}
                    </div>
                    <div className="col-md-4 text-center">
                      <span className="fs-16 fw-500">$0</span>
                    </div>
                    <div className="col-md-3 text-center">
                      <span
                        className="badge rounded-pill py-2 px-2 fs-16 fw-500 float-end"
                        style={{
                          backgroundColor: "#E3F7E1",
                          color: "#437E43",
                          padding: "5px",
                        }}
                      >
                        $0
                      </span>
                    </div>
                    <div className="col-md-1 text-center">
                      <img
                        onClick={() => deleteQuickAddBudget()}
                        className="float-end"
                        style={{ width: "28px", height: "28px" }}
                        src={CrossIcon}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="position-absolute"
              style={{
                backgroundColor: adjustLightness(
                  selectedCategory?.categoryColour,
                  6
                ),
                bottom: "0px",
                width: "100% ",
                height: "8px",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <motion.div
                style={{
                  width: `${100}%`,
                  backgroundColor:
                    selectedCategory?.categoryColour ?? "#BFBFBF",
                  height: "8px",
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                  borderTopRightRadius: "7px",
                }}
                initial={{ width: 100 }}
                animate={{ width: `${100}%` }}
                aria-valuemin="0"
                aria-valuemax="100"
              ></motion.div>
            </div>
          </div>
        )}
      </div>

      <div className="budget-item-card mt-4">
        <div className="position-relative">
          <div className="budget-item" style={{ cursor: "pointer" }}>
            <div className="row align-items-center mb-2">
              <div className="col-md-7">
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <span className="fs-18 fw-500 text-dark-steel-gray">
                      Total expenses
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row align-items-center">
                  <div className="col-md-4 text-center">
                    <input
                      readOnly
                      value={formatAmount(totalExpenseBudget)}
                      className="fs-16"
                      type="text"
                      size={8}
                      style={{
                        height: "31px",
                        textAlign: "center",
                        border: "none",
                        // border: "1px solid #F0F0F0",
                        outline: "none",
                        borderRadius: "10px",
                        fontWeight: 500,
                      }}
                    />
                  </div>
                  <div className="col-md-4 text-center">
                    <span className="fs-16 fw-500">
                      {formatAmount(totalExpenseMonthly)}
                    </span>
                  </div>
                  <div className="col-md-4 text-center ">
                    <span
                      class="badge rounded-pill py-2 px-2 fs-16 fw-500"
                      style={{
                        backgroundColor:
                          totalExpenseBudgetRemainingMonthly >= 0
                            ? "#E3F7E1"
                            : "#F4E2E2",
                        color:
                          totalExpenseBudgetRemainingMonthly >= 0
                            ? "#437E43"
                            : "#BE3C39",
                        padding: "5px",
                      }}
                    >
                      {formatAmount(totalExpenseBudgetRemainingMonthly)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="position-absolute"
            style={{
              backgroundColor: "rgba(255, 214, 214, 1)",
              bottom: "0px",
              width: "100% ",
              height: "8px",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            <motion.div
              style={{
                width: `${expenseTotalPercentage}%`,
                backgroundColor: "rgba(254, 48, 48, 1)",
                height: "8px",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
                borderTopRightRadius: "7px",
              }}
              initial={{ width: expenseTotalPercentage }}
              animate={{ width: `${expenseTotalPercentage}%` }}
              //   aria-valuenow={"1023".toFixed(2)}
              aria-valuemin="0"
              aria-valuemax="100"
            ></motion.div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end gap-2 mt-2 pe-3">
        <div
          className="pointer-cursor"
          onClick={() => navigate(`/editbudgetmonthly/${uuid}`)}
        >
          <span className="fs-14 fw-500 me-1" style={{ color: "#636363" }}>
            Edit
          </span>
          <img
            style={{ width: "12px", height: "12px" }}
            src={EditIcon}
            alt="Edit Icon"
          />
        </div>
        <div
          className="pointer-cursor"
          onClick={() => {
            addQuickBudget();
          }}
        >
          <span className="fs-14 fw-500 me-1" style={{ color: "#636363" }}>
            Quick add
          </span>
          <img
            style={{ width: "14px", height: "14px" }}
            src={QuickIcon}
            alt="Edit Icon"
          />
        </div>
        <div className="pointer-cursor" onClick={toggleModal}>
          <span className="fs-14 fw-500 me-1" style={{ color: "#636363" }}>
            Add new
          </span>
          <img
            style={{ width: "14px", height: "14px" }}
            src={PlusIcon}
            alt="Edit Icon"
          />
        </div>
      </div>

      {
        <IncomeExpenseBudgetModal
          isModalOpen={isModalOpen}
          isOpen={isModalOpen}
          toggleModal={toggleModal}
          mainHead={"Expense"}
          showFields={true}
          isIncome={false}
          fetchBudgetData={fetchExpenseAllData}
        />
      }
    </div>
  );
};

export default ExpenseBudgetCompactView;
