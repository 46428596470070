import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ReactComponent as Editnw } from "../../assests/images/icons/edit.svg";
import { ReactComponent as Edit } from "../../assests/images/icons/main-plus.svg";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle";
import { amountMinusWithSign, formatValue } from "../../utilities/helper.jsx";
import {
  PopUpWhoops,
  PopUpWhoopsGoals,
} from "../../Components/BudgetDetails/PopUpForUpdateDelete.jsx";
import {
  adjustLightness,
  pickColorItemList,
  progressColorItemList,
} from "../../utilities/color-generator.jsx";
import useGoals from "../../hooks/Goals/useGoals/useGoals.jsx";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer.jsx";
import { Loading } from "../../Components/Loading/Loading.jsx";
import AddIcon from "../../assests/images/plus-add-icon.svg";
import PageTitle from "../../Components/PageTitle/index.jsx";

export const colorCss = (color, percent = 0) => {
  switch (color) {
    case "Red":
      return "#E0543D";
    case "Light green":
      return "#E0543D";
    case "Yellow":
      return "#DEE03D";
    case "Blue":
      return "#3D6CE0";
    case "Purple":
      return "#983DE0";
    case "Orange":
      return "#DF9F3D";
    case "Pink":
      return "#DD3CE0";
    case "Light blue":
      return "#3DAFE1";
    case "Green":
      return "#3DE06B";
    case "Dark blue":
      return "dark-blue-color";
    default:
      return "green";
  }
};

export const progressbarColor = (color) => {
  switch (color) {
    case "Dark green":
      return "progress-dark-green-color";
    case "Red":
      return "progress-red-color";
    case "Yellow":
      return "progress-yellow-color";
    case "Blue":
      return "progress-blue-color";
    case "Purple":
      return "progress-purple-color-color";
    case "Orange":
      return "progress-orange-color";
    case "Pink":
      return "progress-pink-color";
    case "Light blue":
      return "progress-light-blue-color";
    case "Lime green":
      return "progress-lime-green-color";
    case "Dark red":
      return "progress-dark-red-color";
    case "Green":
      return "progress-green-color";
    case "Dark blue":
      return "progress-dark-blue-color";
    default:
      return "progress-green";
  }
};

const Financial = () => {
  const {
    handleNavigate,
    isPopupOpenInfo,
    togglePopupInfo,
    goals,
    uuid,
    handleEditGoal,
    whoopsPopUp,
    planDetails,
    setWhoopsPopUp,
    isLoading,
    oneTimeOTP,
  } = useGoals();

  return (
    <>
      <div className="app-main__outer financial-goals-main">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />

        <div className="row">
          <PageTitle
            title={"Your Goals"}
            description={`Let's set some goals, it’s always good having goals and
                something to aim for.`}
          />

          <div
            className="col-lg-6 d-flex justify-content-end"
            style={{ gap: "75px", alignItems: "center" }}
          >
            <div>
              <div className="edit-btn-col">
                <div
                  className="edit-btn"
                  onClick={handleNavigate}
                  style={{
                    width: "251px",
                    height: "53px",
                    borderRadius: "50px",
                    marginRight: "-3rem",
                    boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                    padding: "11px 22px",
                    cursor: "pointer",
                  }}
                >
                  <div className="ms-2">
                    <h4 className="bold">Add a new goal</h4>
                    <p style={{ color: "#04BAFF", textAlign: "left" }}>
                      Keep on track
                    </p>
                  </div>
                  <div className="right">
                    <img
                      src={AddIcon}
                      alt="Add Icon"
                      style={{ width: "29px", height: "29px" }}
                      className="bg-white rounded-circle p-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <UserImageInfoToggle customStyle={true} />
          </div>
        </div>

        {/* <div className="header-budget-main-div">
          <div className="header-budget-text-div" style={{ marginTop: "0px" }}>
            <div className="col-lg-10">
              <div className="bold big-head-new">Your Goals</div>
              <div className="fst-normal setting_text_color">
                Let's set some goals, it’s always good having goals and
                something to aim for.
              </div>
            </div>
          </div>

          <div
            className="header-budget-sub-div me-1"
            style={{ gap: "75px", alignItems: "center" }}
          >
            <div
              style={{
                display: "inline-flex",
                justifyContent: "flex-start",
              }}
            >
              <div className="edit-btn-col">
                <div
                  className="edit-btn"
                  onClick={handleNavigate}
                  style={{
                    width: "251px",
                    height: "53px",
                    borderRadius: "50px",
                    marginRight: "-3rem",
                    boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.10)",
                    padding: "11px 22px",
                    cursor: "pointer",
                  }}
                >
                  <div className="ms-2">
                    <h4 className="bold">Add a new goal</h4>
                    <p style={{ color: "#04BAFF", textAlign: "left" }}>
                      Keep on track
                    </p>
                  </div>
                  <div className="right">
                    <img
                      src={AddIcon}
                      alt="Add Icon"
                      style={{ width: "29px", height: "29px" }}
                      className="bg-white rounded-circle p-1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <UserImageInfoToggle customStyle={true} />
          </div>
        </div> */}

        <div className="new-finance">
          <div className="mt-5">
            <div className="row">
              {goals && goals.length > 0 ? (
                goals?.map((it) => (
                  <div className="col-sm-12 col-md-6 mt-4" key={it.goalUuid}>
                    <Link
                      to={`/goals/${uuid}/GoalDetails/${it.goalUuid}`}
                      style={{
                        color: "white",
                      }}
                    >
                      <div
                        className={`financial-holiday financial cursor-pointer text-white`}
                        style={{
                          backgroundColor:
                            pickColorItemList?.[it?.colour] ?? it?.colour,
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <div
                                className={` d-flex justify-content-center align-items-center rounded-circle w-42 h-42`}
                                style={{
                                  backgroundColor:
                                    pickColorItemList.hasOwnProperty(it?.colour)
                                      ? adjustLightness(
                                          pickColorItemList[it?.colour],
                                          10
                                        )
                                      : adjustLightness(it?.colour, 14),
                                  borderRadius: "50px",
                                }}
                              >
                                {it.icon}
                              </div>
                              <div className="ms-2 fw-600 fs-18 lh-24 text-white">
                                {it.goalName}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <Editnw onClick={() => handleEditGoal(it)} />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                          <div className="fs-12 fw-400 lh-18 text-white">
                            Balance
                          </div>
                          <div className="financial-text">
                            {parseInt(it?.goalComplete)}%
                          </div>
                        </div>
                        <div
                          className="progress red-progress"
                          style={{
                            height: "7px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                          }}
                        >
                          <div
                            role="progressbar"
                            aria-valuenow={it?.goalComplete}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              height: "7px",
                              borderRadius: "20px",
                              width: it?.goalComplete,
                              opacity: "2 !important",
                              backgroundColor:
                                progressColorItemList.hasOwnProperty(it?.colour)
                                  ? progressColorItemList[it?.colour]
                                  : adjustLightness(it?.colour, -16),
                            }}
                          ></div>
                        </div>
                        <div className="amount-top d-flex justify-content-between align-items-start">
                          <div>
                            <span className="fs-16 fw-600 lh-23 fw-normal text-white">
                              {amountMinusWithSign(it?.currentBalance)}
                            </span>
                            <span className="fs-12 fw-400 lh-18 text-white">
                              &nbsp; of &nbsp;$
                              {formatValue(it.value)}
                            </span>

                            <div className="mt-3 lh-18 fs-12 fw-600 text-white">
                              See detail
                            </div>
                          </div>
                          <div className="fs-12 fw-400 lh-18 text-white">
                            {parseInt(it?.goalComplete) > 100
                              ? "Goal Achieved 🎉"
                              : it?.endDate
                              ? moment(it?.endDate).format("Do MMMM YYYY")
                              : ""}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="col-lg-6 col-md-12 mt-4">
                  <div className="text-danger">
                    No goal, please add your goal
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {whoopsPopUp && (
        <PopUpWhoops
          planDetails={planDetails}
          oneTimeOTP={oneTimeOTP}
          uuid={uuid}
          onClose={() => {
            setWhoopsPopUp(false);
          }}
        />
      )}

      {isLoading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default Financial;
