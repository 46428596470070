import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  userPreferredViewPeriodSetting,
  userPreferredViewSetting,
  userAllMonthDataSetting,
} from "../../../store/slices/SettingPopUpSlice/SettingPopUpSlice";

const useBudgetSetting = () => {
  const [budgetView, setBudgetView] = useState("compact");
  const [preferredPeriodView, setPreferredPeriodView] = useState("");
  const [applyToAllFutureMonths, setApplyToAllFutureMonths] = useState("allMonth");

  const dispatch = useDispatch();

  const budgetViewHandle = (viewType) => {
    setBudgetView(viewType);
    dispatch(userPreferredViewSetting(viewType));
  };

  const preferredPeriodHandle = (preferredPeriod) => {
    setPreferredPeriodView(preferredPeriod);
    dispatch(userPreferredViewPeriodSetting(preferredPeriod));
  };

  const allMonthHandle = (month) => {
    setApplyToAllFutureMonths(month);
    dispatch(userAllMonthDataSetting(month));
  };

  useEffect(() => {
    const budgetViewType = localStorage.getItem("budget_view") || "compact";
    const preferredPeriodType = localStorage.getItem("preferred_period");
    const isAllFutureMonths = localStorage.getItem("isAllFutureMonth") || "allMonth";
    setBudgetView(budgetViewType || "compact");
    setPreferredPeriodView(preferredPeriodType || "monthly");
    setApplyToAllFutureMonths(isAllFutureMonths || 'allMonth');
  }, []);

  return {
    budgetView,
    budgetViewHandle,
    preferredPeriodHandle,
    preferredPeriodView,
    applyToAllFutureMonths,
    allMonthHandle,
  };
};

export default useBudgetSetting;
