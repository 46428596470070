import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as CrossIcon } from "../../assests/images/icons/cross-icon.svg";
import {
  categoryColorForAll,
  categoryBackgroundCss,
} from "../../utilities/Statics/index.jsx";
import { motion } from "framer-motion";
import AddCategoriesModal from "../TransactionModals/AddCategoriesModal.jsx";
import { toast } from "react-toastify";
import HttpClient from "../../client/HttpClient.js";
import { API_ENDPOINTS } from "../../client/ApiEndpoints.js";
import { useParams } from "react-router-dom";

const restrictedCategories = ["Uncategorised", "Transfer", "Ignore", "One off"];

const SettingCategoryPopover = ({
  togglePopover,
  isOpen,
  handleCategoryClick,
  onClose,
  incomeCat,
  expenseCat,
  getCategory,
}) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);
  const popoverRef = useRef(null);
  const [showAddCatogory, setShowAddCatogory] = useState(false);
  const [incomeExpenseBudgetsName, setIncomeExpenseBudgetsName] = useState("");

  const { uuid } = useParams();

  const excludedCategories = ["Uncategorised", "Transfer", "Ignore", "One off"];

  const filterCategories = (categories, excludeList, exclude = true) =>
    categories?.filter((category) =>
      exclude
        ? !excludeList.includes(category?.name)
        : excludeList.includes(category?.name)
    );

  const incomeCategories = filterCategories(incomeCat, excludedCategories);
  const expenseCategories = filterCategories(expenseCat, excludedCategories);
  const presetCategories = filterCategories(
    incomeCat,
    ["Ignore", "One off", "Transfer"],
    false
  );

  const handleAddCategory = (type) => {
    // setPopoverOpen((prevState) => ({
    //   ...prevState,
    // }));

    setIncomeExpenseBudgetsName(type);
    setShowAddCatogory(!showAddCatogory);
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_EXPENSE_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }

      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_INCOME_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }

      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };
  const addNewCategory = (data, type) => {
    const isIncomeNameExist = restrictedCategories
      ?.map((name) => name?.toLowerCase())
      ?.includes((data?.incomeName || data?.expenseName)?.toLowerCase());

    if (isIncomeNameExist) {
      toast.error("Category name is already used, please use another name.");
      return;
    } else {
      if (type === "Expense") {
        addNewExpenseCategory(data);
      } else {
        addNewIncomeCategory(data);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  return (
    <>
      <div
        className="modal-overlay"
        isOpen={isOpen}
        toggle={onClose}
        innerRef={popoverRef}
      >
        <motion.div
          className="modal-container"
          style={{
            maxWidth: "693px",
            padding: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5 }}
        >
          <div className="transaction-categories mt-2 ">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "15px",
              }}
            >
              <div className="bold popover-live" style={{ fontSize: "22px" }}>
                Select a category to edit
              </div>
              <button
                onClick={() => togglePopover()}
                className="close-edit-category-button"
                style={{ top: "0px", position: "static" }}
              >
                <CrossIcon />
              </button>
            </div>
            <div className="categories green-btn">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  marginBottom: "5px",
                }}
              >
                Your income categories
              </p>
              <div className="categories green-btn ">
                {incomeCategories &&
                  incomeCategories?.length > 0 &&
                  incomeCategories?.map((it) => (
                    <div
                      key={it?.userCategoryId}
                      className="button-blck"
                      onClick={() => handleCategoryClick(it)}
                      style={{ marginRight: "10px" }}
                    >
                      <div className={`icon-tag`}>
                        {it?.name !== "Uncategorised" && (
                          <div
                            className={`icon-transaction-img ${
                              isHexColor(it?.colour)
                                ? ""
                                : categoryBackgroundCss(it?.colour)
                            }`}
                            style={{
                              backgroundColor: categoryColorForAll(it?.colour),
                            }}
                          >
                            {it?.emoji}
                          </div>
                        )}
                        <div className="content">
                          <h6 className={`icon-content hello`}>{it?.name}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                <button
                  className="new-category-btn green"
                  onClick={() => handleAddCategory("income")}
                >
                  + New category
                </button>
              </div>
            </div>

            <div className="categories green-btn">
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  marginBottom: "5px",
                }}
              >
                Your expense categories
              </p>
              <div className="categories group-btn green-btn ">
                {expenseCategories &&
                  expenseCategories?.length > 0 &&
                  expenseCategories?.map((it) => (
                    <div
                      key={it?.userCategoryId}
                      className="button-blck"
                      onClick={() => handleCategoryClick(it)}
                      style={{ marginRight: "10px" }}
                    >
                      <div className={`icon-tag`}>
                        {it?.name !== "Uncategorised" && (
                          <div
                            className={`icon-transaction-img ${
                              isHexColor(it?.colour)
                                ? ""
                                : categoryBackgroundCss(it?.colour)
                            }`}
                            style={{
                              backgroundColor: categoryColorForAll(it?.colour),
                            }}
                          >
                            {it?.emoji}
                          </div>
                        )}
                        <div className="content">
                          <h6 className={`icon-content hello`}>{it?.name}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                <button
                  className="new-category-btn green"
                  onClick={() => handleAddCategory("expense")}
                >
                  + New category
                </button>
              </div>
            </div>

            <div className="categories">
              <div>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  Pre-set categories
                </p>
              </div>
              <div className="categories group-btn green-btn ">
                {presetCategories &&
                  presetCategories?.length > 0 &&
                  presetCategories?.map((it) => (
                    <div
                      key={it?.userCategoryId}
                      className="button-blck"
                      onClick={() => handleCategoryClick(it)}
                      style={{ marginRight: "10px" }}
                    >
                      <div className={`icon-tag`}>
                        {it?.name !== "Uncategorised" && (
                          <div
                            className={`icon-transaction-img ${
                              isHexColor(it?.colour)
                                ? ""
                                : categoryBackgroundCss(it?.colour)
                            }`}
                            style={{
                              backgroundColor: categoryColorForAll(it?.colour),
                            }}
                          >
                            {it?.emoji}
                          </div>
                        )}
                        <div className="content">
                          <h6 className={`icon-content hello`}>{it?.name}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      {showAddCatogory && (
        <AddCategoriesModal
          modalOpen={showAddCatogory}
          addNewCategory={addNewCategory}
          transactionType={
            incomeExpenseBudgetsName.charAt(0).toUpperCase() +
            incomeExpenseBudgetsName.slice(1)
          }
          // closeModal={handleAddCategory}
          closeModal={() => {
            setShowAddCatogory(!showAddCatogory);
          }}
        />
      )}
    </>
  );
};

export default SettingCategoryPopover;
