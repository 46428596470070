import React, { useState } from "react";
import Logo from "../../assests/images/icons/Black Logo 1.png";
import { isEmpty } from "lodash";
import NoDataFound from "../NoDataFound";
import CrossIcon from "../../assests/images/icons/cross-dark-icon.svg";
import { amountMinusWithSign } from "../../utilities/helper.jsx";

const ToggleSwitch = ({ value, onClick }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={value} onClick={onClick} />
      <span className={`switch-slider round`}></span>
    </label>
  );
};

const MyBalancePopOver = ({
  balanceData,
  setBalanceData,
  totalBalance,
  setTotalBalance,
  setActiveBalanceAccount,
  isOpen,
  onClose,
}) => {
  const currencyCode = localStorage.getItem("currencyCode");

  const activeAccount = (item) => {
    const updatedAccounts = balanceData?.map((acc) =>
      acc.accountUuid === item?.accountUuid
        ? { ...acc, isActive: !acc.isActive }
        : acc
    );

    const filterActiveAccounts = updatedAccounts?.filter(
      (account) => account.isActive === true
    );

    setBalanceData(updatedAccounts);
    setActiveBalanceAccount(filterActiveAccounts);

    localStorage.setItem("accounts", JSON.stringify(updatedAccounts));
  };

  return (
    <div className={`modal-background ${isOpen ? "open-modal" : ""}`}>
      <div
        className="modal-content"
        style={{
          borderRadius: "20px",
          padding: "20px",
          width: "65%",
          height: "550px",
        }}
      >
        <div className="d-flex justify-content-between flex-row">
          <div>
            <span className="bold black fs-25 fw-700 lh-24">
              Select your accounts
            </span>
            <p className="fs-12 fw-400 mb-0">
              Select which accounts you’d like added to your total balance
            </p>
          </div>
          <div className="ms-5 d-flex align-items-center">
            <button
              onClick={onClose}
              style={{
                height: "45px",
                width: "223px",
                padding: "9px 24px",
                boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
              }}
              className="border-0 text-white bg-teal-green py-2 fs-14 fw-500 lh-16 me-2 br-10"
            >
              Save changes
            </button>
            <button
              onClick={onClose}
              className="close-modal-button bg-transparent border-0 fs-24 fw-500"
            >
              <img src={CrossIcon} alt="Cross Icon" />
            </button>
          </div>
        </div>

        {/* Styled with CSS Grid to evenly distribute accounts */}
        <div
          className="row"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "12px",
            padding: "10px",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}
        >
          {balanceData && balanceData?.length > 0 ? (
            balanceData?.map((item, index) => (
              <div
                key={index}
                className="col-md-4"
                style={{
                  height: "65px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  width: "auto",
                }}
              >
                <div
                  className="p-2 w-100 bg-white d-flex align-items-center"
                  style={{
                    boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.15)",
                    height: "65px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="accordion-header w-100">
                    <div>
                      <img
                        className="img-fluid rounded-circle"
                        style={{
                          width: "40px",
                        }}
                        src={!isEmpty(item?.avatar) ? item?.avatar : Logo}
                        alt=""
                      />
                    </div>
                    <div className="account-text mx-2">
                      <div className="fs-16 fw-600 text-ellipsis">
                        {item?.accountName}
                      </div>
                      <div className="text-fresh-green fs-14 fw-700">
                        {currencyCode !== item?.currency ? (
                          <div className="d-flex align-items-center gap-1">
                            <div className="account-number">
                              <span
                                className={
                                  item?.value < 0
                                    ? "amount-red"
                                    : "amount-green"
                                }
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "700",
                                }}
                              >
                                {item?.accountType === "INVESTMENT" ||
                                item?.accountType === "WALLET" ||
                                item?.accountType === "FOREIGN"
                                  ? `${amountMinusWithSign(
                                      item?.convertedAmount,
                                      currencyCode
                                    )} ${currencyCode}`
                                  : amountMinusWithSign(
                                      item?.convertedAmount,
                                      currencyCode
                                    )}
                              </span>
                            </div>

                            <span
                              style={{
                                color: "#B8B8B8",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              className=""
                            >
                              |
                            </span>

                            <div
                              className="d-flex align-items-center"
                              style={{
                                color: "#B8B8B8",
                                fontSize: "14px",
                                fontWeight: "700",
                                float: "right",
                              }}
                            >
                              {amountMinusWithSign(item?.value, item?.currency)}{" "}
                              {item?.currency}
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className={`account-number mb-0`}>
                              <span
                                className={
                                  item?.value < 0
                                    ? "amount-red"
                                    : "amount-green"
                                }
                                style={{ fontSize: "14px", fontWeight: "700" }}
                              >
                                {item?.accountType === "INVESTMENT" ||
                                item?.accountType === "WALLET" ||
                                item?.accountType === "FOREIGN"
                                  ? `${amountMinusWithSign(
                                      item?.value,
                                      item?.currency
                                    )} ${item?.currency}`
                                  : amountMinusWithSign(
                                      item?.value,
                                      item?.currency
                                    )}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        transform: "scale(0.9)",
                        display: "inline-block",
                      }}
                    >
                      <ToggleSwitch
                        value={item?.isActive}
                        onClick={() => activeAccount(item)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyBalancePopOver;
