import React, { useCallback, useState } from "react";
import moment from "moment";
import AddCategoriesModal from "../../ModalForm/TransactionModals/AddCategoriesModal.jsx";
import Amount from "../../Components/Amount.jsx";
import { ReactComponent as Split } from "../../assests/images/split.svg";
import { ReactComponent as Check } from "../../assests/images/icons/check.svg";
import { ReactComponent as UnCheck } from "../../assests/images/icons/uncheck.svg";
import {
  categoryBackgroundCss,
  categoryColorForAll,
} from "../../utilities/Statics/index.jsx";
import PendingTransactionModel from "../../ModalForm/TransactionModals/PendingTransactionModel.jsx";
import EditCategoriesModal from "../../ModalForm/TransactionModals/EditCategoriesModal.jsx";

export const DashboardTransactionsListing = ({
  data,
  allDashboardDetails,
  modalOpen,
  expenseCategories,
  updateTransactionCategory,
  addNewCategory,
  editCategory,
  incomeCategories,
  oneOffCategory,
  isSelectionMode,
  setSelectionMode,
  setSelectedForBulkUpdate,
  selectedForBulkUpdate,
  updateCategoriesInBulk,
  isAccountModule = false,
  categoryTitle,
  categorySubTitle,
  deleteCategoryConfirm,
}) => {
  const [showAddCatogory, setShowAddCatogory] = useState(false);
  const [isIncomeCategoryChange, setIsIncomeCategoryChange] = useState(false);
  const [EditAddCatogory, setEditAddCatogory] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [showPendingTransaction, setShowPendingTransaction] = useState(false);
  const [transactionType, setTransactionType] = useState();
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  const togglePopover = (transactionId, amount) => {
    setIsIncomeCategoryChange(amount > 0);

    setTransactionType(amount < 0 ? "Expense" : "Income");
    setIsEdit(false);
  };

  const handleAddCategory = () => {
    setShowAddCatogory(!showAddCatogory);
  };

  const editCategoryOnTap = () => {
    setEditAddCatogory(!EditAddCatogory);
  };
  const handlePendingTransaction = () => {
    setShowPendingTransaction(!showPendingTransaction);
  };

  const handleLongPress = useCallback(() => {
    if (!isAccountModule) {
      setSelectionMode(true);
    }
  }, [setSelectionMode, isAccountModule]);

  const handleCardSelect = (id, amount) => {
    if (!isAccountModule) {
      const type = amount < 0 ? "Expense" : "Income";
      setSelectedForBulkUpdate((prevSelected) => {
        const newSelected = {
          ...prevSelected,
          [type]: [...prevSelected[type]],
        };
        if (newSelected[type].includes(id)) {
          newSelected[type] = newSelected[type].filter((item) => item !== id);
        } else {
          newSelected[type].push(id);
        }
        return newSelected;
      });
    }
  };

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      e.currentTarget.longPressTimer = setTimeout(handleLongPress, 500);
    }
  };

  const handleMouseUp = (e) => {
    if (e.currentTarget.longPressTimer) {
      clearTimeout(e.currentTarget.longPressTimer);
    }
  };

  return (
    <div onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} style={{ marginBottom: "1.5rem" }}>
      {allDashboardDetails?.data?.transactions?.thisMonth?.map(
        (transaction, index) => (
          <div className={`merchant-box mt-2`}>
            <div
              className="d-flex w-75"
              key={transaction.transactionUuid}
              onClick={() => {
                if (!isSelectionMode) {
                  modalOpen("viewTransaction", transaction);
                }
                if (transaction.status !== "Pending") {
                  handleCardSelect(
                    transaction.transactionUuid,
                    transaction.amount
                  );
                }
              }}
            >
              <div className="img-box">
                {isSelectionMode && transaction.status !== "Pending" ? (
                  <div>
                    {selectedForBulkUpdate[
                      transaction.amount < 0 ? "Expense" : "Income"
                    ].includes(transaction.transactionUuid) ? (
                      <Check />
                    ) : (
                      <UnCheck />
                    )}
                  </div>
                ) : (
                  <img
                    src={transaction.merchantLogo ?? transaction.accountAvatar}
                    alt=""
                    style={{ borderRadius: "50%" }}
                  />
                )}
              </div>
              <div className="ms-3">
                <div className="text-merchant bold black" style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '260px',
                  display: 'block',
                }}>
                  {transaction?.merchantName ?? transaction.transactionName}
                </div>
                <div
                  className="small-text-merchant"
                  style={{
                    fontWeight: "600",
                    fontSize: "14px",
                    fontStyle: "normal",
                  }}
                >
                  <Amount amount={transaction.amount} />
                  <span className="black ms-1">
                    <span className="black">|</span>
                  </span>
                  <h4 className="small-text-merchant space black">
                    <div
                      className="setting_text_color-2"
                      style={{
                        color: "#9B9B9B",
                        fontWeight: "500",
                        fontSize: "14px",
                        fontStyle: "normal",
                      }}
                    >
                      {moment(transaction.dateTime).format("DD/MM/YYYY")}
                    </div>
                  </h4>
                  {transaction.status === "Pending" && (
                    <div className="pending-item">P</div>
                  )}
                  {transaction.isSplit && (
                    <Split
                      style={{
                        height: "15px",
                        width: "15px",
                        marginTop: "2px",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="button-blck">
              <div
                className="icon-tag"
                style={{ marginBottom: "0px" }}
                id={`Popover-${transaction.transactionUuid}`}
                onClick={() =>
                  transaction.status === "Pending"
                    ? ""
                    : togglePopover(
                      transaction.transactionUuid,
                      transaction?.amount
                    )
                }
              >
                {transaction.masterCategoryName !== "Uncategorised" && (
                  <div
                    className={`icon-transaction-img ${isHexColor(transaction.masterCategoryColour)
                      ? ""
                      : categoryBackgroundCss(
                        transaction.masterCategoryColour
                      )
                      }`}
                    style={{
                      background:
                        transaction.status === "Pending"
                          ? "white"
                          : categoryColorForAll(
                            transaction.masterCategoryColour
                          ),
                    }}
                  >
                    {transaction.status === "Pending"
                      ? "⏳"
                      : transaction.masterCategoryEmoji}
                  </div>
                )}

                <div className="content">
                  <h6 className="icon-content">
                    {transaction.status === "Pending"
                      ? "Pending"
                      : transaction.masterCategoryName}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      {
        showAddCatogory && (
          <AddCategoriesModal
            modalOpen={showAddCatogory}
            addNewCategory={addNewCategory}
            transactionType={transactionType}
            closeModal={handleAddCategory}
          />
        )
      }
      {
        EditAddCatogory && (
          <EditCategoriesModal
            modalOpen={EditAddCatogory}
            editCategory={editCategory}
            transactionType={transactionType}
            closeModal={editCategoryOnTap}
            selectedCategory={selectedCategory}
            setEditAddCatogory={setEditAddCatogory}
            deleteCategoryConfirm={deleteCategoryConfirm}
          />
        )
      }
      {
        showPendingTransaction && (
          <PendingTransactionModel
            modalOpen={showPendingTransaction}
            closeModal={handlePendingTransaction}
          />
        )
      }
    </div >
  );
};
