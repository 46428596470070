import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as UnselectedCheck } from "../../../assests/images/icons/unselected-check.svg";
import "./help_us_screen.css";
import weburls from "../../../Weburls/weburls";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { ReactComponent as WordLogoBlack } from "../../../assests/images/WordLogoBlack.svg";
import { motion } from "framer-motion";

const HelpUsScreen = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const options = [
    { id: "save-emergency-fund", label: "Save an emergency fund 💰" },
    { id: "pay-off-debt-faster", label: "Pay off debt faster 💳" },
    {
      id: "save-house-or-other-purchase",
      label: "Save for a house or other big purchase 🏡",
    },
    {
      id: "save-for-holiday-or-big-event",
      label: "Save for a holiday or big life event ✈️",
    },
    {
      id: "start-investing-for-future",
      label: "Start investing & saving for my future 📈",
    },
    {
      id: "stop-living-paycheck",
      label: "Stop living paycheck to paycheck 💸",
    },
    {
      id: "master-budget-and-control-spending",
      label: "Master my budget & control spending ⚖️",
    },
  ];

  const getRanking = (id) => selectedOptions.indexOf(id) + 1;

  const handleOptionClick = (id) => {
    if (selectedOptions.includes(id)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== id));
    } else {
      const newSelectedOptions = [...selectedOptions, id];
      setSelectedOptions(newSelectedOptions);
    }
  };

  const handleSubmit = async () => {
    if (selectedOptions.length !== options.length) {
      toast.warning("Please select all options before proceeding.");
      return;
    }
    setLoading(true);
    try {
      const userUuid = localStorage.getItem("userUuid");
      let tokenUser = localStorage.getItem("budget-token");
      if (!userUuid) {
        throw new Error("User  UUID not found");
      }

      const helpUsPayload = {
        helpus: selectedOptions.map((id, index) => {
          const option = options.find((option) => option.id === id);
          return {
            no: index + 1,
            // details: option.label.replace(/\s*[^\w\s&]*$/, ""), // Remove emoji
            details: option.label,
          };
        }),
      };

      const helpUsResponse = await axios.post(
        `${weburls.Create_Help_Us_Help_You}${userUuid}`,
        helpUsPayload,
        {
          headers: {
            authorization: `Basic ${tokenUser}`,
          },
        }
      );

      if (helpUsResponse.data.code === 1) {
        const setupResponse = await axios.put(
          `${weburls.Set_Setup}${userUuid}`,
          {},
          {
            headers: {
              authorization: `Basic ${tokenUser}`,
            },
          }
        );

        navigate("/sign-up/connect-accounts");
      } else {
        throw new Error(
          helpUsResponse.data.message || "Failed to submit help us data"
        );
      }
      setLoading(false);
    } catch (error) {
      console.error("Error in submission:", error);
      toast.error("Failed to submit your preferences. Please try again.");
    }
  };

  return (
    <>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <motion.div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.5 }}
            >
              <div className="logoCss text-center mb-4">
                <WordLogoBlack />
              </div>
              <div
                className="loginFormWrp"
                style={{
                  borderRadius: "20px",
                  padding: "20px 30px 20px 30px",
                  width: "450px",
                }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 0.5 }}
              >
                <div className="transaction-categories mt-2">
                  <p
                    className="fs-26 fw-700 text-center"
                    style={{
                      marginBottom: "10px",
                      color: "#240F51",
                    }}
                  >
                    {"Help us, help you 🙌🏻"}
                  </p>
                  <p
                    className="fs-14 fw-400 text-center"
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    {
                      "Rank your financial priorities from 1-7 below and we'll help you achieve them. "
                    }
                  </p>
                  <div
                    className="feedback-options"
                    style={{
                      maxHeight: "600px",
                      overflow: "auto",
                    }}
                  >
                    {options.map((option) => (
                      <div
                        key={option.id}
                        className="feedback-option"
                        onClick={() => handleOptionClick(option.id)}
                        style={{
                          border: `2px solid ${
                            selectedOptions.includes(option.id)
                              ? "#14B8A6"
                              : "#E4E4E7"
                          }`,
                          cursor: "pointer",
                        }}
                      >
                        <p
                          className="fs-14 fw-500"
                          style={{
                            margin: 0,
                            color: "#18181B",
                          }}
                        >
                          {option.label}
                        </p>
                        {selectedOptions.includes(option.id) ? (
                          <span
                            className="d-flex justify-content-center align-items-center fw-600 fs-12"
                            style={{
                              color: "white",
                              background: "#14B8A6",
                              borderRadius: "10px",
                              width: "20px",
                              height: "20px",
                              paddingTop: "1px",
                            }}
                          >
                            {getRanking(option.id)}
                          </span>
                        ) : (
                          <UnselectedCheck />
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="modal-popup-btn-wrp">
                    <button
                      className={`cursor-pointer change-password-btn`}
                      type="button"
                      onClick={handleSubmit}
                      style={{
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      {loading ? (
                        <ClipLoader size={15} color={"#ffffff"} />
                      ) : (
                        "Connect your accounts"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HelpUsScreen;
