import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ReactComponent as Success } from "../../assests/images/icons/passwordSucess.svg";
import HttpClient from "../../client/HttpClient";
import { API_ENDPOINTS } from "../../client/ApiEndpoints";

const SettingPromoCode = (props) => {
  const { modalOpen, onClose, fetchedPlanData } = props;
  const [promoApplied, setPromoApplied] = useState(false);
  const [promoValue, setPromoValue] = useState("");
  const [plan, setPlan] = useState();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const applyPromoCode = async () => {
    const payload = {
      email: userData.username,
      promoCode: promoValue,
      isRegister: false,
    };

    const resp = await HttpClient.post(API_ENDPOINTS.APPLY_PROMO_CODE, payload);

    if (resp?.data?.code === 1) {
      setPlan(resp?.data?.planMessage);
      setPromoApplied(true);
      fetchedPlanData();
    }
    if (Number(resp?.data?.code) === 0) {
      toast.error(`${resp?.data?.message}`);
    }
  };

  return (
    <div
      className={`modal-background ${modalOpen ? "open-modal" : ""} `}
      onClick={onClose}
    >
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />

      <div
        className="modal-content delete-modal-width"
        onClick={(e) => e.stopPropagation()}
      >
        {promoApplied ? (
          <div>
            <div style={{ textAlign: "center" }}>
              <Success />
            </div>
            <p
              className="bold popover-live mb-0"
              style={{
                textAlign: "center",
                color: "#240F51",
              }}
            >
              <span className="fs-24 fw-500 text-deep-violet">
                Promo code applied
              </span>
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {plan} 🎉
            </p>
            <div className="modal-popup-btn-wrp">
              <button
                className="cursor-pointer"
                onClick={() => onClose()}
                type="button"
                style={{
                  marginTop: "20px",
                  boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
                  color: "#fff",
                  background: "rgba(70, 155, 136, 1)",
                  width: "295px",
                  height: "45px",
                  padding: "9px 24px",
                  borderRadius: "10px",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                Back to settings
              </button>
            </div>
          </div>
        ) : (
          <div className="">
            <p
              style={{
                fontSize: "30px",
                marginBottom: "20px",
                fontWeight: "700",
                textAlign: "center",
                color: "#240F51",
              }}
            >
              Have a promo code?
            </p>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "20px",
                fontWeight: "400",
                textAlign: "center",
                color: "#292B2D",
              }}
            >
              Have a promo code to use? Well you’re in luck! Get access to all
              the features we have to offer for free, for an extended period of
              time.
            </p>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "20px",
                fontWeight: "400",
                textAlign: "center",
                color: "#292B2D",
              }}
            >
              Simply enter your promo code below and we’ll take care of
              everything for you.
            </p>
            <div className="fs-18 fw-500" style={{ color: "#040C22" }}>
              Promo code
            </div>
            <div className="form-outline">
              <div
                className="welcome-form-input-box"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  className="input-text-box-margin"
                  style={{
                    paddingTop: "13px",
                    paddingBottom: "13px",
                    width: "100%",
                  }}
                >
                  <input
                    value={promoValue}
                    onChange={(e) => setPromoValue(e.target.value)}
                    type="text"
                    id="form3Example3"
                    className="register-focus"
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "none",
                      outline: "none",
                      width: "100%",
                      fontFamily: "Neurialgrotesk",
                    }}
                  />
                </div>
              </div>
              <p className="mt-1 error-message">
                {/* {errors.email?.message} */}
              </p>
            </div>
            <div className="modal-popup-btn-wrp">
              <button
                type="button"
                onClick={applyPromoCode}
                style={{
                  marginTop: "20px",
                  boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.1)",
                  color: "#fff",
                  background: "rgba(70, 155, 136, 1)",
                  width: "272px",
                  height: "45px",
                  padding: "9px 24px",
                  borderRadius: "10px",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Confirm promo code
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingPromoCode;
