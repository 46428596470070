import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Popover, PopoverBody } from "reactstrap";

const CustomDateRangePopup = ({
  isOpen,
  onClose,
  targetId,
  title,
  options,
  selectedOption,
  handleOptionClick,
  setSelectedDateRange,
}) => {
  const [isCustomDateOpen, setIsCustomDateOpen] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // Handle option selection
  const handleCustomDateClick = (option) => {
    if (option === "Custom date range") {
      setIsCustomDateOpen(true);
    } else {
      handleOptionClick(option);
    }
  };

  // Handle date selection from date picker
  const handleDateSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  // Apply selected date range
  const handleApply = () => {
    setSelectedDateRange(dateRange);
    handleOptionClick("Custom date range");
    setIsCustomDateOpen(false);
  };

  return (
    <>
      {isCustomDateOpen ? (
        <div className="modal-background open-modal">
          <div className="modal-content-calendar">
            <DateRange
              ranges={dateRange}
              onChange={handleDateSelect}
              moveRangeOnFirstSelection={false}
              retainEndDateOnFirstSelection={false}
              rangeColors={["#469B88"]}
              showPreview={false}
              showDateDisplay={false}
              className="custom-calendar-size"
            />
            <div className="button-container">
              <button
                className="text-button"
                onClick={() => setIsCustomDateOpen(false)}
              >
                Cancel
              </button>
              <button className="text-button" onClick={handleApply}>
                Ok
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Popover
          placement="bottom-end"
          isOpen={isOpen}
          target={targetId}
          toggle={onClose}
          className="custom-popover-w-30"
        >
          <PopoverBody>
            <div className="d-flex justify-content-between align-items-center flex-row">
              <div className="">
                <span className="bold black popup-top-heading">{title}</span>
              </div>
              <button onClick={onClose} className="close-modal-button">
                <i className="fa fa-times"></i>
              </button>
            </div>

            <div className="group-backgroud-color">
              <div
                className="bold black mb-3"
                style={{ float: "left", fontSize: "14px" }}
              >
                Periods
              </div>
              <div
                className="group-btn green-btn popup-padding-zero"
                style={{ float: "left", paddingBottom: "25px", width: "100%" }}
              >
                {options?.length > 0 &&
                  options?.map((option) => (
                    <div key={option} className="button-blck">
                      <span
                        className={`group-button pointer-cursor `}
                        onClick={() => handleCustomDateClick(option)}
                        style={{
                          backgroundColor:
                            selectedOption === option ? "#469B88" : "",
                          color: selectedOption === option ? "white" : "black",
                          border: "1px solid #E1E1E1",
                          margin: "4px 8px 4px 0px",
                        }}
                      >
                        {option}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};

export default CustomDateRangePopup;
