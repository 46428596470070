import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  lightBackgroundColor,
  progressbarColor,
  getCustomCodeToName,
} from "../../utilities/Statics/index.jsx";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as OverBudget } from "../../assests/images/over_budget.svg";
import { formatNumberWithCommas, monthNames } from "../../utilities/helper.jsx";
import PopupModalMonths from "../../ModalForm/Popup/PopupModelMonthsOnly.jsx";

const getPast12Months = () => {
  const monthsList = [];
  const currentDate = new Date();

  for (let i = 0; i < 13; i++) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    );
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    monthsList.push(`${month}/${year}`);
  }

  return monthsList.reverse();
};

const past12Months = getPast12Months();

const FormattedAmount = ({ amount }) => {
  const isNegative = amount < 0;
  const absoluteAmount = Math.abs(amount);
  const formattedAmount = formatNumberWithCommas(absoluteAmount);
  return isNegative ? `-$${formattedAmount}` : `$${formattedAmount}`;
};

const SpentBudget = (prop) => {
  const { allDashboardDetails } = prop;

  const { uuid } = useParams();
  const navigate = useNavigate();

  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("en-US", { month: "long" });
  const currentYear = currentDate.getFullYear(); // Current year

  const [selectSpentOption, setSelectSpentOption] = useState(
    `${currentMonth}/${currentYear}`
  );
  const [spendBudgetDummy, setSpendBudgetDummy] = useState([]);
  const [spentPopOver, setSpentsPopOver] = useState(false);

  const handleIncomeClick = (option) => {
    setSelectSpentOption(option);
    setSpentsPopOver(false);
  };

  const handleCardClick = (budgetId, fetchBudget) => {
    const [monthName, getYear] = selectSpentOption.split("/");
    const month = monthNames[monthName];
    const year = parseInt(getYear, 10);
    const completeBudgetData =
      allDashboardDetails?.spendVsBudget?.monthlyData ?? [];

    navigate(`/budget/${uuid}/budgetdetails/expense/${budgetId}`, {
      state: {
        year,
        month,
        isCompactView: "false",
        applyToAllFutureMonths: localStorage.getItem("isAllFutureMonth") || "allMonth",
        fetchBudget,
        completeBudgetData,
      },
    });
  };

  useEffect(() => {
    const spendVsBudget = allDashboardDetails?.spendVsBudget?.monthlyData ?? [];
    if (selectSpentOption) {
      const [monthName, year] = selectSpentOption.split("/");
      const month = monthNames[monthName];
      const numericYear = parseInt(year, 10);

      const filteredData = spendVsBudget.filter(
        (budget) => budget.month === month && budget.year === numericYear
      );

      setSpendBudgetDummy(filteredData);
    }
  }, [selectSpentOption, allDashboardDetails]);

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center mb-1 mt-1"
        style={{ width: "100%" }}
      >
        <h4 className="fs-18 fw-500 lh-34 text-charcoal mb-0">
          Spend vs budget
        </h4>
        <span
          className="cursor-pointer d-flex justify-content-center align-items-center bg-teal-green white-text br-30 fs-15 fw-400 lh-19"
          style={{
            // width: "100px",
            padding: "2px 9px",
            height: "25px",
            borderRadius: "50px",
            cursor: "pointer",
          }}
          id="SpentPopover"
          onClick={() => setSpentsPopOver(!spentPopOver)}
          aria-haspopup="true"
          aria-expanded={spentPopOver}
        >
          {selectSpentOption.replace(/\//g, " ")}
        </span>
        {spentPopOver && (
          <PopupModalMonths
            isOpen={spentPopOver}
            onClose={() => setSpentsPopOver(!spentPopOver)}
            targetId="SpentPopover"
            title="Date range"
            options={past12Months ?? []}
            selectedOption={selectSpentOption}
            handleOptionClick={handleIncomeClick}
          />
        )}
      </div>

      <div
        className="row-cols-lg-4"
        style={{
          gap: "18px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          marginBottom: "1rem",
        }}
      >
        {spendBudgetDummy?.length > 0 ? (
          (spendBudgetDummy ?? [])?.map((item) => {
            let remainingAmount =
              item?.adjusted_expense_amount - item?.total_transaction_amount;

            // Calculate dynamic progress
            let progress = 0;
            if (item?.adjusted_expense_amount > 0) {
              progress =
                (item?.total_transaction_amount /
                  item?.adjusted_expense_amount) *
                100;
            }
            progress = progress > 100 ? 100 : progress; // Cap progress at 100%

            const categoryColor = getCustomCodeToName(item.colour);
            const progressBarColor = progressbarColor(categoryColor);
            const backgroundColor = lightBackgroundColor(categoryColor);

            return (
              <div
                key={item?.id}
                className="col-md-3"
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              >
                <div
                  onClick={() =>
                    handleCardClick(
                      item?.expense_uuid || item?.income_uuid,
                      item
                    )
                  }
                  className="budget-item br-10 bg-white"
                  style={{ padding: "6px 12px", cursor: "pointer" }}
                >
                  <div className="budget-label d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-row align-items-center">
                      <div>
                        <h6 className="fs-16 fw-400 lh-24 text-medium-gray mb-0">
                          {item?.expense_name}
                        </h6>
                        <h4 className="fs-18 fw-700 lh-25 text-dark-gray">
                          <FormattedAmount
                            amount={item?.total_transaction_amount}
                          />
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="budget-remaining d-flex justify-content-end fs-12 fw-400">
                    {remainingAmount < 0 ? (
                      <>
                        <FormattedAmount amount={Math.abs(remainingAmount)} />{" "}
                        over budget
                        <OverBudget
                          style={{
                            marginLeft: "2px",
                            marginBottom: "0px",
                          }}
                        />
                      </>
                    ) : (
                      <span className="fs-12 fw-400 lh-20 text-dark-gray">
                        <FormattedAmount amount={remainingAmount} /> left
                      </span>
                    )}
                  </div>
                  <div
                    className="budget-progress-container w-100 position-relative"
                    style={{
                      backgroundColor: backgroundColor,
                      marginTop: "5px",
                    }}
                  >
                    <motion.div
                      className="budget-progress"
                      style={{
                        width: `${progress}%`,
                        backgroundColor: progressBarColor,
                      }}
                      initial={{ width: 0 }}
                      animate={{ width: `${progress}%` }}
                      transition={{ duration: 0.5 }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></motion.div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="selected-account text-center">
            <h4 className="fs-18 fw-600 text-dark">
              No budgets set up yet{" "}
              <span role="img" label="thinking">
                🤔
              </span>
            </h4>
            <p className="fs-14 text-dark-gray">
              Set up some budgets to start tracking your spending.
            </p>
            <button
              className="btn btn-primary cursor-pointer"
              style={{
                backgroundColor: "#6A36FF", // Match purple color
                color: "white",
                padding: "10px 20px",
                borderRadius: "10px",
                fontSize: "14px",
                fontWeight: "500",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/budget/${uuid}`)} // Handle navigation
            >
              Set up a budget
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SpentBudget;
