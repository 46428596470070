import React, { useState } from "react";
import SettingPopUp from "../Innerpanel/SettingPopUp/SettingPopUp";
import SettingIcon from "../../assests/images/setting.svg";

const ViewSetting = () => {
  const [showSettingModal, setShowSettingModal] = useState(false);

  return (
    <>
      <button
        style={{
          border: "1.5px solid #C8C8C8",
          padding: "8px",
          gap: "10px",
        }}
        type="button"
        className="btn rounded-pill d-flex align-items-center text-gray-color fs-15 fw-500 lh-20 shadow-none"
        onClick={() => {
          setShowSettingModal(true);
        }}
      >
        <img
          style={{ width: "20px", height: "20px" }}
          src={SettingIcon}
          alt="setting icon"
        />
      </button>

      {showSettingModal && (
        <SettingPopUp
          onClose={() => {
            setShowSettingModal(false);
          }}
        />
      )}
    </>
  );
};

export default ViewSetting;
