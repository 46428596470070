import React, { useEffect, useState } from "react";
import AddCategoriesModal from "../../../../ModalForm/TransactionModals/AddCategoriesModal";
import IncomeExpenseCategories from "./IncomeExpenseCategories";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { categoryColorForAll } from "../../../../utilities/Statics";
import { API_ENDPOINTS } from "../../../../client/ApiEndpoints";
import HttpClient from "../../../../client/HttpClient";

const EditDetails = ({
  amount,
  handleAmountChange,
  selectedFrequency,
  incomeExpenseBudgetsData,
  incomeExpenseBudgetsName,
  showCategory,
  expenseOrIncome,
  mainHead,
  setIncomeApi,
  setExpenseApi,
  autoExpense,
  autoSingleBudgetExpenses,
  handleNameChange,
  editName,
  setType,
  setChosenEmoji,
  setSelectedColorOption,
  setMainSelectedCategory,
  mainSelectedCategory,
  EditDetails,
  isGrouped,
  setIsGrouped,
}) => {
  const { uuid } = useParams();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [amountLoader, setAmountLoader] = useState(false);
  const [showAddCatogory, setShowAddCatogory] = useState(false);
  const [categoryData, setCategoryData] = useState(null);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleCategoryClick = async (category) => {
    setMainSelectedCategory(category);
    setIncomeApi({
      incomeName: category?.categoryName,
      user_category_id: category?.categoryId,
      isDefaultCategory: category?.defaultCategory || "",
    });
    setExpenseApi({
      expenseName: category?.categoryName,
      user_category_id: category?.categoryId,
      isDefaultCategory: category?.defaultCategory,
    });
    togglePopover();
    if (autoExpense && category?.defaultCategory === true) {
      setAmountLoader(true);
      await autoSingleBudgetExpenses(category?.categoryId, false);
      setAmountLoader(false);
    }
  };

  const handleAddCategory = () => {
    // setPopoverOpen((prevState) => ({
    //   ...prevState,
    // }));

    setShowAddCatogory(!showAddCatogory);
    setPopoverOpen({});
    togglePopover();
  };

  const defaultCategoriesData =
    incomeExpenseBudgetsName === "expense"
      ? {
          expense: [...(categoryData?.expense || [])],
          yourExpense: [...(categoryData?.yourExpense || [])],
        }
      : { income: categoryData?.income || [] };

  const yourCreatedCategoriesData =
    incomeExpenseBudgetsName === "expense"
      ? {
          customCategory: [...(categoryData?.customCategory || [])],
          yourCustomCategory: [...(categoryData?.yourCustomCategory || [])],
        }
      : { customCategory: categoryData?.customCategory || [] };

  // API Calls starts
  const incomeExpenseCategory = async () => {
    try {
      const url =
        incomeExpenseBudgetsName === "expense"
          ? `${API_ENDPOINTS.GET_EXPENSE_REMAINING_BY_UUID}${uuid}`
          : `${API_ENDPOINTS.GET_INCOME_REMAINING_BY_UUID}${uuid}`;

      const response = await HttpClient.get(url);

      if (response.status === 200) {
        if (response?.data?.code === 1) {
          setCategoryData(response?.data);
          // toast.success(response?.data?.message);
        } else if (response?.data?.code === 0) {
          toast.error(response?.data?.message);
        } else {
          toast.warn("Unexpected response from server");
        }
      } else {
        toast.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("Something went wrong while fetching data");
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_EXPENSE_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      incomeExpenseCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await HttpClient.post(
        `${API_ENDPOINTS.ADD_INCOME_CATEGORY}${uuid}`,
        data
      );
      if (response?.data?.code === 1) {
        toast.success(`Category created`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      incomeExpenseCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  // API Calls Ends
  useEffect(() => {
    incomeExpenseCategory();
  }, [incomeExpenseBudgetsName]);

  return (
    <>
      <div className="modal-input-group" style={{ marginBottom: "0px" }}>
        <h2
          style={{ fontSize: "20px", fontWeight: "500", marginBottom: "15px" }}
        >
          {EditDetails ? "Edit details" : "Enter details"}
        </h2>
      </div>
      <div className="modal-section-card">
        {showCategory ? (
          <div>
            <div className="modal-input-group">
              <label>Category</label>

              {mainSelectedCategory ? (
                <div
                  className="icon-tag"
                  id="popUpoverTarget"
                  onClick={togglePopover}
                  aria-haspopup="true"
                  style={{
                    marginBottom: "0px",
                  }}
                >
                  <div
                    className="icon-transaction-img"
                    style={{
                      backgroundColor: categoryColorForAll(
                        mainSelectedCategory?.categoryColour
                      ),
                    }}
                  >
                    {mainSelectedCategory?.categoryEmoji}
                  </div>
                  <div className="content">
                    <h6 className="icon-content">
                      {mainSelectedCategory?.categoryName}
                    </h6>
                  </div>
                </div>
              ) : (
                <span
                  aria-haspopup="true"
                  id="popUpoverTarget"
                  onClick={togglePopover}
                  style={{ borderRadius: "360px" }}
                >
                  Select or create a category
                </span>
              )}
            </div>

            <IncomeExpenseCategories
              togglePopover={togglePopover}
              isOpen={popoverOpen}
              onclose={togglePopover}
              mainHead={mainHead}
              handleCategoryClick={handleCategoryClick}
              defaultCategory={defaultCategoriesData}
              yourCreatedCategory={yourCreatedCategoriesData}
              handleChange={handleAddCategory}
              targetId="popUpoverTarget"
              isHowOftenCustom={selectedFrequency}
              expenseOrIncome={expenseOrIncome}
              setType={setType}
              setChosenEmoji={setChosenEmoji}
              setSelectedColorOption={setSelectedColorOption}
            />

            {showAddCatogory && (
              <AddCategoriesModal
                modalOpen={showAddCatogory}
                addNewCategory={addNewCategory}
                transactionType={
                  incomeExpenseBudgetsName.charAt(0).toUpperCase() +
                  incomeExpenseBudgetsName.slice(1)
                }
                closeModal={handleAddCategory}
              />
            )}
          </div>
        ) : expenseOrIncome === "oneoff" ? (
          <div className="modal-input-group">
            <label>Name</label>
            <input
              className="toggle-button"
              type="text"
              style={{
                height: "35px",
                width: "246px",
                textAlign: "center",
                border: "none",
                outline: "none",
                background: "#EFEDED",
                borderRadius: "10px",
              }}
              value={editName}
              onChange={handleNameChange}
            />
          </div>
        ) : (
          <>
            {/* <div className="modal-input-group">
              <label>Is this a group budget?</label>
              <button
                className="toggle-button"
                type="button"
                style={{
                  borderRadius: "30px",
                  background: isGrouped ? "#479985" : "#E0533D",
                  color: "white",
                  width: "94px",
                  height: "31px",
                  padding: "0px",
                }}
                onClick={() => setIsGrouped(!isGrouped)}
              >
                {isGrouped ? "Yes" : "No"}
              </button>
            </div> */}
            <div className="modal-input-group">
              <label>Category</label>
              <div className="button-blck">
                <div className="icon-tag">
                  <div
                    className="icon-transaction-img"
                    style={{
                      backgroundColor: categoryColorForAll(
                        incomeExpenseBudgetsData?.category_colour
                      ),
                    }}
                  >
                    {incomeExpenseBudgetsData?.category_emoji}
                  </div>
                  <div className="content">
                    <h6 className="icon-content hello">
                      {incomeExpenseBudgetsData?.category_name}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className="modal-input-group"
          style={{
            marginBottom: "0px",
          }}
        >
          <label>Amount</label>
          {amountLoader == true ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              <div
                className="spinner-border text-light-gray mt-1"
                role="status"
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight:"24px"
                }}
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <input
              className="toggle-button"
              type="text"
              style={{
                height: "35px",
                width: "175px",
                textAlign: "center",
                border: "none",
                outline: "none",
                background: "#EFEDED",
                borderRadius: "10px",
              }}
              value={amount}
              onChange={handleAmountChange}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditDetails;
