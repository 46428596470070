import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  parseGoalData,
  parseYourAccountBalancesData,
} from "../../../utilities/helper";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "../../../client/ApiEndpoints";
import HttpClient from "../../../client/HttpClient";

const useGoalDetails = () => {
  const [active, setActive] = useState(false);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const { uuid, goalUuid } = useParams();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [loading, setLoading] = useState(true);
  const [accountData, setAccountData] = useState([]);
  const [isLinkedToAccount, setIsLinkedToAccount] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [isUpdadting, setIsUpdating] = useState(false);

  const timeoutRef = useRef(null);

  const navigate = useNavigate();
  const fetchGoalDetails = useCallback(async () => {
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_GOALS_BY_ID}${uuid}/${goalUuid}`
      );
      if (response?.data) {
        const parsedData = parseGoalData(response?.data?.goal);
        setGoals(parsedData);
        setIsLinkedToAccount(parsedData.isConnected);
        setSelectedAccounts(parsedData.accounts);
        setActive(true);
        setLoading(false);
        setChosenEmoji(parsedData.icon);
      }
    } catch (error) {
      console.error("error", error);
      setLoading(false);

      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [goalUuid]);

  const fetchCashAccount = useCallback(async () => {
    setLoading(true);
    try {
      const response = await HttpClient.get(
        `${API_ENDPOINTS.GET_CASH_ACCOUNT}${uuid}`
      );
      if (response?.data) {
        const parsedData = parseYourAccountBalancesData(
          response?.data?.accounts
        );
        setAccountData(parsedData);
      }
      setLoading(false);
    } catch (error) {
      console.error("error", error);

      toast.error("Something went wrong please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchGoalDetails();
    fetchCashAccount();
  }, [fetchGoalDetails]);

  const handleEditGoal = (goal) => {
    setSelectedGoal(goal);
    setActive(true);
  };

  const updateGoal = async (goals) => {
    const data = { ...goals };

    try {
      const response = await HttpClient.put(
        `${API_ENDPOINTS.UPDATE_GOALS}${uuid}`,
        data
      );

      console.log(response, "response");

      toast.success("Goal successfully updated.", {
        position: "top-right",
        autoClose: 1000,
      });
      // navigate(`/goals/${uuid}`);
    } catch (error) {
      toast.error("Something went wrong while updating goal.");
    }
  };

  const handleDeleteGoal = async () => {
    setLoading(true);
    try {
      await HttpClient.delete(
        `${API_ENDPOINTS.DELETE_GOALS}${uuid}/${goalUuid}`
      );
      fetchGoalDetails();
      // toast.success("Goal successfully Deleted...", {
      //   position: "top-right",
      //   autoClose: 1000,
      // });
      setLoading(false);
      setSelectedGoal(null);
      setActive(false);
      fetchGoalDetails();
      navigate(`/goals/${uuid}`);
    } catch (error) {
      toast.error("Goal details not update. Please try again.");
    }
  };

  const handleCancelEdit = () => {
    setSelectedGoal(null);
    setActive(false);
  };

  const handleSaveEdit = async (editedGoal) => {
    setIsUpdating(true);
    if (!isLinkedToAccount) {
      editedGoal.accounts = [];
      editedGoal.isConnected = false;
    }

    try {
      const response = await HttpClient.put(
        `${API_ENDPOINTS.UPDATE_GOALS}${uuid}`,
        editedGoal
      );
      if (response?.data.code === 1) {
        // toast.success("Goal successfully updated.", {
        //   position: "top-right",
        //   autoClose: 2000,
        // });
        setSelectedGoal(null);
        // setActive(false);
        // navigate(`/goals/${uuid}`);
      } else if (response.status === 500) {
        toast.error("Goal details not update. Please try again.");
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setIsUpdating(false);
        fetchGoalDetails();
      }, 1000);
    }
  };

  const handleToggleSwitch = (data) => {
    const { accountId } = data;
    setSelectedAccounts((prevAccounts) => {
      const newAccounts = prevAccounts.includes(accountId)
        ? prevAccounts.filter((id) => id !== accountId)
        : [...prevAccounts, accountId];

      const currentBalance = accountData
        .filter((acc) => newAccounts.includes(acc.accountId))
        .reduce((total, acc) => total + acc.value, 0);
      setGoals((prevGoalData) => ({
        ...prevGoalData,
        accounts: newAccounts,
        isConnected: true,
        currentBalance: currentBalance,
      }));
      return newAccounts;
    });
  };

  return {
    isPopupOpenInfo,
    togglePopupInfo,
    active,
    goals,
    handleCancelEdit,
    handleSaveEdit,
    handleDeleteGoal,
    isLinkedToAccount,
    setIsLinkedToAccount,
    setShowPicker,
    showPicker,
    chosenEmoji,
    updateGoal,
    setShowConfirmationModal,
    showConfirmationModal,
    selectedAccounts,
    setChosenEmoji,
    accountData,
    handleToggleSwitch,
    loading,
    isUpdadting,
  };
};

export default useGoalDetails;
