import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  preferredView: "compact",
  preferredPeriod: "monthly",
  isAllFutureMonth: 'allMonth',
};

export const counterSlice = createSlice({
  name: "popupsetting",
  initialState,
  reducers: {
    userPreferredViewSetting: (state, action) => {
      const { payload } = action;

      localStorage.setItem("budget_view", payload);
      state.preferredView = payload;
    },

    getUserPreferredView: (state, action) => {
      const preferredViewLocal = localStorage.getItem("budget_view") || "compact";
      state.preferredView = preferredViewLocal;
    },

    userPreferredViewPeriodSetting: (state, action) => {
      const { payload } = action;
      localStorage.setItem("preferred_period", payload);
      state.preferredPeriod = payload;
    },

    getUserPreferredViewPeriod: (state, action) => {
      const preferredPeriodLocal = localStorage.getItem("preferred_period");
      state.preferredPeriod = preferredPeriodLocal;
    },

    userAllMonthDataSetting: (state, action) => {
      const { payload } = action;
      localStorage.setItem("isAllFutureMonth", payload);
      state.isAllFutureMonth = payload;
    },

    getAllMonthDataSetting: (state, action) => {
      const preferredPeriodLocal = localStorage.getItem("isAllFutureMonth") || "allMonth";
      state.isAllFutureMonth = preferredPeriodLocal;
    },
  },
});

export const {
  userPreferredViewSetting,
  getUserPreferredView,
  userPreferredViewPeriodSetting,
  userAllMonthDataSetting,
  getAllMonthDataSetting,
} = counterSlice.actions;

export default counterSlice.reducer;
